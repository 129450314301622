/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenClickToCall } from "../typings/api/neogen-click-to-call";
import { NeogenClickToCallLog } from "../typings/api/neogen-click-to-call-log";
import APIService from "./API.service";


class ClickToCallLogService extends APIService<NeogenClickToCallLog> {
    constructor() {
        super("neogen-click-to-call-logs");
    }
    async getAllByUUID(uuid: string):Promise<void|AxiosResponse<NeogenClickToCallLog[]>> {
        // if ( await AuthService.canIAccess("SEE_ALL_CLICK_TO_CALLS")) {
        let filter = {
            where: {
                uuid:uuid
            }
        };
        return this.getURL("/neogen-click-to-call-logs?filter="+encodeURIComponent(JSON.stringify(filter)));
        // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
        // if (response && Array.isArray(response.data)) {
        // return response.data[0];
        // } else {
        // console.error(response,response);
        // return;
        // }
        // return response[0];
        // } else {
        //     let filter = {
        //         where: {
        //             uuid:uuid
        //         }
        //     };
        //     const response = await this.getURL("/my-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
        //     // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
        //     if (response && Array.isArray(response.data)) {
        //         return response.data[0];
        //     } else {
        //         console.error(response,response);
        //         return;
        //     }
        // }
    }
}

export default new ClickToCallLogService();