
import { AxiosResponse } from "axios";
import { NeogenTransferDestinationHours } from "../typings/api/neogen-transfer-destination-hours";
import APIService from "./API.service";

class TransferDestinationHoursService extends APIService<NeogenTransferDestinationHours> {
    constructor() {
        super("neogen-transfer-destination-hours");
    }
    getByDestination(destinationId: number):Promise<void|AxiosResponse<NeogenTransferDestinationHours[]>> {
        const filter = {
            where: {
                destinationId: destinationId
            }
        };
        return this.getURL(this.endpoint+"?filter="+encodeURIComponent(JSON.stringify(filter)));
    }
}

export default new TransferDestinationHoursService();