import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import clickToCallsService from "../../services/click-to-calls.service";
import scriptService from "../../services/script.service";
import { NeogenScript } from "../../typings/api";
import ButtonNeoGen from "../layout/button-neogen";
import PageDescription from "../layout/page-description";
import PrintPre from "../layout/print-pre";
import Loader2 from "../utilities/Loader2";
import DeleteEntry from "./modals/delete-entry";
import { v4 as uuid } from "uuid";
import clickToCallLogService from "../../services/click-to-call-log.service";
import { NeogenClickToCallLog } from "../../typings/api/neogen-click-to-call-log";
import UserContext from "../../services/user-context";

const people = [
    { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    // More people...
];
interface IDictionary {
    [index: string]: NeogenClickToCallLog[];
}
export default function ClickToCallSelector() {
    const [user,setUser]  = useContext(UserContext);
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>({});
    const [logs,setLogs] = useState<IDictionary>({});
    
    const clickToCallsQuery = useQuery(["ClickToCalls"], async () => {
        const result = await clickToCallsService.getAll();
        if (result) {
            // console.error(result.data);
            return result.data;
        }
    });

    const clickToCallLogsQuery = useQuery(["ClickToCallLogs"], async () => {
        const result = await clickToCallLogService.getAll();
        const entries:IDictionary = {};
        if (result) {
            result.data.forEach(e => {
                if (!Array.isArray(entries[e.uuid])) {
                    entries[e.uuid] = [];
                }
                entries[e.uuid].push(e);
            });
            setLogs(entries);
            return result.data;
        }
    });

    const scriptQuery = useQuery(["scripts"], async () => {
        const result = await scriptService.getAll();
        if (result) {
            return result.data;
        }
    });

    async function addEntry() {
        const result = await clickToCallsService.create({buttonText:"Call Me Now", uuid: uuid(), owner: user.company??-1});
        if (result) {
            navigate("/click-to-call/"+result.data.id);
        }
        // alert(JSON.stringify(result));
    }

    function deleteEntry(entry: any) {
        setItemToDelete(entry);
        setShowDelete(true);
    }

    return (
        <>
            <PageDescription>
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Click To Call</p>
                        <p className="mb-5">Here you&apos;ll find calling via clicking</p>

                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            onClick={() => addEntry()}
                        >
                            Add Click To Call
                        </button>
                    </div>
                </div>
            </PageDescription>
            {clickToCallsQuery.isLoading || scriptQuery.isLoading || clickToCallLogsQuery.isLoading? <Loader2 /> : (
                // <div className="px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded-lg shadow p-5 m-3">
                <div className="px-4 sm:px-6 lg:px-4">
                    {/* <PrintPre>{logs}</PrintPre> */}

                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900">
                                        <thead className="bg-gray-50 dark:bg-gray-800">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 sm:pl-6">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Text Color
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Button Color
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Button Text
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Agent
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Clicks
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Instructions</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Delete</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                                            {clickToCallsQuery.data?.map((entry: any) => (
                                                <tr key={entry.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900  dark:text-gray-400 sm:pl-6">
                                                        {entry.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 "><div className="border border-gray-600 rounded h-5 w-5 " style={{background: entry.textColor}}></div></td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 "><div className="border border-gray-600 rounded h-5 w-5 " style={{background: entry.buttonColor}}></div></td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">{entry.buttonText}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">{scriptQuery.data?.find((i: NeogenScript) => i.id === entry.scriptId)?.name}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">
                                                        
                                                        <ButtonNeoGen onClick={() => navigate("/click-to-call-logs/"+entry.uuid)} text={(logs[entry.uuid]?.length??0)+" Clicks"} type="info" hidden={(logs[entry.uuid]?.length??0)===0?true:false}/>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        {/* <Link to={"/click-to-call/"+entry.id} className="text-indigo-600 hover:text-indigo-900">
                                                            Edit<span className="sr-only">, {entry.name}</span>
                                                        </Link> */}
                                                        <ButtonNeoGen onClick={() => navigate("/click-to-call-instructions/"+entry.id)} text="Instructions" type="info"/>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        {/* <Link to={"/click-to-call/"+entry.id} className="text-indigo-600 hover:text-indigo-900">
                                                            Edit<span className="sr-only">, {entry.name}</span>
                                                        </Link> */}
                                                        <ButtonNeoGen onClick={() => navigate("/click-to-call/"+entry.id)} text="Edit" type="info"/>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        
                                                        <ButtonNeoGen onClick={() => deleteEntry(entry)} text="Delete" type="danger"/>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DeleteEntry show={showDelete} close={() => setShowDelete(false)} itemToDelete={itemToDelete}/>
                </div>
            )}


        </>
    );
}