/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

// import { useContext } from "react";
// import { NeogenUser } from "../typings/api";
import { AxiosResponse } from "axios";
import { NeoGenTransaction } from "../typings/api/neogen-transaction";
import APIService from "./API.service";
import AuthService from "./auth.service";
// import UserContext from "./user-context";

// const debug        = require('debug')('Neogen:CDRService');

class TransactionService extends APIService<NeoGenTransaction> {
    constructor() {
        super("my-neogen-transactions");
    }
    async getAllMyAmounts(companyId: number):Promise<void|AxiosResponse<NeoGenTransaction[]>> {
        // const user:NeogenUser  = useContext(UserContext);
        if ( await AuthService.canIAccess("SEE_ALL_TRANSACTIONS")) {
            let filter = {
                where: {
                    companyId: companyId
                },
                fields: {
                    "amount": true
                }
            };
            return super.getURL("/neogen-transactions/"+"?filter="+encodeURIComponent(JSON.stringify(filter)));
        } else {
            let filter = {
                fields: {
                    "amount": true
                }
            };
            return super.getURL("/my-neogen-transactions/?filter="+encodeURIComponent(JSON.stringify(filter)));
        }
        
    }
    async getAllMy(companyId: number):Promise<void|AxiosResponse<NeoGenTransaction[]>> {
        if ( await AuthService.canIAccess("SEE_ALL_TRANSACTIONS")) {
            let filter = {
                where: {
                    companyId: companyId
                }
            };
            return super.getURL("/neogen-transactions/"+"?filter="+encodeURIComponent(JSON.stringify(filter)));
        } else {
            let filter = {};
            return super.getURL("/my-neogen-transactions/?filter="+encodeURIComponent(JSON.stringify(filter)));
        }
    }
}

export default new TransactionService();