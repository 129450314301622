/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

// import PropTypes            from "prop-types";
// import React                from "react";
// import {Modal} from "react-bootstrap";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/outline";
 
// const debug = require("debug")("NeoGen:AddScriptModal");
 
 
/**
  * @component
  * @category Scripts
  */
function ModalNeoGen(props:ModalNeoGenProps) {
    const cancelButtonRef = useRef(null);
    // debug(props.folders);
    let size = "md:w-1/4 xxl:w-96";
    switch (props.size) {
        case "md":
            size="md:w-1/2 xxl:w-1/4";
            break;
        case "lg":
            size="md:w-4/5 xxl:w-1/2";
            break;
        case "xl":
            size="xl:w-4/5";
            break;
    }
    return (
        <>
            <Transition.Root show={props.show} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={props.show}
                    // onClose={() => props.close()}
                    onClose={() => null}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                        
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-2" />
                        </Transition.Child>
 
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className={"inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle "+size+" sm:p-6"}>
                                <div className="z-20">
                                    
                                    <div className="mt-0  ">
                                        <Dialog.Title as="h3" className="text-lg leading-6 text-center font-medium text-gray-900 dark:text-gray-500">
                                            {props.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={"flex justify-end mt-5 sm:mt-6 "}>
                                    {props.showCancel !== false && (<button
                                        type="button"
                                        className="py-2 px-4  mt-3 ring-0  rounded-md border border-gray-300 dark:border-gray-600 shadow-sm dark:bg-gray-800  bg-white text-base font-medium text-gray-700 dark:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-indigo-500 sm:mt-0  sm:text-sm"
                                        onClick={() => props.close()}
                                        ref={cancelButtonRef}
                                    >
                                        {props.cancelText??"Cancel"}
                                    </button>
                                    )}
                                    {props.showOk !== false && (
                                        <button
                                            type="button"
                                            className="py-2 px-4 ml-3 ring-0 rounded-md border border-transparent shadow-sm  bg-indigo-600 dark:bg-indigo-800 dark:text-gray-300 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-sm"
                                            onClick={() => props.okAction && props.okAction()}
                                        >
                                            {props.okText??"Ok"}
                                        </button>
                                    )}
                                    
                                    
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            
        </>
    );
}
 
export default ModalNeoGen;
 
 type ModalNeoGenProps = {
     close:                () => void,
     show:                 boolean,
     children: any,
     title: string|JSX.Element,
     okText?: string,
     cancelText?: string,
     okAction?: () => void,
     size?: string,
     showOk?: boolean,
     showCancel?: boolean,
 };