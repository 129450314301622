import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import carrierService from "../../../services/carrier.service";
import TransactionService from "../../../services/transaction.service";
import { useQueryClient } from "react-query";
import phoneNumberService from "../../../services/phone-number.service";
import { CountryDropdown, RegionDropdown, CountryRegionData } from "react-country-region-selector";
import PrintPre from "../../layout/print-pre";
import { NeogenPhoneNumbers } from "../../../typings/api";
import {formatPhonenumber}                    from "../../utilities/formatters";
import UserContext                            from "../../../services/user-context";
import Swal from "sweetalert2";
import { NeoGenTransaction } from "../../../typings/api/neogen-transaction";
import transactionService from "../../../services/transaction.service";
import PhoneNumberFinder from "./components/phone-number-finder";
import ModalNeoGen from "../../layout/modal-neogen";
import Loader2 from "../../utilities/Loader2";
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
type partOfTransaction = {
    amount: number
}

export default function PurchasePhoneNumber(props: PurchasePhoneNumberProps) {
    const [user,setUser]  = useContext(UserContext);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pricePerMinute, setPricePerMinute] = useState("0.00");
    const [pricePerMonth, setPricePerMonth] = useState("0.00");
    const [concurrentChannels, setConcurrentChannels] = useState("");
    const [country, setCountry] = useState("US");
    const [carrier, setCarrier] = useState(-1);
    const cache = useQueryClient();

    const balanceQuery = useQuery(["balance", user.company], async () => {
        const result = await TransactionService.getAllMyAmounts(user.company??-1);
        if (result) {
            let sum =  result.data.reduce( function(a: number, b: partOfTransaction){
                return parseFloat((a + b.amount).toFixed(10));
                // return (a + b.amount).toFixed(2);
            }, 0);
            return sum;
        }
    });

    // Get carriers
    const carrierQuery = useQuery(["carriers"], async () => {
        const result = await carrierService.getAll();
        if (result !== undefined) {
            return result.data;
        } else {
            return [];
        }
    });

    // Get carriers
    const availableNumbersQuery = useQuery(["availableNumbers", country], async () => {
        const result = await phoneNumberService.getAvailableByCountry(country);
        if (result !== undefined) {
            return result.data;
        } else {
            return [];
        }
    });


    useEffect(() => {
        console.log(country);
    }, [country]);

    
    function savePhoneNumber(updatedNumber: any) {
        
        return phoneNumberService.patchURL(phoneNumberService.endpoint+"/"+updatedNumber.id, updatedNumber);
        // return true;
    }

    const mutator = useMutation(savePhoneNumber, {
        onSuccess: (data) => {
            // Update `todos` and the individual todo queries when this mutation succeeds
            cache.invalidateQueries(["phoneNumbers"]).then(r => {
                console.log(r);
            });
            cache.invalidateQueries(["availableNumbers"]).then(r => {
                console.log(r);
            });
        },
        onError: (e: any) => {
            console.error(e);
            console.error(e?.response);
        }
    });

    function getNumber(n: string) {
        console.log(n);
    }

    // function purchaseNumber(p: NeogenPhoneNumbers) {
    //     if (!user.company) {
    //         Swal.fire({
    //             title: "You need to add a billing account first - click on billing then go to My Account",
    //             icon: "error",
    //             // showConfirmButton: false,
    //             // timer: 3000
    //         });
    //     } else if (balanceQuery.data < (p.salePricePerMonth??0)) {
    //         Swal.fire({
    //             title: "Insufficient funds in your account",
    //             icon: "error",
    //             // showConfirmButton: false,
    //             // timer: 3000
    //         });
    //     } else {
    //         let data:NeoGenTransaction = {
    //             amount: 0-(p.salePricePerMonth??0),
    //             companyId: (user.company),
    //             transactionType: 4,
    //             userId: user.id,
    //             notes: "Purchase of phone number: "+formatPhonenumber(p.number??""),
    //             associatedPhonenumber: p.id,
    //             lastUpdated: new Date()
    //         };
    //         console.log(p);
    //         console.log(user);
    //         // const onSubmit = function(data:any) {
    //         console.log(data);
            
    //         // setSaving(true);
    //         transactionService.create(data).then(() => {
    //             // setSaving(false);
    //             cache.invalidateQueries(["Transactions"]);
    //             cache.invalidateQueries(["balance"]);
    //             if (!user.company) {
    //                 alert("No company");
    //             } else {
    //                 p.owner=user.company;
    //                 p.notes = "";
    //                 p.costBody = "";
    //                 p.bluemixOverride = ""; 
    //                 p.costApi = -1;
    //                 p.scriptId = -1;
    //                 p.billingRenewal = new Date();
    //                 mutator.mutate(p);
    //                 Swal.fire({
    //                     title: "Number Purchased",
    //                     icon: "success",
    //                     showConfirmButton: false,
    //                     timer: 3000
    //                 }).then(() => {
    //                     if (props.purchased) {
    //                         props.purchased(p.id??-1);
    //                     }
    //                     props.hide();
    //                 });
    //             }
    //             // props.hide();
    //         }).catch((e:any) => {
    //             console.error(e);
    //             // setSaving(false);
    //         });
    //         // companyService.create(data).then((r:any) => {
    //         //     console.log(r);
    //         //     if (r) {
    //         //         console.log(r.data);
    //         //         userService.patchURL(userService.endpoint+"/"+user.id, {company: r.data.id}).then((res:any) => {
    //         //             console.log(res);
    //         //             // user.company = r.data.id;
    //         //             let existing = JSON.parse(localStorage.getItem("user")??"");
    //         //             existing.user.company = r.data.id;
    //         //             localStorage.setItem("user", JSON.stringify(existing));
    //         //             window.location.reload();
    //         //         });
    //         //     }
    //         // });
    //         // };
            
    //     }
        
        
        
        
    // }

    function requestNumber() {
        console.log(country);
    }

    return (
        <ModalNeoGen showOk={false} title="Purchase Phone Number" show={props.show} size="xl" close={() => props.hide()}>
            
                
            {!carrierQuery.isSuccess||availableNumbersQuery.isLoading?(<Loader2 />):(
                <>
                    {/* <div className="form-group">
                            <label htmlFor="formGroupExampleInput">Country</label>
                            <CountryDropdown
                                value={country}
                                valueType="short"
                                classes={"form-control"}
                                onChange={(val: string) => setCountry(val)} />
                        </div> */}
                        
                    <div className="form-group">
                        {availableNumbersQuery.data?.length === 0 ? (
                            <>
                                <p>Sorry we don&apos;t have any phone numbers for this country. Please select another country or contact us.</p>
                                <button type="button" className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg" onClick={() => requestNumber()}>Request {country} Number</button>
                            </>
                        ):(
                            <>
                                <div className="cardx">
                                    <div className="card-bodyx p-0">
                                        <PhoneNumberFinder selectNumber={(s: string) => getNumber(s)}/>
                                        {/* <table className="table-auto">
                                                <thead>
                                                    <tr>
                                                        <th>Phone Number</th>
                                                        <th>Country</th>
                                                        <th className="text-right">Price Per Month</th>
                                                        <th className="text-right">Price Per Minute</th>
                                                        <th>Free Minutes Included</th>
                                                        <th>Channels</th>
                                                        <th style={{width: "20%"}}>Purchase Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {availableNumbersQuery.data.map((p:NeogenPhoneNumbers) => {
                                                        return (
                                                            <tr key={p.id} >
                                                                <td>
                                                                    {formatPhonenumber(p.number??"")}
                                                                </td>
                                                                <td>
                                                                    {p.country}
                                                                </td>
                                                                <td className="text-right">
                                                                    {formatter.format(p.salePricePerMonth??0)}
                                                                </td> 
                                                                <td className="text-right">
                                                                    {formatter.format(p.salePricePerMinute??0)}
                                                                </td> 
                                                                <td>
                                                                    {p.freeMinutesIncluded}
                                                                </td> 
                                                                <td>
                                                                    {p.maxChannels}
                                                                </td> 
                                                                <td>
                                                                    {balanceQuery.isSuccess?(<button type="button" onClick={() => purchaseNumber(p)} className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg mr-2 mb-2"><i className="fal fa-shopping-cart mr-3"></i>Purchase</button>):(<>Loading Account Balance</>)}
                                                                    
                                                                </td> 
                                                            </tr>
                                                
                                                        );
                                                    })}
                                                </tbody>
                                        
                                            </table> */}
                                    </div>
                                </div>
                                    
                            </>
                        )}
                            
                            
                        {/* <input type="text" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} id="formGroupExampleInput" placeholder="Exactly how it is presented (i.e. +393291871754)" /> */}
                    </div>
                        
                </>
            )}
                

            
        </ModalNeoGen>
    );
}

type PurchasePhoneNumberProps = {
    show: boolean,
    hide: any,
    scriptToAttach?: number,
    purchased?: (newNumberId: number) => void
}