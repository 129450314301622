import React, { useState } from "react";
import { NeoGenIntegrationWrapper } from "../../../../../typings/api/neogen-integration";
import ServicesStep from "./services";
// import Switch from "react-switch";
// import { useState } from 'react'
import { Switch } from "@headlessui/react";

function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}

export default function AdvancedStep(props: AdvancedStepProps) {
    const [advancedEnabled, setAdvancedEnabled] = useState(false);
    const [callTransfers, setCallTransfers] = useState(false);
    const [api, setApi] = useState(false);
    const [custom, setCustom] = useState(false);
    
    return (
        <>
            <p className="wizard-step-explanation">You can enable some more complicated options here or you can leave them disabled and add them at any time.</p>
            <div className="grid grid-cols-1 justify-items-start mb-5">
                <Switch.Group as="div" className="flex items-start">
                    <Switch
                        checked={advancedEnabled}
                        onChange={setAdvancedEnabled}
                        className={classNames(
                            advancedEnabled ? "bg-indigo-600 " : "bg-gray-200 dark:bg-gray-800",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                    >
                        <span className="sr-only">Use Advanced Options</span>
                        <span
                            aria-hidden="true"
                            className={classNames(
                                advancedEnabled ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            )}
                        />
                    </Switch>
                    <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-400">Advanced Options</span>
                        
                    </Switch.Label>
                </Switch.Group>
            </div>
            <div className="">
                {/* <div className="card-header">
                    <button type="button" className={"btn "+(advancedEnabled?"btn-success":"btn-danger")} onClick={() => setAdvancedEnabled(c => !c)}>{advancedEnabled?(<>Turn Off Advanced Options</>):(<>Turn On Advanced Options</>)}</button>
                </div> */}

                <div className="">
                    {advancedEnabled?(<>
                        <div className="grid grid-cols-1 justify-items-start mt-10 mb-5">
                            <Switch.Group as="div" className="flex items-start">
                                <Switch id={"api"}
                            
                                    onChange={(enabled) => {
                                        setApi(enabled);
                                    }}
                                    checked={api}
                                    className={classNames(
                                        api ? "bg-indigo-600" : "bg-gray-200 dark:bg-gray-800",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            api ? "translate-x-5" : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                    />
                                </Switch>
                                <Switch.Label as="span" className="ml-3">
                                    <span className="text-sm font-medium text-gray-900 dark:text-gray-400">API Integrations</span>
                                    <span className="align-self-start ml-3" >API Integration with platforms like Slack or Asana</span>
                                </Switch.Label>
                            </Switch.Group>
                        </div>
                        
                        <div className="grid grid-cols-1 w-full">
                            {api?(<ServicesStep services={props.services} setServices={props.setServices}/>):(<></>)}
                        </div>
                        
                        
                        {/*  */}
                        {/* <div className="mt-5 grid grid-cols-1 justify-items-start mb-5">
                            <div className="grid grid-cols-1 justify-items-start mb-5">
                                <Switch.Group as="div" className="flex items-start">
                                    <Switch id={"callTransfers"}
                            
                                        onChange={(enabled) => {
                                            setCallTransfers(enabled);
                                        }}
                                        checked={callTransfers}
                                        className={classNames(
                                            callTransfers ? "bg-indigo-600" : "bg-gray-200",
                                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                callTransfers ? "translate-x-5" : "translate-x-0",
                                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                            )}
                                        />
                                    </Switch>
                                    <Switch.Label as="span" className="ml-3">
                                        <span className="text-sm font-medium text-gray-900">Call Transfers</span>
                                        <span className="align-self-center ml-3" >Live call transfers to a phone number</span>
                                    </Switch.Label>
                                </Switch.Group>
                            </div>
                            
                        
                        </div>
                        <div className="mt-5 grid grid-cols-1 justify-items-start mb-5">
                            <div className="grid grid-cols-1 justify-items-start mb-5">
                                <Switch.Group as="div" className="flex items-start">
                                    <Switch id={"callTransfers"}
                            
                                        onChange={(enabled) => {
                                            setCustom(enabled);
                                        }}
                                        checked={custom}
                                        className={classNames(
                                            custom ? "bg-indigo-600" : "bg-gray-200",
                                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                custom ? "translate-x-5" : "translate-x-0",
                                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                            )}
                                        />
                                    </Switch>
                                    <Switch.Label as="span" className="ml-3">
                                        <span className="text-sm font-medium text-gray-900">Custom information gathering</span>
                                        <span className="align-self-start ml-3" >Collect answers to custom fields</span>
                                    </Switch.Label>
                                </Switch.Group>
                            </div>
                            
                        
                        </div> */}

                        
                    </>):(<>
                        <div className="grid grid-cols-1 justify-items-start mb-5">
                            <div className="advanced-examples mb-5 mt-5"><i className="fal fa-phone mr-3 fa-2x text-blue-500 fa-fw" style={{verticalAlign: "middle"}}></i>Live call transfers to a phone number</div>
                            <div className="advanced-examples mb-5 mt-5"><i className="fal fa-cogs mr-3 fa-2x text-red-300 fa-fw" style={{verticalAlign: "middle"}}></i>API Integration with platforms like Slack or Asana</div>
                            <div className="advanced-examples mb-5 mt-5"><i className="fal fa-comment-check mr-3 fa-2x text-green-500 fa-fw" style={{verticalAlign: "middle"}}></i>Custom information gathering</div>
                        </div>
                    </>)}
                    
                </div>
            </div>
        
        </>
    );
}

type AdvancedStepProps = {
    services: NeoGenIntegrationWrapper[],
    setServices: any
}