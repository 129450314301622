
import { NeoGenCustomReport } from "../typings/api/neogen-custom-report";
import APIService from "./API.service";

class CustomReportService extends APIService<NeoGenCustomReport> {
    constructor() {
        super("neogen-custom-reports");
    }
    
}

export default new CustomReportService();