
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import PrintPre from "../../../../layout/print-pre";
export default function EmailStep(props: EmailStepProps) {
    
    return (
        <>
            
            
            <p className="wizard-step-explanation">When we take a message for you we will send the details to an email address.</p>
            {/* <PrintPre>{props.isError}</PrintPre> */}
            {/* <div className="form-group-wizard">
                <label htmlFor="formGroupExampleInput" className="wizard">Company Name:</label>
                <input type="text" className="form-control-wizard form-control"  name="companyName" placeholder="i.e. Acme Widgets LLC" value={props.companyName} onChange={(e) => props.setCompanyName(e.currentTarget.value)}/>
            </div> */}
            {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Company Name
            </label> */}
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    type="text"
                    name="email"
                    id="email"
                    className={"dark:text-gray-400 dark:bg-gray-800 dark:border-gray-800 "+(props.isError?"block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md")}
                    placeholder="i.e. john@acmewidgets.com"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    value={props.email} onChange={(e) => props.setEmail(e.currentTarget.value)}
                />

                {props.isError && (<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>)}
            </div>
            {props.isError && (<p className="mt-2 text-sm text-red-600" id="email-error">
                You need to provide your email address so we can send your messages
            </p>)}
        </>
    );
}

type EmailStepProps = {
    email: string;
    setEmail: (email: string) => void;
    isError: boolean;
}