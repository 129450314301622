/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenFlowchart } from "../typings/api";
import APIService from "./API.service";

const debug        = require("debug")("Neogen:FlowchartService");

class FlowchartService extends APIService<NeogenFlowchart> {
    constructor() {
        super("neogen-flowcharts");
    }

    async getFlowchartByScriptID(scriptID: number ):Promise<void|AxiosResponse<NeogenFlowchart[]>> {
        return await this.getURL( "neogen-flowcharts/?filter=" + encodeURIComponent("{\"order\": [\"dateSaved DESC\"], \"limit\": 1, \"where\": {\"scriptId\":\"" + scriptID + "\"}}"));
        // return await axios.get(API_URL + 'neogen-flowcharts/?filter=' + encodeURIComponent('{"where": {"scriptId":"' + scriptID + '"}}'), {headers: authHeader()});
    }
}

export default new FlowchartService();