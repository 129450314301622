import React, { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useForm } from "react-hook-form";
import { start } from "repl";
import calendarService from "../../../services/calendar.service";
import ModalNeoGen from "../../layout/modal-neogen";
import { useQuery, useQueryClient } from "react-query";
import PrintPre from "../../layout/print-pre";
import { PaperClipIcon } from "@heroicons/react/solid";
import nl2br from "react-nl2br";
type FormData = {
    search:string
}

export default function ShowEvent(props:ShowEventProps):JSX.Element {
    const { register, setValue, handleSubmit, formState: { errors }, getValues } = useForm<FormData>();
    const queryCache                          = useQueryClient();
    const [slots, setSlots] = useState<Date[]>([]);
    // const onSubmit = handleSubmit(data => console.log(data));
    function deleteAction() {
        // const data = getValues();
        // calendarService.findSlot(data.search).then((r:any) => {
        //     console.log("x",r);
        //     const arr:Date[] = [];
        //     if (r) {
        //         r.data.forEach((d:string) => {
        //             let da = new Date(d);
        //             let dau = new Date(da.getUTCFullYear(), da.getUTCMonth(), da.getUTCDate(), da.getUTCHours(), da.getUTCMinutes());
        //             arr.push(dau);
        //         });
        //         setSlots(arr);
        //     }
            
        // });
        calendarService.deleteEvent(props.event).then((r:any) => {
            console.log({r});
            queryCache.invalidateQueries(["events"]).then((r:any) => {
                // 
                props.close();
            });
        });
    }
    return (
        <>
            <ModalNeoGen title="Show Event" show={props.show} size="lg" close={props.close} cancelText="Close"
                okText="Delete Event" okAction={() => deleteAction()}
            >
                {props.show&& (
                    <div>
                    
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Event Information</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Event details and cancellation.</p>
                            </div>
                            <div className="border-t border-gray-200">
                                <dl>
                                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.event._status}</dd>
                                    </div>
                                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Location</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.event.location}</dd>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Start</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{new Date(props.event.start).toUTCString().replaceAll(" GMT", "")}</dd>
                                    </div>
                                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">End</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{new Date(props.event.end).toUTCString().replaceAll(" GMT", "")}</dd>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Summary</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {nl2br(decodeURIComponent(props.event.summary))}
                                        </dd>
                                    </div>
                                    
                                </dl>
                            </div>
                        </div>



                        
                        {/* <PrintPre>
                            {props.event}
                        </PrintPre>  */}
                    
                    </div>
                )}
            </ModalNeoGen>
        </>
    );
}

type ShowEventProps = {
    close: () => void,
    show: boolean,
    event: any,
}