/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosResponse }       from "axios";
import authHeader  from "./auth-header";
import APIService  from "./API.service";
import AuthService from "./auth.service";
import { NeogenScript, NeogenScriptFolderEntries, NeogenScriptFolders } from "../typings/api";

const API_URL = process.env.REACT_APP_API_URL;

const debug = require("debug")("Neogen:ScriptFolderService");

class ScriptFolderService extends APIService<NeogenScriptFolders> {
    constructor() {
        super("neogen-script-folders");
    }

    async getScriptFolderEntries():Promise<any> {
        return await axios.get(API_URL + "neogen-script-folder-entries", {headers: authHeader()});
    }

    async getScriptsByFolder(id:string|number):Promise<void|AxiosResponse<NeogenScript[]>> {
        return await axios.get(API_URL + "neogen-script-folders/" + id + "/neogen-scripts", {headers: authHeader()});
    }

    async getFolderEntriesByFolder(id:string|number):Promise<void|AxiosResponse<NeogenScriptFolderEntries[]>> {
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"neogenscriptFolder\":\"" + id + "\"}}"), {headers: authHeader()});
    }

    async getByScriptID(scriptID:string|number):Promise<any> {
        // if (await AuthService.canIAccess("LIST_ALL_SCRIPT_FOLDERS")) {
        // return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + Number(scriptID) + "\"}}"), {headers: authHeader()});
        // } else {
        // if the user doesn't have access to all phone numbers they should see a subset
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + Number(scriptID) + "\"}}"), {headers: authHeader()});
        // console.info("Can access my script folders");
        // return super.getURL("my-neogen-script-folders");

        // return [];
        // }
        
    }

    async getSoundsByIDArray(idArray:number[]):Promise<any> {
        return await axios.get(API_URL + "/neogen-scripts/?filter=" + encodeURIComponent("{\"where\": {\"id\": {\"inq\": [" + idArray.join(",") + "]}}}"), {headers: authHeader()});
    }

    async getAll():Promise<void|AxiosResponse<NeogenScriptFolders[]>> {
        if (await AuthService.canIAccess("LIST_ALL_SCRIPT_FOLDERS")) {
            debug("I can see all script folders");
            return await super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset

            console.info("Can access my script folders");
            return super.getURL("my-neogen-script-folders");

            // return [];
        }

    }


    async inviteToFolder(email:string, folderId:number, fromEmail:string, fromName:string, folderName:string):Promise<any> {
        let data = {
            email, folderId, fromEmail, fromName, folderName
        };
        return this.postURL("/neogen-script-folders/invite", data);
    }
}

export default new ScriptFolderService();