/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenQuestionOptionType, NeogenResponseCatcherFolders } from "../typings/api";
import APIService  from "./API.service";
import AuthService from "./auth.service";
const debug = require("debug")("NeoGen:QuestionOptionType");

export enum NLPTypes {
    Unknown = 0,
    NLPV1 = 1,
    NLPV2 = 2,
    BERT = 4,
    NER = 8,
    GPT = 16,
    HAWKING = 32,
    CUSTOM_BERT = 64,
    JOB_TYPE = 128,
    CUSTOM_SPACY = 256,
    DUCKLING = 512,
}

class QuestionOptionTypeService extends APIService<NeogenQuestionOptionType> {
    constructor() {
        super("neogen-question-option-types");
    }
    // getAll() {
    //     // debug("x1");
    //     return super.getAll();
    // }

    async getAllFolders():Promise<void|AxiosResponse<NeogenResponseCatcherFolders[]>> {
        debug("a1");
        // let that = this;
        debug(this);
        if (await AuthService.canIAccess("SEE_ALL_RESPONSE_CATCHER_FOLDERS")) {
            return await this.getURL("neogen-response-catcher-folders/");
        } else {
            return await this.getURL("my-neogen-response-catcher-folders/");
        }
    }

    // @TODO: secure this
    async getAllByFolder(id: number):Promise<void|AxiosResponse<NeogenQuestionOptionType[]>> {
        const filter = {
            where: {
                folder: id
            }
        };
        // debug(filter);
        return this.getURL("neogen-question-option-types/?filter=" + encodeURIComponent(JSON.stringify(filter)));

    }
}

export default new QuestionOptionTypeService();