

import { NeogenVirtualAgentFeatures } from "../typings/api/neogen-virtual-agent-features";
import APIService  from "./API.service";

class VirtualAgentFeatures extends APIService<NeogenVirtualAgentFeatures> {
    constructor() {
        super("neogen-virtual-agent-features");
    }
}

export default new VirtualAgentFeatures();