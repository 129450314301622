/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/25/20, 8:36 AM
 *
 */

import {
    BellIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuAlt1Icon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
} from "@heroicons/react/outline";

export const items = [{
    name: "index",
    url: "/",
    label: "Home",
    icon: "fal fa-home fa-fw mr-2",
    description: "Shows the pipeline",
},
{
    name: "scripts",
    label: "Virtual Agents",
    url: "/scripts",
    extraURLs: [
        "/scripts"
    ],
    description: "Shows the pipeline",
    icon: "fal fa-user-headset fa-fw mr-2",
},
{
    name: "recording",
    label: "Recording",
    requiredRole: "URL_RECORDING",
    url: "/recording",
    description: "Shows the pipeline",
    extraURLs: [
        "/recording/record",
        "/recording/explain",
        "/recording/show",
        "/recording/help",
        "/recording/select-folder",
        "/recording/select-script",
    ],
    icon: "fal fa-microphone fa-fw mr-2",
},
{
    name: "virtualAgents",
    label: "Artificial Intelligence",
    url: "/artificial-intelligence",
    requiredRole: "URL_AI",
    icon: "fal fa-head-side-brain fa-fw mr-2",
    description: "Shows the pipeline",
    items: [
        {
            name: "nlp",
            label: "Test NLP v3",
            url: "/nlpv3",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-vial fa-fw mr-2",
            description: "Test detections for version 3 NLP code (Sentence Embedding Transformers)",
        },{
            name: "nlp",
            label: "Test NLP v1",
            url: "/nlp",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-vial fa-fw mr-2",
            description: "Test detections for version 1 NLP code (NLP.js from AXA)",
        }, {
            name: "train-nlp",
            label: "Train NLP v1",
            url: "/train-nlp",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-person-chalkboard fa-fw mr-2",
            description: "Train detections for version 1 NLP code (NLP.js from AXA)",
        }, 
        {
            name: "customNLU",
            label: "Custom NLU",
            url: "/custom-nlu",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-gears fa-fw mr-2",
            description: "Custom BERT based NLU with auto intents",
        }, {
            name: "pipeline",
            label: "Pipeline",
            url: "/pipeline",
            requiredRole: "URL_AI",
            icon: "fal fa-conveyor-belt-alt fa-fw mr-2",
            description: "Shows the pipeline - allows you to see how information is learnt by the system",
        }, {
            name: "calendar",
            label: "Calendar Tests",
            url: "/ai-calendar",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-calendar fa-fw mr-2",
            description: "Allows you to check the AI Calendar",
        }, {
            name: "summarize",
            label: "Summarize",
            url: "/summarize",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-message-pen fa-fw mr-2",
            description: "Allows you to summarize text",
        }, {
            name: "translation",
            label: "Translation Layer",
            url: "/translation",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-bandage fa-fw mr-2",
            description: "Allows you to manage the translation layer for ASR inconsistencies",
        }, {
            name: "responseCatchers",
            label: "Response Catchers",
            url: "/response-catchers",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-bullseye-pointer fa-fw mr-2",
            description: "Manages the list of response catchers in the system",
        }, {
            name: "actions",
            label: "Actions",
            url: "/actions",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-circle-play fa-fw mr-2",
            description: "Manages the list of actions in the system",
        }, {
            name: "nlp2",
            label: "NLP v2 Debug",
            url: "/test-nlp-v2",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-brain fa-fw mr-2",
            description: "Test detections for version 2 NLP code (spacy.io)",
        }, {
        
            name: "train-nlp2",
            label: "NLP v2 Actions",
            url: "/train-nlp-v2",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-brain fa-fw mr-2",
            description: "Manage V2 Verb Mappings (spacy.io)",
        }, {
            name: "custom-models",
            label: "Custom Languages",
            url: "/custom-language-models",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-language fa-fw mr-2",
            description: "Manage IBM Language Grammars",
            extraURLs: [
                "/custom-grammars"
            ],
        }, {
            name: "modules",
            label: "Modules",
            url: "/modules",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-comments fa-fw mr-2",
        }, {
            name: "response_types",
            label: "Response Types",
            url: "/response_types",
            requiredRole: "URL_DASHBOARD",
            icon: "fal fa-comment-edit fa-fw mr-2",
        },
    ]

},

{
    name: "phoneNumbers",
    url: "/phone-numbers",
    label: "Phone Numbers",
    icon: "fal fa-phone-office fa-fw mr-2",
    description: "Shows the pipeline",
    extraURLs: [
        "/phonenumbers",
        "/transfer-destinations"
    ]
},
{
    name: "allSounds",
    url: "/selectFolder",
    requiredRole: "INVISIBLE",
    extraURLs: [
        "/allSounds"
    ],
    label: "Sounds",
    description: "Shows the pipeline",
    icon: "fal fa-speaker fa-fw mr-2"
},
{
    name: "billing",
    url: "/billing",

    label: "Billing",
    icon: "fal fa-shopping-cart fa-fw mr-2",
    description: "Shows the pipeline",
    items: [{
        name: "balance",
        url: "/billing/balance",
        label: "My Account",
        description: "Shows the pipeline",
        icon: "fal fa-user fa-fw mr-2"
    },
    {
        name: "Transactions",
        url: "/billing/transactions",
        requiredRole: "URL_ADMIN",
        description: "Shows Transactions",
        label: "Transactions",
        icon: "fal fa-receipt fa-fw mr-2"
    },
    {
        name: "invoices",
        url: "/billing/invoices",
        requiredRole: "URL_ADMIN",
        label: "Invoices",
        description: "Shows the pipeline",
        icon: "fal fa-file-invoice fa-fw mr-2"
    },
    {
        name: "billing-overview",
        url: "/billing/overview",
        requiredRole: "URL_ADMIN",
        label: "Minutes",
        description: "Shows the pipeline",
        icon: "fal fa-chart-line fa-fw mr-2"
    },
    ]
},
{
    name: "admin",
    url: "/admin_menu",
    requiredRole: "URL_ADMIN",
    label: "Admin",
    icon: "fal fa-cog fa-fw mr-2",
    description: "Shows the pipeline",
    items: [
        {
            name: "users",
            url: "/users",
            requiredRole: "URL_ADMIN",
            label: "Users",
            description: "Lets you add, edit or remove users",
            icon: "fal fa-users fa-fw mr-2"
        },
        {
            name: "smoothtorque",
            url: "/st-menu",
            requiredRole: "URL_DASHBOARD",
            label: "SmoothTorque",
            description: "Lets you connect to remote SmoothTorque systems",
            icon: "fal fa-phone-arrow-up-right fa-fw mr-2"
        },
        {
            name: "click-to-call",
            url: "/click-to-call-selector",
            requiredRole: "URL_DASHBOARD",
            label: "Click To Call",
            description: "Click to call buttons",
            icon: "fal fa-arrow-pointer fa-fw mr-2"
        },
        {
            name: "tests",
            url: "/tests",
            requiredRole: "URL_DASHBOARD",
            label: "Script Tests",
            description: "An interface for creating and managing tests",
            icon: "fal fa-vial fa-fw mr-2"
        },
        {
            name: "ari-reload",
            url: "/ari-reload",
            requiredRole: "URL_DASHBOARD",
            label: "Reload All ARI Nodes",
            description: "Causes all ARI Nodes to reload their configuration",
            icon: "fal fa-refresh fa-fw mr-2"
        },
        {
            name: "admin-onboarding",
            url: "/admin/onboarding",
            requiredRole: "URL_ADMIN",
            label: "Onboarding Process Creation",
            description: "Allows you to set up the onboarding process",
            icon: "fal fa-refresh fa-fw mr-2"
        },
        {
            name: "onboarding-wizard",
            url: "/onboarding-wizard",
            requiredRole: "URL_ADMIN",
            label: "Onboarding Wizard",
            description: "A wizard for onboarding users",
            icon: "fal fa-refresh fa-fw mr-2"
        },
        {
            name: "script_training",
            url: "/script-training",
            requiredRole: "URL_DASHBOARD",
            label: "Script Training",
            description: "Trains scripts",
            icon: "fal fa-terminal fa-fw mr-2"
        },
    
        {
            name: "onboard",
            url: "/onboard",
            requiredRole: "URL_DASHBOARD",
            label: "Onboard Test",
            description: "A test of the onboarding process",
            icon: "fal fa-cog fa-fw mr-2"
        },
        
        {
            name: "audio",
            url: "/selectFolder",
            requiredRole: "URL_DASHBOARD",
            label: "Audio Files",
            description: "List All Audio Files",
            icon: "fal fa-cog fa-fw mr-2"
        },
        {
            name: "webrtc",
            url: "/webrtc",
            requiredRole: "URL_DASHBOARD",
            label: "WebRTC Test",
            description: "WebRTC Test",
            icon: "fal fa-cog fa-fw mr-2"
        },
        {
            name: "integration-types",
            url: "/integration-types",
            requiredRole: "URL_DASHBOARD",
            label: "Integration Types",
            description: "Gives a list of integration types",
            icon: "fal fa-cog fa-fw mr-2"
        },
        {
            name: "companies",
            url: "/companies",
            requiredRole: "URL_DASHBOARD",
            label: "Companies",
            description: "Lets you add/edit/remove companies",
            icon: "fal fa-building fa-fw mr-2"
        },
        {
            name: "apis",
            url: "/push-apis",
            requiredRole: "URL_DASHBOARD",
            label: "APIs",
            description: "Shows a list of the APIs from the Shim Layer",
            icon: "fal fa-terminal fa-fw mr-2"
        },
        {
            name: "roles",
            url: "/roles",
            requiredRole: "ADD_ROLES",
            label: "Roles",
            description: "Shows the roles for role based authentication",
            icon: "fal fa-user-tag fa-fw mr-2"
        },
        {
            name: "role-groups",
            url: "/role-groups",
            requiredRole: "URL_ROLE_GROUPS",
            label: "Role Groups",
            description: "Shows the role groups",
            icon: "fal fa-folder fa-fw mr-2"
        },
        {
            name: "logs",
            url: "/logs",
            requiredRole: "URL_LOGS",
            label: "Logs",
            description: "Shows the logs",
            icon: "fal fa-book fa-fw mr-2"
        },
        {
            name: "clusters",
            url: "/clusters",
            requiredRole: "URL_INFRASTRUCTURE",
            label: "Clusters",
            description: "Shows the clusters",
            icon: "fal fa-layer-group fa-fw mr-2"
        },
        {
            name: "servers",
            url: "/servers",
            requiredRole: "URL_INFRASTRUCTURE",
            label: "Servers",
            description: "Shows the servers",
            icon: "fal fa-server fa-fw mr-2"
        },
    ]
},
{
    name: "reporting",
    url: "/reporting",
    label: "Reporting",
    description: "Shows the pipeline",
    icon: "fal fa-chart-bar fa-fw mr-2",
    items: [
        {
            name: "callHistory",
            url: "/call-history",
            label: "Call History",
            description: "Shows the pipeline",
            icon: "fal fa-list-alt fa-fw mr-2"
        },
        {
            name: "inOut",
            url: "/in-out",
            label: "In and Out",
            description: "Shows the pipeline",
            icon: "fal fa-list-alt fa-fw mr-2"
        },
        {
            name: "apiPush",
            url: "/push-apis",
            label: "API Pushes",
            requiredRole: "URL_ADMIN",
            description: "Shows the pipeline",
            icon: "fal fa-list-alt fa-fw mr-2"
        },
        {
            name: "responses",
            label: "Responses",
            requiredRole: "URL_LOGS",
            url: "/responses",
            description: "Shows the pipeline",
            icon: "fal fa-comments fa-fw mr-2",
        },
        {
            name: "calls",
            url: "/calls",
            label: "Virtual Agent Calls",
            description: "Shows the pipeline",
            icon: "fal fa-user-headset fa-fw mr-2"
        },

        {
            name: "transcriptions",
            url: "/transcriptions",
            requiredRole: "URL_ADMIN",
            label: "Transcriptions",
            description: "Shows the pipeline",
            icon: "fal fa-user-headset fa-fw mr-2"
        },

        {
            name: "transferredCalls",
            url: "/transferred-calls",
            label: "Transferred Calls",
            description: "Shows the pipeline",
            icon: "fal fa-paper-plane fa-fw mr-2"
        },
        
        
        
        {
            name: "reportBuilder",
            label: "Report Builder",
            url: "/report-builder",
            description: "Allows you to build custom reports",
            icon: "fal fa-cogs fa-fw mr-2",
        },
        
        {
            name: "customReporting",
            label: "Custom Reports",
            url: "/custom-reporting",
            description: "Shows the pipeline",
            icon: "fal fa-cogs fa-fw mr-2",
        },
        {
            name: "sankey",
            label: "Flow",
            url: "/sankey-report",
            description: "Shows the pipeline",
            icon: "fal fa-cogs fa-fw mr-2",
        },
        {
            name: "trained-percentage",
            label: "Trained Percentage",
            requiredRole: "URL_ADMIN",
            url: "/reports/training/trained-percentage",
            description: "Shows the pipeline",
            icon: "fal fa-cogs fa-fw mr-2",
        },
        {
            name: "scheduledReports",
            url: "/reports/scheduled",
            label: "Emailed Reports",
            description: "Shows the pipeline",
            requiredRole: "URL_ADMIN",
            icon: "fal fa-envelope fa-fw mr-2"
        },
    ]
},
{
    name: "settings",
    url: "/settings",
    label: "Settings",
    description: "Shows the pipeline",
    icon: "fal fa-home fa-fw mr-2",
    requiredRole: "INVISIBLE"
},
{
    name: "languages",
    url: "/languages",
    requiredRole: "URL_LANGUAGES",
    label: "Languages",
    description: "Shows the pipeline",
    icon: "fal fa-language fa-fw mr-2"
},
// {
//     name:  'playground',
//     url:   '/playground',
//     label: 'Playground',
//     icon:  'fal fa-star fa-fw mr-2'
// },
{
    name: "help",
    url: "/faq",
    label: "Help",
    requiredRole: "URL_ADMIN",
    description: "Shows the pipeline",
    icon: "fal fa-question-circle fa-fw mr-2",
    items: [{
        name: "faq",
        url: "/faq",
        label: "FAQ",
        requiredRole: "URL_ADMIN",
        description: "Shows the pipeline",
        icon: "fal fa-book fa-fw mr-2"
    },
    {
        name: "tutorials",
        url: "/tutorials",
        requiredRole: "URL_DASHBOARD",
        description: "Shows the pipeline",
        label: "Tutorials",
        icon: "fal fa-chalkboard-teacher fa-fw mr-2"
    },
    {
        name: "ai",
        url: "/ai",
        requiredRole: "URL_DASHBOARD",
        description: "Shows the pipeline",
        label: "AI Helper",
        icon: "fal fa-user-robot fa-fw mr-2"
    },
    {
        name: "updates",
        url: "/updates",
        requiredRole: "URL_ADMIN",
        label: "Updates",
        description: "Shows the pipeline",
        icon: "fal fa-sync fa-fw mr-2"
    },
    ]
},
{
    name: "logout",
    url: "/logout",
    label: "Log Out",
    description: "Shows the pipeline",
    icon: "fal fa-sign-out-alt fa-fw mr-2"
}
];


// export items;