/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
    ArchiveIcon,
    ArrowCircleRightIcon,
    ChevronDownIcon,
    DuplicateIcon,
    HeartIcon,
    PencilAltIcon,
    TrashIcon,
    UserAddIcon,
    CheckIcon,
    XIcon
} from "@heroicons/react/solid";
import { Languages, NeogenPhoneNumbers } from "../../../typings/api";
import { NeogenTransferDestinations } from "../../../typings/api/neogen-transfer-destinations";
import { NeoGenTranscriptionNumber } from "../../../typings/api/neogen-transcription-number";
import { useQuery } from "react-query";
import languageService from "../../../services/language.service";
import EditHours from "../modals/edit-hours";
import { ExternalLinkIcon, PencilIcon } from "@heroicons/react/outline";
import scriptFolderEntryService from "../../../services/script-folder-entry.service";
import { useNavigate } from "react-router-dom";
import authService from "../../../services/auth.service";
import AddOutboundPhoneNumber from "../modals/add-outbound-phonenumber";
function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}

function compare(a:any, b:any) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export default function Actions(props:ActionsProps) {
    const [canEditVScipts, setCanEditVScripts] = useState(false);
    const [showEditHours, setShowEditHours] = useState(false);
    const [showEditOutbound, setShowEditOutbound] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filter, setFilter] = useState("all");
    const [editId, setEditId] = useState(-1);
    const navigate = useNavigate();
    // Get Languages
    const languageQuery = useQuery(["languages"], async () => {
        const results = await languageService.getAll();
        // debug("data: ", data);
        if (results) {
            return results.data.sort(compare);
        }
    });
    useEffect(() => {
        authService.canIAccess("BETA_EDIT_VIRTUAL_AGENTS").then(r => {
            setCanEditVScripts(r);
        });
    },[]);

    return (
        <>
            <Menu as="div" className="relative inline-block text-left mr-2">
                <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 z-10">
                    Actions
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-20 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-200 dark:divide-gray-800">






                        
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            setIsEdit(true);
                                            setEditId(props.phonenumber.id??-1);    
                                            props.editAction(props.phonenumber);                                            
                                        }}
                                        className={classNames(
                                            active ? "bg-gray-100 dark:bg-gray-800 dark:text-gray-400 text-gray-900" : "text-gray-700 dark:text-gray-400 ",
                                            "group flex items-center px-4 py-2 text-sm w-full"
                                        )}
                                    >
                                        <PencilAltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Edit
                                    </button>
                                )}
                            </Menu.Item>



                            {canEditVScipts && (<Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            // setIsEdit(true);
                                            // setEditId(props.phonenumber.id??-1);    
                                            // props.editAction(props.phonenumber);     
                                            
                                            scriptFolderEntryService.getByScript(props.phonenumber.scriptId).then(r => {
                                                console.log(r);
                                                navigate("/scripts/"+r.data[0].scriptFolderId+"/"+r.data[0].scriptId);
                                            });



                                        }}
                                        className={classNames(
                                            active ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 " : "text-gray-700 dark:text-gray-400 ",
                                            "group flex items-center px-4 py-2 text-sm w-full"
                                        )}
                                    >
                                        <ExternalLinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        Open Script
                                    </button>
                                )}
                            </Menu.Item>)}




                            {props.showLanguage && languageQuery.isSuccess && (
                                <>
                                    {languageQuery.data?.map((l:Languages) => {
                                        return (
                                            <Menu.Item key={l.id}>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => {
                                                            props.changeLanguage(props.phonenumber, l.id);
                                                        }}
                                                        className={classNames(
                                                            active ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400  " : "text-gray-700 dark:text-gray-400 ",
                                                            "group flex items-center px-4 py-2 text-sm"
                                                        )}
                                                    >
                                                        {l.id === props.phonenumber.language ? (<>
                                                            <CheckIcon className="mr-3 h-5 w-5 text-green-600 group-hover:text-gray-500" aria-hidden="true" />
                                                        Current: {l.name}
                                                        </>
                                                        ):(
                                                            <>
                                                        Change to {l.name}
                                                            </>
                                                        )}
                                                    
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        );
                                    })}
                                
                                </>
                            )}
                            {props.showHours && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setIsEdit(true);
                                                setEditId(props.phonenumber.id??-1);
                                                setShowEditHours(true);
                                            }}
                                            className={classNames(
                                                active ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 " : "text-gray-700 dark:text-gray-400 ",
                                                "group flex items-center px-4 py-2 text-sm"
                                            )}
                                        >
                                            <DuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                            Set Hours
                                        </a>
                                    )}
                                </Menu.Item>
                            )}
                        </div>
                        <div className="py-1">
                            {props.showASR && (
                                <>
                                    {props.asrEntries.map((e:any) => {
                                        return (
                                            <Menu.Item key={e.id}>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 " : "text-gray-700 dark:text-gray-400 ",
                                                            "group flex items-center px-4 py-2 text-sm"
                                                        )}
                                                        onClick={() => {
                                                            props.changeASR(props.phonenumber, e.id );
                                                        }}
                                                    >
                                                    
                                                        {props.currentASR === e.id?(
                                                            <>
                                                                <CheckIcon className="mr-3 h-5 w-5 text-green-600 group-hover:text-gray-500" aria-hidden="true" />
                                                            Currently {e.name}
                                                            </>
                                                        ):(
                                                            <>
                                                                {/* <XIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />     */}
                                                        Set To {e.name}
                                                            </>
                                                        )}
                                                    
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        );
                                    })}
                                </>
                            )}
                        
                        </div>
                    
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        onClick={() => props.deleteAction()}
                                        className={classNames(
                                            active ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 " : "text-gray-700 dark:text-gray-400 ",
                                            "group flex items-center px-4 py-2 text-sm"
                                        )}
                                    >
                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 " aria-hidden="true" />
                                    Delete
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                        
                    </Menu.Items>
                </Transition>
            </Menu>
            
            <EditHours
                id={editId}
                show={showEditHours}
                hide={() => setShowEditHours(false)}
            />
        </>
    );
}

type ActionsProps = {
    deleteAction: any,
    editAction?: any,
    showHours?: boolean,
    showLanguage?: boolean,
    showASR?: boolean,
    currentASR?: string,
    asrEntries?: any,
    changeASR?: any,
    changeLanguage?: any,
    phonenumber: any,
}
