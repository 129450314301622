/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:29 AM
 *
 */

// src/Title.js

import PropTypes from "prop-types";
import React     from "react";
import "./Title.css";

/**
 * Makes a shimmering title for the top of a page
 * @component
 * @example
 * const text = 'My Title'
 * return (
 *   <Title title={text} />
 * )
 */
const Title = ({title, hidden}) => {
    return (
        <>
            {/* <h1 className="mb-4 shimmer title text-xl" style={{paddingTop: "0px"}} hidden={hidden}>{title}</h1> */}
        </>
    );
};

export default Title;

Title.propTypes = {
    /**
     * The title to display
     */
    title: PropTypes.string.isRequired,
    hidden: PropTypes.bool
};