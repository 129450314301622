import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import clickToCallLogService from "../../services/click-to-call-log.service";
import clickToCallsService from "../../services/click-to-calls.service";
import scriptService from "../../services/script.service";
import { NeogenClickToCallLog } from "../../typings/api/neogen-click-to-call-log";
import ButtonNeoGen from "../layout/button-neogen";
import PageDescription from "../layout/page-description";
import PrintPre from "../layout/print-pre";
import CTCSelector from "../utilities/ctc-selector";
import { formatPhonenumber } from "../utilities/formatters";
import Loader2 from "../utilities/Loader2";
import ShowCDR from "./modals/show-cdr";

interface IDictionary {
    [index: string]: NeogenClickToCallLog[];
}
const people = [
    { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    // More people...
];

export default function ClickToCallLogs() {
    // const [logs,setLogs] = useState<NeogenClickToCallLog[]>([]);
    const {uuid} = useParams();
    const [showCDR, setShowCDR] = useState(false);
    const [ctc, setCTC] = useState<number>(-1);
    const [uuidInt, setUUIDInt] = useState("");
    const [cdrUUID, setCDRUUID] = useState("");
    const clickToCallsQuery = useQuery(["ClickToCalls", uuidInt, ctc], async () => {
        const result = await clickToCallsService.getOneEntryByUUID(uuidInt??"");
        // console.error(result);
        if (result) {
            setCTC(result.id);
            setUUIDInt(result.uuid??"");
            // alert(result.id);
            return result;
        }
    });

    useEffect(() => {
        setUUIDInt(uuid??"");
    }, [uuid]);

    useEffect(() => {
        if (ctc > 0) {
            clickToCallsService.getOneEntry(ctc).then(r => {
                if (r && uuid !== r.uuid) {
                    setUUIDInt(r.uuid??"");
                    // console.info(window.location.href);
                    window.location.href = "/click-to-call-logs/"+r.uuid;
                }
                
            });
        }
        
        
    }, [ctc, uuid]);

    const scriptQuery = useQuery(["scripts"], async () => {
        const result = await scriptService.getAll();
        if (result) {
            return result.data;
        }
    });


    const clickToCallLogsQuery = useQuery(["ClickToCallLogs", uuidInt], async () => {
        const result = await clickToCallLogService.getAllByUUID(uuidInt);
        const entries:IDictionary = {};
        if (result) {
            result.data.forEach(e => {
                if (!Array.isArray(entries[e.uuid])) {
                    entries[e.uuid] = [];
                }
                entries[e.uuid].push(e);
            });
            // setLogs(entries);
            return result.data;
        }
    });
    return (
        <>
            {clickToCallsQuery.isLoading || scriptQuery.isLoading || clickToCallLogsQuery.isLoading? <Loader2 /> : (
                <>
                    {/* <PrintPre>
                        {clickToCallLogsQuery.data}
                    </PrintPre> */}
                    <PageDescription>
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Click To Call Logs</p>
                        <p className="mb-5">Here you&apos;ll find the click to call logs</p>
                        <CTCSelector setCTC={(e) => setCTC(e)} ctc={ctc}/>
                    </PageDescription>
                    {/* <div className="m-3"> */}
                    <div className="mt-8 flex flex-col m-3">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900">
                                        <thead className="bg-gray-50 dark:bg-gray-700">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-gray-400 ">
                                                    Date/Time
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Phone Number
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Remote IP Address
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Body
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Result
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-900 dark:bg-gray-800">
                                            {clickToCallLogsQuery.data?.map((log:NeogenClickToCallLog) => (
                                                <tr key={log.id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 dark:text-gray-400 ">
                                                        {new Date(log.eventDatetime).toLocaleString()}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">{formatPhonenumber(log.destination)}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">{log.remoteIp}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">{log.body}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">
                                                        <ButtonNeoGen text="Show CDR" onClick={() => {
                                                            setCDRUUID(log.result);
                                                            setShowCDR(true);
                                                        }}
                                                        />
                                                        {/* {log.result} */}
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ShowCDR show={showCDR} close={() => setShowCDR(false)} uuid={cdrUUID}/>
                    {/* </div> */}
                </>
            )}
        </>
    );
}