/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosResponse }      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenScriptFolderEntries } from "../typings/api";

const debug = require("debug")("NeoGen:ScriptFolderEntryService");

const API_URL = process.env.REACT_APP_API_URL;

class ScriptFolderEntryService extends APIService<NeogenScriptFolderEntries> {
    constructor() {
        super("neogen-script-folder-entries");
    }


    /**
     *
     * @param {Number} id
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getFolderEntriesByFolder(id:number) {
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"neogenScriptFolder\":\"" + id + "\"}}"), {headers: authHeader()});
    }

    /**
     *
     * @param {Numbers[]} folders
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getFolderEntriesByMultipleFolders(folders:number[]):Promise<void|AxiosResponse<NeogenScriptFolderEntries[]>> {
        debug(folders);
        let sortedFolders = folders.sort(function (a, b) {
            return a - b;
        });

        sortedFolders = [...new Set(sortedFolders)];
        debug("sortedFolders: ", sortedFolders);
        let newArray = [];
        for (let i = 0; i < sortedFolders.length; i++) {
            newArray.push(sortedFolders[i]);
        }

        let filter = {
            where: {
                scriptFolderId: {
                    inq:
                        newArray

                }
            }
        };
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent(JSON.stringify(filter)), {headers: authHeader()}).catch(e => {
            debug(e.response);
        });
    }

    async getByScriptAndFolder(scriptID:number, folderID:number) {
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + scriptID + "\", \"scriptFolderId\":\"" + folderID + "\"}}"), {headers: authHeader()});
    }

    /**
     * Get any script folder entries that are associated with a particular script
     *
     * @param {Number} scriptID
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getByScript(scriptID:number) {
        return await axios.get(API_URL + "/neogen-script-folder-entries/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + scriptID + "\"}}"), {headers: authHeader()});
    }
}

export default new ScriptFolderEntryService();