import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import authService from "../../../../services/auth.service";
import companyService from "../../../../services/company.service";
import flowchartService from "../../../../services/flowchart.service";
import integrationsService from "../../../../services/integrations-service";
import scriptPushIntegrationService from "../../../../services/script-push-integration.service";
import scriptService from "../../../../services/script.service";
import UserContext from "../../../../services/user-context";
import userService from "../../../../services/user.service";
import { NeogenFlowchart, NeogenScript, NeogenUser } from "../../../../typings/api";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../../../typings/api/neogen-integration";
import PrintPre from "../../../layout/print-pre";
import PurchasePhoneNumber from "../../../phone-numbers/modals/purchase-phonenumber";
import { formatPhonenumber } from "../../../utilities/formatters";
import "./answering-service.css";
import AdvancedStep from "./steps/advanced";
import BasicInfoStep from "./steps/basic-info";
import CallAnsweringStep from "./steps/call-answering-step";
import CreateStep from "./steps/create";
import EmailStep from "./steps/email";
import HoursStep from "./steps/hours";
import ServicesStep from "./steps/services";

type FormData = {
    companyName: string;
    companyAddress: string;
  };

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/solid";
import ButtonNeoGen from "../../../layout/button-neogen";

let twsteps = [
    { id: "01", name: "Basic Info", description: "Vitae sed mi luctus laoreet.", href: "#", status: "current" },
    { id: "02", name: "Call Answering", description: "Cursus semper viverra.", href: "#", status: "upcoming" },
    { id: "03", name: "Email", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
    { id: "04", name: "Advanced", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
    { id: "05", name: "Create Script", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
];

function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}


export default function AnsweringServiceWizard(props: AnsweringServiceWizardProps) {
    const [user,setUser]  = useContext(UserContext);
    const cache = useQueryClient();
    let {id} = useParams();
    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [answerTranscript, setAnswerTranscript] = useState("");
    const [email, setEmail] = useState(user.email??"");
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const [services, setServices] = useState<NeoGenIntegrationWrapper[]>([]);
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [steps, setSteps] = useState<JSX.Element[]>([]);
    const [buyNumber, setBuyNumber] = useState(false);
    const [canSeeBetaFeatures, setCanSeeBetaFeatures] = useState(false);
    const [recordAudio, setRecordAudio] = useState(false);
    const [showPurchaseNumber, setShowPurchaseNumber] = useState(false);
    const [number, setNumber] = useState("");
    const [creating, setCreating] = useState(false);
    const [basicInfoError, setBasicInfoError] = useState(false);
    const [answeringError, setAnsweringError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        if (buyNumber) {
            console.log("on");
        }
    }, [buyNumber]);

    useEffect(() => {
        // authService.canIAccess("CAN_SEE_BETA").then(r => {
        // console.error(r);
        // if (r) {
        setCanSeeBetaFeatures(true);
        // }
        // });
    }, []);

    const companyQuery = useQuery(["company"], async () => {
        let response = await companyService.getOne(user.company??-1);
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (companyQuery?.data?.name) {
            setCompanyName(companyQuery.data.name);
        }
    }, [companyQuery.data, companyQuery.isSuccess]);

    const onSubmit = function(data:any) {
        console.log(data);
        companyService.create(data).then((r:any) => {
            console.log(r);
            if (r) {
                console.log(r.data);
                userService.patchURL(userService.endpoint+"/"+user.id, {company: r.data.id}).then((res:any) => {
                    console.log(res);
                    // user.company = r.data.id;
                    let existing = JSON.parse(localStorage.getItem("user")??"");
                    existing.user.company = r.data.id;
                    localStorage.setItem("user", JSON.stringify(existing));
                    window.location.reload();
                });
            }
        });
    };

    function createBillingAccount() {
        console.log("go");
    }

    function validate() {
        if (props.step === 1 && companyName === "") {
            setBasicInfoError(true);
            // Swal.fire("Missing company name", "You forgot to put a company name", "error");
            return false;
        } else {
            setBasicInfoError(false);
        }
        if (props.step === 2 && answerTranscript === "") {
            // Swal.fire("Answering Option", "You need to let us know how to answer the call", "error");
            setAnsweringError(true);
            return false;
        } else {
            setAnsweringError(false);
        }
        console.error(props.step);
        if (props.step === 3 && email === "") {
            console.error("Email Blank");
            setEmailError(true);
            return false;
        } else {
            setEmailError(false);
        }
        setCompletedSteps([...completedSteps, props.step]);
        return true;
    }

    function setDuplicateProgress(progress: number) {
        console.log(progress);
    }

    async function createAgent(buyNumber: boolean, recordAudio: boolean) {
        setCreating(true);
        // if (buyNumber) {
        //     setShowPurchaseNumber(true);
        // } else {
        const userObj   = JSON.parse(localStorage.getItem("user")??"");
        // alert("BuyNumber: "+number);
        let folder = Number(props.folderId);
        // alert("Folder:"+id);
        let script:false|NeogenScript|undefined = await scriptService.duplicateScript(folder, 2221, companyName+" Virtual Agent", "Created By "+(user.name??"")+" on "+new Date().toLocaleString(), setDuplicateProgress, 2221);
        // Alter the flowchart to change the transcript
        if (!script ) {
            console.error("Couldn't duplicate script");
            return;
        }
        if (!script.newScriptId ) {
            console.error("Couldn't duplicate script (script.newScriptId)");
            return;
        }
        let response = await flowchartService.getFlowchartByScriptID(script.newScriptId);
        // console.error(response);
        if (response) {
            let flowchart:NeogenFlowchart = response.data[0];
            console.log(flowchart.jsonData);
            flowchart.jsonData = flowchart.jsonData?.replaceAll("%%answer%%", answerTranscript);
            flowchart.jsonData = flowchart.jsonData?.replaceAll("%%email%%", email);
            flowchartService.patchURL(flowchartService.endpoint+"/"+flowchart.id, flowchart);
        }
        


        let result = await axios.post("https://pp.neogen.ai/purchase", {token: userObj.token, number: number, scriptId: script.newScriptId??-1, language: 1, email: email, answerAs: companyName, cleanNumber: formatPhonenumber(number)});
        console.log(result);
        console.log(script);
        setCreating(false);
        let scriptId = script.newScriptId;

        services.forEach((service: NeoGenIntegrationWrapper) => {
            let sp: any = {
                scriptId: scriptId,
                integrationId: service.integration.id,
                destination: service.propOne,
                body: service.propTwo??""
            };
            scriptPushIntegrationService.create(sp).then(r => {
                console.log(r);
            });
        });


        
        // console.log(r, r1);
            
        Swal.fire({
            title: "Virtual Agent Created!",
            text: "Your virtual agent has been added to the pipeline",
            icon: "success",
            timerProgressBar: true,
            timer: 2000
        }).then(() => {
            cache.invalidateQueries(["phoneNumbers"]).then(r => {
                props.close();
            });
        });
        

        // }
    }

    function purchased(newNumberId: number) {
        if (status) {
            alert("BuyNumber: "+buyNumber+" Record Own Audio: "+recordAudio+" new Number ID: "+newNumberId);
            console.log("Purchase complete");
        }
    }


    function goNext() {
        if (!validate()) {
            return;
        }
        console.log(companyName);
        
        if (props.step < (canSeeBetaFeatures?5:4)) {
            goToStep(props.step+1);
        } else {
            // if (buyNumber && ((user.company??-1)<=0)) {
            //     console.log("x");
            // }
            createAgent(buyNumber, recordAudio);
        }
    }
    function goPrevious() {
        if (!validate()) {
            return;
        }
        if (props.step > 0) {
            goToStep(props.step-1);
        }
    }

    useEffect(() => {
        const allSteps = [];
        allSteps.push(<BasicInfoStep setCompanyName={setCompanyName} companyName={companyName} setCompanyAddress={setCompanyAddress} companyAddress={companyAddress} isError={basicInfoError}/>);
        allSteps.push(<CallAnsweringStep setAnswerTranscript={setAnswerTranscript} answerTranscript={answerTranscript} companyName={companyName}/>);
        allSteps.push(<EmailStep email={email} setEmail={setEmail} isError={emailError}/>);
        //<HoursStep timezone={timezone} setTimezone={setTimezone}/>
        if (canSeeBetaFeatures) {
            allSteps.push(<AdvancedStep services={services} setServices={setServices}/>);
        }
        allSteps.push(<CreateStep createBillingAccount={createBillingAccount} buyNumber={buyNumber} setBuyNumber={setBuyNumber} recordAudio={recordAudio} setNumber={setNumber} setRecordAudio={setRecordAudio} services={services}/>);
        setSteps(allSteps);
        
    }, [answerTranscript, basicInfoError, buyNumber, canSeeBetaFeatures, companyAddress, companyName, email, emailError, recordAudio, services]);

    useEffect(() => {
        twsteps = [
            { id: "01", name: "Basic Info", description: "Vitae sed mi luctus laoreet.", href: "#", status: "current" },
            { id: "02", name: "Call Answering", description: "Cursus semper viverra.", href: "#", status: "upcoming" },
            { id: "03", name: "Email", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
            { id: "04", name: "Advanced", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
            { id: "05", name: "Create Script", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
        ];
    }, []);


    function renderWizardBody() {
        return steps[props.step-1];
        // let finalStep = 4;
        // if (canSeeBetaFeatures) {
        //     finalStep = 5;
        // }
        // switch (props.step) {
        //     case 1:
        //         return <BasicInfoStep setCompanyName={setCompanyName} companyName={companyName} setCompanyAddress={setCompanyAddress} companyAddress={companyAddress}/>;
        //     case 2:
        //         return <CallAnsweringStep setAnswerTranscript={setAnswerTranscript} answerTranscript={answerTranscript} companyName={companyName}/>;
        //     case 3:
        //         return <EmailStep email={email} setEmail={setEmail}/>;
            
        //         // case 4:
        //         //     return <HoursStep timezone={timezone} setTimezone={setTimezone}/>;
            
        //     case finalStep:
        //     // return <ServicesStep />;
        //         return <CreateStep createBillingAccount={createBillingAccount} buyNumber={buyNumber} setBuyNumber={setBuyNumber} recordAudio={recordAudio} setNumber={setNumber} setRecordAudio={setRecordAudio}/>;
        // }
    }

    function goToStep(step: number) {
        let newStep = step;
        if (step > props.step) {
            if (!(completedSteps.includes(step-1) || props.step === step-1)) {
                newStep = (Math.max(props.step+1, Math.max(...completedSteps)+1));
            }
        }
        props.setStep(newStep);
        twsteps = twsteps.map((t:any) => {
            if (t.status === "current") {
                t.status = "complete";
            }
            if ((Number(t.id)) === newStep) {
                t.status = "current";
            } else {
                console.log((Number(t.id)) +"!=="+ newStep);
            }
            return t;
        });
    }


    return (
        

        <div className="wizardx p-0 m-0">

            {/* <PrintPre>{twsteps}</PrintPre> */}


            <div className=" lg:dark:border-gray-700 dark:bg-gray-600 rounded-t-lg bg-gray-200">
                <nav aria-label="Progress">
                    <ol className="border-b border-gray-300 dark:border-gray-700 rounded-t-md divide-y divide-gray-300 dark:divide-gray-700 grid grid-cols-5 max-w-full md:flex md:divide-y-0">
                        {twsteps.map((step, stepIdx) => (
                            <li key={step.name} className="relative md:flex-1 md:flex">
                                {step.status === "complete" ? (
                                    <div onClick={() => validate() && goToStep(stepIdx+1)} className="group flex items-center w-full ">
                                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 dark:bg-green-700 rounded-full group-hover:bg-indigo-800">
                                                <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                            </span>
                                            <span className="ml-4 hidden md:inline-block  text-sm font-medium text-gray-900 dark:text-green-400">{step.name}</span>
                                        </span>
                                    </div>
                                ) : step.status === "current" ? (
                                    <div onClick={() => validate() && goToStep(stepIdx+1)} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 dark: dark:bg-indigo-600 rounded-full dark:border-indigo-200">
                                            <span className="text-indigo-600 dark:text-gray-100">{step.id}</span>
                                        </span>
                                        <span className="hidden md:inline-block ml-4 text-sm font-medium text-indigo-600 dark:text-white">{step.name}</span>
                                    </div>
                                ) : (
                                    <div onClick={() => validate() && goToStep(stepIdx+1)} className="group flex items-center">
                                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 dark:border-gray-700 rounded-full group-hover:border-gray-400">
                                                <span className="text-gray-500 group-hover:text-gray-900 dark:text-gray-400">{step.id}</span>
                                            </span>
                                            <span className="hidden md:inline-block ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 dark:text-indigo-400">{step.name}</span>
                                        </span>
                                    </div>
                                )}

                                {stepIdx !== twsteps.length - 1 ? (
                                    <>
                                        {/* Arrow separator for lg screens and up */}
                                        <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                                            <svg
                                                className="h-full w-full text-gray-300 dark:text-gray-700"
                                                viewBox="0 0 22 80"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path
                                                    d="M0 -2L20 40L0 82"
                                                    vectorEffect="non-scaling-stroke"
                                                    stroke="currentcolor"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>



            <div className="wizard-body p-4 grid grid-cols-1">
                <form >
                    {renderWizardBody()}
                </form>
            </div>
            <div className="flex">
                <div className="wizard-footer mt-5 mb-0 pl-4 pr-4">
                    <button className="bg-gray-100 hover:bg-indigo-700 text-gray-400 py-2 px-4 dark:bg-gray-600 rounded-lg mr-3" onClick={() => props.close()}>Cancel</button>
                    
                </div>
                <div className="flex-grow"></div>
                <div className="wizard-footer mt-5 mb-0 pl-4 pr-4">
                    <button className="bg-gray-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg mr-3" disabled={props.step === 1} onClick={() => goPrevious()}>Previous</button>
                    <ButtonNeoGen text={props.step===(canSeeBetaFeatures?5:4)?"Finish":"Next"} onClick={ () => goNext()} className="mb-5"></ButtonNeoGen>
                </div>
            </div>
            
            {/* <PurchasePhoneNumber show={showPurchaseNumber} hide={() => setShowPurchaseNumber(false)} purchased={(e) => purchased(e)}/> */}
        </div>

        
    );
}

type AnsweringServiceWizardProps = {
    step: number,
    folderId: number,
    setStep: (step: number) => void,
    close: () => void,
}