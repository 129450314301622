import { ExclamationCircleIcon } from "@heroicons/react/solid";
export default function BasicInfoStep(props: BasicInfoStepProps) {
    return (
        <>
            <p className="wizard-step-explanation">
                What is the company name you would like the virtual agent to answer the calls as?
            </p>
            
            {/* <div className="form-group-wizard">
                <label htmlFor="formGroupExampleInput" className="wizard">Company Name:</label>
                <input type="text" className="form-control-wizard form-control"  name="companyName" placeholder="i.e. Acme Widgets LLC" value={props.companyName} onChange={(e) => props.setCompanyName(e.currentTarget.value)}/>
            </div> */}
            {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Company Name
            </label> */}
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    type="text"
                    name="email"
                    id="email"
                    className={"dark:bg-gray-800 border-0 "+(props.isError?"block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md")}
                    placeholder="i.e. Acme Widgets"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    value={props.companyName} onChange={(e) => props.setCompanyName(e.currentTarget.value)}
                />
                {props.isError && (<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>)}
            </div>
            {props.isError && (<p className="mt-2 text-sm text-red-600" id="email-error">
                You need to provide your company name
            </p>)}
            {/* <div className="form-group">
                <label htmlFor="formGroupExampleInput2">Full Address:</label>
                <input type="text" className="form-control" name="companyName" placeholder="i.e. 123 Some Street, Some City, Some Country" value={props.companyAddress} onChange={(e) => props.setCompanyAddress(e.currentTarget.value)}/>
            </div> */}
        </>
    );
}

type BasicInfoStepProps = {
    companyName: string;
    setCompanyName: (companyName: string) => void;
    companyAddress: string;
    setCompanyAddress: (companyAddress: string) => void;
    isError: boolean
}