import PageDescription from "../layout/page-description";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Switch from "react-switch";
import integrationsService from "../../services/integrations-service";
import virtualAgentFeaturesService from "../../services/virtual-agent-features.service";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../typings/api/neogen-integration";
import IntegrationTypes from "../integrations/integration-types";
import Service from "../scripts/modals/components/steps/service";

type feature = {
    id: number,
    
    enabled: boolean,
    data: string
}


export default function Integrations(props:IntegrationProps) {
    const [services, setServices] = useState<NeoGenIntegrationWrapper[]>([]);
    const cache = useQueryClient();
    const [features, setFeatures] = useState<feature[]>([]);

    const servicesQuery = useQuery(["virtualAgentFeatures"], async () => {
        const response = await virtualAgentFeaturesService.getAll();
        if (response) {
            return response.data;
        }
    });

    function addIntegration(i: NeoGenIntegrationWrapper) {
        console.log("add",i);
        setServices((c:any) => [...c, i]);
    }

    function removeIntegration(i: NeoGenIntegration) {
        console.log("remove",i);
        if (i.id) {
            integrationsService.deleteByID(i.id).then(r1 => {
                cache.invalidateQueries(["integrations"]).then(r2 => {
                    console.log(r1,r2);
                    // setSaving(false);
                });
            });
            
            cache.invalidateQueries(["integrationTypes"]).then(r => {
                console.log(r);
                // setSaving(false);
            });
        }
        
    }

    return (
        <>
        
            <PageDescription>
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Integrations</p>
                <p className="mb-5">Here you&apos;ll find a list of ways we alert you about new calls.</p>
                <IntegrationTypes addIntegration={addIntegration} removeIntegration={removeIntegration} services={services}/>
            </PageDescription>

            {/* <p className="wizard-step-explanation">
                Here you can select any API&apos;s you&apos;d like to push records to.<br />
                <br />
                Just click connect on any you would like to use and it will be set up for you.  You don&apos;t have to
                select anything here if you&apos;d just like messages delivered to your email.
            </p> */}
            
            {/* {servicesQuery.isLoading?(<>Loading</>):(
                servicesQuery.data.map((s:any) => {
                    return (
                        <Service key={s.id} s={s} />
                    );
                })
                
            )} */}
            
            
            
        </>
    );
}
type IntegrationProps = {
    
    
}

    
