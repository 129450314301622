import { useState } from "react";
import InputNeoGenControlled from "./input-neogen-controlled";
import ModalNeoGen from "./modal-neogen";
import TextAreaNeoGenControlled from "./text-area-neogen-controlled";

export default function ModalFieldValue(props: ModalFieldValueProps) {
    const [field, setField] = useState<string>(props.field??"");
    const [value, setValue] = useState<string>(props.value??"");
    const [description, setDescription] = useState<string>(props.description??"");
    function saveRecord() {
        // console.log(data);
        if (props.isEdit && props.saveEdit) {
            props.saveEdit(field, value, description);
        } else {
            props.save(field, value, description);
        }
        
    }
    return (
        <>
            <ModalNeoGen title={props.title} close={props.close} show={props.show} okAction={saveRecord} okText="Save">
                <InputNeoGenControlled 
                    label={"Field Name"}
                    name={props.name}
                    value={field}
                    setValue={(value:string) => setField(value)}
                />
                <InputNeoGenControlled 
                    label={"Label"}
                    name={props.label}
                    value={value}
                    setValue={(value:string) => setValue(value)}
                />
                <TextAreaNeoGenControlled 
                    label={"Description Line"}
                    name={props.description}
                    value={description}
                    setValue={(value:string) => setDescription(value)}
                />
            </ModalNeoGen>
        </>
    );
}

type ModalFieldValueProps = {
    title: string;
    show: boolean;
    close: () => void;
    label: string;
    description: string;
    name: string;
    isEdit?: boolean;
    field?: string;
    value?: string;
    save: (field: string, value: string, description: string) => void;
    saveEdit?: (field: string, value: string, description: string) => void;
}