import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NeogenTransferDestinationHours } from "../../../typings/api/neogen-transfer-destination-hours";
import moment from "moment-timezone";
import transferDestinationHoursService from "../../../services/transfer-destination-hours.service";
import { useQuery, useQueryClient } from "react-query";
import TimePicker from "../../utilities/timepicker";
// @ts-ignore I can't get the .d.ts working
import ScheduleSelector from "react-schedule-selector";
import TimeZonePicker from "../../utilities/TimeZonePicker";
import Swal from "sweetalert2";
import "./edit-hours.css";
import ModalNeoGen from "../../layout/modal-neogen";
 
const today = moment();
const from_date = today.startOf("isoWeek");

export default function EditHours(props: EditHoursProps) {
    const [hoursList, setHoursList] = useState<NeogenTransferDestinationHours[]>([]);
    const [dayToAdd, setDayToAdd] = useState(1);
    const [startHour, setStartHour] = useState(9);
    const [startMinute, setStartMinute] = useState(0);
    const [endHour, setEndHour] = useState(17);
    const [endMinute, setEndMinute] = useState(0);
    const [schedule, setSchedule] = useState<Date[]>([]);
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [timeOptions, setTimeOptions] = useState<Date[]>([]);
    const [start, setStart] = useState<Date>();
    const [minTime, setMinTime] = useState(8);
    const [maxTime, setMaxTime] = useState(18);
    const [blocksPerHour, setBlocksPerHour] = useState(2);
    
    const tzNames = moment.tz.names();
    const cache = useQueryClient();
    const transferDestinationQuery = useQuery(["transferDestinationHours", props.id], async () => {
        const result = await transferDestinationHoursService.getByDestination(props.id);
        if (result !== undefined) {
            console.log(result);
            let dates:Date[] = [];
            result.data.forEach((i:NeogenTransferDestinationHours) => {
                console.log(i);
                const newDate = moment();
                newDate.hours(i.openHour??-1);
                newDate.day(i.day??0);
                newDate.minute(i.openMinute??0);
                // console.log(newDate.toDate());
                dates.push(newDate.toDate());
            });
            setSchedule(dates);
            return result.data;
        }
    });
    // console.log(props.id);
    // console.log(moment.tz.names());
    
    // function getStartTime() {
    //     return start;
    // //     // let date = new Date(2021, 1, 1,startHour, startMinute);
    // //     // let currentDay = date.getDay();
    // //     // let dayToSet = dayToAdd;
    // //     // let distance = dayToSet - currentDay;
    // //     // console.log(distance);
    // //     // console.log(date.getTime());
    // //     // date.setDate(date.getDate() + distance);
    // //     console.warn(date.toLocaleTimeString());
    // //     return date ;
    // // }
    function setStartTime(time: string) {
        
        console.warn(moment(time, "h:mm a"));
        setStart(moment(time, "h:mm a").toDate());
    //     // console.warn(time);
    //     // console.warn();
    //     let newTime = moment(time, "h:mm a").toDate();
    //     setStartHour(newTime.getHours());
    //     setStartMinute(newTime.getMinutes());
    }
    function getEndTime() {
        let date = new Date(2021, 1, 1,endHour, endMinute);
        let currentDay = date.getDay();
        let dayToSet = dayToAdd;
        let distance = dayToSet - currentDay;
        date.setDate(date.getDate() + distance);
        return date ;
    }
    function setEndTime(time: string) {
        let newTime = new Date(Number(time));
        setEndHour(newTime.getHours());
        setEndMinute(newTime.getMinutes());
    }
    useEffect(() => {
        let timesTemp: Date[] = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute +=15) {
                timesTemp.push( new Date(2021, 1, 1, hour, minute));
            }
        }
        setTimeOptions(timesTemp);
    }, []);

    function addHours() {
        // console.log(getStartTime()+ " - "+getEndTime());
        // const start = new Date(getStartTime().getTime());
        // const end = new Date(getStartTime().getTime());
        // console.log(start.toLocaleDateString());
        // console.log(end.toLocaleDateString());
        // console.log(start.getDay());
        console.log(dayToAdd);
        const newEntry: NeogenTransferDestinationHours = {
            openHour: startHour,
            openMinute: startMinute,
            closeHour: endHour,
            closeMinute: endMinute,
            day: dayToAdd,
            destinationId: props.id,
            timezone: timeZone,
        };
        console.log(newEntry);
        transferDestinationHoursService.create(newEntry).then(r => {
            console.log(r);
            cache.invalidateQueries(["transferDestinationHours", props.id]).then(r1 => {
                console.log(r, r1);
            });
        });
    }

    function deleteEntry(id: number) {
        console.log(id);
        transferDestinationHoursService.deleteByID(id).then(r => {
            cache.invalidateQueries(["transferDestinationHours", props.id]).then(r1 => {
                console.log(r, r1);
            });
        });
    }

    let dayNames: string[] = [];
    dayNames[0] = "Sunday";
    dayNames[1] = "Monday";
    dayNames[2] = "Tuesday";
    dayNames[3] = "Wednesday";
    dayNames[4] = "Thursday";
    dayNames[5] = "Friday";
    dayNames[6] = "Saturday";

    function handleChange(newSelection: any) {
        console.warn(newSelection);
        
        setSchedule(newSelection);
    }

    async function saveChanges() {
        // First delete existing schedules for this number
        Swal.fire({
            title: "Saving",
            html:
                        "<div class=\"save_loading\"><svg viewBox=\"0 0 140 140\" width=\"140\" height=\"140\"><g class=\"outline\"><path d=\"m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84\" stroke=\"rgba(0,0,0,0.1)\" stroke-width=\"4\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><g class=\"circle\"><path d=\"m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84\" stroke=\"#71BBFF\" stroke-width=\"4\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dashoffset=\"200\" stroke-dasharray=\"300\"></path></g></svg></div><div></div>",
            showConfirmButton: false,
            allowOutsideClick: false
        });
        const existingResult = await transferDestinationHoursService.getByDestination(Number(props.id));
        console.log(existingResult);

        const promises: any[] = [];

        if (existingResult && existingResult.data.length > 0) {
            existingResult.data.forEach((e: NeogenTransferDestinationHours) => {
                promises.push(transferDestinationHoursService.deleteByID(e.id));
            });
        }

        

        // Next add back in the new ones
        schedule.forEach((i: Date) => {
            const day = i.getDay();
            const openHour = i.getHours();
            const openMinute = i.getMinutes();
            const end = moment(i).add((60/blocksPerHour), "minutes").toDate();
            const closeHour = end.getHours();
            const closeMinute = end.getMinutes();
            const newEntry = {day,  openHour,  openMinute,  closeHour,  closeMinute, timezone: timeZone, destinationId: Number(props.id)};
            promises.push(transferDestinationHoursService.create(newEntry));
            // console.log(r);
        });
        Promise.all(promises).then(() =>
        {
            Swal.fire({
                title: "Hours Saved",
                icon: "success",
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                props.hide();
            });}
        );
        
    }

    // function save() {
    //     console.warn(schedule);
    // }

    function renderDateCell(datetime: Date, selected: boolean, refSetter: (dateCell: HTMLElement | null) => void)  {
        // console.log(datetime);
        const end = moment(datetime).add((60/blocksPerHour), "minutes");
        // console.log(datetime+" -> "+end.toDate());
        return (
            <div className="text-center" ref={refSetter} style={{background: selected?"#078bf2":"#f7f7f7", color: "#f7f7f7"}} onMouseEnter={(e) => {
                e.currentTarget.style.background="#00ffec";
                e.currentTarget.style.color="#000";
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.background=(selected?"#078bf2":"#f7f7f7");
                e.currentTarget.style.color="#f7f7f7";
            }}>
                {
                    // @ts-ignore - Typescript incorrectly says timeStyle does not exist - see https://github.com/microsoft/TypeScript/issues/35865
                    datetime.toLocaleTimeString([], {timeStyle: "short"}).replace("AM","").replace("PM","")+" -> "+end.toDate().toLocaleTimeString([], {timeStyle: "short"}).replace("AM","").replace("PM","")
                }
            </div>
        );
    }

    return (
        <ModalNeoGen show={props.show} close={() => props.hide()} size="xl" title="Edit Hours" okText="Save Hours" okAction={() => saveChanges()}>
            <Modal.Header closeButton>
                Edit Hours
            </Modal.Header>
            <Modal.Body className="">
                <form>
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-3">
                            <TimeZonePicker timezone={timeZone} setTimeZone={(tz:string) => setTimeZone(tz)} />
                            
                        </div>
                        <div className="col-span-3">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Minimum Start Time to Display</label>
                                <select name="time" className="form-control" onChange={(e) => setMinTime(Number(e.currentTarget.value))} value={minTime}>
                                    <option value="0">12.00 AM</option>
                                    <option value="1">01.00 AM</option>
                                    <option value="2">02.00 AM</option>
                                    <option value="3">03.00 AM</option>
                                    <option value="4">04.00 AM</option>
                                    <option value="5">05.00 AM</option>
                                    <option value="6">06.00 AM</option>
                                    <option value="7">07.00 AM</option>
                                    <option value="8">08.00 AM</option>
                                    <option value="9">09.00 AM</option>
                                    <option value="10">10.00 AM</option>
                                    <option value="11">11.00 AM</option>
                                    <option value="12">12.00 PM</option>
                                    <option value="13">01.00 PM</option>
                                    <option value="14">02.00 PM</option>
                                    <option value="15">03.00 PM</option>
                                    <option value="16">04.00 PM</option>
                                    <option value="17">05.00 PM</option>
                                    <option value="18">06.00 PM</option>
                                    <option value="19">07.00 PM</option>
                                    <option value="20">08.00 PM</option>
                                    <option value="21">09.00 PM</option>
                                    <option value="22">10.00 PM</option>
                                    <option value="23">11.00 PM</option>
                    
                                </select>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput2">Minimum End Time To Display</label>
                                <select name="time" className="form-control" onChange={(e) => setMaxTime(Number(e.currentTarget.value))} value={maxTime}>
                                    <option value="0">12.00 AM</option>
                                    <option value="1">01.00 AM</option>
                                    <option value="2">02.00 AM</option>
                                    <option value="3">03.00 AM</option>
                                    <option value="4">04.00 AM</option>
                                    <option value="5">05.00 AM</option>
                                    <option value="6">06.00 AM</option>
                                    <option value="7">07.00 AM</option>
                                    <option value="8">08.00 AM</option>
                                    <option value="9">09.00 AM</option>
                                    <option value="10">10.00 AM</option>
                                    <option value="11">11.00 AM</option>
                                    <option value="12">12.00 PM</option>
                                    <option value="13">01.00 PM</option>
                                    <option value="14">02.00 PM</option>
                                    <option value="15">03.00 PM</option>
                                    <option value="16">04.00 PM</option>
                                    <option value="17">05.00 PM</option>
                                    <option value="18">06.00 PM</option>
                                    <option value="19">07.00 PM</option>
                                    <option value="20">08.00 PM</option>
                                    <option value="21">09.00 PM</option>
                                    <option value="22">10.00 PM</option>
                                    <option value="23">11.00 PM</option>
                    
                                </select>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput2">Blocks To Show</label>
                                <select name="time" className="form-control" onChange={(e) => setBlocksPerHour(Number(e.currentTarget.value))} value={blocksPerHour}>
                                    <option value="1">1 per hour</option>
                                    <option value="2">Every 30 minutes</option>
                                    <option value="4">Every 15 minutes</option>
                                    <option value="6">Every 10 minutes</option>
                                    <option value="12">Every 5 minutes</option>
                                    <option value="60">Every minute</option>
                    
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    
                </form>
                
                

                


                <div className="card">
                    <div className="card-header">
                        Click and drag to select/deselect open hours
                    </div>
                    <div className="card-body">
                        {transferDestinationQuery.isSuccess && (
                            <ScheduleSelector
                                selection={schedule}
                                startDate={from_date.toDate()}
                                numDays={7}
                                minTime={minTime}
                                maxTime={maxTime}
                                hourlyChunks={blocksPerHour}
                                dateFormat={"ddd"}
                                timeFormat={"h:mm a +"}
                                onChange={handleChange}
                                onHover={(e:any) => console.log(e)}
                                renderDateCell={renderDateCell}
                            />
                        )}
                        
                    </div>
                </div>
                
                
                
            </Modal.Body>
            <Modal.Footer>
                {/* <button className={"btn btn-secondary"} onClick={() => props.hide()}>Close</button> */}
                {/* <button className={"bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg"} onClick={() => saveChanges()}>Save Changes</button> */}
            </Modal.Footer>
        </ModalNeoGen>
    );
}

type EditHoursProps = {
    show: boolean,
    hide: any,
    // isEdit: boolean,
    id: number
}