/*
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/9/20, 8:15 AM
 *
 */

import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({theme}) => theme.body.background};
    color: ${({theme}) => theme.text};
    font-family: ${({theme}) => theme.body.fontFamily};
    // transition: all .50s linear;
  }
  .card {
    box-shadow: ${({theme}) => theme.card.boxShadow} ;
    color: ${({theme}) => theme.text};
    // transition: all .50s linear;
  }
  `;