import { useState } from "react";
import { getARINodes, reloadARINodes } from "../../../services/arinode.service";
import ButtonNeoGen from "../../layout/button-neogen";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";

export default function AriReload() {
    const [loading, setLoading] = useState(false);
    const [nodes, setNodes] = useState([]);
    async function reload() {
        const nodes = await getARINodes();
        if (nodes) {
            setNodes(nodes.data);
            const status = await reloadARINodes(nodes.data.responses);
            console.log(status);
        }
        
    }
    return (
        <>
            <PageDescription >
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">ARI Reload</p>
                <p className="mb-5">This allows you to request an instant update of all ARI node cached data</p>
            </PageDescription>
            <div className="px-0">
                <ButtonNeoGen onClick={() => {
                    reload();
                }}
                text="Reload ARI Nodes"
                />
                <PrintPre>
                    {nodes}
                </PrintPre>
            </div>
        </>
    );
}