/**
 * Script Service
 *
 * Provides access to script information via the API
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import FlowchartService from "./flowchart.service";
import QuestionService from "./question.service";
import QuestionOptionService from "./question-option.service";
import QuestionAudioService from "./question-audio.service";
import APIService from "./API.service";
import ScriptFolderAccessService from "./script-folder-access.service";
import ScriptFolderEntryService from "./script-folder-entry.service";
import { NeogenScript } from "../typings/api";
import { AxiosResponse } from "axios";


const debug = require("debug")("NeoGen:ScriptService");

class ScriptService extends APIService<NeogenScript> {

    constructor() {
        super("neogen-scripts");
    }

    async getAccessLevel(userId: number, scriptId: number) {
        let entries = await ScriptFolderEntryService.getByScript(Number(scriptId));
        for (let i = 0; i < entries.data.length; i++) {
            let access = await ScriptFolderAccessService.getAllByFolder(Number(entries.data[i].scriptFolderId));
            console.log(access);
        }
        // entries.data.forEach(e => {
        //
        // })

        // console.log(entries);
    }
    getAllByIDs(ids: number[]):Promise<void|AxiosResponse<NeogenScript[]>> {
        let filter = {
            where: {
                id: {
                    inq: ids
                }
            }
        };
        return this.getURL(this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }


    /**
     * Deletes a script and anything associated with it
     *
     * @param {Number} id - the ID of the script to duplicate
     * @param {function} setProgress - callback to update UI
     * @returns {Promise}
     */
    async deleteScript(id: number, setProgress: any):Promise<boolean> {
        
        console.log("delete script"+id);
        setProgress(0);
        let response = await this.getOne(id);
        if (response) {
            let script = response.data;
            console.log("Got script: ", script);
            setProgress(1);
            const response2 = await FlowchartService.getFlowchartByScriptID(id);
            let flowchart = response2?.data[0];
            setProgress(2);
            const response3 = await QuestionService.getAllQuestionsByScriptID(id);
            let questions = response3?.data;
            setProgress(3);
            const response4 = await QuestionOptionService.getAll();
            let allQuestionOptions = response4?.data;
            setProgress(5);
            const response5 = await QuestionAudioService.getAllByScriptID(id);
            let audio = response5?.data;
            setProgress(7);
    
            console.log(script);
            console.log(this.getFields(script));
    
            console.log("Flowchart: ", flowchart);
            console.log("Questions: ", questions);
            console.log("All Question Options: ", allQuestionOptions);
            console.log("Audio: ", audio);
    
            // try {
            // response = await this.create(script)
            // console.log(response)
            setProgress(10);
            // let newScriptId    = response.data.id
            console.log(flowchart);
            if (typeof (flowchart) === "undefined") {
                console.log("No flowchart");
            } else {
                await FlowchartService.deleteByID(flowchart.id).catch(() => {
                    console.log("Flowchart Not Deleted");
                });
            }
    
            setProgress(20);
    
            let countOfOps = 0;
            questions?.forEach((question:any) => {
                countOfOps++;
    
                allQuestionOptions?.forEach((questionOption:any) => {
                    if (questionOption.question === question.id) {
                        countOfOps++;
                    }
                });
            });
            if (Array.isArray(audio)) {
                audio.forEach(() => {
                    countOfOps++;
                });
            }
            
            let increment:number;
    
            if (countOfOps > 0) {
                increment = 80 / countOfOps;
            } else {
                increment = 80;
            }
    
            let currentNum = 0;
            questions?.forEach((question:any) => {
                currentNum += increment;
                setProgress(20 + currentNum);
    
                // question.scriptId = newScriptId
    
                allQuestionOptions?.forEach(async (questionOption:any) => {
                    if (questionOption.question === question.id) {
                        currentNum += increment;
                        setProgress(20 + currentNum);
                        await QuestionOptionService.deleteByID(questionOption.id);
                        // questionOption.deleteByID();
                    }
                });
                QuestionService.deleteByID(question.id);
                // question.deleteByID();
            });
    
            audio?.forEach((file:any) => {
                // file.deleteByID();
                currentNum += increment;
                setProgress(20 + currentNum);
                QuestionAudioService.deleteByID(file.id);
            });
            await this.deleteByID(script.id);
            console.log("All Done!!!");
            setProgress(100);
    
            return true;
        } else {
            return false;
        }
        
    }

    /**
     * Duplicates a script and anything associated with it
     *
     * @param {Number} folder - id of the folder
     * @param {Number} id - the ID of the script to duplicate
     * @param {String?} newName - the name of a script if you are adding a new one from a template
     * @param newDescription
     * @param {function} setDuplicateProgress - callback to update UI
     * @returns {Promise}
     */
    async duplicateScript(folder:number, id:number, newName:(string|null), newDescription:string, setDuplicateProgress:any, willBecomeTemplate = 0) {
        console.log("duplicating script"+id);
        setDuplicateProgress(0);
        console.log("Set Progress To 0");
        
        let response = await this.getOne(id);
        if (!response) {
            console.error("Couldn't find script!!!");
            return false;
        }
        console.log("Got script "+id);
        let script = response.data;
        if (!script.id) {
            console.error("No script ID on script");
            return;
        }
        setDuplicateProgress(1);
        const response2 = await FlowchartService.getFlowchartByScriptID(script.id);
        let flowchart = response2?.data[0];
        if (!flowchart) {
            console.error("No Flowchart");
            return;
        }
        setDuplicateProgress(2);
        const response3 = await QuestionService.getAllQuestionsByScriptID(script.id);
        let questions = response3?.data;
        setDuplicateProgress(3);
        const response4 = await QuestionOptionService.getAll();
        let allQuestionOptions = response4?.data;
        setDuplicateProgress(5);
        const response5 = await QuestionAudioService.getAllByScriptID(id);
        let audio = response5?.data;
        setDuplicateProgress(7);
        console.log(script);
        console.log(this.getFields(script));
        console.log("Flowchart: ", flowchart);
        console.log("Questions: ", questions);
        console.log("All Question Options: ", allQuestionOptions);
        console.log("Audio: ", audio);


        // Test creating a script
        try {
            if (newName !== null) {
                script.name = newName;
                script.description = newDescription;
                script.isTemplate = willBecomeTemplate;
            } else {
                script.name = "Copy of " + script.name;
            }
            script.lastUpdated = new Date();

            const response6:any = await this.create(script);
            console.log(response);
            setDuplicateProgress(10);
            let newScriptId = response6?.data.id;

            // Create a folder entry for it
            ScriptFolderEntryService.create({ scriptId: newScriptId, scriptFolderId: folder }).then(r => {
                console.log(r);
            }).catch(e => {
                console.log(e);
            });

            script.newScriptId = newScriptId;
            flowchart.scriptId = newScriptId;
            const response7 = await FlowchartService.create(flowchart);
            setDuplicateProgress(20);
            console.log(response7);

            let countOfOps = 0;
            questions?.forEach((question:any) => {
                countOfOps++;

                allQuestionOptions?.forEach((questionOption:any) => {
                    if (questionOption.question === question.id) {
                        countOfOps++;
                    }
                });
            });
            audio.forEach(() => {
                countOfOps++;
            });
            let increment: number;
            if (countOfOps > 0) {
                increment = 80 / countOfOps;
            } else {
                increment = 80;
            }

            let currentNum = 0;
            questions?.forEach((question:any) => {
                currentNum += increment;
                setDuplicateProgress(20 + currentNum);

                question.scriptId = newScriptId;
                QuestionService.create(question)
                    .then((createQuestionResponse:any) => {
                        allQuestionOptions?.forEach((questionOption:any) => {
                            if (questionOption.question === question.id) {

                                questionOption.question = createQuestionResponse?.data.id;
                                QuestionOptionService.create(questionOption)
                                    .then(createQuestionOptionResponse => {
                                        setDuplicateProgress(20 + currentNum);
                                        console.log(createQuestionOptionResponse);
                                    });
                            }
                        });
                        console.log(createQuestionResponse);
                    });
            });
            audio.forEach((file:any) => {
                file.scriptId = newScriptId;
                QuestionAudioService.create(file)
                    .then(fileCreateResult => {
                        setDuplicateProgress(20 + currentNum);
                        console.log(fileCreateResult);
                    })
                    .catch(error => {
                        console.log("Tried to send: ", script);
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log("Error", error.message);
                        }
                        console.log(error.config);
                    });
            });
            console.log("All Done!!!");
            setDuplicateProgress(100 + currentNum);
            // let newFlowchartID = result.data.id
            // script.
        } catch
        (error: any) {
            console.log("Tried to send: ", script);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
        }

        return script;
    }

    countUp(setDuplicateProgress:any) {
        let x = 0;
        setInterval((setDuplicateProgressIn) => {
            console.log("firing: " + x);
            x++;
            setDuplicateProgressIn(x);
        }, 1000, setDuplicateProgress);
    }

}

export default new ScriptService();