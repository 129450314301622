/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/24/20, 10:39 AM
 *
 */

import axios, { AxiosError } from "axios";
import { NeogenRoles, NeogenUser } from "../typings/api";
import API from "./API.service";
// import { useQuery, useMutation, useQueryClient, QueryCache, ReactQueryCacheProvider } from "react-query";

const debug = require("debug")("NeoGen:AuthService");

const API_URL = process.env.REACT_APP_API_URL;
const NLP_URL = process.env.REACT_APP_NLP_URL;

class AuthService {
    roles = [];
    user = null;
    API: API<NeogenUser>;
    // cache = useQueryClient();

    constructor() {
        this.API = new API("/users");
    }

    async login(email:string, password:string) {
        localStorage.clear();
        let that = this;
        try {
            let response = await this.API.postURL("neogen-users/refreshLogin", {
                email,
                password
            });            
            // console.warn(response);
            if (typeof(response) === "undefined") {
                throw new Error("Invalid Username/Password");
            }
            localStorage.setItem("refreshToken", response.data.refreshToken);

            if (!response.data.user.emailVerified) {
                console.log(response.data);
                console.log(response.data.user.emailVerified);
                const e:any = new Error("Email Not Verified");
                e.id = response.data.id;
                throw e;
            }

            if (response.data.user.stPass && response.data.user.stPass.length > 0) {
                const r2 = await this.stLogin(response.data.user.email,response.data.user.stPass, response.data.user.stUrl);
                // console.error(r2);
                // alert(JSON.stringify(r2.token));
                response.data.user.stToken = r2.token;
                delete(response.data.user.stPass);
                // alert(r.user.email);
                // alert(r.user.stUrl);
                // alert(r.user.stPass);
            }

            response.data.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out

            this.roles = response.data.roles;
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                // @todo: is this ok? Are we cool with potential MITM attacks giving us a different response?
                this.user = response.data;
                // console.log(this.data);
            }
            
            // this.API.getURL("users/" + response.data.id + "/neogen-roles").then(rbac_response => {
            //     console.log("rbac_response: ", rbac_response);
            //     that.roles = rbac_response.data;
            // });

            // console.log("Logging NLP in to " + NLP_URL + "login");
            // let nlp_response = await axios.post(NLP_URL + "login", {
            //     email: email,
            //     password: password
            // });
            // // console.log("nlp_response: ",nlp_response)
            // localStorage.setItem("nlp", nlp_response.data);
            return response.data;
        } catch (e) {
            console.error(e);
            console.error((e as AxiosError).response);
            throw(e);
        }

    }




    async stLogin(email:string, password:string, url: string) {
        // let that = this;
        try {
            const credentials = {
                email,
                password
            };
            // console.log(credentials);
            let response = await axios.post(url+"/users/login", credentials);            
            // console.warn(response);
            // alert(response.data);
            // if (typeof(response) === "undefined") {
            //     throw new Error("Invalid Username/Password");
            // }
            // localStorage.setItem("refreshToken", response.data.refreshToken);

            // if (!response.data.user.emailVerified) {
            //     console.log(response.data);
            //     console.log(response.data.user.emailVerified);
            //     const e:any = new Error("Email Not Verified");
            //     e.id = response.data.id;
            //     throw e;
            // }

            // response.data.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out

            // this.roles = response.data.roles;
            // if (response.data.token) {
            //     localStorage.setItem("user", JSON.stringify(response.data));
            //     // @todo: is this ok? Are we cool with potential MITM attacks giving us a different response?
            //     this.user = response.data;
            //     // console.log(this.data);
            // }
            
            // this.API.getURL("users/" + response.data.id + "/neogen-roles").then(rbac_response => {
            //     console.log("rbac_response: ", rbac_response);
            //     that.roles = rbac_response.data;
            // });

            // console.log("Logging NLP in to " + NLP_URL + "login");
            // let nlp_response = await axios.post(NLP_URL + "login", {
            //     email: email,
            //     password: password
            // });
            // // console.log("nlp_response: ",nlp_response)
            // localStorage.setItem("nlp", nlp_response.data);
            return response.data;
        } catch (e) {
            console.error(e);
            console.error((e as AxiosError).response);
            throw(e);
        }

    }

    async getRoles() {
        // console.log()
        // console.log(this);
        if (this.getCurrentUser() !== null) {
            // console.log("User is not null");
            if (this.roles.length === 0) {
                let id = await this.API.getURL("/whoAmI");
                // console.log("id: ", id);
                if (id?.data) {
                    let rbac_response = await this.API.getURL("users/" + id.data + "/neogen-roles");
                    // console.log("rbac_response: ", rbac_response);
                    this.roles = rbac_response?.data;
                    return rbac_response?.data;
                } else {
                    return [];
                }
            } else {
                // } else {
                // console.log("this.roles: ", this.roles);
                return this.roles;
                // }
            }
        } else {
            console.log("User is null");
            return [];
        }
        // if (this.roles.length === 0) {
        //     let id            = await this.API.getURL('/whoAmI');
        //     let rbac_response = await this.API.getURL("users/" + id + "/neogen-roles");
        //     console.log("rbac_response: ", rbac_response);
        //     // this.roles = rbac_response.data;
        //     return rbac_response.data;

        // } else {
        //     console.log("this.roles: ", this.roles);
        //     return this.roles;
        // }
    }

    logout() {
        // console.log("Logging Out");
        // console.log("Before: ", JSON.parse(localStorage.getItem('user')));
        localStorage.clear();
        console.error("Logout2");
        localStorage.removeItem("user");
        localStorage.removeItem("nlp");
        window.location.href = "/";
    }

    register(email:string, password:string) {
        return axios.post(API_URL + "neogen-users/signup", {
            email: email,
            password: password
        });
    }

    whoAmiI() {
        return this.API.getURL("/whoAmI");
    }

    async refresh() {
        const refreshToken = localStorage.getItem("refreshToken");
        console.log(refreshToken);
        let refresh = await this.API.postURL("/refresh", { refreshToken: refreshToken }).catch((e:AxiosError) => {
            // console.error(e.response)
            if (e?.response?.status === 401 || e?.response?.status === 403) {
                console.error("Logout1");
                localStorage.removeItem("user");
                window.location.href = "/";
            }
        });
        if (refresh?.data?.accessToken) {
            if (localStorage.getItem("user") !== null) {
                const user = JSON.parse(localStorage.getItem("user")??"{}");
                user.token = refresh.data.accessToken;
                localStorage.setItem("user", JSON.stringify(user));
                // localStorage.setItem("refreshToken", refresh.data.accessToken);
                console.log("Login Refreshed with ", refresh.data.accessToken);
            }
            
        }
    }

    getCurrentUser() {
        // @TODO This is *not* ok (actually maybe it is if the API is secure)
        // Pulling the user from localstorage means that the actual user can set it to whatever they want.
        // Relying on this being an actual user object later on means that someone could just alter their
        // local storage and add roles for example.
        // I would recommend against removing the todo simply so the same mistake is not made again
        //
        // And, into the future:
        // Or maybe it's ok if the API is locked down?
        // In that case, it doesn't matter what the user does, they're not going to get access to something they
        // should have access to.  @todo - review with a third party for discussion. This will move 100% of the
        // @todo onus to the API. Maybe that's the way it should be anyway? Someone could make it so they can see
        // @todo links they shouldn't be able to see, but if they click on them they'll get a 401
        // I'll leave my though process below till I've discussed this, but I'm leaning towards it being ok
        // History:
        // I guess I must have copied and pasted code from somewhere because normally I would never trust
        // (potential) user input.  Crazy.  I've commented out and left the code as a warning:
        try {
            if (localStorage.getItem("user") !== null) {
                return JSON.parse(localStorage.getItem("user")??"{}") ?? null;
            } else {
                return null;
            }
        } catch (e) {
            localStorage.setItem("user", "{}");
            console.error(e);
            return {};
        }
        
        // console.log(this.user, JSON.parse(localStorage.getItem('user')));
        // return this.user ?? this.whoAmiI();
    }

    setCurrentUser(user:any) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    async canIAccess(roleCode:string) {
        // console.log(this.roles);
        // console.trace("Can I Access");
        let roles = await this.getRoles();
        // console.log(roles);
        // console.log("Checking for " + roleCode + " in: ", roles);
        // console.log("Result: ", roles.find((role:NeogenRoles) => role.roleCode === roleCode));
        // console.log(roleCode+" Allowed: ", roles.find((role:NeogenRoles) => role.roleCode === roleCode) !== undefined);
        return roles.find((role:NeogenRoles) => role.roleCode === roleCode) !== undefined;
    }
}

export default new AuthService();
