import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
// import PrintPre from "./print-pre";
import { motion } from "framer-motion";
// import "./folder.css";

function NewFolder(props:newFolderPropTypes) {
    const navigate = useNavigate();

    return (
        
        <div 
            // transition={{ duration: 0.1, delay:props.delay??0 }} 
            // initial={{ opacity: 0.3 }} 
            // animate={{ opacity: 1 }}  
            className="text-gray-800 mx-3 md:mx-0 col-span-1 bg-white rounded-lg shadow className=-y className=-gray-200 dark:bg-gray-800 p-0 cursor-pointer hover:bg-indigo-800 hover:text-gray-100 dark:hover:bg-indigo-900 duration-1000 transition hover:duration-100 " 
            onClick={() => navigate(props.url)}
        >
            <div className="w-full flex items-center justify-between py-3 pl-3 pr-3 space-x-6">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className=" flex-grow text-sm font-medium truncate dark:text-gray-300">{props.folder.name}</h3>
                        
                        {/* </div> */}
                        {/* <div className="flex items-center space-x-3 mt-1 mb-0 "> */}
                        <span className="flex-end flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full dark:bg-green-900 dark:text-green-500">{props.entries} Scripts</span>
                    </div>
                </div>
                {/* <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=cwTW0rYpEF&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60" alt="" /> */}
            </div>
            {/* <div>
                <div className="mt-0 flex className=-x className=-gray-200">
                    <div className="w-0 flex-1 flex p-3">
                        <a href={props.url} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center p-1 border border-indigo-300 text-s font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-900 dark:border-indigo-900 ">
                            <i className={"text-indigo-500 dark:text-gray-400 "+props.icon} />
                            <span className="ml-3 text-sm text-indigo-500 dark:text-gray-400">Open Folder</span>
                        </a>
                        
                    </div>
                    
                </div>
            </div> */}
        </div>

            
        
    );
}

type newFolderPropTypes = {
    url: string,
    icon: string,
    folder: any,
    entries: any,
    children: any,
    darkMode: boolean,
    delay?: number,
};

export default NewFolder;