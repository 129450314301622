import React from "react";
import * as dateFns from "date-fns";
import "./calendar.css";

class CalendarComponent extends React.Component {
    state = {
        currentMonth: new Date(),
        selectedDate: new Date()
    };

    renderHeader() {
        const dateFormat = "MMMM yyyy";

        return (
            <div className="calendar-header calendar-row calendar-flex-middle">
                <div className="calendar-col calendar-col-start">
                    <div className="calendar-icon" onClick={this.prevMonth}>
            chevron_left
                    </div>
                </div>
                <div className="calendar-col calendar-col-center">
                    <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
                </div>
                <div className="calendar-col calendar-col-end" onClick={this.nextMonth}>
                    <div className="calendar-icon">chevron_right</div>
                </div>
            </div>
        );
    }

    renderDays() {
        const dateFormat = "iiii";
        const days = [];

        let startDate = dateFns.startOfWeek(this.state.currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="calendar-col calendar-col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="calendar-days calendar-row">{days}</div>;
    }

    renderCells() {
        const { currentMonth, selectedDate } = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`calendar-col calendar-cell ${
                            !dateFns.isSameMonth(day, monthStart)
                                ? "calendar-disabled"
                                : dateFns.isSameDay(day, selectedDate) ? "calendar-selected" : ""
                        }`}
                        key={day}
                        onClick={() => this.onDateClick(dateFns.toDate(cloneDay))}
                    >
                        <span className="calendar-number">{formattedDate}</span>
                        <span className="calendar-bg">{formattedDate}</span>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="calendar-body">{rows}</div>;
    }

    onDateClick = day => {
        this.setState({
            selectedDate: day
        });
    };

    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
        });
    };

    render() {
        return (
            <div className="calendar-calendar">
                {this.renderHeader()}
                {this.renderDays()}
                {this.renderCells()}
            </div>
        );
    }
}

export default CalendarComponent;
