import { ResponsiveBarCanvas } from "@nivo/bar";

const data = [
    {
        "country": "AD",
        "hot dog": 176,
        "hot dogColor": "hsl(29, 70%, 50%)",
        "burger": 1,
        "burgerColor": "hsl(303, 70%, 50%)",
        "sandwich": 37,
        "sandwichColor": "hsl(208, 70%, 50%)",
        "kebab": 149,
        "kebabColor": "hsl(300, 70%, 50%)",
        "fries": 8,
        "friesColor": "hsl(312, 70%, 50%)",
        "donut": 47,
        "donutColor": "hsl(247, 70%, 50%)"
    },
    {
        "country": "AE",
        "hot dog": 91,
        "hot dogColor": "hsl(192, 70%, 50%)",
        "burger": 46,
        "burgerColor": "hsl(144, 70%, 50%)",
        "sandwich": 47,
        "sandwichColor": "hsl(281, 70%, 50%)",
        "kebab": 103,
        "kebabColor": "hsl(64, 70%, 50%)",
        "fries": 156,
        "friesColor": "hsl(286, 70%, 50%)",
        "donut": 55,
        "donutColor": "hsl(132, 70%, 50%)"
    },
    {
        "country": "AF",
        "hot dog": 103,
        "hot dogColor": "hsl(263, 70%, 50%)",
        "burger": 71,
        "burgerColor": "hsl(72, 70%, 50%)",
        "sandwich": 68,
        "sandwichColor": "hsl(167, 70%, 50%)",
        "kebab": 132,
        "kebabColor": "hsl(135, 70%, 50%)",
        "fries": 146,
        "friesColor": "hsl(162, 70%, 50%)",
        "donut": 48,
        "donutColor": "hsl(58, 70%, 50%)"
    },
    {
        "country": "AG",
        "hot dog": 4,
        "hot dogColor": "hsl(209, 70%, 50%)",
        "burger": 19,
        "burgerColor": "hsl(80, 70%, 50%)",
        "sandwich": 109,
        "sandwichColor": "hsl(240, 70%, 50%)",
        "kebab": 183,
        "kebabColor": "hsl(171, 70%, 50%)",
        "fries": 133,
        "friesColor": "hsl(293, 70%, 50%)",
        "donut": 81,
        "donutColor": "hsl(230, 70%, 50%)"
    },
    {
        "country": "AI",
        "hot dog": 176,
        "hot dogColor": "hsl(274, 70%, 50%)",
        "burger": 176,
        "burgerColor": "hsl(328, 70%, 50%)",
        "sandwich": 49,
        "sandwichColor": "hsl(19, 70%, 50%)",
        "kebab": 9,
        "kebabColor": "hsl(36, 70%, 50%)",
        "fries": 151,
        "friesColor": "hsl(353, 70%, 50%)",
        "donut": 36,
        "donutColor": "hsl(192, 70%, 50%)"
    },
    {
        "country": "AL",
        "hot dog": 95,
        "hot dogColor": "hsl(320, 70%, 50%)",
        "burger": 47,
        "burgerColor": "hsl(83, 70%, 50%)",
        "sandwich": 8,
        "sandwichColor": "hsl(167, 70%, 50%)",
        "kebab": 62,
        "kebabColor": "hsl(53, 70%, 50%)",
        "fries": 28,
        "friesColor": "hsl(132, 70%, 50%)",
        "donut": 195,
        "donutColor": "hsl(8, 70%, 50%)"
    },
    {
        "country": "AM",
        "hot dog": 174,
        "hot dogColor": "hsl(164, 70%, 50%)",
        "burger": 200,
        "burgerColor": "hsl(149, 70%, 50%)",
        "sandwich": 16,
        "sandwichColor": "hsl(137, 70%, 50%)",
        "kebab": 18,
        "kebabColor": "hsl(130, 70%, 50%)",
        "fries": 40,
        "friesColor": "hsl(287, 70%, 50%)",
        "donut": 53,
        "donutColor": "hsl(30, 70%, 50%)"
    }
];

export default function DateChart(props: DateChartProps) {
    return (
        <>
            <div style={{width:"100%", height: "400px"}}>
                <ResponsiveBarCanvas
                    data={props.data??[]}
                    keys={props.keys}
                    indexBy={props.indexBy}
                    // keys={dests}
                    // indexBy="date"
                    // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    // padding={0.3}
                    // valueScale={{ type: "linear" }}
                    // indexScale={{ type: "band", round: true }}
                    // colors={{ scheme: props.darkMode?"purpleRed_green":"pastel2" }}
                    margin={{ top: 50, right: 200, bottom: 120, left: 90 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={props.darkMode?
                        [
                            "#4624a3",
                            "#053f0b","#007f21",
                            "#0c5476", "#5310a5","#955fff","#0f68d6", "#d6830f"
                        ]
                        :
                        [
                            "#c6dfff","#5ffff3",
                            "#d4ff65", "#fffc62","#ffb349","#ffc1e6", "#e894f7", "#bb8dff"
                        ]
                    }
                                
                    borderColor={{ from: "color", modifiers: [ [ "darker", 1.6 ] ] }}
                                
                    // axisTop={null}
                    // axisRight={null}
                    borderRadius={2}
                    // borderColor={{ from: "color", modifiers: [ [ "darker", 1.6 ] ] }}
                                
                    // axisTop={null}
                                
                    // axisRight={null}
                    // xFormat="time:%Y-%m-%d"
                    axisBottom={{
                        tickSize: 5,
                        // format: (v:any) => vals.find((vts: string) => {
                        //     // console.log(vts+" === "+v);
                        //     return vts === v;}) ? "" : v,
                        tickPadding: 5,
                        tickRotation: 90,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: 52,
                        
                        // type: "time",
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.legend,
                        legendPosition: "middle",
                        legendOffset: -60
                    }}
                    theme={{
                        textColor: props.darkMode?"#aaa":"#000",
                        axis: {
                            ticks: {
                                line: {
                                    stroke: "green"
                                },
                                text: {
                                    fill: props.darkMode ? "#777" : "#444",
                                }
                            }
                        },
                        grid: {
                            line: {
                                stroke: props.darkMode ? "#223" : "#eee",
                                strokeWidth: 2,
                                strokeDasharray: "3 3"
                            }
                        }
                    }}
                    labelSkipWidth={2}
                    labelSkipHeight={22}
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: "left-to-right",
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    enableLabel={false}
                    labelTextColor={{ from: "color", modifiers: [ [ "brighter", 1.6 ] ] }}
                    // legends={[
                    //     {
                    //         dataFrom: "keys",
                    //         anchor: "bottom-right",
                    //         direction: "column",
                    //         justify: false,
                    //         translateX: 120,
                    //         translateY: 0,
                    //         itemsSpacing: 2,
                    //         itemWidth: 100,
                    //         itemHeight: 20,
                    //         itemDirection: "left-to-right",
                    //         itemOpacity: 0.85,
                    //         symbolSize: 20,
                    //         effects: [
                    //             {
                    //                 on: "hover",
                    //                 style: {
                    //                     itemOpacity: 1
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}
                    isInteractive={true}
                    // animate={true}
                    // motionStiffness={90}
                    // motionDamping={15}
                />
                {/* <PrintPre>
                        {secondsQuery.data}
                    </PrintPre> */}
            </div>
        </>
    );
}

type DateChartProps = {
    darkMode: boolean,
    data: any[],
    keys: string[],
    indexBy: string,
    legend: string
}