/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenQuestionAudio } from "../typings/api";

const API_URL = process.env.REACT_APP_API_URL;

class QuestionAudioService extends APIService<NeogenQuestionAudio> {

    constructor() {
        super("neogen-question-audios");
    }

    getAllByScriptID(scriptID:number):any {
        if (typeof(scriptID) === "undefined") {
            return null;
        }
        return axios.get(API_URL + "neogen-question-audios/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + scriptID + "\"}}"), {headers: authHeader()});
    }
}

export default new QuestionAudioService();