// Sections
import PropTypes from "prop-types";
import ErrorBoundary from "./error-boundary";

import SelectScriptFolder from "./sections/scripts/select-script-folder";
import Level1New from "./sections/dashboard/level1-new";
import AuthService from "./services/auth.service";
import LanguageService from "./services/language.service";
import CacheBuster from "./sections/utilities/CacheBuster";
import * as Sentry from "@sentry/react";
import React, { useCallback, useState, useEffect , Suspense, lazy} from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { items as RBACItems } from "./services/menu.service";
import {
    ThemeProvider,
    withTheme
} from "styled-components";
import { GlobalStyles } from "./globalStyles";
import { darkTheme, lightTheme } from "./Themes";
import Avatar, { Cache, ConfigProvider } from "react-avatar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import axios from "axios";
import { ReactQueryDevtools } from "react-query-devtools";
import semver from "semver";
import {
    faMapMarkerAlt, faIdCard,
    faQuestionCircle ,
    faBullseyePointer,
    faLayerGroup,
    faCirclePlay,
    faBoxOpen,
    faPhoneAlt,
    faBirthdayCake,
    faBuilding,
} from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheckCircle,
    faTimes as fasTimes,
    faPlus,
    faTimesCircle,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import {
    
    BrowserRouter as Router,
    // Redirect,
    Route,
    Routes,
    // Switch,
    useLocation,
} from "react-router-dom";

// CSS
import "./connect.css";
import "./main.css";
import "./dark_theme.css";
import "./App.css";
import PhoneNumbers from "./sections/phone-numbers/phone-numbers";
import RoleContext from "./services/role-context";
import UserContext from "./services/user-context";
import ScriptFolderAccessService from "./services/script-folder-access.service";
import ScriptFolderEntryService from "./services/script-folder-entry.service";
import ResetPasswordComplete from "./sections/user/reset-password-complete";
import SideBarNew from "./sections/layout/SideBarNew";
import { persistQueryClient } from "react-query/persistQueryClient";
import { createWebStoragePersister } from "react-query/createWebStoragePersister";
import Integrations from "./sections/integrations/integrations";
import Calendar from "./sections/calendar/calendar";
import Kafka from "./sections/kafka/kafka";
import WebRTC from "./sections/playground/webrtc";
import Loader2 from "./sections/utilities/Loader2";
const Translation = lazy(() => import("./sections/translation/translation"));
const Users = lazy(() => import("./sections/admin/users/users"));
const Onboard = lazy(() => import("./sections/admin/onboard/onboard"));
const Logs = lazy(() => import("./sections/admin/logs/logs"));
const Upload = lazy(() => import("./sections/sounds/Upload"));
const Settings = lazy(() => import("./sections/settings/settings"));
const Updates = lazy(() => import("./sections/updates/Updates"));
const CallHistory = lazy(() => import("./sections/reports/call-history/call-history"));
const Admin = lazy(() => import("./sections/admin/admin"));
const Reporting = lazy(() => import("./sections/reports/reporting"));
const Servers = lazy(() => import("./sections/servers/servers"));
const Faq = lazy(() => import("./sections/help/faq"));
const USA = lazy(() => import("./sections/reports/call-volume/usa"));
const Companies = lazy(() => import("./sections/admin/companies/companies"));
const Actions = lazy(() => import("./sections/actions/actions"));
const ResponseCatchers = lazy(() => import("./sections/response-catchers/response-catchers"));
const Playground = lazy(() => import("./sections/playground/chart"));
const NLP = lazy(() => import("./sections/nlp/NLP"));
const NLPv3 = lazy(() => import("./sections/ai/nlp-v3"));
const Transcriptions = lazy(() => import("./sections/reports/transcription/transcriptions"));
const TrainNLP = lazy(() => import("./sections/nlp/train-NLP"));
const AcceptInvite = lazy(() => import("./sections/scripts/accept-invite"));
const Roles = lazy(() => import("./sections/admin/roles/roles"));
const Apis = lazy(() => import("./sections/admin/apis/apis"));
const Ai = lazy(() => import("./sections/ai/ai"));
const ApiPushes = lazy(() => import("./sections/reports/api-pushes/api-pushes"));
const ScheduledReports = lazy(() => import("./sections/reports/scheduled/scheduled-reports"));
const TransferredCalls = lazy(() => import("./sections/reports/transferred-calls/transferred-calls"));
const CustomReporting = lazy(() => import("./sections/reports/custom-reporting/custom-reporting"));
const VerifyToken = lazy(() => import("./sections/user/verify-token"));
const EmailNotVerified = lazy(() => import("./sections/user/email-not-verified"));
const TrainNLPV2 = lazy(() => import("./sections/nlpv2/train-nlp"));
const CustomNLU = lazy(() => import("./sections/ai/custom-nlu"));
const TestNLPV2 = lazy(() => import("./sections/nlpv2/test-nlp"));
const Pipeline = lazy(() => import("./sections/pipeline/pipeline"));
const Login = lazy(() => import ( "./sections/user/login"));
const Logout = lazy(() => import ( "./sections/user/Logout"));
const Register = lazy(() => import ( "./sections/user/Register"));
const ResetPassword = lazy(() => import ( "./sections/user/reset-password"));
const Title = lazy(() => import ( "./sections/layout/Title"));
const CustomGrammars = lazy(() => import ( "./sections/custom-grammars/custom-grammars"));
const CustomLanguageModels = lazy(() => import ( "./sections/custom-language-models/custom-language-models"));
const AllSounds = lazy(() => import ( "./sections/sounds/AllSounds"));
const DisplayNFTs = lazy(() => import ( "./sections/displayNFTs"));
const Articles = lazy(() => import ( "./sections/articles/articles"));
const SelectFolder = lazy(() => import ( "./sections/sounds/SelectFolder"));
const SelectScript = lazy(() => import ( "./sections/scripts/select-script"));
const Response = lazy(() => import ( "./sections/reports/responses/responses"));
const Reports = lazy(() => import ( "./sections/reports/reports"));
const ScriptEditorWrapper = lazy(() => import ( "./sections/scripts/script-editor-wrapper"));
const Recording = lazy(() => import ( "./sections/recording/recording"));
const Record = lazy(() => import ( "./sections/recording/record"));
const RecordingShow = lazy(() => import ( "./sections/recording/show"));
const RecordingHelp = lazy(() => import ( "./sections/recording/help"));
const RecordingSelectScript = lazy(() => import ( "./sections/recording/select-script"));
const SelectRecordLanguage = lazy(() => import ( "./sections/recording/select-record-language"));
const EditAPI = lazy(() => import ( "./sections/admin/apis/edit-api"));
const ShowOneSet = lazy(() => import ( "./sections/recording/show-one-set"));
const AdminDash = lazy(() => import ( "./sections/dashboard/admin-dash"));
const GenerateSoundSet = lazy(() => import ( "./sections/recording/generate"));
const NeoChat = lazy(() => import ( "./sections/chatbot/neochatbot"));
const SelectPhoneType = lazy(() => import ( "./sections/phone-numbers/select-phone-type"));
const TransferDestinations = lazy(() => import ( "./sections/phone-numbers/transfer-destinations"));
const RecordingExplainer = lazy(() => import ( "./sections/recording/recording-explainer"));
const ScriptTraining = lazy(() => import ( "./sections/reports/script-training/script-training"));
const InOut = lazy(() => import ( "./sections/reports/in-out/in-out"));
const Sankey = lazy(() => import ( "./sections/reports/sankey/sankey-report"));
const Calls = lazy(() => import ( "./sections/reports/calls/calls"));
const TrainedPercentage = lazy(() => import ( "./sections/reports/training/trained-percentage"));
const BillingOverview = lazy(() => import ( "./sections/billing/overview"));
const Billing = lazy(() => import ( "./sections/billing/billing"));
const Suggestions = lazy(() => import ( "./sections/suggestions/suggestions"));
const Balance = lazy(() => import ( "./sections/billing/balance"));
const Transactions = lazy(() => import ( "./sections/billing/transactions"));
const IntegrationTypes = lazy(() => import ( "./sections/integrations/integration-types"));
import {
    RecoilRoot
} from "recoil";
import AICalendar from "./sections/ai/ai-calendar";
import Summarize from "./sections/ai/summarize";
import MakeCall from "./sections/api/make-call";
import PushAPIs from "./sections/push-apis/push-apis";
import ClickToCall from "./sections/click-to-call/click-to-call";
import ClickToCallSelector from "./sections/click-to-call/selector";
import ClickToCallInstructions from "./sections/click-to-call/instructions";
import { NeogenScriptFolderEntries, NeogenScriptFolders } from "./typings/api";
import { Language } from "./classes/language";
import ClickToCallLogs from "./sections/click-to-call/logs";
import ClickToCallReport from "./sections/reports/click-to-calls/click-to-calls";
import AddFaq from "./sections/help/modals/add-faq";
import AriReload from "./sections/admin/ari-reload/ari-reload";
import OnboardingAdmin from "./sections/admin/onboarding/onboarding";
import ReportBuilder from "./sections/reports/custom-reporting/report-builder";
import ListReports from "./sections/reports/custom-reporting/list-reports";
import ShowCustomReport from "./sections/reports/custom-reporting/show-custom-report";
import OnboardingWizard from "./sections/admin/onboarding/onboarding-wizard";

// import StSingleCampaign from "./sections/admin/smoothtorque/st-single-campaign";
const SmoothTorque = lazy(() => import ( "./sections/admin/smoothtorque/smoothtorque"));
const SmoothTorqueUpload = lazy(() => import ( "./sections/admin/smoothtorque/upload"));
const Tests = lazy(() => import ( "./sections/tests/tests"));
const StManageData = lazy(() => import ( "./sections/admin/smoothtorque/st-manage-data"));
const StData = lazy(() => import ( "./sections/admin/smoothtorque/st-data"));
const StDataLists = lazy(() => import ( "./sections/admin/smoothtorque/st-data-lists"));
const StCampaigns = lazy(() => import ( "./sections/admin/smoothtorque/st-campaigns"));
const StSingleCampaign = lazy(() => import ( "./sections/admin/smoothtorque/st-single-campaign"));
// import SmoothTorque from "./sections/admin/smoothtorque/smoothtorque";
// import SmoothTorqueUpload from "./sections/admin/smoothtorque/upload";
// import StMenu from "./sections/admin/smoothtorque/st-menu";

// Add FontAwesome Icons
library.add(
    faCirclePlay, 
    fasTimes, faBuilding, faCheckCircle, faBirthdayCake, faPhoneAlt, faTimesCircle, faTimes, faPlus, faQuestionCircle, faBoxOpen, faBullseyePointer, faLayerGroup, faTrash, faMapMarkerAlt, faIdCard);

const debug = require("debug")("NeoGen:App");

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
});

const localStoragePersister = createWebStoragePersister({storage: window.localStorage});

persistQueryClient({
    queryClient,
    persister: localStoragePersister,
});

function App() {


    const cache = new Cache({

        // Keep cached source failures for up to 7 days
        sourceTTL: 7 * 24 * 3600 * 1000,
        // sourceTTL: 7 * 24 * 3600 * 1000,

        // Keep a maximum of 20 entries in the source cache
        sourceSize: 20
    });
    const handle = useFullScreenHandle();
    return (
        <>
            <Router>
                {/* <FullScreen handle={handle}> */}
                {/* <ConfigProvider cache={cache} avatarRedirectUrl={"https://neogenavatar.herokuapp.com"}> */}
                <QueryClientProvider client={queryClient} contextSharing={true}>
                    <RecoilRoot>
                            
                            
                            
                                
                        <Routes>
                            <Route path={"/api/make-call"} element={<MakeCall />} />
                            <Route path="/*" element={<NeoGen fsHandle={handle} cache={cache}/>} />
                                    
                        </Routes>
                    
                            
                            
                    </RecoilRoot>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
                {/* </ConfigProvider> */}
                {/* </FullScreen> */}
            </Router>
        </>
    );
}

function NeoGen(props:NeoGenProps) {
    let location = useLocation();
    let [user, setUser] = useState(AuthService.getCurrentUser());

    // useEffect(() => {
    //     axios.get("/meta.json").then(r => {
    //         console.log(r.data.version, global.appVersion);
    //         if (semver.gt(r.data.version, global.appVersion)) {
    //             console.log("################################");
    //             console.log("#### Newer version available ###");
    //             console.log("################################");
    //         } else {
    //             console.log("global.appVersion: " + global.appVersion);
    //             console.log("r.data.version: " + r.data.version);
    //         }
    //     }).catch(e => {
    //         console.error(e);
    //     });
    // }, [location.pathname]);


    const myFoldersQuery = useQuery(["userFolders", user?.id],  async () => {
        let result = await ScriptFolderAccessService.getByCompanyIdOrUserId(user?.user?.company, user?.id);
        if (result) {
            return result.data;
        } 
    },
    {
        enabled: user !== null,
    });
    const myFolderEntriesQuery = useQuery(["myFolderEntries", user?.id], async () => {
        if (myFoldersQuery && myFoldersQuery.data) {
            const folderIds = [];
        
            for (let folder of myFoldersQuery.data) {
                if (folder.scriptFolderId !== null) {
                    folderIds.push(folder.scriptFolderId);
                }
    
            }
            let result = await ScriptFolderEntryService.getFolderEntriesByMultipleFolders(folderIds);
            if (result) {
                return result.data as NeogenScriptFolderEntries[];
            } else {
                return [];
            }
            
        } else {
            return [];
        }
    },
    {
        enabled: myFoldersQuery.isSuccess,
    });


    const getDarkMode = useCallback(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            return false;
        }


    }, []);

    const [currentLanguage, setCurrentLanguage] = useState(1);
    const [darkMode, setDarkMode] = useState(getDarkMode());
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [myFolderEntries, setMyFolderEntries] = useState<NeogenScriptFolderEntries[]>([]);
    const [myFolders, setMyFolders] = useState<NeogenScriptFolders[]>([]);
    const [statePresent, setStatePresent] = useState(false);
    useEffect(() => {
        if (!statePresent) {
            // If we don't have state we will need to recalculate the folders and scripts we have access to
            // console.log("No state - getting again");
            if (myFolderEntriesQuery.isSuccess) {
                document.body.classList.remove("login");
                document.body.classList.remove("no-loader");
                setMyFolderEntries(myFolderEntriesQuery.data);
                if (myFoldersQuery.data) {
                    setMyFolders(myFoldersQuery.data);
                }
                
                setStatePresent(true);
            }
        }
    }, [myFolderEntries, myFolderEntriesQuery.data, myFolderEntriesQuery.isSuccess, myFolders, myFoldersQuery.data, statePresent]);


    let languageQuery = useQuery(["languages"], async () => {
        let result = await LanguageService.getAll();
        if (result) {
            return result.data as Language[];
        } else {
            return [];
        }
        
    });

    let items: typeof RBACItems = [];
    if (user) {
        items = RBACItems;
    }
    const [mode, setMode] = useState("light");
    const onSelectMode = (mode:any) => {
        alert(mode);
        setMode(mode);
        if (mode === "dark")
            document.body.classList.add("dark-mode");
        else
            document.body.classList.remove("dark-mode");
    };
    const changeDarkMode = useCallback((e:any) => {
        
        setDarkMode(e.matches);
        localStorage.setItem("theme", e.matches? "dark":"light");
        
    }, []);

    useEffect(() => {
        // Add listener to update styles
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", changeDarkMode);
      
        // Setup dark/light mode for the first time
        // onSelectMode(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
        // setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
        // Remove listener
        return () => {
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", changeDarkMode);
        };
    }, [changeDarkMode, getDarkMode]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("bar");

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <>
                {/* <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }:{loading:boolean, isLatestVersion: boolean, refreshCacheAndReload:any}) => {
                        if (loading) {
                            return null;
                        }
                        if (!isLatestVersion) {
                            // You can decide how and when you want to force reload
                            refreshCacheAndReload();
                        }

                        return (
                            <>
                            </>
                        );
                    }}
                </CacheBuster> */}
                <GlobalStyles />

                {/* <Route path="*" element={<Logout />} /> */}

                <div className={"fill"}>
                    {user === null && (
                        <Suspense fallback={<Loader2 />}>
                            <Routes >
                                {/* <Suspense fallback={<Loader2 />}> */}
                                <Route path={"/register"} element={<Register />} />
                                <Route path={"/api/make-call"} element={<MakeCall />} />
                                <Route path={"/reset"} element={<ResetPassword />} />
                                <Route path={"/reset-password/:token/:user"} element={<ResetPasswordComplete />} />
                                <Route path={"/verify-token/:token/:user"} element={<VerifyToken />} />
                                <Route path={"/email-not-verified/:user"} element={<EmailNotVerified />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path={"/*"} element={
                                    <Login
                                        setMyFolderEntries={setMyFolderEntries}
                                        setMyFolders={setMyFolders}
                                        setStatePresent={setStatePresent}
                                    />} />
                                {/* </Suspense> */}
                            </Routes>
                        </Suspense>
                    )}
                    {(user !== null && user.name) && (
                        <UserContext.Provider value={[user?.user, setUser]}>
                            <RoleContext.Provider value={user?.roles??[]}>
                                <Suspense fallback={<Loader2 />}>
                                    <Routes>
                                    
                                        
                                        <Route path="/*" element={<SideBarNew items={items} currentUser={user} darkMode={darkMode}
                                            // cache={props.cache}
                                            setDarkMode={setDarkMode} location={location}>
                                            <div className={foo === "7"?"":"page-containerx "}>


                                                <div className={foo === "7"?"":"page-contentx p-0 pt0 pb-8 min"}>
                                                    
                                                    {/* <Sentry.ErrorBoundary> */}
                                                    <Suspense fallback={<Loader2 />}>
                                                            
                                                        <Routes >
                                                            <Route path="logout" element={<Logout />} />
                                                                
                                                            {/* Scripts */}
                                                            <Route path="scripts/:folder/:id" element={
                                                                <ScriptEditorWrapper currentLanguage={currentLanguage}
                                                                    darkMode={darkMode}
                                                                    isFullScreen={isFullScreen}
                                                                    setIsFullScreen={setIsFullScreen}
                                                                    myFolderEntries={myFolderEntriesQuery}
                                                                    myFolders={myFolders}
                                                                />}
                                                            />
                                                            <Route path="/scripts/:folder/:id/:module2/:module" element={<ScriptEditorWrapper currentLanguage={currentLanguage}
                                                                darkMode={darkMode}
                                                                isFullScreen={isFullScreen}
                                                                setIsFullScreen={setIsFullScreen}
                                                                myFolderEntries={myFolderEntriesQuery}
                                                                myFolders={myFolders}
                                                            />} />
                                                            <Route path="/scripts/:folder/:id/:module" element={<ScriptEditorWrapper currentLanguage={currentLanguage}
                                                                darkMode={darkMode}
                                                                isFullScreen={isFullScreen}
                                                                setIsFullScreen={setIsFullScreen}
                                                                myFolderEntries={myFolderEntriesQuery}
                                                                myFolders={myFolders}
                                                            />} />
                                                            <Route path="scripts/:id" element={<SelectScript darkMode={darkMode} />} />
                                                            <Route path="scripts" element={<SelectScriptFolder allowAdd={true} darkMode={darkMode} pathname={"/scripts/"} />} />
                                                            <Route path="selectFolder/" element={<SelectFolder />} />
                                                            <Route path="/allSounds/:id" element={<AllSounds languages={languageQuery} />} />
                                                            <Route path="/upload/:id" element={<Upload />}/>
                                                            <Route path="/accept-invite/:id" element={<AcceptInvite />} />
                                                            <Route path="/settings/" element={<Settings darkMode={darkMode} setDarkMode={setDarkMode} />} />
                                                            <Route path="/response_types/" element={<Title title="Response Types" />} />
                                                            <Route path="/responses/" element={<Response darkMode={darkMode} />} />
                                                            <Route path="/api-pushes" element={<ApiPushes  myFolderEntriesQuery={myFolderEntriesQuery} darkMode={darkMode} />} />
                                                            <Route path="/custom-grammars/:customization" element={<CustomGrammars darkMode={darkMode} />} />
                                                            <Route path="/custom-language-models" element={<CustomLanguageModels darkMode={darkMode} />} />
                                                            <Route path="/actions/" element={<Actions  />} />
                                                            <Route path="/billing/balance" element={<Balance />} />
                                                            <Route path="/suggestions" element={<Suggestions />} />
                                                            <Route path="/billing/transactions" element={<Transactions  darkMode={darkMode}/>} />
                                                            <Route path="/response-catchers" element={<ResponseCatchers />} />
                                                            <Route path="/phone-numbers" element={<PhoneNumbers myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/transfer-destinations" element={<TransferDestinations />} />
                                                            <Route path="/select-phone-type" element={<SelectPhoneType />} />
                                                            <Route path="/billing" element={<Transactions   darkMode={darkMode}/>} />
                                                            <Route path="/transferred-calls" element={<TransferredCalls darkMode={darkMode} />} />
                                                            <Route path="/articles" element={<Articles />} />
                                                            <Route path="/articles/:articleId" element={<Articles />} />
                                                            <Route path="/reports/scheduled" element={<ScheduledReports myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/reports" element={<Reports/>} />
                                                            <Route path="/reporting" element={<Reporting/>} />
                                                            <Route path="/report/:id" element={<ShowCustomReport darkMode={darkMode}/>} />
                                                            <Route path="/reports/call-volume/usa" element={<USA />} />
                                                            <Route path="/onboarding-wizard" element={<OnboardingWizard />} />
                                                            <Route path="/transcriptions" element={<Transcriptions />} />
                                                            <Route path="/custom-reporting" element={<CustomReporting darkMode={darkMode} />} />
                                                            <Route path="/add-report-builder" element={<ReportBuilder darkMode={darkMode} isEdit={false}/>} />
                                                            <Route path="/report-builder/:id" element={<ReportBuilder darkMode={darkMode} isEdit={true}/>} />
                                                            <Route path="/report-builder" element={<ListReports />} />
                                                            <Route path="/nlp" element={<NLP darkMode={darkMode} />} />
                                                            <Route path="/nlpv3" element={<NLPv3 />} />
                                                            <Route path="/tests" element={<Tests />} />
                                                            <Route path="/train-nlp" element={<TrainNLP />} />
                                                            <Route path="/integrations" element={<Integrations />} />
                                                            <Route path="/calendar" element={<Calendar />} />
                                                            <Route path="/kafka" element={<Kafka />} />
                                                            <Route path="/train-nlp-v2/:verbId?" element={<TrainNLPV2  />} />
                                                            <Route path="/pipeline" element={<Pipeline />} />
                                                            <Route path="/ai-calendar" element={<AICalendar />} />
                                                            <Route path="/translation" element={<Translation myFolderEntriesQuery={myFolderEntriesQuery} />} />
                                                            <Route path="/test-nlp-v2" element={<TestNLPV2 />} />
                                                            <Route path="/playground" element={<Playground />} />
                                                            <Route path="/push-apis" element={<PushAPIs />} />
                                                            <Route path="/clusters/" element={<Title title="Clusters" />} />
                                                            <Route path="/billing/overview" element={<BillingOverview darkMode={darkMode} />} />
                                                            <Route path="/sankey-report/" element={<Sankey myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/reports/training/trained-percentage" element={<TrainedPercentage darkMode={darkMode} myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/call-history/" element={<CallHistory darkMode={darkMode} myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/servers/" element={<Servers />} />
                                                            <Route path="/users" element={<Users />} />
                                                            <Route path="/display" element={<DisplayNFTs />} />
                                                            <Route path="/script-training/:folder?/:script?/:unknown?" element={<ScriptTraining  myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/companies" element={<Companies />} />
                                                            <Route path="/apis" element={<Apis />} />
                                                            <Route path="/edit-api/:id" element={<EditAPI />} />
                                                            <Route path="/roles" element={<Roles />} />
                                                            <Route path="/role-groups" element={<Title title="Role Groups" />} />
                                                            <Route path="/in-out" element={<InOut darkMode={darkMode} myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/calls/:scriptId" element={<Calls  myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/click-to-call-logs/:uuid" element={<ClickToCallLogs />} />
                                                            <Route path="/click-to-call-logs/" element={<ClickToCallLogs />} />
                                                            <Route path="/reports/click-to-call" element={<ClickToCallReport />} />
                                                            <Route path="/calls/" element={<Calls  myFolderEntriesQuery={myFolderEntriesQuery}/>} />
                                                            <Route path="/faq" element={<Faq />} />
                                                            <Route path="/faq/edit/:id" element={<AddFaq />} />
                                                            <Route path="/faq/new" element={<AddFaq  />} />
                                                            <Route path="/faq/:term" element={<Faq />} />
                                                            <Route path="/click-to-call-instructions/:id" element={<ClickToCallInstructions />} />
                                                            <Route path="/artificial-intelligence" element={<Ai />} />
                                                            <Route path="/custom-nlu" element={<CustomNLU />} />
                                                            <Route path="/tutorials" element={<Title title="Tutorials" />} />
                                                            <Route path="/ai" element={<NeoChat />} />
                                                            <Route path="/smoothtorque" element={<SmoothTorque />} />
                                                            <Route path="/ari-reload" element={<AriReload />} />
                                                            <Route path="/admin/onboarding" element={<OnboardingAdmin />} />
                                                            <Route path="/st-menu" element={<StCampaigns darkMode={darkMode} />} />
                                                            {/* <Route path="/st-data" element={<StData />} /> */}
                                                            <Route path="/st-data/:id/:list" element={<StData />} />
                                                            <Route path="/st-data-lists/:id" element={<StDataLists />} />
                                                            <Route path="/st-manage-data/:id" element={<StManageData />} />
                                                            <Route path="/st-campaign" element={<StCampaigns darkMode={darkMode} />}/>
                                                            <Route path="/st-campaigns/:id" element={<StSingleCampaign darkMode={darkMode} />}/>
                                                            <Route path="/smoothtorque-upload/:id" element={<SmoothTorqueUpload />} />
                                                            <Route path="/summarize" element={<Summarize />} />
                                                            <Route path="/languages" element={<Title title="Languages" />} />
                                                            <Route path="/help" element={<Title title="Help" />} />
                                                            <Route path="/updates" element={<><Title title="Updates" /><Updates /></>} />
                                                            <Route path="/recording/explain/:id/:language" element={<RecordingExplainer />} />
                                                            <Route path="/recording/record/:id/:language" element={<Record />} />
                                                            <Route path="/recording/record/:id" element={<SelectRecordLanguage  synthesis={false}  languageQuery={languageQuery} nextDest={"/recording/explain/"}/>} />
                                                            <Route path="/recording/show/:id" element={<ShowOneSet />} />
                                                            <Route path="/recording/show" element={<RecordingShow />} />
                                                            <Route path="/recording/done/:soundSet" element={<RecordingShow />} />
                                                            <Route path="/recording/select-folder" element={<SelectScriptFolder allowAdd={false} darkMode={darkMode} pathname={"/recording/select-script/"} />}/>
                                                            <Route path="/recording/select-script/:folder" element={<RecordingSelectScript url='/recording/record/'  darkMode={darkMode}/>} />
                                                            <Route path="/recording/help" element={<RecordingHelp />} />
                                                            <Route path="/recording/generate" element={<SelectScriptFolder allowAdd={false} darkMode={darkMode} pathname={"/recording/generate-select-script/"} />} />
                                                            <Route path="/recording/generate-select-script/:folder" element={<RecordingSelectScript url='/recording/generate-soundset/'  darkMode={darkMode}/>} />
                                                            <Route path="/recording/generate-soundset/:id/:language" element={<GenerateSoundSet  />} />
                                                            <Route path="/recording/generate-soundset/:id" element={<SelectRecordLanguage synthesis={true} languageQuery={languageQuery} nextDest={"/recording/generate-soundset/"}/>} />
                                                            <Route path="/recording" element={<Recording />} />
                                                            <Route path="/webrtc" element={<WebRTC myFolderEntriesQuery={myFolderEntriesQuery} />} />
                                                            <Route path="/click-to-call/:id" element={<ClickToCall />} />
                                                            <Route path="/click-to-call-selector" element={<ClickToCallSelector />} />

                                                            <Route path="/admin_menu" element={<Admin />} />
                                                            <Route path="/onboard" element={<Onboard />} />
                                                            <Route path="/logs" element={<><Title title="Logs" /><Logs /></>} />
                                                            <Route path="/logout" element={<Logout />} />
                                                            <Route path="*" element={user?.roles?.find((r:any)=> r.roleCode === "ADMIN_DASHBOARD") !== undefined ? (
                                                                <AdminDash darkMode={darkMode} />
                                                            ) : (
                                                                <Level1New user={user.user} />
                                                            )} />
                                                        </Routes>
                                                            
                                                    </Suspense>
                                                    {/* </Sentry.ErrorBoundary> */}
                                                    <ErrorBoundary>
                                                        <Suspense fallback={<Loader2 />}>
                                                            <Routes location={location}>
                                                                <Route path="/script-editor-m-m/:folder/:id/:module2/:module" element={<ScriptEditorWrapper currentLanguage={currentLanguage}
                                                                    darkMode={darkMode}
                                                                    isFullScreen={isFullScreen}
                                                                    setIsFullScreen={setIsFullScreen}
                                                                    myFolderEntries={myFolderEntriesQuery}
                                                                    myFolders={myFolders}
                                                                />} />
                                                                <Route path="/script-editor-m/:folder/:id/:module" element={<ScriptEditorWrapper currentLanguage={currentLanguage}
                                                                    darkMode={darkMode}
                                                                    isFullScreen={isFullScreen}
                                                                    setIsFullScreen={setIsFullScreen}
                                                                    myFolderEntries={myFolderEntriesQuery}
                                                                    myFolders={myFolders}
                                                                />} />
                                                                
                                                            </Routes>
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                </div>
                                            </div>
                                        </SideBarNew>
                                        }>
                                        </Route>
                                    </Routes>
                                </Suspense>
                            </RoleContext.Provider>
                        </UserContext.Provider>
                    )}
                </div>
            </>
        </ThemeProvider>
    );
}

export default (App);

type NeoGenProps = {
    fsHandle: any,
    cache: any,
};