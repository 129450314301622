/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import PrintPre from "./print-pre";


function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}

export default function SelectNeoGen(props:SelectNeoGenProps) {
    const [selected, setSelected] = useState(props.value??0);
    // console.error(props.entries);
    // console.error(props.entries);
    useEffect(() => {
        if (typeof(props.value) !== "undefined") {
            // alert(props.value);
            setSelected(props.value);
            console.log(props.value);
        }
        
    }, [props.value]);
    return (
        <>
            
            <Listbox value={selected}  onChange={(e:any) => {
            // console.log({e});
                setSelected(e.id);
                // console.info({id: e.id, selected});
                props.onChange(e.id, props.entries?.find(ee => {
                    
                    return ee?.id === e.id;
                }
                )?.name);}} >
                {({ open }) => (
                    <div className={props.className}>
                        
                        <Listbox.Label className="block text-sm font-medium text-gray-700 dark:text-gray-400">{props.label}</Listbox.Label>
                        <div className="relative">
                            <Listbox.Button className="bg-white dark:bg-gray-800 dark:text-gray-400 relative w-full border border-gray-300 dark:border-gray-900 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <span className="block truncate">{props.entries?.find(e => {
                                // console.info({id: e?.id, selected});
                                    return e?.id === selected;
                                }
                                )?.name??"-- Please Select An Option --"}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                            // show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white  shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm dark:bg-gray-700"
                                >
                                
                                    {props.entries?.map((entry, idx) => (
                                        <React.Fragment key={entry?.id+" "+idx}>
                                            <PrintPre></PrintPre>
                                            <Listbox.Option
                                                
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? "text-white bg-indigo-600 dark:text-gray-300" : "text-gray-900 dark:text-gray-400",
                                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                                    )
                                                }
                                                value={entry}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                                                            {entry.name}
                                                        </span>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? "text-white" : "text-indigo-600",
                                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        </React.Fragment>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </div>
                )}
            </Listbox>
        </>
    );
}

type Entry = {
    id: number|string,
    name: string
}

type SelectNeoGenProps = {
    entries?: (any&Entry)[],
    label?: string,
    value?: number|string,
    onChange: (idx: (number|string), text?: string) => void,
    className?: string,
}