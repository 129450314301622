import { useQuery, QueryClient } from "react-query";
import scriptFolderService from "../services/script-folder.service";
import { sortByName } from "../sections/utilities/sort.js";

export function useAllScriptFolders() {
    const queryCache = new QueryClient();
    return useQuery(["scriptFolders"], async() => {
        let results = await scriptFolderService.getAll();
        // console.log(result);
        if (results) {
            results.data.map((i) => {
                // console.log(i);
                queryCache.setQueryData(["scriptFolder", i.id], i);
            });
            // console.log(results);
            return results.data.sort(sortByName);
        } else {
            return [];
        }
        
    }, { staleTime: 10000, });
}