/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosResponse }      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenQuestion } from "../typings/api";

const API_URL = process.env.REACT_APP_API_URL;

class QuestionService extends APIService<NeogenQuestion> {
    constructor() {
        super("neogen-questions");
    }

    async getQuestions() {
        return await this.getURL("neogen-questions");
    }

    async getQuestion(id:number) {
        return await this.getURL("neogen-questions/" + id);
    }

    async getAllQuestionsByScriptID(scriptID:number):Promise<void|AxiosResponse<NeogenQuestion[]>> {
        return await this.getURL( "neogen-questions/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + scriptID + "\"}}"));
    }
}

export default new QuestionService();