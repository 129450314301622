// import { id } from "date-fns/locale";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import clickToCallsService from "../../services/click-to-calls.service";
import PageDescription from "../layout/page-description";
import PrintPre from "../layout/print-pre";
import Loader2 from "../utilities/Loader2";
import nl2br from "react-nl2br";

const code = "<script src=\"//call.neogen.ai/caller.js?uuid=%UUID%\" type=\"text/javascript\" ></script>";



export default function ClickToCallInstructions() {
    const [button, setButton] = useState("");
    const {id} = useParams();
    const navigate = useNavigate();
    const clickToCallsQuery = useQuery(["ClickToCalls", id], async () => {
        const result = await clickToCallsService.getOne(Number(id));
        if (result) {
            // setData(JSON.stringify(result.data));
            // setColor(result.data.buttonColor??"#0062B1");
            // setTextColor(result.data.textColor??"#ffffff");
            // setScript(result.data.scriptId??-1);
            // setButtonText(result.data.buttonText??"Call Me Now");
            // const existing = JSON.parse(result.data.fields);
            // setFields(existing);
            // alert("")
            const defaultButton = `<button type="button" onClick="makeNeoGenCall('phone', '${result.data.uuid}');" style="padding: 10px;border-radius: 5px;background: ${result.data.buttonColor};color:${result.data.textColor};border: none;font: inherit;outline: inherit;">${result.data.buttonText}</button>`;
            setButton(defaultButton);
            return result.data;
        }
    });


    // const copyButtonLabel = "Copy Code";

    // // you can use a class selector instead if you, or the syntax highlighting library adds one to the 'pre'. 
    // let blocks = document.querySelectorAll("pre");
    
    // blocks.forEach((block) => {
    //     // only add button if browser supports Clipboard API
    //     if (navigator.clipboard) {
    //         let button = document.createElement("button");
    //         button.innerText = copyButtonLabel;
    //         button.addEventListener("click", copyCode);
    //         block.appendChild(button);
    //     }
    // });
    
    async function copyCode(elementId:string, buttonId: string) {
        // const button = event.srcElement;
        // const pre = button.parentElement;
        let code = document.getElementById(elementId);
        let button = document.getElementById(buttonId);
        if (code && button) {
            let text = code.innerText;
            await navigator.clipboard.writeText(text);
            const copyButtonLabel = button.innerText;
            button.innerText = "Code Copied";
            button.classList.add("bg-red-600");
            button.classList.add("dark:bg-red-900");
            button.classList.add("text-white");
            setTimeout(()=> {
                if (button) {
                    button.innerText = copyButtonLabel;
                    button.classList.remove("bg-red-600");
                    button.classList.remove("text-white");
                    button.classList.add("bg-white");
                    button.classList.remove("dark:bg-red-900");
                }
                
            },2000, copyButtonLabel);
        }
        
    }


    return (
        <>
            {clickToCallsQuery.isLoading?(
                <Loader2 />
            ):(
                <>
                    <PageDescription>
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Click To Call Instructions</p>
                        <p className="mb-5">Follow the customized instructions below to implement your button.</p>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            onClick={() => navigate("/click-to-call/"+id)}
                        >
                            Edit Button
                        </button>
                    </PageDescription>
                    <div className="px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded-lg shadow p-5 m-3">
                        <p className="my-5">
                            In order to implement your button you&apos;ll need to add two lines of javascript to your website.  The first one loads the current script and the second one draws the button.                                                                            
                        </p>
                        {/* <hr /> */}
                        <button type="button" id="xyz" onClick={() => copyCode("bl1", "xyz")} className="py-1 px-3 border border-gray-400 rounded-lg transition-all duration-1000  dark:bg-gray-800">Copy Code</button>
                        <div className="bg-gray-200 py-3 rounded my-5 dark:bg-gray-900">
                            <code className="bg-gray-200 p-5 rounded  inline-block dark:bg-gray-900" id="bl1">
                                {nl2br(code.replace("%UUID%", clickToCallsQuery.data?.uuid??""))}
                            </code>
                        </div>
                        
                        {/* <hr /> */}
                        <p className="my-5">
                            In order to implement your button you&apos;ll need to add two lines of javascript to your website.  The first one loads the current script and the second one draws the button.                                                                            
                        </p>
                        <p className="my-5">
                            <span className="text-red-500">Important!</span> Normally the phone number by default gets pulled from a field called phone, but if you want to pull it from another field you can just put the name of the field instead of <strong>phone</strong> in the example below.
                        </p>
                        <button type="button" id="xyz2" onClick={() => copyCode("bl2", "xyz2")} className="py-1 px-3 border border-gray-400 rounded-lg transition-all duration-1000 dark:bg-gray-800">Copy Code</button>
                        
                        <div className="bg-gray-200 py-3 rounded my-5 dark:bg-gray-900">
                            <code className="bg-gray-200 p-5 rounded inline-block dark:bg-gray-900" id="bl2">
                                {nl2br(button)}
                            </code>
                        </div>
                        <p className="my-5">
                            Here is an example of it in action:
                        </p>
                        
                        <div className="my-5">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Phone Number To Call
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md w-64"
                                    // placeholder="you@example.com"
                                />
                            </div>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{__html: button}}
                        />
                        {/* <PrintPre>{clickToCallsQuery.data}</PrintPre> */}
                    </div>
                </>
            )}
        
        </>
    );
}