import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NeogenApis } from "../../../typings/api";
import ModalNeoGen from "../../layout/modal-neogen";
import PrintPre from "../../layout/print-pre";
import SelectNeoGen from "../../layout/select-neogen";
import UserContext from "../../../services/user-context";

const schema = [

    {
        name: "successField",
        label: "success Field",
        type: "string",
        display: true,
        required: false,
    },
];
export default function TestAPI(props: TestAPIProps) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data:any) => console.log(data);
    const [fields, setFields] = useState<string[]>([]);
    const [user,setUser] = useContext(UserContext);
    const [response, setResponse] = useState<any>(); 
    function getField(field: any) {
        field.label = field.label?.toLowerCase().replace(/\b[a-z]/g, function(letter:string) {
            return letter.toUpperCase();
        });
        if (field.display === false) {
            return;
        }
        switch (field.type) {
            case "string":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-md"
                                    // placeholder="you@example.com"
                                    {...register(field.name)}
                                />
                            </div>
                        </div>
                    </>
                );
            case "textarea":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    {...register(field.name)}
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 "
                                    defaultValue={""}
                                />
                            </div>
                        </div>
                    </>
                );
            case "number":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="number"
                                    {...register(field.name)}
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-md"
                                    // placeholder="you@example.com"
                                />
                            </div>
                        </div>
                    </>
                );
            case "combo":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <SelectNeoGen entries={field.entries} onChange={(e) => console.log(e)}/>
                            </div>
                        </div>
                    </>
                );
            default:
                return (
                    <></>
                );
        }
    }
    
    useEffect(() => {
        if (props.api?.parsedBody) {
            const body = (props.api?.parsedBody??"");
            const stringBody = JSON.stringify(body);
            let tokens:string[] = [];
            stringBody?.replace(/(\r\n|\n|\r)/gm, "").replace(/%%(.*?)%%/g, function (a: any, b: any) {
                console.log("Found token: "+b);
                tokens.push(b);
                return b;
            });
            setFields(tokens);
            for (const [key, value] of Object.entries(body)) {
                console.log({key, value});
            }
            
            console.log(body, props.api?.parsedBody);
        }
        
    }, [props.api?.parsedBody]);
    if (!props.api) {
        return <></>;
    }
    function doTest() {
        const x = watch();
        console.log(x, props.api?.parsedBody);
        const body = (props.api?.parsedBody??"");
        let stringBody = JSON.stringify(body);
        
        // const body = (props.api?.parsedBody??"");
        for (const [key, value] of Object.entries(x)) {
            stringBody = stringBody.replace("%%"+key+"%%", value);

        }
        console.log(stringBody);
        console.log(props.api);
        const url ="https://shim1.neogen.ai:7443/API/"+props.api?.id;
        console.log(url);
        const bodyObj:any = JSON.parse(stringBody);
        const loc = JSON.parse(localStorage.getItem("user")??"");
        bodyObj.token = loc.token;
        console.log({bodyObj});
        axios.post(url, bodyObj).then(r => {
            console.log(r);
            setResponse(r.data);
        });
        // alert("Hi");
    }
    return (
        <>
            {props.show && (
            
                <ModalNeoGen size="md" title={"Test API"} show={props.show} close={() => props.close()}
                    okAction={() => doTest()}
                    cancelText="Close"
                    okText="Run API Test"
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="my-5 grid grid-cols-2 gap-5">
                        
                        
                        
                            {fields.map(field => {
                                return getField({name: field, label: field, type: "string"});
                            })}
                        
                        
                        </div>
                    </form>
                    <PrintPre>{response}</PrintPre>
                </ModalNeoGen>
            
            )}
            
        </>
    );
}

export type TestAPIProps = {
    show: boolean,
    close: () => void,
    entry?: any,
    api?: NeogenApis
}