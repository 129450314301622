import PageDescription from "../layout/page-description";
import FullCalendar, { DateSelectArg, EventApi, EventClickArg, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarComponent from "../layout/Calendar";
import React from "react";
import TabsNeoGen from "../layout/tabs-neogen";

export default function Calendar() {
    function createEventId() {
        return "1";
    }
    const handleEventClick = (clickInfo: EventClickArg) => {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove();
        }
    };
    function renderEventContent(eventContent: EventContentArg) {
        return (
            <>
                <b>{eventContent.timeText}</b>
                <i>{eventContent.event.title}</i>
            </>
        );
    }
    const handleEvents = (events: EventApi[]) => {
        
    };
    const handleDateSelect = (selectInfo: DateSelectArg) => {
        let title = prompt("Please enter a new title for your event");
        let calendarApi = selectInfo.view.calendar;
    
        calendarApi.unselect(); // clear date selection
    
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            });
        }
    };

    const tabs = [
        { name: "Appointments", href: "#", current: true },
        { name: "Work Hours", href: "#", current: false },
        { name: "Integrations", href: "#", current: false },
        { name: "Billing", href: "#", current: false },
    ];

    return (
        <>
            <PageDescription>
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Calendar</p>
                <p className="mb-5">You can either integrate with an external calendar or use ours to do things like book appointments or set hours.</p>
                <div className="flex">
                    <div className="block w-full ">
                        <TabsNeoGen tabs={tabs} fullWidth={true}/>
                    </div>
                </div>
                
            </PageDescription>

            <div className="rounded-lg bg-white dark:bg-gray-700 p-5 shadow m-2">
                <CalendarComponent />
                {/* <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                /> */}
            </div>
            
        </>
    );
}