import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import carrierService from "../../../services/carrier.service";
import { useQueryClient } from "react-query";
import phoneNumberService from "../../../services/phone-number.service";
import transferDestinationService from "../../../services/transfer-destination.service";
import UserContext from "../../../services/user-context";
import { NeogenUser } from "../../../typings/api";
import AuthService from "../../../services/auth.service";
import ModalNeoGen from "../../layout/modal-neogen";
import Loader2 from "../../utilities/Loader2";
import InputNeoGenControlled from "../../layout/input-neogen-controlled";

export default function AddOutboundPhoneNumber(props: AddOutboundPhoneNumberProps) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [carrier, setCarrier] = useState(0);
    const [type, setType] = useState(0);
    const [sipURI, setSIPURI] = useState("");
    const [name, setName] = useState("");
    const [id, setId] = useState(0);
    const [description, setDescription] = useState("");
    const cache = useQueryClient();
    const [user,setUser]  = useContext(UserContext);
    const [canSeeCarriers, setCanSeeCarriers] = useState(false);
    // Get carriers
    const carrierQuery = useQuery(["carriers"], async () => {
        const result = await carrierService.getAll();
        if (result) {
            return result.data;
        } else {
            return [];
        }
    });

    useEffect(() => {
        AuthService.canIAccess("CAN_SELECT_CARRIER").then(r => {
            // debug(r);
            // debug(props.show);
            if (r) {
                setCanSeeCarriers(true);
            }
        });
    }, []);
    

    const transferDestinationQuery = useQuery(["transferDestinations", props.id], async () => {
        console.log(props.id);
        const result = await transferDestinationService.getOne(props.id);
        console.log(result);
        
        if (result && result.data) {
            setPhoneNumber(result.data.phoneNumber??"");
            setSIPURI(result.data.sipUri??"");
            setName(result.data.name??"");
            setDescription(result.data.description??"");
            setId(props.id);
            setCarrier(result.data.carrier??-1);
            return result.data;
        }
    }, {enabled: props.isEdit});


    function savePhoneNumber(newNumber: any) {
        if (props.isEdit) {
            return transferDestinationService.patchURL(transferDestinationService.endpoint+"/"+props.id,newNumber);
        } else {
            return transferDestinationService.create(newNumber);
        }
        
        // return true;
    }

    const mutator = useMutation(savePhoneNumber, {
        onSuccess: (data) => {
            cache.invalidateQueries(["transferDestinations"]).then(r => {
                console.log(r);
            });
        },
        onError: (e: any) => {
            console.error(e);
            console.error(e?.response);
        }
    });

    function addNumber() {
        const newNumber = {
            "phoneNumber": phoneNumber,
            "isSipTransfer": Number(type),
            "sipUri": sipURI,
            "name": name,
            "description": description,
            "carrier": carrier,
            owner: user.company
        };
        mutator.mutate(newNumber);
        props.hide();
    }

    function saveChanges() {
        const newNumber = {
            "phoneNumber": phoneNumber,
            "isSipTransfer": Number(type),
            "sipUri": sipURI,
            "name": name,
            "description": description,
            "carrier": carrier,
            "id":id,
            owner: transferDestinationQuery.data?.owner
        };
        mutator.mutate(newNumber);
        props.hide();
    }


    return (
        <ModalNeoGen show={props.show} close={() => props.hide()} okAction={() => {props.isEdit?saveChanges():addNumber();}} title={props.isEdit?"Edit Transfer Destination":"Add Phone Number"}>
            
            {!carrierQuery.isSuccess || (!transferDestinationQuery.isSuccess && props.isEdit)? (<>
                <div className="m-5 p-5 text-center"><Loader2 /></div>
            </>) : (
                <form>

                    <div className="mt-5" >
                        <InputNeoGenControlled name="name" label="Name:" value={name} setValue={(e) => setName(e)}/>                    
                    </div>
                    <div className="mt-5" >
                        <label htmlFor="formGroupExampleInput2">Description:</label>
                        <textarea rows={5} className="w-full" value={description} onChange={(e) => setDescription(e.currentTarget.value)} id="formGroupExampleInput2"  />
                    </div>
                    <div className="mt-5">
                        <label htmlFor="formGroupExampleInput">Type:</label>
                        <select title="Select" className="w-full mr-sm-2" value={type} onChange={(e) => setType(Number(e.currentTarget.value))}>
                            <option value={0}>Phone Number</option>
                            <option value={1}>SIP URI</option>
                        </select>
                    </div>
                    <div className="mt-5" style={{display: type==0?"block":"none"}}>
                        <InputNeoGenControlled name="name" label="Phone Number:" value={phoneNumber} setValue={(e) => setPhoneNumber(e)}/>
                        {/* <label htmlFor="formGroupExampleInput3">Phone Number:</label> */}
                        {/* <input type="text" className="w-full" value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} id="formGroupExampleInput3" placeholder="E.164 Format (i.e. +14075551234)" /> */}
                    </div>
                    <div className="mt-5" style={{display: type==1?"block":"none"}}>
                        <InputNeoGenControlled name="name" label="SIP URI:" value={sipURI} setValue={(e) => setSIPURI(e)}/>
                        {/* <label htmlFor="formGroupExampleInput4">SIP URI:</label> */}
                        <input type="text" className="w-full" value={sipURI} onChange={(e) => setSIPURI(e.currentTarget.value)} id="formGroupExampleInput4" placeholder="i.e. sip:alice@192.168.0.1" />
                    </div>
                    {canSeeCarriers && (
                        <div className="mt-5" style={{display: type==0?"block":"none"}}>
                            <label htmlFor="formGroupExampleInput2">Carrier</label>
                            <select title="Select" className="w-full" value={carrier} onChange={(e) => setCarrier(Number(e.currentTarget.value))}>
                                {carrierQuery.data.map((c: any) => {
                                    return (
                                        <option key={c.id} value={c.id}>{c.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                        
                </form>
            )}


            
        </ModalNeoGen>
    );
}

type AddOutboundPhoneNumberProps = {
    show: boolean,
    hide: any,
    isEdit: boolean,
    id: number
}