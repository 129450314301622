import { propTypes } from "react-bootstrap/esm/Image";
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";


  
function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}
  
type greeting = {
    id: number,
    name: string
}

export default function CallAnsweringStep(props: CallAnsweringStepProps) {
    const people = [
        { id: 1, name: "Thanks for calling "+props.companyName+".  You've reached us outside of office hours, how can I help you?" },
        { id: 2, name: "Hi, you've reached "+props.companyName+".  How can I help you today?" },
        { id: 3, name: ""+props.companyName+",  how can I help you today?" },
    ];
    const [selected, setSelected] = useState(people[0]);
    function setSelectedWrapper(entry:greeting) {
        // let entry = people.find(p => p.id = id);
        // alert(entry.name);
        props.setAnswerTranscript(entry.name);
        setSelected(entry);
    }
    useEffect(() => {
        props.setAnswerTranscript(selected.name);
    }, [props, selected.name]);
    const renderWelcomeMsg = (currentTime = new Date()) => {
        const currentHour = currentTime.getHours();
        const splitAfternoon = 12; // 24hr time to split the afternoon
        const splitEvening = 17; // 24hr time to split the evening
      
        if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
            // Between 12 PM and 5PM
            return "Good afternoon";
        } else if (currentHour >= splitEvening) {
            // Between 5PM and Midnight
            return "Good evening";
        }
        // Between dawn and noon
        return "Good morning";
    };
    return (
        <>
            <p className="wizard-step-explanation">How would you like your call answered?</p>
            <Listbox value={selected} onChange={setSelectedWrapper}>
                {({ open }) => (
                    <>
                        
                        <div className="mt-1 relative">
                            <Listbox.Button className="bg-white dark:bg-gray-800 dark:border-gray-800 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <span className="block truncate">{selected.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full dark:bg-gray-800 dark:border-0 dark:text-gray-300 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                    {people.map((person) => (
                                        <Listbox.Option
                                            key={person.id}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? "text-white bg-indigo-600 dark:text-gray-200" : "dark:text-gray-400",
                                                    "cursor-default select-none relative py-2 pl-3 pr-9 "
                                                )
                                            }
                                            value={person}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                                                        {person.name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? "text-white dark:text-gray-400" : "text-indigo-600 dark:text-gray-400",
                                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            
            
            
        </>
    );
}

type CallAnsweringStepProps = {
    setAnswerTranscript: (s: string) => void;
    answerTranscript: string;
    companyName: string;
}