import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import carrierService from "../../../services/carrier.service";
import { useQueryClient } from "react-query";
import phoneNumberService from "../../../services/phone-number.service";
import ModalNeoGen from "../../layout/modal-neogen";

export default function AddPhoneNumber(props: AddPhoneNumberProps) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [salePricePerMinute, setSalePricePerMinute] = useState("0.00");
    const [pricePerMinute, setPricePerMinute] = useState("0.00");
    const [salePricePerMonth, setSalePricePerMonth] = useState("0.00");
    const [pricePerMonth, setPricePerMonth] = useState("0.00");
    const [concurrentChannels, setConcurrentChannels] = useState("");
    const [freeMinutesIncluded, setFreeMinutesIncluded] = useState(0);
    const [carrier, setCarrier] = useState(-1);
    const cache = useQueryClient();

    // Get carriers
    const carrierQuery = useQuery(["carriers"], async () => {
        const result = await carrierService.getAll();
        if (result !== undefined) {
            return result.data;
        } else {
            return [];
        }
    });

    
    function savePhoneNumber(newNumber: any) {
        
        return phoneNumberService.create(newNumber);
        // return true;
    }

    const mutator = useMutation(savePhoneNumber, {
        onSuccess: (data) => {
            // Update `todos` and the individual todo queries when this mutation succeeds
            cache.invalidateQueries(["phoneNumbers"]).then(r => {
                console.log(r);
            });
        },
        onError: (e: any) => {
            console.error(e);
            console.error(e?.response);
        }
    });

    function addNumber() {
        const newNumber = {
            "number": phoneNumber,
            "salePricePerMonth": Number(salePricePerMonth),
            "salePricePerMinute": Number(salePricePerMinute),
            "pricePerMonth": Number(pricePerMonth),
            "pricePerMinute": Number(pricePerMinute),
            "freeMinutesIncluded": Number(freeMinutesIncluded),
            "maxChannels": Number(concurrentChannels),
            "source": carrier,
            owner: -1
        };
        mutator.mutate(newNumber);
        props.hide();
    }


    return (
        <ModalNeoGen show={props.show} close={() => props.hide()} title="Add Phone Number" okAction={() => addNumber()} okText="Add Phone Number">

            
            {!carrierQuery.isSuccess?(<>Loading...</>):(
                <form>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleInput" className="block text-sm font-medium text-gray-700">Phone Number</label>
                        <input type="text" 
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md"
                            value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} id="formGroupExampleInput" placeholder="Exactly how it is presented (i.e. +393291871754)" />
                    </div>
                    
                    <div>
                        <label htmlFor="price2" className="block text-sm font-medium text-gray-700">
                            Purchase Price Per Minute
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm mb-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="price2"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                value={pricePerMinute} onChange={(e) => setPricePerMinute(e.currentTarget.value)} 
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currency3">
                                    USD
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="price5" className="block text-sm font-medium text-gray-700">
                            Purchase Price Per Month
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm mb-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="price5"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                value={pricePerMonth} onChange={(e) => setPricePerMonth(e.currentTarget.value)} 
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currencyx">
                                    USD
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="priced" className="block text-sm font-medium text-gray-700">
                            Free Minutes Included
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm mb-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="priced"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                value={freeMinutesIncluded} onChange={(e) => setFreeMinutesIncluded(Number(e.currentTarget.value))} 
                            />

                        </div>
                    </div>
                    <div>
                        <label htmlFor="priceh" className="block text-sm font-medium text-gray-700">
                            Price Per Minute To Consumer
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm mb-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="priceh"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                value={salePricePerMinute} onChange={(e) => setSalePricePerMinute(e.currentTarget.value)} 
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currencyh">
                                    USD
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                            Price Per Month To Consumer
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm mb-5">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="price"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                                value={salePricePerMonth} onChange={(e) => setSalePricePerMonth(e.currentTarget.value)} 
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                    USD
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleInput2" className="block text-sm font-medium text-gray-700">Concurrent Channels</label>
                        <input type="number" 
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md"
                            id="formGroupExampleInput2" placeholder="i.e. 300" value={concurrentChannels}
                            onChange={(e) => {setConcurrentChannels(e.currentTarget.value);}}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput2" className="block text-sm font-medium text-gray-700">Carrier</label>
                        <select title="Select" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" value={carrier} onChange={(e) => setCarrier(Number(e.currentTarget.value))}>
                            {carrierQuery.data.map((c: any) => {
                                return (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                );
                            })}
                        </select>
                    </div>
                </form>
            )}
            
        </ModalNeoGen>
    );
}

type AddPhoneNumberProps = {
    show: boolean,
    hide: any,
}