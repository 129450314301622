import PrintPre from "./print-pre";

export default function CheckBoxNeoGenControlled(props:CheckBoxNeoGenControlledProps) {
    return (
        <fieldset className="space-y-5">
            <legend className="sr-only">Notifications</legend>
            <div className="relative flex items-start">
                <div className="flex items-center h-5">
                    <input
                        id="comments"
                        aria-describedby="comments-description"
                        name={props.name}
                        type="checkbox"
                        checked={props.checked|| false }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        onChange={props.onChange}
                    />
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">
                        {props.label}
                    </label>
                    <p id="comments-description" className="text-gray-500">
                        {props.description}
                    </p>
                </div>
            

            </div>
        </fieldset>
    );
}
type CheckBoxNeoGenControlledProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    label: string;
    name: string;
    description: string;
}
