import React, { useCallback, useEffect, useRef } from "react";
import VoiceControl from "@neogenai/voicecontrol";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintPre from "./print-pre";
import axios from "axios";


const vc = new VoiceControl(process.env.REACT_APP_NODE_TURN_URL??"turn:turn.neogen.ai:5349", process.env.REACT_APP_NODE_USER??"guest", process.env.REACT_APP_NODE_PASS??"starsock", process.env.REACT_APP_NODE_URL??"wss://node.neogen.ai");
// const vc = new VoiceControl("turn:turn.neogen.ai:5349", "guest", "starsock", "ws://localhost:9001");
// const vc = new VoiceControl("turn:turn.neogen.ai:5349", "guest", "starsock", "wss://node.neogen.ai");

type GuiEvent = {
    variable: string,
    value: any
}

type df = {name: string, url: string, score: number}

export default function CallAI(): JSX.Element {
    const [guiEvent, setGuiEvent] = useState<GuiEvent>();
    const [callTranscript, setCallTranscript] = useState("");
    const [final, setFinal] = useState(false);
    const [localStream, setLocalStream] = useState<MediaStream>();
    const [pc, setPc] = useState<RTCPeerConnection>();
    const [state, setState] = useState<any[]>([]);
    const [inCall, setInCall] = useState(false);
    const [calling, setCalling] = useState(false);
    const [unknown, setUnknown] = useState(false);
    // const navigate = useNavigate();
    const audio = useRef<HTMLAudioElement>(null);
    const endCall = useCallback(() => {
        vc.hangup();
    }, []);
    const handleGuiEvent = useCallback((event:any) => {
        async function lookup(destinations:any, dobjs:any, destinationsFull: any) {
            let options:df[] = [];
            for (let dobj of dobjs) {
                console.log("DOBJ: "+dobj);
                
                let r:any = await axios.post("https://embed.neogen.ai/compare-sentences", [...destinations, dobj]);
                console.error(r.data[0]);
                r.data.forEach((i:any) => {
                    if (i[0] === dobj||i[1]=== dobj) {
                        let option = destinationsFull.find((d:any) => d.name === i[0]||d.name===i[1]);
                        if (option) {
                            option.score = i[2];
                            options.push(option);
                        }
                    }
                });
            }
            return options;
        }
        console.log("Received Event: ", event);
        setGuiEvent(event);
        if (event.variable === "nlpv2Responses") {
            console.error(event.value);
            if (!event.value) {
                return;
            }
            let verbs:string[] = [];
            let dobjs:string[] = [];
            let pobjs:string[] = [];
            let propns:string[] = [];
            let nouns:string[] = [];
            event.value.forEach((i:any) => {
                console.log(i.dep+": "+i.text);
                // console.log(i.text);
                if (i.pos === "VERB") {
                    verbs.push(i.text);
                }
                if (i.dep === "dobj") {
                    let children = "";
                    if (Array.isArray(i.children_text)) {
                        children = i.children_text.join(" ")+" ";
                        console.log(i.children_text);
                    }
                    dobjs.push(children+i.text);
                }
                if (i.dep === "pobj") {
                    let children = "";
                    if (Array.isArray(i.children_text)) {
                        children = i.children_text.join(" ")+" ";
                        console.log(i.children_text);
                    }
                    pobjs.push(children+i.text);
                    
                }
                if (i.pos === "PROPN") {
                    propns.push(i.text);
                }
                if (i.pos === "NOUN") {
                    nouns.push(i.text);
                }
            });
            console.log({verbs, dobjs, pobjs, propns, nouns});
            
            let destinationsFull:df[] = [
                {name: "Home", url: "/", score: 0},
                {name: "Virtual Agents", url: "/scripts", score: 0},
                {name: "Create a new script", url: "/", score: 0},
                {name: "Log out", url: "/", score: 0},
                {name: "Billing", url: "/billing", score: 0},
                {name: "Phone Numbers", url: "/phone-numbers", score: 0},
                {name: "NLP V2", url: "/train-nlp-v2", score: 0},
                {name: "NLP", url: "/train-nlp", score: 0},
                {name: "Artificial Intelligence", url: "/artificial-intelligence", score: 0},
                {name: "Call Detail Report", url: "/call-history", score: 0},
                {name: "Add a user", url: "/users", score: 0},
            ];
            let destinations = destinationsFull.map(d => {
                return d.name;
            });
            let action = "";
            let changed = false;
            // let compare = dobjs.join(" ")+" "+ pobjs.join(" ")+" "+ propns.join(" ")+" "+ nouns;
            let promises = [];
            let options:df[] = [];
            // alert(JSON.stringify(verbs));
            if (dobjs.length > 0) {
                lookup(destinations, dobjs, destinationsFull).then(newOpts => {
                    console.log("New Opts:",newOpts);
                    if (pobjs.length > 0) {
                        lookup(destinations, pobjs, destinationsFull).then(newOpts => {
                            console.log("New Opts2:",newOpts);
                            if (newOpts.length > 0) {
                                const max = newOpts.reduce(function(prev, current) {
                                    return (prev.score > current.score) ? prev : current;
                                });
                                console.log(max);
                                endCall();
                                verbs.forEach(verb => {
                    
                                    switch(verb) {
                                        case "see":
                                        case "open":
                                        case "show":
                                        case "take":
                                        case "delete":
                                        case "remove":
                                        case "create":
                                        case "add":
                                            action = "open";
                                            // alert("Open: "+JSON.stringify(objs));
                                            break;
                                    }
                                });
                                if (action === "open") {
                                    // navigate(max.url);
                                    setCallTranscript("");
                                    changed = true;
                                }
                            }
                            
                            
                        });
                    } else {
                        if (newOpts.length > 0) {
                            const max = newOpts.reduce(function(prev, current) {
                                return (prev.score > current.score) ? prev : current;
                            });
                            console.log(max);
                            endCall();
                            verbs.forEach(verb => {
                
                                switch(verb) {
                                    case "see":
                                    case "open":
                                    case "show":
                                    case "take":
                                    case "delete":
                                    case "remove":
                                    case "create":
                                    case "add":
                                        action = "open";
                                        // alert("Open: "+JSON.stringify(objs));
                                        break;
                                }
                            });
                            if (action === "open") {
                                // navigate(max.url);
                                setCallTranscript("");
                                changed = true;
                            }
                        }
                    }
                });
            } else if (pobjs.length > 0) {
                lookup(destinations, pobjs, destinationsFull).then(newOpts => {
                    if (newOpts.length > 0) {
                        console.log("New Opts2:",newOpts);
                        const max = newOpts.reduce(function(prev, current) {
                            return (prev.score > current.score) ? prev : current;
                        });
                        console.log(max);
                        endCall();
                        verbs.forEach(verb => {
                
                            switch(verb) {
                                case "see":
                                case "open":
                                case "show":
                                case "take":
                                case "delete":
                                case "remove":
                                case "create":
                                case "add":
                                    action = "open";
                                    // alert("Open: "+JSON.stringify(objs));
                                    break;
                            }
                        });
                        if (action === "open") {
                            // navigate(max.url);
                            setCallTranscript("");
                            changed = true;
                        }
                    }
                });
            } 
            if (!changed) {
                setUnknown(true);
                // alert("Done");
                endCall();
            }
            
            
            // console.log(options);
            // // destinations.push(compare);
            // verbs.forEach(verb => {
                
            //     switch(verb) {
            //         case "see":
            //         case "open":
            //         case "take":
            //             action = "open";
            //             // alert("Open: "+JSON.stringify(objs));
            //             break;
            //     }
            // });
            // if (action === "open") {
            //     axios.post("https://embed.neogen.ai/compare-sentences", destinations).then (r => {
            //         console.log(r);
            //     });
            // }
            
        }
        // navigate("/scripts");
    }, [endCall]);
    const handleCallStart = useCallback((event:any) => {
        setUnknown(false);
        console.log("Call Started: " + event);
        // setInCall(true);
        setCalling(true);
    }, []);
    const handleCallEnd = useCallback((event:any) => {
        setInCall(false);
        setCalling(false);
        console.log("Call Ended: " + event);
        localStream?.getTracks().forEach(track => track.stop());
        if (audio.current) {
            audio.current.srcObject = null;
            pc?.close();
            // setPc(undefined);
        }
        
    }, [localStream, pc]);
    function startCall(destination: number, userId: string) {
        // vc.makeCall({destionationdestination, setLocalStream, setPc, setState, setFinal, setCallTranscript, setAudioSrc, userId});
    }
    function setAudioSrc(remoteStream: MediaStream | null) {
        if (audio && audio.current) {
            audio.current.srcObject = remoteStream;
        }
    }
    const handleMessage = useCallback((event:any) => {
        console.log(Math.random(),"Message: " , event);
        if (event.fromQuestion === -1) {
            setCalling(false);
            setInCall(true); 
        }
        // setInCall(true);
    }, []);

    useEffect(() => {
        vc.on("gui", handleGuiEvent);
        vc.on("call-start", handleCallStart);
        vc.on("call-end", handleCallEnd);
        vc.on("Transition", handleMessage);
        return () => {
            vc.off("gui", handleGuiEvent);
            vc.off("call-start", handleCallStart);
            vc.off("call-end", handleCallEnd);
            vc.on("Transition", handleMessage);
        };
    }, [handleCallEnd, handleCallStart, handleGuiEvent, handleMessage]);
    return (
        <div className="p-3">
            <button
                onClick={() => inCall?endCall():startCall(2288, "abc123")}
                className={"w-full text-white border p-2 rounded-lg dark:border-gray-400 dark:text-gray-400 "+(inCall?" bg-red-500 border-red-800 dark:border-red-900 dark:bg-red-800  ":"border-gray-200 ")}
            >
                {inCall && "End Call"}
                {calling && "Calling AI"}
                {!inCall && !calling && "Speak with AI"}
            </button>
            
            {callTranscript && (
                <>
                    <div className="my-5 text-white text-center">     
                        
                        <div className={"mt-5 mb-5 dark:text-gray-400 " + (final ? "text-white " : "text-red-400")}>
                            {callTranscript} {unknown && (
                                <>
                                    <i className="fa fa-question-circle fa-fade dark:text-indigo-700 text-indigo-400 text-xl" />
                                </>
                            )}
                        </div>
                        
                    </div>
                </>
            )}
            
            <div id="audio">
                <div style={{ display: "none" }}>
                    <div className="label">Remote audio:</div>
                    <audio id="audio2" ref={audio} autoPlay controls></audio>
                </div>
            </div>
        </div>
    );
}