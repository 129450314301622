import React, { useEffect, useState } from "react";
import { formatPhonenumber } from "../utilities/formatters";
import PrintPre from "./print-pre";

const camel2title = (camelCase: string) => camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import ChangeAgents from "../admin/smoothtorque/modals/change-agents";
import scriptService from "../../services/script.service";
import { useQuery } from "react-query";
import Loader2 from "../utilities/Loader2";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function TableNeogen(props: TableNeogenProps) {
    // const entries = [
    //     { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    //     // More people...
    // ];
    const scriptsQuery = useQuery(["scripts"], async () => {
        let response = await scriptService.getAll();
        // console.log("scripts for this folder: ", data);
        if (response) {
            return response.data;
        }
    });
    const [entries, setEntries] = useState<any[]>([]);
    const [px, setPx] = useState(3);
    const [py, setPy] = useState(3.5);
    const [pytd, setPytd] = useState(4);

    const [headers, setHeaders] = useState<string[]>([]);
    useEffect(() => {
        if (props.compact) {
            setPx(1);
            setPy(1.5);
            setPytd(2);
        }
    }, [props.compact]);
    useEffect(() => {
        if (!props.entries) {
            return;
        } else {
            if (!props.entries[0]) {
                // console.error(props.entries);
                return;
            }

        }
        const entries = JSON.parse(JSON.stringify(props.entries));
        // console.error(entries);
        const newEntries = entries.map((e: any) => {
            if (props.ignoreFields) {
                props.ignoreFields.forEach(i => {
                    try {
                        // console.error("Deleting "+i, e);
                        delete (e[i]);
                        // console.error("Deleted "+i, e);
                    } catch (x) {
                        console.error(x);
                    }

                });
            }
            if (e) {
                Object.entries(e).forEach(e2 => {
                    // console.error(e2);
                    const fo = props.formatters?.find(f => {
                        // console.error(f.field, e2[0]);
                        return f.field === e2[0];
                    });
                    if (fo) {
                        // console.error(fo);
                        switch (fo.type) {
                            case "Date":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = new Date(e2[1] as string).toLocaleDateString();
                                break;
                            case "Time":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = new Date(e2[1] as string).toLocaleTimeString();
                                break;
                            case "Datetime":
                            case "DateTime":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = new Date(e2[1] as string).toLocaleString();
                                break;
                            case "Phone":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = formatPhonenumber(e2[1] as string);
                                break;
                            case "PhoneNoSpace":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = formatPhonenumber(e2[1] as string)?.replaceAll(" ", "");
                                break;
                            case "Boolean":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = e2[1] ? "Yes" : "No";
                                break;
                            case "Script":
                                // console.error("Date", e2[1] );
                                e[e2[0]] = scriptsQuery.data?.find(i => Number(i.id) === Number(e2[1]))?.name;
                                break;
                        }
                    }
                    // console.error(typeof e2[1]);
                });
            }


            return e;
        });
        setEntries(newEntries);
        if (props.headers) {
            setHeaders(props.headers);
        } else {
            if (newEntries[0]) {
                // console.error(newEntries);
                let keys = Object.keys(newEntries[0]);
                keys = keys.map(k => {
                    return camel2title(k);
                });
                setHeaders(keys);
            }

        }
    }, [props.entries, props.formatters, props.headers, props.ignoreFields, scriptsQuery.data]);

    if (!props.entries || props.entries.length === 0) {
        return <></>;
    }
    

    if (scriptsQuery.isLoading) {
        return <Loader2 />;
    }
    return (
        <>
            <div className="mt-5 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-xl">
                            <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900">
                                <thead className={"bg-gray-50  dark:bg-gray-900 transition-colors duration-500 " + (props.refreshing && "bg-red-50 dark:bg-gray-800")}>
                                    <tr>
                                        {headers.map((h, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {h === "Id" ? <>
                                                    </> : (
                                                        <th key={i} scope="col" className={"dark:text-gray-500 " +
                                                            (i === 0 ?
                                                                "py-" + py + " pl-4 pr-" + px + " text-left text-sm font-semibold text-gray-900 sm:pl-6" :
                                                                "px-" + px + " py-" + py + " text-left text-sm font-semibold text-gray-900"
                                                            )}>
                                                            {h}
                                                        </th>
                                                    )}
                                                </React.Fragment>

                                            );
                                        })}
                                        {/* <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Title
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Email
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Role
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">Edit</span>
                                    </th> */}
                                        {props.actions && (
                                            <>
                                                {props.actions.map((a: any, idx: number) => (
                                                    <th key={idx}></th>
                                                ))}
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white  dark:bg-gray-800 dark:divide-gray-900">
                                    {entries.map((entry, idx) => (

                                        <tr key={idx}>
                                            {
                                                Object.entries(entry).map(([key, value], idx2) => {

                                                    return (
                                                        <React.Fragment key={key}>
                                                            {key === "id" ? <>
                                                            </> : (
                                                                <td key={key} className={((props.breaks?.find(x => x.field === key)?.type) ?? " break-normal ") + " " + ((props.maxWidths?.find(x => x.field === key)?.width) ?? "  ") + " " + (idx2 === 0 ? "py-" + pytd + " pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6" : "px-" + px + " py-" + pytd + " text-sm text-gray-500")}>
                                                                    {(value as string)}

                                                                    {/* {(key as string)} */}
                                                                    {/* {(idx2 as unknown as string)} */}
                                                                </td>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                            {props.actions && (
                                                <>
                                                    {props.actions.map((a: TableAction, idx: number) => (
                                                        <td key={idx} className={"px-" + px + " py-" + pytd + " text-sm text-gray-500 dark:bg-gray-800"}>
                                                            <button
                                                                type="button"
                                                                className={
                                                                    "inline-flex items-center px-2.5 py-1.5 border border-transparent" +
                                                                    " text-xs font-medium rounded shadow-sm text-white " + (a.disabled(entry.id) ? "bg-gray-400" : a.buttonClass) +
                                                                    " hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2" +
                                                                    " focus:ring-indigo-500"
                                                                }
                                                                onClick={() => a.action(entry.id)}
                                                                disabled={a.disabled(entry.id)}
                                                            >
                                                                <span className={a.icon} />
                                                                {a.name}

                                                            </button>

                                                        </td>
                                                    ))}
                                                </>
                                            )}
                                            {/* <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {entry.name}
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500">{entry.title}</td>
                                        <td className="px-3 py-4 text-sm text-gray-500">{entry.email}</td>
                                        <td className="px-3 py-4 text-sm text-gray-500">{entry.role}</td>
                                        <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                Edit<span className="sr-only">, {entry.name}</span>
                                            </a>
                                        </td> */}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export type TableAction = {
    name: string,
    action: (id: any) => void,
    buttonClass: string,
    icon?: string,
    disabled: (id: any) => boolean,
}

export type Formatter = {
    field: string,
    type: string
}

export type TableNeogenProps = {
    entries: any[],
    headers?: string[],
    ignoreFields?: string[];
    formatters?: Formatter[];
    breaks?: any[];
    maxWidths?: any[];
    actions?: TableAction[];
    refreshing?: boolean;
    compact?: boolean;
}