/**
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/17/20, 5:31 PM
 *
 */
import "./wdyr";
// import apm from "./rum";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import packageJson from "../package.json";
// import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://d706a5a6d1284b4ab01ffb0d228ba928@o514481.ingest.sentry.io/5618361",
        integrations: [new Integrations.BrowserTracing()],
        release: "VirtualAgentFrontEnd@" + packageJson.version,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // @ts-ignore This is allowed
        environment: (process.env.NODE_ENV??"") === "development" ? "development" : "production",
        tracesSampleRate: 1.0,
    });
} else {
    Sentry.init({
        integrations: [new Integrations.BrowserTracing()],
        release: "VirtualAgentFrontEnd@" + packageJson.version,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        environment: process.env.NODE_ENV === "development" ? "development" : "production",
        tracesSampleRate: 1.0,
    });
}

// @ts-ignore This is allowed
global.appVersion = packageJson.version;
// apm.setInitialPageLoadName("Base Load");
// Warn if overriding existing method

// @ts-ignore This is allowed
if (Array.prototype.equals) {
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
}
// attach the .equals method to Array's prototype to call it on any array

// @ts-ignore This is allowed
Array.prototype.equals = function(array) {
    // if the other array is a falsy value, return
    if (!array) {
        return false;
    }

    // compare lengths - can save a lot of time
    if (this.length !== array.length) {
        return false;
    }

    for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i])) {
                return false;
            }
        } else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });

// ReactDOM.render( < App / > , document.getElementById("root"));
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render( < App / > );
    
    // serviceWorker.register();
}
