/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenClickToCall } from "../typings/api/neogen-click-to-call";
import APIService from "./API.service";
import AuthService from "./auth.service";

class ClickToCallService extends APIService<NeogenClickToCall> {
    constructor() {
        super("neogen-click-to-calls");
    }
    async getAll() {
        if ( await AuthService.canIAccess("SEE_ALL_CLICK_TO_CALLS")) {
            return this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
        } else {
            
            let filter = {
                where: {
                    1: 1
                }
            };
            return this.getURL("/my-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
        }
    }
    async getOneEntry(id: number):Promise<void|NeogenClickToCall> {
        if ( await AuthService.canIAccess("SEE_ALL_CLICK_TO_CALLS")) {
            let filter = {
                where: {
                    id: Number(id)
                }
            };
            const response = await this.getURL("/neogen-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            if (response && Array.isArray(response.data)) {
                return response.data[0];
            } else {
                console.error(response,response);
                return;
            }
            // return response[0];
        } else {
            let filter = {
                where: {
                    id: Number(id)
                }
            };
            const response = await this.getURL("/my-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            if (response && Array.isArray(response.data)) {
                return response.data[0];
            } else {
                console.error(response,response);
                return;
            }
        }
    }
    async getOneEntryByUUID(uuid: string):Promise<void|NeogenClickToCall> {
        if ( await AuthService.canIAccess("SEE_ALL_CLICK_TO_CALLS")) {
            let filter = {
                where: {
                    uuid:uuid
                }
            };
            const response = await this.getURL("/neogen-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            if (response && Array.isArray(response.data)) {
                return response.data[0];
            } else {
                console.error(response,response);
                return;
            }
            // return response[0];
        } else {
            let filter = {
                where: {
                    uuid:uuid
                }
            };
            const response = await this.getURL("/my-click-to-calls?filter="+encodeURIComponent(JSON.stringify(filter))) as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            // const response = this.getURL("neogen-click-to-calls") as unknown as (void|AxiosResponse<NeogenClickToCall[]>);
            if (response && Array.isArray(response.data)) {
                return response.data[0];
            } else {
                console.error(response,response);
                return;
            }
        }
    }
}

export default new ClickToCallService();