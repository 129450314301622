
import React, { useEffect, useState } from "react";
import ButtonNeoGen from "../layout/button-neogen";
import PageDescription from "../layout/page-description";
import { Color, ColorResult, CompactPicker } from "react-color";
import useDebounce from "../../hooks/useDebounce";
import PrintPre from "../layout/print-pre";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import clickToCallsService from "../../services/click-to-calls.service";
import Loader2 from "../utilities/Loader2";
import { useForm } from "react-hook-form";
import AddFieldModal from "./modals/add-field";
import ScriptSelector from "../utilities/script-selector";
import authService from "../../services/auth.service";
import scriptFolderAccessService from "../../services/script-folder-access.service";
import scriptFolderEntryService from "../../services/script-folder-entry.service";
import scriptFolderService from "../../services/script-folder.service";
import Saving from "./modals/saving";

export default function ClickToCall() {
    const [currentColor, setColor] = useState<string>("#0062B1");
    const [buttonText, setButtonText] = useState<string>("Call Me Now");
    const [textColor, setTextColor] = useState<string>("#ffffff");
    // const [weight, setWeight] = useState<number>(50);
    const {id} = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { register, handleSubmit, watch, setValue } = useForm();
    const [data, setData] = useState("");
    const [script, setScript] = useState<number>(-1);
    const [folder, setFolder] = useState<number>();
    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [fields, setFields] = useState<string[]>([]);
    let [user, setUser] = useState(authService.getCurrentUser());
    async function getMyFolders() {
        
    }
    useEffect(() => {
        // console.log("x");
        
        if (script > 0) {
            // alert(script);
            scriptFolderService.getByScriptID(script).then(r => {
                if (r) {
                    // alert(JSON.stringify(r));
                    setFolder(r.data[0].scriptFolderId);
                }
            });
        }
        
    }, [script]);
    const myFoldersQuery = useQuery(["userFolders", user?.id], async () => {
        let result = await scriptFolderAccessService.getByCompanyIdOrUserId(user?.user?.company, user?.id);
        if (result) {
            return result.data;
        }
    },{
        enabled: user !== null,
    });
    async function getMyFolderEntries() {
        const folderIds = [];
        if (!myFoldersQuery.data) {
            return;
        }
        for (let folder of myFoldersQuery.data) {
            if (folder.scriptFolderId !== null) {
                folderIds.push(folder.scriptFolderId);
            }
    
        }
        let result = await scriptFolderEntryService.getFolderEntriesByMultipleFolders(folderIds);
        if (result) {
            return result.data;
        }
        // return result;
    }
    const myFolderEntriesQuery = useQuery(["myFolderEntries", user?.id], getMyFolderEntries,
        {
            enabled: myFoldersQuery.isSuccess,
        });
    const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  
    const clickToCallsQuery = useQuery(["ClickToCalls", id], async () => {
        const result = await clickToCallsService.getOne(Number(id));
        if (result) {
            setData(JSON.stringify(result.data));
            setColor(result.data.buttonColor??"#0062B1");
            setTextColor(result.data.textColor??"#ffffff");
            setScript(result.data.scriptId??-1);
            setValue("buttonText", result.data.buttonText??"Call Me Now");
            const existing = Object.keys(result.data.fields??{});
            setFields(existing);
            // alert("");
            // console.error(result);
            return result.data;
        }
        console.error(result, id);
    });

    const mutation = useMutation((details:any) => {
        // await api.patchURL("/neogen-scripts/" + id, scriptInternal);
        
        return clickToCallsService.patchURL(clickToCallsService.endpoint+"/"+id, details);
    });

    const debouncedColor = useDebounce(currentColor, 150);

    useEffect(() => {
        if (typeof debouncedColor === "object") {
            // props.setLinkColor(debouncedColor as unknown as ColorResult);
        }
        
    }, [debouncedColor]);

    // useEffect(() => {
    //     console.log(watchAllFields);
    // }, [watchAllFields]);

    function addField(fieldName: string) {
        console.log("add: "+fieldName);
        console.log(fields);
        // const obj = JSON.parse(fields);
        // fields.push(fieldName);
        // console.log(obj);
        if (Array.isArray(fields)) {
            setFields(c => [...c, fieldName]);
        } else {
            setFields([fieldName]);
        }
        
    }

    async function saveData() {
        const data = {...watchAllFields};
        data.fields = JSON.stringify( fields);
        data.buttonColor = currentColor;
        data.textColor = textColor;
        data.scriptId = script;
        // alert("Save: "+JSON.stringify(data));
        await mutation.mutate(data);
        await queryClient.invalidateQueries(["ClickToCalls"]);
        navigate("/click-to-call-instructions/"+id);
    }

    function removeOption(f: string) {
        setFields(e => {
            const existing = [...e];
            return existing.filter(entry => entry !== f);
        });
    }

    // function setScript(s:any) {
    // console.log(s);
    // }

    return (
        <>
            <PageDescription>
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Click To Call</p>
                <p className="mb-5">Here you&apos;ll find calling via clicking (i.d.: {id})</p>
            </PageDescription>
            {clickToCallsQuery.isLoading?<Loader2 />:(
                <div className="px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 rounded-lg shadow p-5 m-3">
                    {/* <PrintPre>
                        {clickToCallsQuery.data}
                    </PrintPre> */}
                    <form onSubmit={handleSubmit((data) => saveData())}>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left text-sm font-medium text-gray-500">
                    
    
                            <input type="hidden" {...register("fields")} />
                            <div className="space-y-6  sm:space-y-5">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Carrier Information</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Please fill in your information from Flowroute.  For more information please <a href="#">Click Here</a></p>
                                </div>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="cc-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                            Click To Call Name
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="cc-name"
                                                autoComplete="off"
                                                defaultValue={clickToCallsQuery.data?.name??""} 
                                                {...register("name")}
                                                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="cc-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                            Agent
                                        </label>
                                        <ScriptSelector myFolderEntriesQuery={myFolderEntriesQuery} setScript={setScript} script={script} folder={folder} setFolder={setFolder}/>
                                    </div>
                                </div>



                                


                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="flowroute-user" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                            Flowroute SIP Username
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="flowroute-user"
                                                autoComplete="off"
                                                defaultValue={clickToCallsQuery.data?.sipUser??""} 
                                                {...register("sipUser")}
                                                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                        Flowroute SIP Password
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                defaultValue={clickToCallsQuery.data?.sipPass??""} 
                                                {...register("sipPass")}
                                                id="last-name"
                                                autoComplete="new-password"
                                                className=" block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="clid" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                        Outbound Caller ID
                                        </label>
                                        <div className="mt-1 sm:mt-0 ">
                                            <input
                                                type="text"
                                                defaultValue={clickToCallsQuery.data?.clid??""} 
                                                {...register("clid")}
                                                id="clid"
                                                
                                                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                        Button Text (i.e. Call Me)
                                        </label>
                                        <div className="mt-1 sm:mt-0 ">
                                            <input
                                                type="text"
                                                // name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                defaultValue={clickToCallsQuery.data?.buttonText??"Call Me Now"} 
                                                {...register("buttonText")}
                                                // value={buttonText}
                                                // onChange={(e) => setButtonText(e.currentTarget.value)}
                                                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                            />

                                        </div>
                                    </div>

                                
                                    <div className="space-y-6 sm:space-y-5 col-span-6">
                                    
                                    
                                        <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-900 sm:pt-5">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                                Button Color
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <CompactPicker color={(currentColor)} onChange={color => setColor(color.hex)} />
                                            </div>
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                                Text Color
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">                                            
                                                <CompactPicker color={(textColor)} onChange={color => setTextColor(color.hex)} />
                                            </div>                                        
                                        </div>



                                        <div className="col-span-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-b pb-5 sm:border-gray-200 dark:border-gray-900 sm:pt-5">
                                            <div className=" sm:col-span-2">
                                                <button
                                                    type="button"
                                                    style={{
                                                        border: "1px solid #ccc",
                                                        borderRadius: "7px",
                                                        paddingTop: "7px",
                                                        paddingBottom: "7px",
                                                        paddingLeft: "12px",
                                                        paddingRight: "12px",
                                                        background: currentColor,
                                                        color: textColor,
                                                    }}
                                                >
                                                    <span className="flex-grow">
                                                        {watchAllFields.buttonText}
                                                    </span>
                                                </button>
                                                {/* <br /> */}
                                                <label htmlFor="first-name" className="sr-only ">
                                                    Number to call
                                                </label>
                            
                                                <input
                                                    type="text"
                                                    name="phonenumber"
                                                    id="phonenumber"
                                                    autoComplete="given-name"
                                                    placeholder="Phone Number To Call"
                                                    className="ml-5 mt-5 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-900"
                                                />
                                            </div>
                                
                                        </div>
                                
                                    </div>
                                    {/* <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-900 sm:pt-5">
                                        </div>
                                    </div> */}
                                </div>
                                <div className="space-y-6  sm:space-y-5 mt-5">
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">API Information</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">We can pull data from fields that exist on your landing page. Please add them by clicking the button below.</p>
                                        <div className="mt-5">
                                            <ButtonNeoGen onClick={() => setShowAddFieldModal(true)} text="Add Field" icon="fal fa-plus" type="info"/>
                                        </div>
                            
                                    </div>
                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid grid-cols-8 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 dark:border-gray-900 sm:pb-5">
                                            <p>Existing Fields:</p>
                                            {fields?.map(f => {
                                                return (
                                                    <div key={f}>
                                                        <span className="inline-flex rounded-full items-center py-2.5 pl-4 pr-2 text-sm font-medium bg-indigo-100 text-indigo-700">
                                                            {f}
                                                            <button
                                                                type="button"
                                                                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                                                onClick={() => removeOption(f)}
                                                            >
                                                                <span className="sr-only">Remove {f} option</span>
                                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                </svg>
                                                            </button>
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                            {/* <PrintPre>
                                                {fields}
                                            </PrintPre> */}
                                        </div>
                                    </div>
                        
                                </div>
                    
                            </div>
                            <div className="mt-5">
                                <ButtonNeoGen onClick={() => navigate("/click-to-call-selector")} text="Cancel" type="info"/>
                                <ButtonNeoGen onClick={() => saveData()} text="Get My Button Code" className="ml-5"/>
                            </div>
                        </div>
                    </form>
                    <AddFieldModal show={showAddFieldModal} close={() => setShowAddFieldModal(false)} addField={addField}/>
                    <Saving show={mutation.isLoading} close={() => {}} />
                </div>
            )}
            
        </>
    );
}