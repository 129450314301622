import React, { useState, useEffect, useRef } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { NeogenOnboarding } from "../../../../typings/api/neogen-onboarding";
import ButtonNeoGen from "../../../layout/button-neogen";
import InputNeoGen from "../../../layout/input-neogen";
import ModalNeoGen from "../../../layout/modal-neogen";
import { NeoGenOnboardingStage } from "../../../../typings/api/neogen-onboarding-stage";
import onboardingStageService from "../../../../services/onboarding-stage.service";
import Loader2 from "../../../utilities/Loader2";
import SelectNeoGen from "../../../layout/select-neogen";
import TextAreaNeoGen from "../../../layout/text-area-neogen";
import onboardingService from "../../../../services/onboarding.service";
import { AddToList, Entry } from "../../../layout/add-to-list";
import PrintPre from "../../../layout/print-pre";
import InputNeoGenControlled from "../../../layout/input-neogen-controlled";
import TextAreaNeoGenControlled from "../../../layout/text-area-neogen-controlled";
import Swal from "sweetalert2";


const resolver: Resolver<NeogenOnboarding> = async (values) => {
    return {
        values: values.title ? values : {},
        errors: !values.title
            ? {
                title: {
                    type: "required",
                    message: "This is required.",
                },
            }
            : {},
    };
};

export default function AddEdit(props:AddEditProps) {
    
    const [stage, setStage] = useState<number>(-1);
    const [reliesOnTrue, setReliesOnTrue] = useState<number>(-1);
    const [reliesOnFalse, setReliesOnFalse] = useState<number>(-1);
    const [inputType, setInputType] = useState<number>(-1);
    const [causesRepContactOnCompletion, setCausesRepContactOnCompletion] = useState<boolean>(false);
    const [checkboxEntries, setCheckboxEntries] = useState<Entry[]>([]);
    const [textfieldEntries, setTextfieldEntries] = useState<Entry[]>([]);
    const [textAreaEntries, setTextAreaEntries] = useState<Entry[]>([]);
    const [sendsEmailOnCompletion, setSendsEmailOnCompletion] = useState<boolean>(false);
    const [internal, setInternal] = useState<boolean>(false);
    const [optional, setOptional] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [details, setDetails] = useState<string>("");
    const [textFieldName, setTextFieldName] = useState<string>("");
    const [internalComments, setInternalComments] = useState<string>("");
    const [currentReliesOnTrue, setCurrentReliesOnTrue] = useState<NeogenOnboarding|undefined>(undefined);
    const [checkboxEnabler, setCheckboxEnabler] = useState<string>("");
    const fieldsSet = useRef(false);
    const cache = useQueryClient();
    // const { register, handleSubmit, formState: { errors }, watch } = useForm<NeogenOnboarding>({ resolver });
    // const onSubmit = handleSubmit((data) => {
    // console.log(data);
    // });
    const stageQuery = useQuery(["onboarding-stages"], async () => {
        const response = await onboardingStageService.getAll();
        if (response) {
            return response.data;
        }
    });

    // useEffect(() => {
    //     setInputType(watch("inputType")??"");
    // }, [watch]);
    // console.log(watch("inputType"));
    const entriesQuery = useQuery(["onboarding-entries"], async () => {
        const response = await onboardingService.getAll();
        if (response) {
            const mapped =  response.data.map((entry:NeogenOnboarding) => {
                entry.stageName = stageQuery.data?.find((stage:NeoGenOnboardingStage) => stage.id === entry.stage)?.name ??"Unknown";
                return entry;
            });
            return mapped;
        }
    }, {enabled: stageQuery.isSuccess});
    
    useEffect(() => {
        if (props.isEdit && entriesQuery.isSuccess && !fieldsSet.current) {
            fieldsSet.current = true;
            console.log(entriesQuery.data, props.id);
            const entry = entriesQuery.data?.find((entry:any) => entry.id === props.id);
            if (entry) {
                // alert(JSON.stringify(entry));
                setStage(entry.stage);
                setTitle(entry.title);
                setDetails(entry.details??"");
                setReliesOnTrue(entry.reliesOnTrue??-1);
                setReliesOnFalse(entry.reliesOnFalse??-1);
                setInputType(entry.inputType??0);
                setTextFieldName(entry.textfieldName??"");
                setCheckboxEnabler(entry.checkboxEnabler??"");
                setTextAreaEntries(JSON.parse(entry.textareaEntries??"[]") as Entry[]);
                setTextfieldEntries(JSON.parse(entry.textfieldEntries??"[]") as Entry[]);
                setCausesRepContactOnCompletion(entry.causesRepContactOnCompletion===1);
                setCheckboxEntries(JSON.parse(entry.checkboxEntries??"[]") as Entry[]);
                console.log(entry);
                setSendsEmailOnCompletion(entry.sendsEmailOnCompletion===1);
                setInternal(entry.internalOnly===1);
                setOptional(entry.optional===1);
                setInternalComments(entry.internalComments??"");
            }
        }
    }, [props.id, props.isEdit, entriesQuery.data, entriesQuery.isSuccess]);

    async function save() {
        const entry:NeogenOnboarding = {
            stage: stage,
            title: title,
            details: details,
            reliesOnTrue: reliesOnTrue??-1,
            reliesOnFalse: reliesOnFalse??-1,
            inputType: inputType,
            textfieldName: textFieldName,
            causesRepContactOnCompletion: causesRepContactOnCompletion ? 1 : 0,
            checkboxEntries: JSON.stringify(checkboxEntries),
            textareaEntries: JSON.stringify(textAreaEntries),
            textfieldEntries: JSON.stringify(textfieldEntries),
            sendsEmailOnCompletion: sendsEmailOnCompletion ? 1 : 0,
            internalOnly: internal ? 1 : 0,
            optional: optional ? 1 : 0,
            internalComments: internalComments,
            checkboxEnabler: checkboxEnabler,
            helpDoc: -1,
        };
        try {
            // let response;
            if (props.isEdit) {
                entry.id = props.id;
                await onboardingService.update(props.id, entry);
            } else {
                await onboardingService.create(entry);
            }
            props.close();
            Swal.fire({
                title: "Success",
                text: "Entry saved successfully.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                cache.invalidateQueries(["onboarding-entries"]);
                
            });
            
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: "Error",
                text: "Error saving entry.",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
        }
        
    }

    function renderEntries(existing:any) {
        const newEntries = existing?.map((e:any) => {
            e.name = e.title;
            return e;
        });
        newEntries?.push({id: 0, name: "None"});
        return newEntries;
    }

    useEffect(() => {
        if (reliesOnTrue > -1) {
            setCurrentReliesOnTrue(entriesQuery.data?.find((e:any) => e.id === reliesOnTrue));
        }
    }, [entriesQuery.data, reliesOnTrue]);

    return (
        <ModalNeoGen title={"Add Entry"} show={props.show} close={props.close } size="md" okAction={save} okText="Save">
            {stageQuery.isLoading ?(
                <Loader2 />
            ): (
                <div className="grid grid-cols-2 gap-5">
                    <InputNeoGenControlled label={"Item Title"} value={title} setValue={setTitle} name={"title"} />
                    
                    <SelectNeoGen label={"Stage"} value={stage} onChange={(e:(string|number)) => setStage(Number(e))} entries={stageQuery.data} />
                    <div className="col-span-2">
                        <TextAreaNeoGenControlled value={details} label={"Details"} setValue={setDetails} name={"details"} />
                    </div>
                    <div className="col-span-2">
                        <TextAreaNeoGenControlled value={internalComments} label={"Internal Comments"} setValue={setInternalComments} name={"internalComments"} />
                    </div>
                    <SelectNeoGen label={"Require This Option To Be True"} value={reliesOnTrue} onChange={(e:(string|number)) => setReliesOnTrue(Number(e))}
                        entries={renderEntries(entriesQuery.data)} />
                    
                    {reliesOnTrue > 0 && currentReliesOnTrue?.inputType === 0 &&(
                        <>
                            <SelectNeoGen label={"Select the checkbox entry that turns on this step"} value={checkboxEnabler} onChange={(e:(string|number)) => setCheckboxEnabler(e.toString())} 
                                entries={JSON.parse(currentReliesOnTrue?.checkboxEntries??"")?.map((e:any) => {
                                    e.name = e.field;
                                    e.id = e.field;
                                    return e;
                                })} />
                            {/* <PrintPre>{currentReliesOnTrue}</PrintPre>
                            <PrintPre>{JSON.parse(currentReliesOnTrue?.checkboxEntries??"")?.map((e:any) => {
                                e.name = e.field;
                                return e;
                            })}</PrintPre> */}
                        </>
                    )}
                    <SelectNeoGen label={"Require This Option To Be False"} value={reliesOnFalse} onChange={(e:(string|number)) => setReliesOnFalse(Number(e))}
                        entries={renderEntries(entriesQuery.data)} />
                    {/* <SelectNeoGen label={"Input Type"} value={inputType} onChange={(e:(string|number)) => setInputType(Number(e))}
                        entries={[
                            {id: 0, name: "Check Box"},
                            {id: 1, name: "Yes/No"},
                            {id: 2, name: "Text Input"},
                            {id: 3, name: "Text Area"},
                            {id: 4, name: "None"},
                        ]}
                    /> */}
                    {/* <InputNeoGen label={"inputType"} register={register } name={"inputType"} /> */}
                    {/* {inputType === 0 && ( */}
                    <div className="col-span-2">

                        <AddToList icon="fal fa-circle-check fa-2x" title={"Checkbox Entries"} entries={checkboxEntries} setEntries={setCheckboxEntries}/>
                        {/* <TextAreaNeoGen label={"Checkbox Entries (One per line)"} register={register } name={"checkboxEntries"} /> */}
                    </div>
                    {/* )} */}
                    {/* {inputType === 2 && ( */}
                    <div className="col-span-2">

                        <AddToList icon="fal fa-input-text fa-2x" title={"Text Input Entries"} entries={textfieldEntries} setEntries={setTextfieldEntries}/>
                        {/* <TextAreaNeoGen label={"Checkbox Entries (One per line)"} register={register } name={"checkboxEntries"} /> */}
                    </div>
                        
                    {/* )} */}
                    {/* {inputType === 3 && ( */}
                    <div className="col-span-2">

                        <AddToList icon="fal fa-pen-field fa-2x" title={"Text Area Entries"} entries={textAreaEntries} setEntries={setTextAreaEntries}/>
                        {/* <TextAreaNeoGen label={"Checkbox Entries (One per line)"} register={register } name={"checkboxEntries"} /> */}
                    </div>
                        
                    {/* )} */}
                                    
                    <SelectNeoGen label={"Input is Optional"} entries={
                        [
                            {id: 1, name: "Yes"},
                            {id: 0, name: "No"},
                        ]
                    }
                    value={optional?1:0} onChange={(e:(string|number)) => setOptional(e.toString()==="1")} />
                    <SelectNeoGen label={"Internal Only"} entries={
                        [
                            {id: 1, name: "Yes"},
                            {id: 0, name: "No"},
                        ]
                    }
                    value={internal?1:0} onChange={(e:(string|number)) => setInternal(e.toString()==="1")} />
                    
                    <SelectNeoGen label={"Sends Email On Completion"} entries={
                        [
                            {id: 1, name: "Yes"},
                            {id: 0, name: "No"},
                        ]
                    }
                    value={sendsEmailOnCompletion?1:0} onChange={(e:(string|number)) => setSendsEmailOnCompletion(e.toString()==="1")} />

                    <SelectNeoGen label={"Causes Rep Contact On Completion"} entries={
                        [
                            {id: 1, name: "Yes"},
                            {id: 0, name: "No"},
                        ]
                    }
                    value={causesRepContactOnCompletion?1:0} onChange={(e:(string|number)) => setCausesRepContactOnCompletion(e.toString()==="1")} />
                    
                </div>
            )}
        </ModalNeoGen>
    );
}

type AddEditProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    id: number;
}