import React from "react";
import ModalNeoGen from "../../layout/modal-neogen";
import Loader2 from "../../utilities/Loader2";

export default function Saving(props: SavingProps) {
    return (
        <ModalNeoGen title="Saving" show={props.show} close={props.close} showCancel={false} showOk={false} >
            <Loader2 />
        </ModalNeoGen>
    );
}

type SavingProps = {
    show: boolean,
    close: () => void,
}