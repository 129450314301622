import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useForm } from "react-hook-form";
import { start } from "repl";
import calendarService from "../../../services/calendar.service";
import ModalNeoGen from "../../layout/modal-neogen";
import { useQuery, useQueryClient } from "react-query";

type FormData = {
    start:Date, 
    end:Date, 
    status:string, 
    summary:string, 
    location:string
}

export default function AddEvent(props:AddEventProps):JSX.Element {
    const { register, setValue, handleSubmit, formState: { errors }, getValues } = useForm<FormData>();
    const queryCache                          = useQueryClient();
    // const onSubmit = handleSubmit(data => console.log(data));
    function addEvent() {
        const data = getValues();
        calendarService.addEvent(data.start, data.end, "CONFIRMED", data.summary, data.location).then((r:any) => {
            console.log(r);
            queryCache.invalidateQueries(["events"]).then((r:any) => {
                
                props.close();
            });
        });
    }
    return (
        <>
            <ModalNeoGen title="Add Event" show={props.show} close={props.close} okAction={() => addEvent()}>
                <>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Booking Name
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                id="summary"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                {...register("summary")}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Location
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                id="location"
                                {...register("location")}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Start Date/Time
                        </label>
                        <div className="mt-1">
                            <input
                                type="datetime-local"
                                id="start"
                                {...register("start")}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            End Date/Time
                        </label>
                        <div className="mt-1">
                            <input
                                type="datetime-local"
                                id="end"
                                {...register("end")}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                
                            />
                        </div>
                    </div>
                </>
            </ModalNeoGen>
        </>
    );
}

type AddEventProps = {
    close: () => void,
    show: boolean,
}