/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenAudioFolder } from "../typings/api";
import { NeogenOnboarding } from "../typings/api/neogen-onboarding";
import { NeoGenOnboardingStage } from "../typings/api/neogen-onboarding-stage";
const debug = require("debug")("NeoGen:AudioFolderService");

const API_URL = process.env.REACT_APP_API_URL;

class OnboardingStageService extends APIService<NeoGenOnboardingStage> {
    constructor() {
        super("neogen-onboarding-stages");
    }
}

export default new OnboardingStageService();