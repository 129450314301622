/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeoGenIntegrationTypes } from "../typings/api/neogen-integration-types";
import APIService from "./API.service";

class IntegrationTypesService extends APIService<NeoGenIntegrationTypes> {
    constructor() {
        super("neogen-integration-types");
    }
    getAll():Promise<void|AxiosResponse<NeoGenIntegrationTypes[]>> {
        let filter = {
            where: {
                enabled: 1
            }
        };
        return this.getURL(this.endpoint+"?filter="+encodeURIComponent(JSON.stringify(filter)));
    }
}

export default new IntegrationTypesService();