import React, { useState } from "react";
import { useQuery } from "react-query";
import clickToCallsService from "../../../services/click-to-calls.service";
import PrintPre from "../../layout/print-pre";
import CTCSelector from "../../utilities/ctc-selector";
import Loader2 from "../../utilities/Loader2";

export default function ClickToCallReport() {
    const [ctc, setCTC] = useState<number>(-1);
    // function setCTC(e:number) {
    //     alert(e);
    // }
    const clickToCallsQuery = useQuery(["ClickToCalls", ctc], async () => {
        if (ctc> 0) {
            const result = await clickToCallsService.getOne(Number(ctc));
            if (result) {
                // setData(JSON.stringify(result.data));
                // setColor(result.data.buttonColor??"#0062B1");
                // setTextColor(result.data.textColor??"#ffffff");
                // setScript(result.data.scriptId??-1);
                // setValue("buttonText", result.data.buttonText??"Call Me Now");
                // const existing = Object.keys(result.data.fields??{});
                // setFields(existing);
                // alert("");
                // console.error(result);
                return result.data;
            }
            // console.error(result, id);
        } else {
            return [];
        }
        
    });
    return (
        <>
        
            <CTCSelector setCTC={(e) => setCTC(e)} />
            {ctc > 0 && (
                <>
                    {clickToCallsQuery.isLoading ? <Loader2 /> : (
                        <>

                            
                            <PrintPre>
                                {clickToCallsQuery.data}
                            </PrintPre>
                            
                        </>
                    )}
                
                </>
            )}
        </>
    );
}