/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import APIService from "./API.service";

class CarrierService extends APIService {
    constructor() {
        super("neogen-carriers");
    }

}

export default new CarrierService();