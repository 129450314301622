import { useRef, useState } from "react";
import webRTC from "../../services/webrtc.service";
// import signalingChannel from "../../services/signalling-channel.service";
import ButtonNeoGen from "../layout/button-neogen";
import PrintPre from "../layout/print-pre";
import ScriptSelector from "../utilities/script-selector";
// import

export default function WebRTC(props: WebRTCProps) {
    // const [signalingChannel, setSignallingClient] = useState<signallingChannelService>(null)
    const [transcript, setTranscript] = useState("");
    const [final, setFinal] = useState(false);
    const audio = useRef<HTMLAudioElement>(null);
    const [localStream, setLocalStream] = useState<MediaStream>();
    const [pc, setPc] = useState<RTCPeerConnection>();
    const [state, setState] = useState<any[]>([]);
    const [script, setScript] = useState(-1);
    const [folder, setFolder] = useState(-1);
    // const signalingChannel = new signallingChannelService("remoteClientId");




    function setAudioSrc(remoteStream: MediaStream | null) {
        if (audio && audio.current) {
            // const remoteStream = new MediaStream();         
            // remoteStream.addTrack(e.track);
            // setAudioSrc( remoteStream);
            
            audio.current.srcObject = remoteStream;
        }
    }
    


    
    return (
        <>
            {props.myFolderEntriesQuery.isLoading?(
                <p>Loading Folder Access...</p>
            ):(
            
                <>
                    <ScriptSelector setFolder={setFolder} folder={folder} script={script} myFolderEntriesQuery={props.myFolderEntriesQuery} setScript={setScript}/>
                    {script === -1?<>Select a script</>:(<>
                        <div id="container">
                            
                            <div className="my-5 border border-gray-400 p-5 rounded-xl shadow-lg">
                    Transcript:
                                {transcript!==""?(
                                    <div className={"mt-5 mb-5 "+(final?"text-gray-500 font-bold ":"text-blue-700")}>
                                        {transcript}

                                    </div>
                                ):""}
                            </div>
                            <div className="my-5 border border-gray-400 p-5 rounded-xl shadow-lg">
                                {state.map((e:any, idx: number) => {
                                    return (
                                        <span key={idx}>
                                            {new Date(e.time).toLocaleString()}&nbsp;
                                            <b>{e.variable}: </b>
                                            {<PrintPre>e.value</PrintPre>}<br />
                                        </span>
                                    );
                                })}
                            </div>
                            <div id="audio">
                                <div style={{display:"none"}}>
                                    <div className="label">Remote audio:</div>
                                    <audio id="audio2" ref={audio} autoPlay controls></audio>
                                </div>
                            </div>
                            <div className="my-5 border border-gray-400 p-5 rounded-xl shadow-lg">
                                <ButtonNeoGen onClick={() => webRTC.makeCall(script, setLocalStream, setPc, setState, setFinal, setTranscript, setAudioSrc)}>Call</ButtonNeoGen>
                                <ButtonNeoGen className="ml-5" type="danger" onClick={() => webRTC.hangup(setPc, localStream, pc)}>Hang Up</ButtonNeoGen>
                            </div>
                        </div>
                    </>)}
                    
            

                </>
            )}
        </>
    );
}
type WebRTCProps = {
    myFolderEntriesQuery: any
}
