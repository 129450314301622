/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeoGenIntegration } from "../typings/api/neogen-integration";
import APIService from "./API.service";

class IntegrationsService extends APIService<NeoGenIntegration> {
    constructor() {
        super("neogen-integrations");
    }
    getAll():Promise<void|AxiosResponse<NeoGenIntegration[]>> {
        return super.getURL("my-neogen-integrations");
    }
}

export default new IntegrationsService();