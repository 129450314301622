/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { MultiTenant } from "../typings/api/multitenant";
import APIService from "./API.service";

// const debug        = require('debug')('Neogen:CDRService');

class MultiTenantService extends APIService<MultiTenant> {
    constructor() {
        super("/multitenants");
    }

}

export default new MultiTenantService();