import Pizzly from "pizzly-js";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../services/user-context";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../typings/api/neogen-integration";
import { NeoGenIntegrationTypes } from "../../typings/api/neogen-integration-types";
import AsanaPicker from "./pickers/asana-picker";
import ZendeskChatPicker from "./pickers/slack-picker";
import ClioPicker from "./pickers/clio-picker";
import SlackPicker from "./pickers/slack-picker";

type project = {
    gid: string,
    name: string,
    resource_type: string
}


export default function ConnectedIntegration(props: ConnectedIntegrationProps) {
    const [user,setUser]  = useContext(UserContext);
    const [contents, setContents] = useState<project[]>([]);
    const [connecting, setConnecting] = useState(false);
    const [isError, setIsError] = useState(false);
    function testIntegration(i: NeoGenIntegrationTypes, auth: NeoGenIntegration) {
        setConnecting(true);
        const pizzly = new Pizzly({ host: "https://pizzly.neogen.ai", publishableKey: "d3dk78fgyhskjhdfksjdff7kvledifkmal" }); // Initialize Pizzly
        const myAPI = pizzly.integration(i.slug); // Replace with the API slugname
        console.log(i, auth);
        myAPI
            .auth(auth.authId??"") // Replace with the authId previously obtained
            .get("/projects") // Replace with a valid endpoint of the API
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                setContents(json.data);
                setConnecting(false);
            })
            .catch(e => {
                setConnecting(false);
                console.error(e);
            }
            );
    }
    function renderContents() {
        switch (props.integrationType.id) {
            case 1:  // Asana
                console.log("Asana");
                return <AsanaPicker integration={props.integration} addIntegration={props.addIntegration} removeIntegration={props.removeIntegration} services={props.services||[]}/>;
            
            case 2:  // Slack
                console.log("Slack");
                return <SlackPicker integration={props.integration} addIntegration={props.addIntegration} removeIntegration={props.removeIntegration} services={props.services||[]}/>;
            
            case 3:  // Zendesk Chat
                console.log("Slack");
                return <ZendeskChatPicker integration={props.integration} addIntegration={props.addIntegration} removeIntegration={props.removeIntegration} services={props.services||[]}/>;
            
            case 4:  // Slack
                console.log("Clio");
                return <ClioPicker integration={props.integration} addIntegration={props.addIntegration} removeIntegration={props.removeIntegration} services={props.services}/>;
            
        }
    }

    return (
        <>
            {renderContents()}
        </>
    );
}

type ConnectedIntegrationProps = {
    integrationType: NeoGenIntegrationTypes,
    integration?: NeoGenIntegration,
    addIntegration: any,
    removeIntegration: any,
    services?: NeoGenIntegrationWrapper[],
}