import Pizzly from "pizzly-js";
import  {  useEffect, useState } from "react";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../../typings/api/neogen-integration";
import Loader2 from "../../utilities/Loader2";

type project = {
    gid: string,
    name: string,
    resource_type: string
}

const pizzly = new Pizzly({ host: "https://pizzly.neogen.ai", publishableKey: "d3dk78fgyhskjhdfksjdff7kvledifkmal" });
const myAPI = pizzly.integration("asana");

export default function AsanaPicker(props: AsanaPickerProps) {
    const [workspaces, setWorkSpaces] = useState<project[]>([]);
    const [teams, setTeams] = useState<project[]>([]);
    const [projects, setProjects] = useState<project[]>([]);
    const [sections, setSections] = useState<project[]>([]);

    const [projectSelected, setProjectSelected] = useState("");
    const [workSpaceSelected, setWorkSpaceSelected] = useState("");
    const [teamSelected, setTeamSelected] = useState("");
    const [sectionSelected, setSectionSelected] = useState("");
    const [sectionNameSelected, setSectionNameSelected] = useState("");
    const [tags, setTags] = useState<project[]>([]);
    function resetAll() {
        setProjectSelected("");
        setWorkSpaceSelected("");
        setTeamSelected("");
        setSectionSelected("");

    }
    function compare(a:any, b:any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        console.log({se: props.services});
        props.services.forEach((i: NeoGenIntegrationWrapper) => {
            if (i.integration === props.integration && projects) {
                console.log("Found me s", i);
                setProjectSelected(i.propOne);
                setSectionSelected(i.propTwo);
                let name: string = sections.find((x:any) => {
                    console.log("Checking: ",x,i);
                    return x.gid === i.propTwo;
                })?.name??"";
                setSectionNameSelected(name);
                // setChannel(i.propOne);
            }
            if (i.integration === props.integration && workspaces) {
                console.log("Found me w");
                setWorkSpaceSelected("bla");
                // setChannel(i.propOne);
            }
        });
    }, [props.integration, props.services, projects, workspaces, sections]);

    useEffect(() => {
        myAPI
            .auth(props.integration?.authId??"")
            .get("/workspaces")
            .then((response)=> response.json())
            .then((json) =>  {
                console.info(json);
                setWorkSpaces(json.data.sort(compare));

            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId]);
    useEffect(() => {

        console.log(workSpaceSelected);
        console.log("/organizations/"+workSpaceSelected+"/teams");
        myAPI
            .auth(props.integration?.authId??"")
            .get("/organizations/"+workSpaceSelected+"/teams")
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                if (Array.isArray(json.data)) {
                    setTeams(json.data.sort(compare));
                }
                
            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId, workSpaceSelected]);
    useEffect(() => {

        myAPI
            .auth(props.integration?.authId??"")
            .get("/teams/"+teamSelected+"/projects")
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                if (Array.isArray(json.data)) {
                    setProjects(json.data.sort(compare));
                }
                
            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId, teamSelected]);
    useEffect(() => {

        myAPI
            .auth(props.integration?.authId??"")
            .get("/projects/"+projectSelected+"/sections")
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                if (Array.isArray(json.data)) {
                    setSections(json.data.sort(compare));
                }
                
            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId, projectSelected]);
    useEffect(() => {

        myAPI
            .auth(props.integration?.authId??"")
            .get("/tags")
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                setTags(json.data.sort(compare));
            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId]);
    function pushLead() {

        let newTask = {
            body: JSON.stringify({
                data: {
                    "name": "NeoGen.AI Lead Test Created on "+new Date().toLocaleString(),
                    "notes": "Mittens is a kitten",
                    projects: projectSelected,
                    "resource_subtype": "default_task",
                }
            }), queryString: "/tasks"
        };

        console.log(newTask);

        myAPI
            .auth(props.integration?.authId??"")
            .post("/tasks", newTask)
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                let section = {body: JSON.stringify({
                    data: {
                        "task": json.data.gid
                    }
                })};
                console.info({section,sectionSelected});
                myAPI
                    .auth(props.integration?.authId??"")
                    .post("/sections/"+sectionSelected+"/addTask", section)
                    .then((response)=> response.json())
                    .then((json) =>  {
                        console.error(json);
                        alert("Lead Pushed");
                    })
                    .catch(e => {
                        console.error(e);
                    }
                    );
                
            })
            .catch(e => {
                console.error(e);
            });

    }
    return (
        <>
            {sectionSelected !== ""? (<>
                <div className="card">
                    <div className="card-header">
                        All Done
                    </div>
                    <div className="card-body">
                        <p className="pl-0">Leads Going To: <b>{sectionNameSelected}</b></p>
                        {/* Tags:<br />
                        <br />
                        {tags&&tags.length > 0&&tags.map((t:any) => {
                            return (
                                <div className="badge badge-primary m-1" key={t.gid}>
                                    {t.name}
                                </div>
                            );
                        })}<br />
                        <br /> */}
                        <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg m-1" type="button" onClick={() => pushLead()}>Push Test Lead</button>
                        <button className="bg-transparent hover:bg-red-500 text-red-500  hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded-lg m-1" type="button" onClick={() => {
                            resetAll();
                            props.removeIntegration(props.integration);
                        }}><i className="fal fa-trash mr-3"></i>Remove Integration</button>
            
                    </div>
                </div>
            </>):projectSelected !== ""?(<>
                <div className="card">
                    <div className="card-header">
                        Select A Section
                    </div>
                    <div className="card-body">
                        {sections && sections.length > 0 ? sections.map((c:project) => {
                            return (
            
                                <button key={c.gid}className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg m-1" onClick={() => {
                                    props.addIntegration({integration: props.integration, propOne: projectSelected, propTwo: c.gid, destinationName: c.name});
                                    setSectionSelected(c.gid);
                                    setSectionNameSelected(c.name);
                                }}>{c.name}</button>
            
                            );
                        }):(<Loader2 />)
                        }
                    </div>
                </div>
            </>):teamSelected !== ""?(
                <>
                    <div className="card">
                        <div className="card-header">
                        Select A Project
                        </div>
                        <div className="card-body">
                            {projects && projects.length > 0 ? projects.map((c:project) => {
                                return (
            
                                    <button key={c.gid}className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg m-1" onClick={() => setProjectSelected(c.gid)}>{c.name}</button>
            
                                );
                            }):(<Loader2 />)
                            }
                        </div>
                    </div>
                </>
            ):workSpaceSelected !== ""?(<>
                <div className="card">
                    <div className="card-header">
                        Select A Team
                    </div>
                    <div className="card-body">
                        {teams && teams.length > 0 ? teams.map((c:project) => {
                            return (
            
                                <button key={c.gid}className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg m-1" onClick={() => setTeamSelected(c.gid)}>{c.name}</button>
            
                            );
                        }):(<Loader2 />)
                        }
                    </div>
                </div>
            </>):(
                <div className="card">
                    <div className="card-header">
                        Select A Workspace
                    </div>
                    <div className="card-body">
                        {workspaces && workspaces.length > 0 ? workspaces.map((c:project) => {
                            return (
            
                                <button key={c.gid}className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg m-1" onClick={() => setWorkSpaceSelected(c.gid)}>{c.name}</button>
            
                            );
                        }):(<Loader2 />)
                        }
                    </div>
                </div>
            )}
            
        </>
    );
}
type AsanaPickerProps = {
    integration?: NeoGenIntegration,
    addIntegration: any,
    removeIntegration: any,
    services: NeoGenIntegrationWrapper[],
}