import { EventEmitter } from "events";
import { w3cwebsocket as W3CWebSocket } from "websocket";

class SignalingChannelService extends EventEmitter {
    client: W3CWebSocket | undefined;
    constructor() {
        super();
    } 
    connect() {
        
        this.emit("message","Started");
        alert("Started");
        this.client = new W3CWebSocket(process.env.REACT_APP_SIGNALLING_URL??"wss://node.neogen.ai");
        this.client.onmessage = (m) => {
            this.emit("message", m);
            if (m.data.toString().length > 7 && m.data.toString().substring(0,7) === "Answer:") {
                let x = (JSON.parse(m.data.toString().substring(7)));
                console.table(x);
                this.emit("answer", x);
            }
            if (m.data.toString().length > 10 && m.data.toString().substring(0,10) === "Candidate:") {
                let x = (JSON.parse(m.data.toString().substring(10)));
                console.table(x);
                this.emit("candidate", x);
            }
        };
    }  

    waitForSocketConnection(socket:W3CWebSocket, callback: () => void){
        setTimeout(
            ()=>  {
                if (socket.readyState === 1) {
                    console.log("Connection is made");
                    if (callback != null){
                        callback();
                    }
                } else {
                    console.log("wait for connection...");
                    this.waitForSocketConnection(socket, callback);
                }
    
            }, 50); // wait 50 milisecond for the connection...
    }
    
    send(x:any) {
        if (!this.client) {
            this.connect();
        }
        if (this.client) {
            if (this.client.readyState === 3) {
                this.connect();
            }
            this.waitForSocketConnection(this.client, () => {
                if (x.offer) {
                    console.log((x.offer));
                    console.log((x.offer.sdp));
                }
                if (this.client) {
                    this.client.send(JSON.stringify(x));
                }
            });
        
        }
    }
}

export default new SignalingChannelService();