import Pizzly from "pizzly-js";
import { useEffect, useState } from "react";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../../typings/api/neogen-integration";
import PrintPre from "../../layout/print-pre";


const pizzly = new Pizzly({ host: "https://pizzly.neogen.ai", publishableKey: "d3dk78fgyhskjhdfksjdff7kvledifkmal" });
const myAPI = pizzly.integration("slack");

function compare(a:any, b:any) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

type slackChannel = {
    "id": string,
    "name": string,
    "is_channel": boolean,
    "is_group": boolean,
    "is_im": boolean,
    "created": number,
    "is_archived": boolean,
    "is_general": boolean,
    "unlinked": number,
    "name_normalized": string,
    "is_shared": boolean,
    "parent_conversation": null,
    "creator": string,
    "is_ext_shared": boolean,
    "is_org_shared": boolean,
    "shared_team_ids": [
        string
    ],
    "pending_shared": [],
    "pending_connected_team_ids": [],
    "is_pending_ext_shared": boolean,
    "is_member": boolean,
    "is_private": boolean,
    "is_mpim": boolean,
    "topic": {
        "value": string,
        "creator": string,
        "last_set": number
    },
    "purpose": {
        "value": string,
        "creator": string,
        "last_set": number
    },
    "previous_names": [],
    "num_members": number
}

export default function SlackPicker(props: SlackPickerProps) {
    const [slackChannels, setSlackChannels] = useState<slackChannel[]>([]);
    const [channel, setChannel] = useState("");
    const [channelName, setChannelName] = useState("");

    useEffect(() => {
        myAPI
            .auth(props.integration?.authId??"")
            .get("/conversations.list")
            .then((response)=> response.json())
            .then((json) =>  {
                console.info(json.channels);
                setSlackChannels(json.channels);
                // setWorkSpaces(json.data.sort(compare));

            })
            .catch(e => {
                console.error(e);
            }
            );
    }, [props.integration?.authId]);

    useEffect(() => {
        console.log({se: props.services});
        props.services.forEach((i: NeoGenIntegrationWrapper) => {
            if (i.integration === props.integration && slackChannels) {
                console.log("Found me");
                setChannel(i.propOne);
            }
        });
    }, [props.integration, props.services, slackChannels]);

    function sendTestMessage() {
        console.log("test");
        const message = {body: JSON.stringify( {channel, text: "testing 123", bla: "bla"}), headers: { "Content-Type": "application/json" }};
        console.log(message);
        myAPI
            .auth(props.integration?.authId??"")
            .post("/chat.postMessage", message, )
            .then((response)=> response.json())
            .then((json) =>  {
                console.log(json);
                // console.info(json.channels[0]);
                // setSlackChannels(json.channels);
                // setWorkSpaces(json.data.sort(compare));
                if (json.ok === false) {
                    console.error(json.response_metadata.messages);
                }
            })
            .catch(e => {
                console.error(e);
            }
            );
    }
    function joinChannel(channel: slackChannel) {
        setChannel(channel.id);
        setChannelName(channel.name_normalized);
        props.addIntegration({integration: props.integration, propOne: channel.id, propTwo: null, destinationName: channel.name_normalized});
        // console.log("test");
        // const message = {body: JSON.stringify({"channel": channelId})};
        // console.log(message);
        // myAPI
        //     .auth(props.integration.authId??"")
        //     .post("/conversations.join", message)
        //     .then((response)=> response.json())
        //     .then((json) =>  {
        //         console.log(json);
        //         if (json.ok === false) {
        //             console.error(json.response_metadata);
        //         }
        //         // console.info(json.channels[0]);
        //         // setSlackChannels(json.channels);
        //         // setWorkSpaces(json.data.sort(compare));

        //     })
        //     .catch(e => {
        //         console.error(e);
        //     }
        //     );
    }
    return (
        <div className="card">
            {channel!==""?(<>
                <div className="card-header">
                    All Done: {channelName}
                </div>
                <div className="card-body">
                    <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg m-1" type="button" onClick={() => sendTestMessage()}>Send Test Message</button>
                    <button className="bg-transparent hover:bg-red-500 text-red-500  hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded-lg m-1"  type="button"  onClick={() => {
                        setChannel("");
                        props.removeIntegration(props.integration);
                    }}><i className="fal fa-trash mr-3"></i>Remove Integration</button>
                </div>
                
            </>):(<>
                <div className="card-header">
                Select A Channel
                </div>
                <div className="card-body">

            
                    {slackChannels && slackChannels.length > 0 && slackChannels.map((sc: slackChannel) => {
                        return (
                            <button key={sc.id}className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg m-1" onClick={() => joinChannel(sc)}>{sc.name_normalized}</button>
                        
                        );
                    })}
                </div>
            </>)}
            
        </div>
    );
}

type SlackPickerProps = {
    integration?: NeoGenIntegration,
    addIntegration: any,
    removeIntegration: any,
    services: NeoGenIntegrationWrapper[],
}