import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import clickToCallsService from "../../../services/click-to-calls.service";
import ModalNeoGen from "../../layout/modal-neogen";
import PrintPre from "../../layout/print-pre";

export default function DeleteEntry(props: DeleteEntryType) {
    const [fields, setFields] = useState<string[]>([]);
    const queryClient = useQueryClient();

    async function deleteEntry() {
        console.log(props.itemToDelete);
        await clickToCallsService.deleteByID(props.itemToDelete.id);
        await queryClient.invalidateQueries(["ClickToCalls"]);
        props.close();
    }

    useEffect(() => {
        if (Array.isArray(props.itemToDelete.fields)) {
            // console.log(props.itemToDelete.fields);
            setFields(props.itemToDelete.fields);
        } else {
            try {
                const parsed = JSON.parse(props.itemToDelete.fields??"[]");
                setFields(parsed);
            } catch (e) {
                console.error(e);
            }
            // console.log(props.itemToDelete);
        }
        
    }, [props.itemToDelete]);
    return (
        <>
        
            <ModalNeoGen title="Delete Entry"  close={props.close} show={props.show} okText={"Delete Entry"} okAction={() => deleteEntry()}>
                <p>Are you sure you want to delete this entry?</p>
                <p>Name: {props.itemToDelete.name??"Unnamed"}</p>
                {fields && (<div>
                    <p>Fields:</p>
                    {fields.map(f => {
                        return (
                            <div key={f}>
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800 m-2">
                                    {f}
                                </span>
                            
                            </div>
                        );
                    })}
                </div>
                )}
                    
                {/* <PrintPre>{props.itemToDelete}</PrintPre> */}
            </ModalNeoGen>
        </>
    );
}

type DeleteEntryType = {
    close: () => void,
    show: boolean,
    itemToDelete: any,
    
}