import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import Title from "../../layout/Title";
import Main from "../../layout/Main";
import { useQuery, useQueryClient } from "react-query";
import ScriptService from "../../../services/script.service";
import OptionTypeService from "../../../services/question-option-type.service";
import FlowchartService from "../../../services/flowchart.service";
import { DateRange } from "react-date-range";
import ResponseService from "../../../services/response.service";
import { Table } from "react-bootstrap";
import moment from "moment";
import { formatPhonenumber } from "../../utilities/formatters";
import { CSVLink } from "react-csv";
import ScriptSelector from "../../utilities/script-selector";
import "react-date-range/dist/styles.css"; // main style file
import "../../../date-range.css";
import Loader2 from "../../utilities/Loader2";
import { NeogenFlowchartData } from "../../../typings/api/neogen-flowchart-data";
import scriptFolderAccessService from "../../../services/script-folder-access.service";
import { NeogenScriptFolderEntries } from "../../../typings/api/neogen-script-folder-entries";
import UserContext from "../../../services/user-context";
import scriptFolderEntryService from "../../../services/script-folder-entry.service";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import SelectNeoGen from "../../layout/select-neogen";
import { NeogenResponses } from "../../../typings/api";
import ButtonNeoGen from "../../layout/button-neogen";
import { NeoGenCustomReport } from "../../../typings/api/neogen-custom-report";
import customReportService from "../../../services/custom-report.service";
import Swal from "sweetalert2";
import PageDescription from "../../layout/page-description";
import { useNavigate, useParams } from "react-router";
import InputNeoGenControlled from "../../layout/input-neogen-controlled";
import TextAreaNeoGen from "../../layout/text-area-neogen";
import TextAreaNeoGenControlled from "../../layout/text-area-neogen-controlled";

const debug = require("debug")("NeoGen:Roles");

export default function ReportBuilder(props: ReportBuilderProps) {
    const cache = useQueryClient();
    const [script, setScript] = useState(-1);
    const [folder, setFolder] = useState(-1);
    const [name, setName] = useState("");


    const [question, setQuestion] = useState<string[]>(["-1"]);
    const [responseChosen, setResponse] = useState<string[]>(["-1"]);
    const [responseText, setResponseText] = useState<string[]>([""]);
    const [numberOfEntries, setNumberOfEntries] = useState<number>(1);
    const [description, setDescription] = useState<string>("");


    const [scriptName, setScriptName] = useState("");
    const [range, setRange] = useState(30);
    const [user, setUser] = useContext(UserContext);

    const navigate = useNavigate();

    // Get the id from the get parameters
    const { id } = useParams();

    // Load the custom report using useQuery by id
    const customReportQuery = useQuery(["customReport", id], async () => {
        const response = await customReportService.getOne(Number(id));
        if (response) {
            return response.data;
        }
    });


    useEffect(() => {
        if (props.isEdit && customReportQuery.isSuccess) {
            const report = customReportQuery.data as NeoGenCustomReport;
            setScript(report.script);
            setFolder(report.folder);
            setQuestion(JSON.parse(report.questions));
            setResponse(JSON.parse(report.responses));
            setResponseText(JSON.parse(report.responseTexts));
            setNumberOfEntries(report.numberOfEntries);
            setName(report.name);
            setDescription(report.description);
            console.error(report);
        }

    }, [customReportQuery.data, customReportQuery.isSuccess, props.isEdit]);





    const myFoldersQuery = useQuery(["userFolders", user?.id], async () => {
        let result = await scriptFolderAccessService.getByCompanyIdOrUserId(user?.company ?? -1, user?.id ?? "");
        if (result) {
            return result.data;
        }
    },
    {
        enabled: user !== null,
    });
    const myFolderEntriesQuery = useQuery(["myFolderEntries", user?.id], async () => {
        if (myFoldersQuery && myFoldersQuery.data) {
            const folderIds = [];

            for (let folder of myFoldersQuery.data) {
                if (folder.scriptFolderId !== null) {
                    folderIds.push(folder.scriptFolderId);
                }

            }
            let result = await scriptFolderEntryService.getFolderEntriesByMultipleFolders(folderIds);
            if (result) {
                return result.data as NeogenScriptFolderEntries[];
            } else {
                return [];
            }

        } else {
            return [];
        }
    },
    {
        enabled: myFoldersQuery.isSuccess,
    });

    let scriptsQuery = useQuery(["scripts"], async () => {
        let response = await ScriptService.getAll();
        if (response) {
            return response.data;
        }
    });
    //
    let flowchartQuery = useQuery(["flowchart", "script", script], async () => {
        let response = await FlowchartService.getFlowchartByScriptID(Number(script));
        // debug(script, JSON.parse(data[0]?.jsonData));
        // let texts = [];
        // data.forEach(d => {
        //     texts[d.id] = d.name;
        // })
        // debug(texts);
        // // setResponseTexts(texts);
        if (response) {
            let data = response.data;
            return JSON.parse(data[0].jsonData ?? "") as NeogenFlowchartData;
        }

        // return data.sort((a, b) => {
        //     if (a.dateSaved < b.dateSaved) {
        //         return -1;
        //     }
        //     if (a.dateSaved > b.dateSaved) {
        //         return 1;
        //     }
        //     return 0;
        // });
    }, { enabled: (script !== -1) });

    let optionTypeQuery = useQuery(["optionTypes"], async () => {
        // const {data} = await OptionTypeService.getAll();
        let result = await OptionTypeService.getAll();
        if (result) {
            return result.data;
        }
        // debug("OptionTypeService: ", data);
        // let texts = [];
        // data.forEach(d => {
        //     texts[d.id] = d.name;
        // })
        // debug(texts);
        // setResponseTexts(texts);
        // return data;
        // return data.sort();
    });

    let responsesQuery = useQuery(["customReport", range, script, question, responseChosen], async () => {
        // the date 7 days ago
        let start = new Date(Date.now() - (range * 24 * 60 * 60 * 1000));
        let end = new Date();
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        let result = await ResponseService.getBetweenDatesWithScriptQuestionAndOptions(start, end, script, question, responseChosen);
        if (result) {
            // alert("x");
            let data = result.data;
            console.log("Responses: ", data, start, end);
            // // @TODO: filter using the database
            // data = data.filter((i:any) => Number(i.scriptId) === Number(script));
            // data = data.filter((i:any) => Number(i.questionNumber) === Number(question));
            // console.log({data, response: responseChosen});
            // data = data.filter((i:any) => i.optionType !== null);
            // data = data.filter((i:any) => Number(i.optionType) === Number(responseChosen));

            data = data.map((i: NeogenResponses) => {
                delete (i.questionNumber);
                delete (i.questionType);

                delete (i.id);
                delete (i.unid);
                delete (i.language);
                delete (i.trained);
                delete (i.sentimentScore);
                delete (i.nextQuestionNumber);
                delete (i.sentimentVote);
                delete (i.msIntoCall);
                console.log({ i, responseChosen });
                // i.question = questionText;
                i.response = responseText[responseChosen.findIndex(id => Number(id) === Number(i.optionType))];
                delete (i.optionType);
                i.script = scriptName;
                delete (i.scriptId);
                i.calldate = moment(i.calldate).format("LLLL");
                i.phonenumber = formatPhonenumber((i.phonenumber ?? "").replace(/['"]+/g, ""));
                return i;
            });
            return data.sort((a: any, b: any) => {
                if (a.calldate > b.calldate) {
                    return -1;
                }
                if (a.calldate < b.calldate) {
                    return 1;
                }
                return 0;
            });
        }

        // downloadCSV =
    }, { enabled: (script !== -1 && question[0] !== "-1" && responseChosen[0] !== "-1") });


    function change(e: string, idx: number) {
        setQuestion(current => {
            current[idx] = e;
            return current;
        });
        // setQuestionName(e.currentTarget.value)
        // setQuestionText(text);
        cache.invalidateQueries(["customReport"]).then(r2 => {
            cache.removeQueries(["customReport"]);

        });
    }

    async function saveReport() {

        const customReport: NeoGenCustomReport = {
            name,
            owner: user?.company ?? -1,

            script: script,
            questions: JSON.stringify(question),
            responses: JSON.stringify(responseChosen),
            responseTexts: JSON.stringify(responseText),
            folder: Number(folder),
            numberOfEntries: Number(numberOfEntries),
            description: description
        };
        let r;
        if (props.isEdit) {
            r = await customReportService.update(Number(id), customReport);
        } else {
            r = await customReportService.create(customReport);
        }

        if (r) {
            Swal.fire({
                title: "Report saved",
                text: "",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                navigate("/report-builder");
            });
        } else {
            Swal.fire({
                title: "Error",
                text: "",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        }

        // }).catch(e => {
        //     Swal.fire({
        //         title: "Error",
        //         text: "",
        //         icon: "error",
        //         showConfirmButton: false,
        //         timer: 1500,
        //     });
        // });
    }




    // debug(props.myFolderEntriesQuery.data);

    return (
        <>
            <PageDescription >
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">{props.isEdit ? "Edit Custom Report" : "Add Custom Report"}</p>
                <p className="mb-5">
                    Custom reports are reports that you can create and schedule.
                </p>

                {/* <SelectNeoGen label="Filter:" value="all" className="" entries={filterEntries} onChange={(i) => {typeof(i) === "string" && setFilter(i);}}/> */}
            </PageDescription>
            <Main>
                <div className="bg-white rounded-xl dark:bg-gray-900">
                    <div className="card-header p-0">
                        <div className="grid grid-cols-2">

                            {!myFolderEntriesQuery.isSuccess || !scriptsQuery.isSuccess ? (
                                <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                                    <Loader2 />

                                </div>
                            ) : (
                                <div className="md:col-span-6 p-5 m-0 ">
                                    <InputNeoGenControlled
                                        label="Report Name"
                                        value={name}
                                        setValue={(i: string) => { setName(i); }}
                                        name="name"
                                    />
                                    <TextAreaNeoGenControlled 
                                        label="Description"
                                        value={description}
                                        setValue={(i: string) => { setDescription(i); }}
                                        name="description"

                                    />
                                    <div className="my-5">
                                        <SelectNeoGen entries={[
                                            { id: "1", name: "1 Day" },
                                            { id: "7", name: "7 Days" },
                                            { id: "30", name: "30 Days" },
                                        ]}
                                        onChange={(e: any) => {
                                            setRange(e);

                                        }}
                                        label="Date Range (just for seeing below)"
                                        value={range.toString()}
                                        />
                                    </div>
                                    <ScriptSelector myFolderEntriesQuery={myFolderEntriesQuery} setScript={setScript} setScriptName={setScriptName} folder={folder} setFolder={setFolder} script={script} />


                                    {(script !== -1) && (optionTypeQuery.isSuccess) && (
                                        <>

                                            {!flowchartQuery.isSuccess ? (

                                                <Loader2 />

                                            ) : (
                                                <>
                                                    <ButtonNeoGen
                                                        onClick={() => {
                                                            setNumberOfEntries(numberOfEntries + 1);
                                                        }}
                                                        text="Add Question"
                                                        icon="fa fa-plus"
                                                    />
                                                    <ButtonNeoGen
                                                        className="ml-5"
                                                        type="danger"
                                                        onClick={() => {
                                                            setNumberOfEntries(numberOfEntries - 1);
                                                        }}
                                                        text="Remove Last Question"
                                                        icon="fa fa-trash"
                                                    />
                                                    <ButtonNeoGen
                                                        className="ml-5"
                                                        type="info"
                                                        onClick={() => {
                                                            saveReport();
                                                        }}
                                                        text={props.isEdit ? "Save Report" : "Save as Custom Report"}
                                                        icon="fa fa-save"
                                                    />
                                                    {[...Array(numberOfEntries)].map((x, i) =>
                                                        (
                                                            <div key={i} className="rounded-xl border p-5 mt-5 shadow-lg dark:border-gray-700">

                                                                <div className="form-group mb-5 ">
                                                                    <div className="grid grid-cols-6 gap-4 bg-gray-100 dark:bg-indigo-900 p-3 mb-5 rounded-lg">
                                                                        <p className="text-xl font-black col-start-1 col-end-3">
                                                                        Question {i + 1}
                                                                        </p>

                                                                    </div>

                                                                    <SelectNeoGen
                                                                        label="Question Text"
                                                                        entries={
                                                                            Object.keys(flowchartQuery.data?.operators ?? []).map((o, idx) => {
                                                                            // debug(o);
                                                                                if (flowchartQuery.data?.operators[Number(o)].properties.class !== "detected") {
                                                                                    return {
                                                                                        id: o,
                                                                                        name: (flowchartQuery.data?.operators[Number(o)].properties.transcript[1] ?? flowchartQuery.data?.operators[Number(o)].properties.title) ?? "Unknown"
                                                                                    };

                                                                                }
                                                                            }

                                                                            ).filter(i => i)
                                                                        }
                                                                        value={question[i]}
                                                                        onChange={(e: any, text) => {
                                                                            change(e, i);
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className="mt-3">

                                                                    <SelectNeoGen
                                                                        label="Answer"
                                                                        entries={


                                                                            optionTypeQuery.data?.map((e: any, idx: number) => {
                                                                                return (
                                                                                    {
                                                                                        id: e.id,
                                                                                        name: e.name
                                                                                    }

                                                                                );
                                                                            })


                                                                        }
                                                                        value={responseChosen[i]}
                                                                        onChange={(e, text) => {
                                                                        // alert(text);
                                                                            setResponse(current => {
                                                                                current[i] = e as string;
                                                                                return current;
                                                                            });
                                                                            setResponseText(current => {
                                                                                current[i] = text ?? "Unknown";
                                                                                return current;
                                                                            });
                                                                            cache.invalidateQueries(["customReport"]).then(r2 => {
                                                                                cache.removeQueries(["customReport"]);
                                                                            });
                                                                        // alert("hi");
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                    }
                                                </>
                                            )}
                                        </>
                                    )}


                                </div>
                            )}

                        </div>
                    </div>
                    {script !== -1 && responseText[0] !== "" && (
                        <div className="card-body p-0">
                            <div className="m-5">
                                Response Text: {responseText[0]}<br />
                                Response: {responseChosen[0]}<br />
                                Question: {question[0]}<br />
                            </div>
                            {responsesQuery.isLoading ? (
                                <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                                    {responsesQuery.isLoading}
                                    <Loader2
                                    />
                                </div>

                            ) : (
                                <>
                                    {/* <PrintPre>
                                        {responsesQuery.data}
                                    </PrintPre> */}
                                    <div className="mt-5">

                                        {(responsesQuery.data?.length ?? 0) === 0 ? (<>
                                            <div className="p-5 text-center">
                                                <div className="">
                                                    <h3 className={"mb-5"}>No Responses Found</h3>
                                                    <p className={"text-muted"}>There are no responses for this report.</p>
                                                    <p className={"text-muted"}>Please select a different date range or
                                                        different query parameters.</p>
                                                </div>
                                            </div>
                                        </>) : (
                                            <>
                                                <CSVLink className={"bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg m-5"} filename={"CustomReport.csv"}
                                                    data={responsesQuery.data ?? []}><i
                                                        className={"fa fa-file-alt mr-3"} /> Download CSV Report</CSVLink>

                                                <TableNeogen entries={responsesQuery.data ?? []} />
                                            </>
                                        )}

                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

            </Main>
        </>
    );
}

type ReportBuilderProps = {
    darkMode: boolean,
    isEdit: boolean,
    // myFolderEntriesQuery: any,
};


