import React, { Fragment, useEffect, useRef, useState }      from "react";
// import { Dialog, Transition } from "@headlessui/react";
import {useInput} from "../hooks/input-hook";
import Loader2 from "../sections/utilities/Loader2";
import ModalNeoGen from "../sections/layout/modal-neogen";
// import { CheckIcon } from "@heroicons/react/outline";

function AddFolderModal(props: AddFolderModalProps) {
    const {value, bind, reset} = useInput("");
    const [open, setOpen] = useState(true);

    

    const cancelButtonRef = useRef(null);
    return (
        <>
            {/* <Transition.Root show={props.show} as={Fragment}> */}
            <ModalNeoGen
                title="Add Folder"
                show={props.show}
                close={props.handleClose}
                okAction={() => props.addFolder(value)}
            >
                {/* <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"> */}
                    
                    
                {/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"> */}
                {props.adding?(<><Loader2 /></>):(<>
                    <div>

                                    
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                            New Folder Name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="text"
                                    {...bind}
                                    id="text"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600 dark:placeholder-gray-500" 
                                    placeholder="My New Folder"
                                />
                            </div>
                                        
                        </div>
                    </div>
                    
                </>)}
                {/* </div> */}
                {/* </Transition.Child> */}
                {/* </div> */}
            </ModalNeoGen>
            {/* </Transition.Root> */}
  
            
        </>
    );
}

export default AddFolderModal;

type AddFolderModalProps = {
    addFolder:   any,
    handleClose: any,
    show:        any,
    adding: boolean,
};