/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 7:36 PM
 *
 */

import APIService from "./API.service";
import axios, { AxiosResponse } from "axios";
import { NeogenApis } from "../typings/api";
import AuthService from "./auth.service";
// const debug        = require('debug')('Neogen:CompanyService');

class ApisService extends APIService<NeogenApis> {
    constructor() {
        super("neogen-apis");
    }
    executeAPI(id:number, body:any):Promise<void|AxiosResponse<any>> {
        let user = JSON.parse(localStorage.getItem("user")??"");
        // console.error(user);
        body.token = user.token;
        return axios.post((process.env.REACT_APP_SHIM_URL??"")+id, body);
    }
    async getAll():Promise<void|AxiosResponse<NeogenApis[]>>  {
        if ( await AuthService.canIAccess("SEE_ALL_APIS")) {
            return super.getAll();
        } else {
            console.log("Not All");
            return this.getURL("my-neogen-apis");
        }
    }
}

export default new ApisService();