/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios         from "axios";

// import nlpAuthHeader from "./nlp-auth-header";
// const debug = require("debug")("NeoGen:NLPService");

class HawkingService  {
    // constructor() {
    // super('neogen-question-options');
    // this.API = new
    // }
    // async login(email, password) {
    // debug("Logging in to "+process.env.REACT_APP_NLP_V2_URL+"login");
    // return await axios.post(process.env.REACT_APP_NLP_URL+"login", {email, password});
    // }
    async recognize(text: string, timezone = "UTC"):Promise<any>{
        let ducklingURL = process.env.REACT_APP_DUCKLING_URL??"https://duckling.neogen.ai/parse";
        return axios.post(ducklingURL, "tz="+timezone+"&locale=en_US&text="+encodeURIComponent(text));
        // return axios.post(process.env.REACT_APP_DUCKLING_URL+"?locale="+encodeURIComponent(text)+"&tz="+encodeURIComponent(timezone));
        // .then(r => {
        // return r;
        // }).catch(e => {
        // console.log(e.response);
        // });
 
    }
    // async recognize(language, text) {
    // return await axios.get(process.env.REACT_APP_NLP_URL+"recognize/"+encodeURIComponent(language)+"/"+encodeURIComponent(text), {headers: nlpAuthHeader()});
    // }
    // async train(text, intent, language) {
    // return await axios.get(process.env.REACT_APP_NLP_URL+"train/"+encodeURIComponent(language)+"/"+encodeURIComponent(intent)+"/"+encodeURIComponent(text), {headers: nlpAuthHeader()});
    // }
    // async remove(text, intent, language) {
    // return await axios.get(process.env.REACT_APP_NLP_URL+"remove/"+encodeURIComponent(language)+"/"+encodeURIComponent(intent)+"/"+encodeURIComponent(text), {headers: nlpAuthHeader()});
    // }
    // async getAll() {
    // debug("Auth: ", {headers: nlpAuthHeader()});
    // return await axios.get(process.env.REACT_APP_NLP_URL+"all", {headers: nlpAuthHeader()});
    // }
}
 
export default new HawkingService();