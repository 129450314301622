/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";

const API_URL = process.env.REACT_APP_API_URL;

class QuestionOptionService extends APIService {
    constructor() {
        super("neogen-question-options");
    }

    async getQuestionsOptionsByScriptID(scriptID) {
        return await axios.get(API_URL + "neogen-question-options/?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + scriptID + "\"}}"), {headers: authHeader()});
    }
}

export default new QuestionOptionService();