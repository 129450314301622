import axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";

type Node = {
    ip: string,
    port: number,
}

export async function getARINodes() {
    return await axios.get("https://arinode.voicio.com/node-all");
}
export async function reloadARINodes(nodes:Node[]) {
    
    for (let node of nodes) {
        const client = new W3CWebSocket("ws://"+node.ip+":"+node.port);
        client.onmessage = (message) => {
            console.log(message.data);
        };
        client.onopen = () => {
            const packet = {
                type: "Reload"
            };
            client.send(JSON.stringify(packet));
            console.log(client);
        };
        
    }
    

    
}