import { SearchIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function SearchBox() {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    function doSearch() {
        if (search) {
            // alert(search);

            navigate("/faq/"+ search);
            setSearch("");
        }
        
    }
    return (
        <div>
            {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Search candidates
            </label> */}
            <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="focus:ring-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm dark:bg-gray-700 dark:text-gray-200 text-gray-600"
                        placeholder="Search For Help"
                        value={search}
                        onChange={(e) => setSearch(e.currentTarget.value)}
                    />
                </div>
                <button
                    type="button"
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border-none text-sm font-medium rounded-r-md text-gray-100 bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    onClick={() => doSearch()}
                >
                    {/* <SortAscendingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                    <span>Search</span>
                </button>
            </div>
        </div>
    );
}