import React from "react";
import ModalNeoGen from "../../layout/modal-neogen";
import SelectNeoGen from "../../layout/select-neogen";

const schema = [

    {
        name: "id",
        label: "id",
        type: "number",
        display: false,
        required: false,
        
    },
    {
        name: "name",
        label: "name",
        type: "string",
        display: true,
        required: true,        
        colSpan: 2,
    },
    {
        name: "description",
        label: "description",
        type: "textarea",
        display: true,
        required: false,
        colSpan: 2,
    },
    {
        name: "url",
        label: "url",
        type: "string",
        display: true,
        required: true,
    },
    {
        name: "authUrl",
        label: "auth Url",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "username",
        label: "username",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "password",
        label: "password",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "method",
        label: "method",
        type: "combo",
        entries: [
            {id: "GET", name: "GET"},
            {id: "POST", name: "POST"},
        ],
        display: true,
        required: false,
    },
    {
        name: "owner",
        label: "owner",
        type: "number",
        display: false,
        required: false,
    },
    {
        name: "customType",
        label: "custom Type",
        type: "number",
        display: true,
        required: false,
    },
    {
        name: "system",
        label: "system",
        type: "combo",
        entries: [
            {id: 1, name: "Yes"},
            {id: 0, name: "No"},
        ],
        display: true,
        required: false,
    },
    {
        name: "isPush",
        label: "is Push API",
        type: "combo",
        entries: [
            {id: 1, name: "Yes"},
            {id: 0, name: "No"},
        ],
        display: true,
        required: false,
    },
    {
        name: "responseType",
        label: "response Type",
        type: "combo",
        entries: [
            {id: "XML", name: "XML"},
            {id: "JSON", name: "JSON"},
            {id: "Text", name: "Plain Text"},
        ],
        display: true,
        required: false,
    },
    {
        name: "authResponseField",
        label: "auth Response Field",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "body",
        label: "body",
        type: "textarea",
        colSpan: 2,
        display: true,
        required: false,
    },
    {
        name: "tokenExpires",
        label: "token Expires",
        type: "number",
        display: true,
        required: false,
    },
    {
        name: "requestHeaders",
        label: "request Headers",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "authHeaders",
        label: "auth Headers",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "plainTextFieldSeparator",
        label: "plain Text Field Separator",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "authRequestBody",
        label: "auth Request Body",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "successField",
        label: "success Field",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "successValue",
        label: "success Value",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "failureField",
        label: "failure Field",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "failureValue",
        label: "failure Value",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "idField",
        label: "id Field",
        type: "string",
        display: true,
        required: false,
    },
    {
        name: "exampleResponse",
        label: "example Response",
        type: "textarea",
        colSpan: 2,
        display: true,
        required: false,
    },
    {
        name: "dateAdded",
        label: "date Added",
        type: "string",
        display: false,
        required: false,
    },
    {
        name: "lastUpdated",
        label: "last Updated",
        type: "string",
        display: false,
        required: false,
    },

];


export default function AddEditPushApi(props: AddEditPushApiProps) {

    function getField(field: any) {
        field.label = field.label?.toLowerCase().replace(/\b[a-z]/g, function(letter:string) {
            return letter.toUpperCase();
        });
        if (field.display === false) {
            return;
        }
        switch (field.type) {
            case "string":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name={field.name}
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-md"
                                    // placeholder="you@example.com"
                                />
                            </div>
                        </div>
                    </>
                );
            case "textarea":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    name={field.name}
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 "
                                    defaultValue={""}
                                />
                            </div>
                        </div>
                    </>
                );
            case "number":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="number"
                                    name={field.name}
                                    id={field.name}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-md"
                                    // placeholder="you@example.com"
                                />
                            </div>
                        </div>
                    </>
                );
            case "combo":
                return (
                    <>
                        <div className={"my-1 "+(field.colSpan>0?("col-span-"+field.colSpan):"")} key={field.name}>
                            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-500">
                                {field.label} {field.required?<span className='text-red-500'>* required</span>:""}
                            </label>
                            <div className="mt-1">
                                <SelectNeoGen entries={field.entries} onChange={(e) => console.log(e)}/>
                            </div>
                        </div>
                    </>
                );
            default:
                return (
                    <></>
                );
        }
    }

    return (
        <>
            {props.show && (
            
                <ModalNeoGen size="md" title={props.isEdit ? "Edit Push API":"Add Push API"} show={props.show} close={() => props.close()}>
                    <div className="my-5 grid grid-cols-2 gap-5">
                        {schema.map((e: any, idx: number) => {
                            return getField(e);
                        
                        })}
                    
                    </div>
                
                </ModalNeoGen>
            
            )}
            
        </>
    );
}

export type AddEditPushApiProps = {
    show: boolean,
    close: () => void,
    isEdit: boolean,
    entry?: any,
}