import React, { useEffect, useState } from "react";
import ModalNeoGen from "../../layout/modal-neogen";

export default function AddFieldModal(props:AddFieldModalType) {

    const [field, setField] = useState("");

    useEffect(() => {
        setField("");
    }, [props.show]);

    function save() {
        console.log("saving");
        props.addField(field);
        props.close();
    }

    return (
        <>
            <ModalNeoGen close={props.close} show={props.show} title={"Add Field"} okAction={() => save()}>


                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Field Name
                    </label>
                    <div className="mt-1">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={field}
                            onChange={(e) => setField(e.currentTarget.value)}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                        />
                    </div>
                    <p className="mt-2 text-sm text-gray-500" id="fieldName-description">
                        This is the id of a form field. For example if your form has a first name it will probably be something like first_name.
                    </p>
                </div>





                
            </ModalNeoGen>
        </>
    );
}

type AddFieldModalType = {
    close: () => void,
    show: boolean,
    addField: (fieldName: string) => void,
}