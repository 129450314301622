/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenUser } from "../typings/api";
import APIService  from "./API.service";
import AuthService from "./auth.service";

const debug = require("debug")("NeoGen:UsersService");

class UsersService extends APIService<NeogenUser> {
    constructor() {
        super("neogen-users");
    }

    getAllWithCredentials() {
        return this.getURL(this.endpoint + "?filter=" + encodeURIComponent("[include][][relation]=UserCredentials"));
    }

    getAllWithRoles() {
        return this.getURL(this.endpoint + "?filter=" + encodeURIComponent("[include][][relation]=NeogenRoles"));
    }

    getVerificationToken(id:string, token:string) {
        return this.getURL("/neogen-users/verification/" + id + "/" + token);
    }

    resendVerificationToken(id:string) {
        return this.getURL("/neogen-users/resend-verification/" + id + "/");
    }

    resetPassword(email:string) {
        return this.postURL("/neogen-users/reset/" + encodeURIComponent(email), {}  );
    }

    resetPasswordComplete(id:string, token:string, password:string) {
        return this.postURL("/neogen-users/reset-do/" + encodeURIComponent(id)+"/"+encodeURIComponent(token), {password: password}  );
    }

    async getAll():Promise<void|AxiosResponse<NeogenUser[]>> {
        if (await AuthService.canIAccess("LIST_ALL_USERS")) {
            debug("I can see all users");
            return super.getAll();
        } else {
            // if the user doesn't have access to all users they should see a subset

            debug("Can access my users");
            return super.getURL("my-users") as Promise<void|AxiosResponse<NeogenUser[]>>;

            // return [];
        }

    }

}

export default new UsersService();