import { useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import PrintPre from "../layout/print-pre";
import {JSONTree} from "react-json-tree";
import ButtonNeoGen from "../layout/button-neogen";
const client = new W3CWebSocket("wss://kafkaproxy.neogen.ai:443");
const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
};
export default function Kafka() {
    const [state, setState ] = useState("{}");
    const [phones, setPhones] = useState<string[]>([]);
    const [currentPhone, setCurrentPhone] = useState("");
    client.onopen = () => {
        console.log("WebSocket Client Connected");
    };
    client.onmessage = (message) => {
        console.log(message);
        setState(message.data.toString());
        if (typeof(message.data) === "string" ) {
            let newOne: string = JSON.parse(message.data)?.phonenumber;
            if (!phones.includes(newOne)) {
                console.log(newOne,phones);
                // alert(JSON.parse(message.data)?.phonenumber);
                setPhones(c => {
                    if (typeof(message.data) === "string" ) {
                        let newOne: string = JSON.parse(message.data)?.phonenumber;
                        if (newOne) {
                            return Array.from(new Set([...c, newOne]));
                        } else {
                            return c;
                        }
                        
                    } else {
                        return c;
                    }
                });
            }
        }
    };
    return (
        <div className=" bg-white -mt-24 p-3">
            {/* <PrintPre>{phones}</PrintPre> */}
            {phones.map(number => {
                return (
                    <ButtonNeoGen key={number} onClick={() => {setCurrentPhone(number);}} className="m-3 ml-0">{number}</ButtonNeoGen>
                );
            })}
            <h1 className="text-lg font-semibold">Monitoring: {currentPhone==""?"Nothing":currentPhone}</h1>

            {JSON.parse(state).phonenumber === currentPhone && JSON.parse(state).phonenumber !== "" && (
                <JSONTree data={JSON.parse(state)} theme={theme} invertTheme={true} shouldExpandNode={() => true}/>
            )}
            {/* <JSONTree data={JSON.parse(state)} theme={theme} invertTheme={true} shouldExpandNode={() => true}/> */}
            {/* <JSONTree>{JSON.parse(state)}</JSONTree> */}
        </div>
    );
}