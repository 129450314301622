import { PencilIcon, LinkIcon, CheckIcon, BriefcaseIcon, CalendarIcon, CurrencyDollarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { Tab } from "react-bootstrap";
import { QueryCache, useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import onboardingStageService from "../../../services/onboarding-stage.service";
import onboardingService from "../../../services/onboarding.service";
import { NeogenOnboarding } from "../../../typings/api/neogen-onboarding";
import { NeoGenOnboardingStage } from "../../../typings/api/neogen-onboarding-stage";
import ButtonNeoGen from "../../layout/button-neogen";
import ModalSingleText from "../../layout/modal-single-text";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddEdit from "./modals/add-edit";
import { AddStage } from "./modals/add-stage";

const entries: any[] = [
    { id: 1, name: "NeoGen", description: "NeoGen is a tool that generates a new blockchain and its corresponding smart contracts." },
    { id: 2, name: "NeoGen", description: "NeoGen is a tool that generates a new blockchain and its corresponding smart contracts." },
    { id: 3, name: "NeoGen", description: "NeoGen is a tool that generates a new blockchain and its corresponding smart contracts." },
];

export default function OnboardingAdmin() {
    const [show, setShow] = useState(false);
    const [id, setId] = useState<number>(-1);
    const [stepIsEdit, setStepIsEdit] = useState<boolean>(false);
    const [showAddStage, setShowAddStage] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const cache = useQueryClient();
    const navigate = useNavigate();
    const stageQuery = useQuery(["onboarding-stages"], async () => {
        const response = await onboardingStageService.getAll();
        if (response) {
            return response.data;
        }
    });
    const entriesQuery = useQuery(["onboarding-entries"], async () => {
        const response = await onboardingService.getAll();
        if (response) {
            const mapped = response.data.map((entry: NeogenOnboarding) => {
                entry.stageName = stageQuery.data?.find((stage: NeoGenOnboardingStage) => stage.id === entry.stage)?.name ?? "Unknown";
                return entry;
            });
            return mapped;
        }
    }, { enabled: stageQuery.isSuccess });

    function addItem() {
        // add item to list
        setShow(true);
    }
    function addStage() {
        setShowAddStage(true);
    }

    function removeStage(id: number) {
        return onboardingStageService.deleteByID(id);
    }
    function removeStep(id: number) {
        return onboardingService.deleteByID(id);
    }
    const mutation = useMutation(removeStage, {
        onSuccess: () => {
            cache.invalidateQueries(["onboarding-stages"]);

            Swal.fire({
                title: "Success",
                text: "Stage removed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });

            // }
        },
    });
    const stepMutation = useMutation(removeStep, {
        onSuccess: () => {
            cache.invalidateQueries(["onboarding-entries"]);

            Swal.fire({
                title: "Success",
                text: "Step removed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });

            // }
        },
    });
    return (
        <>
            <PageDescription >
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="flex-1 min-w-0">
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Onboarding</p>
                        <p className="mb-5">This section is for setting up the onboarding process</p>
                    </div>
                    <div className="mt-5 flex lg:mt-0 lg:ml-4">
                        

                        <span className="sm:ml-3">
                            <ButtonNeoGen
                                className=""
                                type="primary"
                                onClick={() => {
                                    navigate("/onboarding-wizard");
                                }}
                                text="Run Onboarding Wizard"
                                icon="fa fa-wand-magic-sparkles"
                            />  
                        </span>

        
                    </div>
                </div>
    
                
            </PageDescription>
            {entriesQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    
                    <div className="bg-white p-5 rounded-xl shadow dark:bg-gray-600">
                        <div>
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-300">Stages</h1>
                                    <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                                    Stages of the setup process
                                    </p>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <ButtonNeoGen
                                        className="mt-3"
                                        type="info"
                                        onClick={() => {
                                            setIsEdit(false);
                                            addStage();
                                        }}
                                        text="Add Stage"
                                        icon="fas fa-plus"
                                    />
                                </div>
                            </div>
                        </div>
                    
                        <div className="mt-5">
                        

                        
                            <TableNeogen
                                entries={stageQuery.data ?? []}
                                actions={[
                                    {
                                        name: "Edit",
                                        action: (id: number) => {
                                        // alert("Edit " + id);
                                            setIsEdit(true);
                                            setId(id);
                                            setShowAddStage(true);
                                        },
                                        buttonClass: "bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded",
                                        disabled: (entry: NeoGenOnboardingStage) => {
                                            return false;
                                        }
                                    },
                                    {
                                        name: "Delete",
                                        action: (id: number) => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Yes, delete it!"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    mutation.mutate(id);
                                                }


                                            });
                                        },
                                        buttonClass: "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
                                        disabled: (entry: NeoGenOnboardingStage) => {
                                            return false;
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="bg-white p-5 rounded-xl shadow mt-5 dark:bg-gray-600 ">
                        <div className="">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-300">Steps</h1>
                                    <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                                    Individual steps in the onboarding process
                                    </p>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    <ButtonNeoGen
                                        className="mt-3"
                                        onClick={() => {
                                            addItem();
                                        }}
                                        text="Add Step"
                                        type="info"
                                        icon="fas fa-plus"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                        
                            <TableNeogen
                                actions={[
                                    {
                                        name: "Edit",
                                        action: (id: number) => {
                                        // alert("Edit " + id);
                                            setStepIsEdit(true);
                                            setId(id);
                                            setShow(true);
                                        },
                                        buttonClass: "bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded",
                                        disabled: (entry: NeoGenOnboardingStage) => {
                                            return false;
                                        }
                                    },
                                    {
                                        name: "Delete",
                                        action: (id: number) => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Yes, delete it!"
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    stepMutation.mutate(id);
                                                }


                                            });
                                        },
                                        buttonClass: "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
                                        disabled: (entry: NeoGenOnboardingStage) => {
                                            return false;
                                        }
                                    },
                                ]
                                }
                                entries={entriesQuery.data ?? []}
                                ignoreFields={["internalComments", "details", "order", "checkboxEntries", "reliesOnTrue", "reliesOnFalse", "stage"]}
                            />
                        </div>
                    </div>
                </>
            )}
            {show && <AddEdit show={show} close={() => setShow(false)} isEdit={stepIsEdit} id={id} />}
            {showAddStage && <AddStage
                // save={(value:string) => {
                //     saveStage(value);
                // }}
                id={id}
                isEdit={isEdit}
                show={showAddStage} close={() => setShowAddStage(false)}
            />}

        </>
    );
}