import React, {useContext, useEffect, useState}                    from "react";
import Title                                  from "../layout/Title";
import {useQuery, useQueryClient, useMutation} from "react-query";
import PhoneNumberService                     from "../../services/phone-number.service";
import ScriptService                          from "../../services/script.service";
import CompanyService                         from "../../services/company.service";
import Main                                   from "../layout/Main";
import API                                    from "../../services/API.service";
import AuthService                                    from "../../services/auth.service";
import {formatPhonenumber}                    from "../utilities/formatters";
import "./phone-numbers.css";
import UserContext                            from "../../services/user-context";
import languageService from "../../services/language.service";
import AddPhoneNumber from "./modals/add-phonenumber";
import PurchasePhoneNumber from "./modals/purchase-phonenumber";
import DeleteNumber from "./modals/delete-number";
import authService from "../../services/auth.service";
import { Languages, NeogenCompany, NeogenPhoneNumbers, NeogenScript } from "../../typings/api";
import transferDestinationService from "../../services/transfer-destination.service";
import carrierService from "../../services/carrier.service";
import { NeogenTransferDestinations } from "../../typings/api/neogen-transfer-destinations";
import EditHours from "./modals/edit-hours";
import transcriptionNumbersService from "../../services/transcription-numbers.service";
import { NeoGenTranscriptionNumber } from "../../typings/api/neogen-transcription-number";
import PageDescription from "../layout/page-description";
import ButtonNeoGen from "../layout/button-neogen";
import SelectNeoGen from "../layout/select-neogen";
import AddTranscriptionNumber from "./modals/add-transcription-number";
import Actions from "./components/actions";
import EditTranscription from "./modals/editTranscription";
import PrintPre from "../layout/print-pre";
import AddOutboundPhoneNumber from "./modals/add-outbound-phonenumber";

const debug = require("debug")("Neogen:PhoneNumbers");

export default function PhoneNumbers(props: PhoneNumbersProps) {
    const [user,setUser]  = useContext(UserContext);
    const [showAddPhonenumber, setShowAddPhonenumber] = useState(false);
    const [showAddTranscriptionPhonenumber, setShowAddTranscriptionPhonenumber] = useState(false);
    const [showEditTranscription, setShowEditTranscription] = useState(false);
    const [showPurchasePhonenumber, setShowPurchasePhonenumber] = useState(false);
    const [showDeletePhonenumber, setShowDeletePhonenumber] = useState(false);
    const [transcriptionNumber, setTranscriptionNumber] = useState<NeoGenTranscriptionNumber>();
    const [canSeeASR, setCanSeeASR] = useState(false);
    const [canAddPhoneNumber, setCanAddPhoneNumber] = useState(false);
    const [canSeeCarriers, setCanSeeCarriers] = useState(false);
    const [showEditHours, setShowEditHours] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showEditDestination, setShowEditDestination] = useState(false);
    const [filter, setFilter] = useState("all");
    const [editId, setEditId] = useState(-1);
    const [numberBeingDeleted, setNumberBeingDeleted] = useState<(undefined|NeogenPhoneNumbers)> (undefined);
    const api   = new API("");
    const cache = useQueryClient();

    function compare(a:any, b:any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        authService.canIAccess("ADD_PHONE_NUMBER").then(r => {
            if (r) {
                // alert("add");
                setCanAddPhoneNumber(true);
            }
        });
        AuthService.canIAccess("SHOW_ASR").then(r => {
            // debug(r);
            // debug(props.show);
            // console.warn("Allowed: ",r);
            if (r) {
                // alert("asr");
                setCanSeeASR(true);
            }
        });
    },[]);

    // Get phone numbers
    const phoneNumbersQuery = useQuery(["phoneNumbers"], async () => {
        const result = await PhoneNumberService.getAll();
        if (result) {
            return result.data;
        }
    });
    const transferDestinationQuery = useQuery(["transferDestinations"], async () => {
        const result = await transferDestinationService.getAll();
        if (result !== undefined) {
            return result.data;
        }
    });
    const transcriptionNumbersQuery = useQuery(["transcriptionNumbers"], async () => {
        const result = await transcriptionNumbersService.getAll();
        if (result !== undefined) {
            return result.data;
        }
    });
    const carriersQuery = useQuery(["carriers"], async () => {
        const result = await carrierService.getAll();
        if (result !== undefined) {
            return result.data;
        }
    });
    

    // Get scripts
    const scriptQuery = useQuery(["myscripts"], async () => {
        let ids: number[] = [];
        props.myFolderEntriesQuery.data.forEach((i:any) => {
            ids.push(i.scriptId);
        });
        // console.log({ids});
        const results = await ScriptService.getAllByIDs(ids);
        // console.log("data: ", data);
        if (results) {
            return results.data.sort(compare);
        }
        
    }, {enabled: props.myFolderEntriesQuery.isSuccess});

    // Get companies
    const companyQuery = useQuery(["companies"], async () => {
        const results = await CompanyService.getAll();
        // debug("data: ", data);
        if (results) {
            return results.data.sort(compare);
        }
    });

    // Get companies
    const languageQuery = useQuery(["languages"], async () => {
        const results = await languageService.getAll();
        // debug("data: ", data);
        if (results) {
            return results.data.sort(compare);
        }
    });

    // Get companies
    const canSelectCarrierQuery = useQuery(["CAN_SELECT_CARRIER"], async () => {
        const r = await AuthService.canIAccess("CAN_SELECT_CARRIER");
        // debug(r);
        // debug(props.show);
        if (r) {
            setCanSeeCarriers(true);
            return true;
        } else {
            return false;
        }
        // });
    });


    async function changePhoneNumber(phonenumber: NeogenPhoneNumbers) {
        try {

        
            // alert(phonenumber.id + " - " + phonenumber.scriptId);
            phonenumber.scriptId = Number(phonenumber.scriptId);
            if (phonenumber.notes === null) {
                delete(phonenumber.notes);
            }
            if (phonenumber.billingRenewal === null) {
                delete(phonenumber.billingRenewal);
            }
            if (phonenumber.source === null) {
                phonenumber.source = -1;
            }
            if (phonenumber.bluemixOverride === null) {
                phonenumber.bluemixOverride = "";
            }
            if (phonenumber.costBody === null) {
                phonenumber.costBody = "";
            }
            if (phonenumber.costApi === null) {
                phonenumber.costApi = -1;
            }
            if (phonenumber.userId === null) {
                phonenumber.userId = "";
            }
            const response = await api.patchURL("/neogen-phone-numbers/" + phonenumber.id, phonenumber);
            if (response) {
                console.log(response);
                return response;
            }
        } catch (e) {
            console.error(e);
        }
    }

    const mutator = useMutation(changePhoneNumber, {
        onSuccess: (data) => {
            console.log("Saved: ",data);
            // Update `todos` and the individual todo queries when this mutation succeeds
            // debug(data);
            // cache.invalidateQueries(["phoneNumbers"]).then(r => {
            //     debug(r);
            // });
        },
        onError: (e) => {
            console.error(e);
        }
    });

    function deleteNumber(phoneumber: NeogenPhoneNumbers) {
        setNumberBeingDeleted(phoneumber);
        setShowDeletePhonenumber(true);
    }

    
    function deleteDestination(id?: number) {
        if (typeof (id) !== "undefined") {
            transferDestinationService.deleteByID(id).then(r1 => {
                cache.invalidateQueries(["transferDestinations"]).then(r => {
                    console.log(r, r1);
                });
            });

        }

    }
    function deleteTranscription(id?: number) {
        if (typeof (id) !== "undefined") {
            transcriptionNumbersService.deleteByID(id).then(r1 => {
                cache.invalidateQueries(["transcriptionNumbers"]).then(r => {
                    console.log(r, r1);
                });
            });

        }

    }
    // console.log(props.myFolderEntriesQuery.data);

    const filterEntries = [
        {id: "all", name: "All Phone Numbers"},
        {id: "inbound", name: "Inbound Numbers Only"},
        {id: "outbound", name: "Outbound Numbers Only"},
        {id: "transcription", name: "Transcription Numbers Only"},
    ];
    const asrEntries = [
        {id:"", name: "IBM Bluemix"},
        {id:"vosk", name: "Vosk On Premise"},
        {id:"google", name: "Google"},
    ];

    function changeASR(phonenumber: NeogenPhoneNumbers, e: string) {
        
        phonenumber.bluemixOverride = typeof(e) === "string" ? e:"";
        mutator.mutate(phonenumber);
        
    }

    function changeLanguage(phonenumber: NeogenPhoneNumbers, e: string) {
        
        phonenumber.language = Number(e);
        mutator.mutate(phonenumber);
        
    }

    function editNumber(phonenumber: NeoGenTranscriptionNumber) {
        // alert("Edit");
        // alert(JSON.stringify(phonenumber));
        setTranscriptionNumber(phonenumber);
        setShowEditTranscription(true);
    }

    function editDestination(number:NeogenTransferDestinations) {
        if (number.id) {
            setIsEdit(true);
            setEditId(number.id);
            setShowEditDestination(true);
        }
        
    }

    console.log({
        canSelectCarrierQuery: canSelectCarrierQuery.isSuccess, 
        transferDestinationQuery: transferDestinationQuery.isSuccess, 
        transcriptionNumbersQuery: transcriptionNumbersQuery.isSuccess, 
        carriersQuery: carriersQuery.isSuccess,  
        phoneNumbersQuery: phoneNumbersQuery.isSuccess, 
        scriptQuery: scriptQuery.isSuccess, 
        companyQuery: companyQuery.isSuccess, 
        mutator: mutator.isSuccess, 
        languageQuery: languageQuery.isSuccess});

    return (
        <>
            {/* <Title title={"Phone Numbers"}/> */}
            <Main>
                <PageDescription >
                    <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Phone Numbers</p>
                    <p className="mb-5">Here you&apos;ll find phone numbers for various purposes. You can also filter them below.</p>
                    <SelectNeoGen label="Filter:" value="all" className="" entries={filterEntries} onChange={(i) => {typeof(i) === "string" && setFilter(i);}}/>
                </PageDescription>
                <div className="mt-0 px-2">

                    <>
                        <div className="">
                            <form className="form-inline">
                                {/* TODO: Check RBAC */}
                                {canAddPhoneNumber && (
                                    <>
                                        <button type="button" className={"mb-5 bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg mr-3"} onClick={() => setShowAddPhonenumber(true)}>
                                            <i className={"fa fa-plus mr-3"}/>
                                            Add Phone Number
                                        </button>
                                        <button type="button" className={"mb-5 bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg mr-3"} 
                                            onClick={() => setShowAddTranscriptionPhonenumber(true)}>
                                            <i className={"fa fa-plus mr-3"}/>
                                            Add Transcription Phone Number
                                        </button>
                                    </>
                                )}
                            
                                <div className="px-3 md:px-0">
                                    <ButtonNeoGen  icon="fa fa-shopping-cart mr-3" text="Purchase Phone Number" size="xl" onClick={() => setShowPurchasePhonenumber(true)} className="mb-5 w-full md:w-auto "></ButtonNeoGen>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="">
                            {
                                (!canSelectCarrierQuery.isSuccess||!transferDestinationQuery.isSuccess||!transcriptionNumbersQuery.isSuccess||!carriersQuery.isSuccess||!props.myFolderEntriesQuery.isSuccess || !phoneNumbersQuery.isSuccess || scriptQuery.isLoading || companyQuery.isLoading || mutator.isLoading || languageQuery.isLoading) ? (
                                    <>
                                        
                                        <div className={"flex justify-center flex-col align-middle h-48"}>
                                            <div className={"mt-10 self-center inline-block align-middle"}>
                                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                            
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex flex-col">
                                        <div className="-my-2 sm:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="shadow border-b border-gray-200 dark:border-gray-900 sm:rounded-lg">
                                                    <table className={"min-w-full divide-y divide-gray-200 dark:divide-gray-900"}>
                                                        <thead  className="bg-gray-50 dark:bg-gray-800">
                                                            <tr>
                                                                <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Type</th>
                                                                <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Phone Number</th>
                                                                <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Owner</th>
                                                                <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Destination</th>
                                                                {/* <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Hours</th> */}
                                                                {/* <th>Billing Renewal Date</th> */}
                                                                {/* <th>Price Per Month</th> */}
                                                                {/* <th>Price Per Minute</th> */}
                                                                {/* <th>Max Channels</th> */}
                                                                {/* <th>Source</th> */}
                                                                {/* <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Language</th>
                                                
                                                                {canSeeASR&& (
                                                                    <th scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">ASR</th>
                                                                )} */}
                                                                <th scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-900 ">
                                                            {
                                                                (filter === "all" ||filter==="inbound")&& phoneNumbersQuery.data?.map((phonenumber:NeogenPhoneNumbers) => {
                                                                    return (
                                                                        <tr key={phonenumber.id}>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">Inbound Number</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">{formatPhonenumber(phonenumber.number)}</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">{phonenumber.owner === -1 ? "-" : companyQuery.data?.find((x:NeogenCompany) => x.id === phonenumber.owner)?.name ?? "Unknown Company"}</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                <SelectNeoGen entries={scriptQuery.data} value={phonenumber.scriptId??-1} onChange={(e) => {
                                                                                    // alert(e);
                                                                                    const changedNumber = {...phonenumber};
                                                                                    changedNumber.scriptId = Number(e);
                                                                                    mutator.mutate(changedNumber);
                                                                                }}/>
                                                                                
                                                                            </td>
                                                                            {/* <td  className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">N/A</td> */}
                                                                            {/* <td>{new Date(phonenumber.billingRenewal??new Date()).toLocaleDateString()}</td> */}
                                                                            {/* <td>{phonenumber.pricePerMonth}</td> */}
                                                                            {/* <td>{phonenumber.pricePerMinute}</td> */}
                                                                            {/* <td>{phonenumber.maxChannels}</td> */}
                                                                            {/* <td>{phonenumber.source}</td> */}
                                                                            {/* <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                <SelectNeoGen entries={languageQuery.data} value={phonenumber.language??"-1"} onChange={(e) => {
                                                                                    phonenumber.language = Number(e);
                                                                                    mutator.mutate(phonenumber);
                                                                                }}/>
                                                                                
                                                                            </td> */}
                                                                            {/* {canSeeASR&& (
                                                                                <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                    <SelectNeoGen entries={asrEntries} value={phonenumber.bluemixOverride ?? ""} onChange={(e) => {
                                                                                        phonenumber.bluemixOverride = typeof(e) === "string" ? e:"";
                                                                                        mutator.mutate(phonenumber);
                                                                                    }}/>
                                                                                    
                                                                                </td>
                                                                            )} */}
                                                                            {/*<td>{JSON.stringify(phonenumber)}</td>*/}
                                                                            
                                                                            <td><Actions changeLanguage={changeLanguage} phonenumber={phonenumber} changeASR={changeASR} deleteAction={() => deleteNumber(phonenumber)} showLanguage={true} showASR={canSeeASR} currentASR={phonenumber.bluemixOverride ?? ""} asrEntries={asrEntries}/></td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                (filter === "all" ||filter==="outbound")&& transferDestinationQuery.data?.map((phonenumber:NeogenTransferDestinations) => {
                                                                    return (
                                                                        <tr key={phonenumber.id}>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">Transfer Destination</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">{formatPhonenumber(phonenumber.phoneNumber)}</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">{phonenumber.owner === -1 ? "-" : companyQuery.data?.find((x:NeogenCompany) => x.id === phonenumber.owner)?.name ?? "Unknown Company"}</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                {phonenumber.name}
                                                                            </td>
                                                                            
                                                                            <td className="py-2">
                                                                                <Actions phonenumber={phonenumber} editAction={() => editDestination(phonenumber)} showHours={true}  deleteAction={() => deleteDestination(phonenumber.id)} />
                                                                            </td>
                                                                            {/* <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400"><button className={"inline-flex items-center px-2.5 py-1.5 border border-red-500 text-xs font-medium rounded-lg shadow-sm text-red-500 bg-red-100 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"} onClick={() => deleteDestination(phonenumber.id)}>Delete Number</button></td> */}
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                (filter === "all" ||filter==="transcription")&& transcriptionNumbersQuery.data?.map((phonenumber:NeoGenTranscriptionNumber) => {
                                                                    return (
                                                                        <tr key={phonenumber.id}>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">Transcription Number</td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                {formatPhonenumber(phonenumber.inboundNumber)}

                                                                            </td>
                                                                            <td className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">{phonenumber.owner === -1 ? "-" : companyQuery.data?.find((x:NeogenCompany) => x.id === phonenumber.owner)?.name ?? "Unknown Company"}</td>
                                                                            <td  className="px-3 py-2 whitespace-normaltext-sm text-gray-500 dark:text-gray-400">
                                                                                {formatPhonenumber(phonenumber.outboundNumber)}
                                                                            </td>
                                                                            <td className="py-2">
                                                                                <Actions phonenumber={phonenumber} editAction={() => editNumber(phonenumber)} deleteAction={() => deleteTranscription(phonenumber.id)} />
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                        </div>
                    </>
                </div>
                <AddPhoneNumber show={showAddPhonenumber} hide={() => setShowAddPhonenumber(false)}/>
                <EditTranscription show={showEditTranscription} hide={() => setShowEditTranscription(false)} numberBeingEdited={transcriptionNumber}/>
                <PurchasePhoneNumber show={showPurchasePhonenumber} hide={() => setShowPurchasePhonenumber(false)}/>
                <AddTranscriptionNumber show={showAddTranscriptionPhonenumber} hide = {() => setShowAddTranscriptionPhonenumber(false)} />
                <DeleteNumber show={showDeletePhonenumber} hide={() => setShowDeletePhonenumber(false)} numberBeingDeleted={numberBeingDeleted}/>
                <AddOutboundPhoneNumber show={showEditDestination} hide={() => setShowEditDestination(false)} isEdit={isEdit} id={editId}/>
                <EditHours 
                    id={editId}
                    show={showEditHours}
                    hide={() => setShowEditHours(false)}
                />
            </Main>
        </>
    );
}

type PhoneNumbersProps = {
    myFolderEntriesQuery: any
};
