/*
  This example requires Tailwind CSS v2.0+
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

  
function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}
  
export default function TabsNeoGen({tabs, fullWidth, setTabs}: TabsNeoGenProps) {
    if (!tabs ) {
        return <></>;
    }
    return (
        <div className={fullWidth?"w-full":""}>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    defaultValue={tabs.find((tab) => tab.current)?.name}
                    onChange={(e) => {
                        if (setTabs) {
                            let newTabs = tabs.map((t) => {
                                t.current = (t.name === e.currentTarget.value);
                                return t;
                            });
                            setTabs(newTabs);
                        }
                    }}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className={"hidden sm:block "}>
                <nav className={"relative z-0 rounded-lg shadow flex divide-x divide-gray-200 "} aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <a
                            key={tab.name}
                            onClick={(e) => {
                                if (setTabs) {
                                    let newTabs = tabs.map((t) => {
                                        t.current = (t.name === tab.name);
                                        return t;
                                    });
                                    setTabs(newTabs);
                                }
                            }}
                            className={classNames(
                                tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
                                tabIdx === 0 ? "rounded-l-lg" : "",
                                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium  hover:bg-gray-50 focus:z-10"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                        >
                            <span>{tab.name}</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    tab.current ? "bg-indigo-500" : "bg-transparent",
                                    "absolute inset-x-0 bottom-0 h-0.5"
                                )}
                            />
                        </a>
                    ))}
                </nav>
            </div>
        </div>
    );
}

type tabType =
    
        { name: string,
            href: string,
            current: boolean }

type TabsNeoGenProps = {
    tabs: tabType[],
    fullWidth?: boolean,
    setTabs?: (t:tabType[]) => void
}
  