/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenResponses } from "../typings/api";
import APIService from "./API.service";

const debug        = require("debug")("Neogen:ResponseService");

class ResponseService extends APIService<NeogenResponses> {
    constructor() {
        super("neogen-responses");
    }

    getBetweenDates(start:Date, end:Date):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                calldate: {between: [start, end]},
            }
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }


    getBetweenDatesWithScripts(start:Date, end:Date, ids:number[], limit=10000):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {scriptId: {inq: ids}}
                ]
                
                
            },
            order: "id DESC",
            limit: limit
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
    getBetweenDatesWithScriptQuestionAndOption(start:Date, end:Date, id:number, question: string, option: string):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {scriptId: id},
                    {optionType: option},
                    {questionNumber: question}
                ]
                
                
            }
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
    getBetweenDatesWithScriptQuestionAndOptions(start:Date, end:Date, id:number, questions: string[], options: string[]):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {scriptId: id},
                    {optionType: {inq: options}},
                    {questionNumber: {inq: questions}}                    
                ]
                
                
            }
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }

    getBetweenDatesForScript(start:Date, end:Date, scriptId:number):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                calldate: {between: [start, end]},
                scriptId: scriptId,
            }
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
    getAllForScript(scriptId:number):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                scriptId: scriptId,
            },
            order: ["calldate ASC", "msIntoCall ASC"]
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
    getAllForScriptMeta(scriptId:number):Promise<void|AxiosResponse<NeogenResponses[]>> {
        let filter = {
            where: {
                and: [
                    {scriptId: scriptId},
                    {optionType: {neq: -2}}
                ]
                
            },
            "fields": {
                "calldate": true,
                "id": false,
                "phonenumber": false,
                "questionNumber": false,
                "sentimentScore": false,
                "sentimentVote": false,
                "nextQuestionNumber": false,
                "questionType": false,
                "optionType": true,
                "scriptId": false,
                "language": false,
                "transcript": true,
                "questionTranscript": false,
                "unid": false
            }
        };
        // debug(filter);
        return this.getURL("neogen-responses?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
}

export default new ResponseService();