import React, { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useForm } from "react-hook-form";
import { start } from "repl";
import calendarService from "../../../services/calendar.service";
import ModalNeoGen from "../../layout/modal-neogen";
import { useQuery, useQueryClient } from "react-query";
import PrintPre from "../../layout/print-pre";

type FormData = {
    search:string
}

export default function FindSlot(props:FindSlotProps):JSX.Element {
    const { register, setValue, handleSubmit, formState: { errors }, getValues } = useForm<FormData>();
    const queryCache                          = useQueryClient();
    const [slots, setSlots] = useState<Date[]>([]);
    // const onSubmit = handleSubmit(data => console.log(data));
    function findSlot() {
        const data = getValues();
        calendarService.findSlot(data.search).then((r:any) => {
            console.log("x",r);
            const arr:Date[] = [];
            if (r) {
                r.data.forEach((d:string) => {
                    let da = new Date(d);
                    let dau = new Date(da.getUTCFullYear(), da.getUTCMonth(), da.getUTCDate(), da.getUTCHours(), da.getUTCMinutes());
                    arr.push(dau);
                });
                setSlots(arr);
            }
            
        });
        // calendarService.addEvent(data.start, data.end, "CONFIRMED", data.summary, data.location).then((r:any) => {
        //     console.log(r);
        //     queryCache.invalidateQueries(["events"]).then((r:any) => {
                
        //         props.close();
        //     });
        // });
    }
    return (
        <>
            <ModalNeoGen title="Find Slot" show={props.show} close={props.close} okAction={() => findSlot()}>
                <>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Search Text
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                id="summary"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                {...register("search")}
                            />
                        </div>
                    </div>
                    <div className="mt-5 grid grid-cols-3 gap-4">
                        {slots.map((d:Date) => {
                            return (
                                <div key={d.toString()} className="shadow-lg grid-cols-1 border border-gray-200 rounded-md p-2 text-sm text-center">
                                    {d.toLocaleString()}
                                </div>
                            );
                        })}
                    </div>
                    {/* <PrintPre>
                        {slots}
                    </PrintPre> */}
                    
                </>
            </ModalNeoGen>
        </>
    );
}

type FindSlotProps = {
    close: () => void,
    show: boolean,
}