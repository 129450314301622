/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./API.service";
import AuthService from "./auth.service";
import axios, { AxiosResponse }      from "axios";
import authHeader from "./auth-header";
import { NeogenScriptFolderAccess } from "../typings/api";

// const debug        = require('debug')('Neogen:ServersService');

class ScriptFolderAccessService extends APIService<NeogenScriptFolderAccess> {
    constructor() {
        super("neogen-script-folder-accesses");
    }
    getAllByFolder(folder: number):Promise<void|AxiosResponse<NeogenScriptFolderAccess[]>> {
        let filter = {
            where: {
                scriptFolderId: folder
            }
        };
        return this.getURL("neogen-script-folder-accesses?filter="+encodeURIComponent(JSON.stringify(filter)));
    }


    async addCompanyToFolder(companyId:number, scriptFolderId:number) {
        let newRecord = {
            scriptFolderId: scriptFolderId,
            companyId: Number(companyId)
        };
        return this.postURL(this.endpoint, newRecord);
    }


    /**
     * Get any script folder entries that are associated with a particular script
     *
     * @param {Number} scriptID
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getByScript(scriptID:number) {
        return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent("{\"where\": {\"scriptId\":\"" + Number(scriptID) + "\"}}"));
    }

    /**
     * Get any script folder entries that are allowed access by a particular user
     *
     * @param {String} userId
     * @param {Number} scriptId
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async getByUserIdAndScriptId(userId:string, scriptId:number) {
        let filter = {
            where: {
                userId: userId,
                scriptId: scriptId
            }
        };
        return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }

    /**
     * Get any script folder entries that are allowed access by a particular user
     *
     * @param {String} userId
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async getByUserId(userId:string) {
        let filter = {
            where: {
                userId: userId
            }
        };
        return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }

    /**
     * Get any script folder entries that are allowed access by a particular company
     *
     * @todo this is missing scriptId
     * @param companyId
     * @param scriptId
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async getByCompanyIdAndScriptId(companyId: number, scriptId: number) {
        return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent("{\"where\": {\"companyId\":\"" + Number(companyId) + "\"}}"));
    }

    /**
     * Get any script folder entries that are allowed access by a particular company
     *
     * @param companyId
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async getByCompanyId(companyId: number) {

        return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent("{\"where\": {\"companyId\":\"" + Number(companyId) + "\"}}"));
    }

    /**
     * Get any script folder entries that are allowed access by a particular company
     *
     * @param companyId
     * @param userId
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async getByCompanyIdOrUserId(companyId: number, userId: string):Promise<AxiosResponse<NeogenScriptFolderAccess[]>|void> {
        if ( await AuthService.canIAccess("SEE_ALL_SCRIPTS")) {
            
            return await this.getAll();
        } else {
            let filter = {
                where: {
                    or: [
                        {userId: userId},
                        {companyId: companyId}
                    ]
    
                }
            };
            return await this.getURL( this.endpoint+"?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
        
    }


}

export default new ScriptFolderAccessService();