import { useEffect, useState, useContext } from "react";
import { getTimeZones, TimeZone } from "@vvo/tzdb";
import { useQuery } from "react-query";
import UserService from "../../services/user.service";
import UserContext from "../../services/user-context";
import { NeogenUserSettings } from "../../typings/api";

export default function TimeZonePicker(props:TimeZonePickerProps) {
    const [timezones, setTimezones] = useState<TimeZone[]>([]);
    const [user, setUser] = useContext(UserContext);
    useEffect(() => {
        setTimezones(getTimeZones());
    }, []);

    let settingsQuery = useQuery(["settings", user.id], async () => {
        if (user.id) {
            const result = await UserService.getSettingsByUserID(user.id);
            if (result) {
                return result.data;
            }
        }
        
    });

    function getSetting(settingName: string) {
        if (settingsQuery.isSuccess) {
            return settingsQuery.data?.find((s:NeogenUserSettings) => s.settingName === settingName) ?? null;
        }
        
    }

    return (
        <div className="form-group">
            <label htmlFor="">Timezone:</label>
            <select title="Select" className="form-control" value={props.timezone??getSetting("timezone")?.settingValue??Intl.DateTimeFormat().resolvedOptions().timeZone} onChange={(e) => props.setTimeZone(e.currentTarget.value)}>
                {timezones.map(tz => {
                    return (
                        <option key={tz.name} value={tz.name}>{tz.alternativeName} ({tz.name})</option>
                    );
                })}
                                        
                                        
            </select>
        </div>
    );
}

type TimeZonePickerProps = {
    timezone?: string;
    setTimeZone: (timezone: string) => void;
}