/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { Language } from "../classes/language";
import APIService from "./API.service";

class LanguageService extends APIService<Language> {
    constructor() {
        super("languages");
    }


    async translate(from:string, to:string, text: string) {
        return await this.getURL("/translateText/"+encodeURIComponent(from)+"/"+encodeURIComponent(to)+"/"+encodeURIComponent(text));
    }
}

export default new LanguageService();