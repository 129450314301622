/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService  from "./API.service";
import AuthService from "./auth.service";
import axios, { AxiosResponse } from "axios";
import { NeogenPhoneNumbers } from "../typings/api";

const debug = require("debug")("Neogen:PhoneNumberService");

class PhoneNumberService extends APIService<NeogenPhoneNumbers> {

    constructor() {
        super("neogen-phone-numbers");
    }

    // function;

    async getAll():Promise<void|AxiosResponse<NeogenPhoneNumbers[]>> {
        if ( await AuthService.canIAccess("LIST_ALL_PHONENUMBERS")) {
            // console.log("I can see all phonenumbers");
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset

            // debug("Can access my numbers");
            return super.getURL("my-neogen-phone-numbers");

            // return [];
        }

    }

    async searchInventory(country:string, state:string, startsWith:string) {
        // console.log("https://pp.neogen.ai/search?state="+state+"&startsWith="+startsWith+"&country="+country);
        return axios.get("https://pp.neogen.ai/search?state="+state+"&startsWith="+startsWith+"&country="+country);
    }

    async getAvailableByCountry(country:string):Promise<void|AxiosResponse<NeogenPhoneNumbers[]>> {
        const filter = {
            where: {
                and: [
                    {
                        owner: -1
                    },
                    {
                        scriptId: null
                    },
                    {
                        country: country
                    }
                ]
                
            }
        };
        if ( await AuthService.canIAccess("LIST_ALL_PHONENUMBERS")) {
            
            return super.getURL("neogen-phone-numbers?filter="+encodeURIComponent(JSON.stringify(filter)));
        } else {
            return super.getURL("available-neogen-phone-numbers?filter="+encodeURIComponent(JSON.stringify(filter)));
        }

    }
}

export default new PhoneNumberService();