import React from "react";
import { ChangeEvent } from "react";
import { Path, useForm, UseFormRegister, SubmitHandler, useFormState } from "react-hook-form";
import { useController } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import PrintPre from "./print-pre";
interface IFormValues {
  "First Name": string;
  Age: number;
}

type InputProps = {
  label: Path<any>;
//   register: UseFormRegister<any>;
  required?: boolean;
  value: string;
  setValue: (e:string) => void;
  name: string;
  onChange?: any;
  fieldState?: any;
  rules?: any;
  errors?: any;
  type?: string;
  className?: string;
  description?: string;
};


// const InputNeoGen = ({ label, register, required, defaultValue,name }: InputProps) => (
//     <>
//         <div className="mb-5">
//             <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//                 {label}
//             </label>
//             <div className="mt-1">
//                 <input
//                     type="text"
//                     id="email"
//                     className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//                     {...register(name, { required })}
//                     defaultValue={defaultValue}
//                 />
//             </div>geronimo gedeci
//         </div>
//     </>
// );
//const InputNeoGen = ({ label, register, required, fieldState, ...rest }: InputProps) => (
export default function InputNeoGenControlled({ value, setValue, type, className, name, label, required, rules, description, errors, ...rest }: InputProps) {
    
    return (
        <>
            <div>
                {/* <input {...field} placeholder={props.name} /> */}
                <div className="mb-5">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                        {label}
                        
                    </label>
                    {/* <PrintPre>{errors?true:false}</PrintPre> */}
                    {/* <PrintPre>{rest}</PrintPre> */}
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type={type??"text"}
                            // {...register(name, {required: rules?.required??false})}
                            {...rest}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            className={className+" "+((errors?true:false)?"block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800  dark:border-gray-900 dark:text-gray-300")}
                        
                        />
                        <p id="comments-description" className="text-gray-500 text-sm">
                            {description}
                        </p>
                        {(errors?true:false) && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        )}
                    </div>
                </div>
                {/* <p>{fieldState.isTouched && "Touched"}</p>
            <p>{fieldState.isDirty && "Dirty"}</p>
            <p>{fieldState.invalid ? "invalid" : "valid"}</p> */}
            </div>
        </>
    );
}
  

