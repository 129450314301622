import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import Title from "../layout/Title";
import AddFolderModal from "../../modals/add-folder-modal";
import { Link, useNavigate } from "react-router-dom";
import ScriptFolderService from "../../services/script-folder.service";
import ScriptFolderAccessService from "../../services/script-folder-access.service";
import ScriptFolderEntryService from "../../services/script-folder-entry.service";
// import { RouteComponentProps } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import NewFolder from "../layout/newFolder";
import Main from "../layout/Main";
import UserContext from "../../services/user-context";
import { useAllScriptFolders } from "../../hooks/useScriptFolders";
import { useAllScriptFolderEntries } from "../../hooks/useScriptFolderEntries";
import "./select-script-folder.css";
import authService from "../../services/auth.service";
import PrintPre from "../layout/print-pre";
import companyService from "../../services/company.service";
import { NeogenCompany, NeogenScriptFolderAccess, NeogenScriptFolderEntries, NeogenScriptFolders } from "../../typings/api";
import PageDescription from "../layout/page-description";
import ButtonNeoGen from "../layout/button-neogen";
import SelectNeoGen from "../layout/select-neogen";
// import { motion } from "framer-motion";

const debug = require("debug")("NeoGen:SelectScriptFolder");

function SelectScriptFolder(props:SelectScriptFolderProps) {
    const [user,setUser] = useContext(UserContext);
    const [addFolderVisible, setAddFolderVisible] = useState(false);
    const [companyFilter, setCompanyFilter] = useState(user.company);
    const [seeAllFolders, setSeeAllFolders] = useState(false);
    const [adding, setAdding] = useState(false);
    const navigate = useNavigate();
    const addFolderRef = useRef(null);
    const cache = useQueryClient();
    // const [txt, setTxt]                           = useState("#ffffff");

    function addFolder(name: string) {
        console.log("Adding a folder: " + name);
        setAdding(true);

        ScriptFolderService.create({ name: name }).then((newFolderResult:any) => {
            if (newFolderResult) {
                console.log("Creation Result: ", newFolderResult);
                console.log("Props: ", props);
                ScriptFolderAccessService.create({
                    scriptFolderId: newFolderResult.data.id,
                    companyId: user.company
                }).then(async (r) => {
                    debug(r);
                    await cache.invalidateQueries(["scriptFolderEntries"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    await cache.invalidateQueries(["scripts"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    await cache.invalidateQueries(["myFolderEntries"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    await cache.invalidateQueries(["userFolders"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    await cache.invalidateQueries(["scriptFolders"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    await cache.invalidateQueries(["scriptFolderAccess"]).then(() => {
                        console.log("Cache Invalidated");
                    });
                    setAddFolderVisible(false);
                    setAdding(false);
                    navigate("/scripts/" + newFolderResult.data.id );
                });
            }
            
        });
    }

    useEffect(() => {
        authService.canIAccess("LIST_ALL_SCRIPT_FOLDERS").then((r) => {
            if (r) {
                // console.error(r);
                setSeeAllFolders(true);
                setCompanyFilter(user.company??-1);
            }
            
        });
    }, [user.company]);

    function compare(a:any, b:any) {

        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
            return -1;
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    // Get scripts
    const scriptFolderEntryQuery = useAllScriptFolderEntries();

    // Get script folders
    const scriptFolderQuery = useAllScriptFolders();

    const scriptFolderAccessQuery = useQuery(["scriptFolderAccess"], async () => {
        let response = await ScriptFolderAccessService.getAll();
        if (response) {
            return response.data;
        }
    });

    const companyQuery = useQuery(["companyQuery"], async () => {
        let response = await companyService.getAll();
        if (response) {
            response.data.push({name: "-- All Companies --", id: -1});
            return response.data.sort(compare);
        }
    });

    

    // useEffect(() => {
    //     debug(props.darkMode);
    //     // setTxt(props.darkMode ? '#4aa764' : '#5129bc');
    // }, [props.darkMode]);
    const { isLoading, error, data, isSuccess } = useQuery(["isAdmin"], async () =>
    {return authService.canIAccess("ADMIN_DASHBOARD");}
    , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false});


    if (!scriptFolderQuery.isSuccess || !scriptFolderEntryQuery.isSuccess || !scriptFolderAccessQuery.isSuccess ||!companyQuery.isSuccess) {
        return (
            <div className={"flex justify-center flex-col align-middle h-48"}>
                <div className={"mt-10 self-center inline-block align-middle"}>
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                        
                </div>
            </div>

        );
    }

    if (scriptFolderQuery.data.length === 0 && !props.allowAdd) {
        return (
            <>
                <Main>
                    <Title title={"No Folders"} />
                    <p>You don&apos;t have any script folders</p>
                </Main>
            </>
        );
    }
    let delay = 0;
    return (
        <>

            { scriptFolderQuery.data.length === 0 && props.allowAdd ? (
                <Main>
                    <Title title={"Add A Folder"} />
                    <div className="card folder-container">
                        <div className="card-body text-center"
                            ref={addFolderRef}
                            onClick={() => {
                                console.log("Showing");
                                setAddFolderVisible(true);
                            }}
                            style={{ cursor: "pointer" }}
                        >


                            <i className={"fal fa-folder-plus fa-5x mb-5 highlight-color"} />
                            <p className={"description-text"}>
                                        To make it simpler to organize your agents
                                        we recommend setting them up
                                        inside folders.
                            </p>
                            <p className={"description-text"}>
                                        That way if you create 100 you&apos;ll be
                                        able to find
                                        them.
                            </p>
                            <p className={"description-text"}>
                                        Don&apos;t worry too much about this at
                                        the moment. You can call your
                                        first folder whatever you like - you can
                                        always change it later.
                            </p>
                            <p className={"description-text"}>
                                        This system will never bite you. Whatever
                                        you click, there&apos;s always a
                                        way back 😀
                            </p>
                        </div>
                    </div>
                </Main>
            ) : (
                <>
                    {/* <Title title="Script Folders" /> */}

                    <PageDescription>
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Agents</p>
                        <p className="mb-5">Here you&apos;ll find a list of folders that contain your scripts or virtual agents.</p>
                        <p className="mb-5">You can add as many folder as you like and you can move scripts between folders. You can also invite other people to be able to see the contents of particular folders</p>
                    </PageDescription>
                        
                    <div   className="px-2">
                        <div className="px-3 md:px-0 flex">
                            <ButtonNeoGen  icon="fa fa-plus mr-3" text="Add New Folder" size={seeAllFolders?"md":"xl"} onClick={() => setAddFolderVisible(!addFolderVisible)} className="mb-5 w-full md:w-auto "></ButtonNeoGen>
                            {seeAllFolders&& (
                                
                                <SelectNeoGen entries={companyQuery.data} className="ml-3 align-top flex-grow" onChange={(e) => typeof(e) === "number" && setCompanyFilter(e)}/>
                                
                            )}
                        </div>
                        
                    
                        
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-5 p-0">
                            
                            {scriptFolderQuery.data.map((folder:NeogenScriptFolders) => {
                                let entries = scriptFolderEntryQuery.data.filter((entry:NeogenScriptFolderEntries) => entry.scriptFolderId === folder.id).length;
                                delay+=0.02;
                                return (
                                    <React.Fragment key={folder.id}>
                                        
                                        {(scriptFolderAccessQuery.data?.map((i:NeogenScriptFolderAccess) => {
                                            if (i.scriptFolderId === folder.id) {
                                                return i.companyId;
                                            }})?.includes(companyFilter) || companyFilter===-1) && (
                                            <>
                                                <NewFolder delay={delay} darkMode={props.darkMode} key={folder.id} icon={" fal fa-folder-open"} url={props.pathname + folder.id} folder={folder} entries={entries}>
                                                    <h5>{folder.name}</h5>
                                                    <span>{entries} Script{entries !== 1 ? "s" : ""}</span>
                                                    {seeAllFolders&&(
                                                        <>
                                                            <br />
                                                            <span className="text-blue-500"><i className="fal fa-key"></i> {companyQuery.data?.find((c:NeogenCompany) => c.id === scriptFolderAccessQuery.data?.find((i:NeogenScriptFolderAccess) => i.scriptFolderId === folder.id)?.companyId)?.name}</span>
                                                        </>
                                                    )}
                                                </NewFolder>
                                            </>
                                        )}
                                        
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </>
                    
            )}
            <AddFolderModal show={addFolderVisible} handleClose={() => setAddFolderVisible(false)}
                addFolder={addFolder} adding={adding}/>
            

        </>
    );
}


interface SelectScriptFolderProps {
    darkMode: boolean,
    allowAdd: boolean,
    // history:  any,
    pathname: string,
}

export default SelectScriptFolder;