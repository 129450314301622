import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Switch from "react-switch";
import UserContext from "../../../../../services/user-context";
import { NeogenUser } from "../../../../../typings/api";
import { NeoGenIntegrationWrapper } from "../../../../../typings/api/neogen-integration";
import PrintPre from "../../../../layout/print-pre";
import PhoneNumberFinder from "../../../../phone-numbers/modals/components/phone-number-finder";
import PurchasePhoneNumber from "../../../../phone-numbers/modals/purchase-phonenumber";
import { formatPhonenumber } from "../../../../utilities/formatters";

type feature = {
    id: number,
    
    enabled: boolean,
    data: string
}


export default function CreateStep(props: CreateStepProps) {
    const [user, setUser]  = useContext(UserContext);
    const [haveNumber, setHaveNumber] = useState(false);
    const [number, setNumber] = useState("");

    function getNumber(s: string) {
        // alert(s);
        props.setBuyNumber(true);
        setHaveNumber(true);
        setNumber(s);
        props.setNumber(s);
    }

    return (
        <div className="grid">
            <p className="wizard-step-explanation">
                Ok, we&apos;re all done with the wizard.  We&apos;re going to create your virtual agent now.<br />
                <br />
                We will also set you up with a phone number so that people can call your virtual agent. This is free for the first 14 days so you can try it out and decide if you want to continue, no strings attached!<br />
                <br />
                {/* <PrintPre>{props.services}</PrintPre> */}
            </p>
            
            {haveNumber?(
                <>
                    <h3>Your Number: <b>{formatPhonenumber(number)}</b></h3>
                    <button type="button" className="mt-3 bg-transparent hover:bg-red-500 text-red-500  hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded-lg" onClick={() => {
                        props.setBuyNumber(false);
                        setHaveNumber(false);
                        setNumber("");
                        props.setNumber("");
                    }}>Select a different number</button>
                </>
                
            ):(
                <div className="grid">
                    <h3>Select Your Phone Number</h3>
                    <PhoneNumberFinder selectNumber={(s: string) => getNumber(s)} wizard={true}/>
                </div>
            )}
            
            
        </div>
    );
}

type CreateStepProps = {
    recordAudio: boolean,
    setRecordAudio: (s: boolean) => void,
    buyNumber: boolean,
    setNumber: (s: string) => void,
    setBuyNumber: (s: boolean) => void,
    createBillingAccount: () => void,
    services: NeoGenIntegrationWrapper[],
}