/*
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:47 AM
 *
 */


import React, { useEffect, useRef, useContext, useState } from "react";
import "./LogoBox.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIsFetching } from "react-query";
// import { gsap } from "gsap";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import UserContext from "../../services/user-context";
import AuthService from "../../services/auth.service";
import MultiTenantService from "../../services/multi-tenant.service";
import PrintPre from "./print-pre";
import {useQuery} from "react-query";
import axios from "axios";
import semver from "semver";

// gsap.registerPlugin(DrawSVGPlugin);
// const debug = require("debug")("NeoGen:LogoBox");

/**
 * @component
 */
function LogoBox(props:LogoBoxProps) {
    const isFetching = useIsFetching();
    const tl = useRef(null);
    const [admin, setAdmin] = useState(false);
    // const location = useLocation();
    const [image,setImage] = useState("");
    const [imageSmall,setImageSmall] = useState("");

    // const [status, data, error]   = useQuery(["admin-access"], async() => {
    //     return await AuthService.canIAccess("ADMIN_DASHBOARD");
    // });
    const { isLoading, error, data, isSuccess } = useQuery(["isAdmin"], async () =>
    {return await AuthService.canIAccess("ADMIN_DASHBOARD");}
    , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false});


    const mtQuery= useQuery(["MultiTenant"], async () =>
    {
        const result = await MultiTenantService.getAll();
        if (result) {
            
            
            return result.data;
        }
        
    }
    , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false});

    useEffect(() => {
        if (mtQuery.isSuccess) {
            setImage(mtQuery.data?.find((i:any) => i.url === window.location.hostname)?.logo?.replace("h-8", "h-20 p-1")?.replaceAll("8px", "0px")??"");
            setImageSmall(mtQuery.data?.find((i:any) => i.url === window.location.hostname)?.smallLogo?.replace("h-8", "h-12 p-1")?.replaceAll("8px", "0px")??"");
        }
        
    }, [mtQuery.data, mtQuery.isSuccess]);

    // console.log(data);
    // useEffect(() => {
    //     tl.current = gsap.timeline({ paused: true, repeat: 0, yoyo: true });
    //     // tl.current.to(".dot", { duration: 2, stroke: "rgba(0,255,0,100)"});
    //     tl.current.fromTo(".dot", {drawSVG: "0 5%"}, {duration: 10, drawSVG: "95% 100%"});
    //     tl.current.to(".voicio1", { duration: 0.8, filter: "blur(100px)"});
    //     //     .to(".logo", { duration: 0.8, filter: 'hue-rotate(0deg)' })
    //     // AuthService.canIAccess("ADMIN_DASHBOARD").then(r => {
    //     //     setAdmin(r);
    //     // });
    // }, []);

    // let vers = useQuery(["vers"], async () => {
    //     // let r = await axios.get("/meta.json");
        
    //     // if (r) {
    //     //     // console.log(r.data.version, global.appVersion);
    //     //     // if (semver.gt(r.data.version, global.appVersion)) {
    //     //     //     console.log("################################");
    //     //     //     console.log("#### Newer version available ###");
    //     //     //     console.log("################################");
    //     //     // // window.location.reload();
    //     //     // } else {
    //     //     //     console.log("global.appVersion: " + global.appVersion);
    //     //     //     console.log("r.data.version: " + r.data.version);
    //     //     // }
    //     //     return r.data;
    //     // }
    // }, []);
    // const balanceQuery = useQuery(["balance"], async () => {
    //     // const result = await TransactionService.getAllMyAmounts(user.company??-1);
    //     // if (result) {
    //     //     let sum =  result.data.reduce( function(a: number, b: partOfTransaction){
    //     //         return parseFloat((a + b.amount).toFixed(10));
    //     //         // return (a + b.amount).toFixed(2);
    //     //     }, 0);
    //     //     return sum;
    //     // }
    // });

    

    // useEffect(() => {
    //     if (isFetching && !tl.current.isActive()) {
    //         tl.current.restart();
    //     } else {
    //         // tl.current.pause();
    //         // tl.current.seek(0);
    //     }
    // }, [isFetching]);
    

    // const newImage = (mtQuery.issSuccess && mtQuery.data.find((i) => i.url === window.location.hostname)?.logo.replaceAll("8px", "0px"))??image;
    
    return (
        <div className="p-0 m-0">
            {mtQuery.isSuccess && (
                <>
                    {/* <PrintPre>{newImage}</PrintPre> */}
                </>
            )}
            {/* <PrintPre>{mtQuery}</PrintPre> */}
            <span className={"md:p-1 hidden md:inline-block"} dangerouslySetInnerHTML={{ __html: image }} />
            <span className={"m-0 p-0 md:hidden"} dangerouslySetInnerHTML={{ __html: props.large?image:imageSmall }} />
            
            {/* <div>
                <img class={(data) ?"logosvg h-12 w-auto":"h-8 w-auto"} src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} />
                
            </div> */}
            
        </div>
    );
}

type LogoBoxProps = {
    large?: boolean,
};

export default LogoBox;