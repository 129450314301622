import moment from "moment";
import { useState, useContext, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import customReportService from "../../../services/custom-report.service";
import flowchartService from "../../../services/flowchart.service";
import questionOptionTypeService from "../../../services/question-option-type.service";
import responseService from "../../../services/response.service";
import scriptService from "../../../services/script.service";
import UserContext from "../../../services/user-context";
import { NeogenResponses } from "../../../typings/api";
import { NeoGenCustomReport } from "../../../typings/api/neogen-custom-report";
import { NeogenFlowchartData } from "../../../typings/api/neogen-flowchart-data";
import DateChart from "../../layout/date-chart";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import SelectNeoGen from "../../layout/select-neogen";
import TableNeogen from "../../layout/table-neogen";
import { formatPhonenumber } from "../../utilities/formatters";
import Loader2 from "../../utilities/Loader2";

export default function ShowCustomReport(props: ShowCustomReportProps) {
    const { id } = useParams();
    const cache = useQueryClient();
    const [script, setScript] = useState(-1);
    const [folder, setFolder] = useState(-1);
    const [name, setName] = useState("");
    const [chartData, setChartData] = useState<any[]>([]);


    const [question, setQuestion] = useState<string[]>(["-1"]);
    const [responseChosen, setResponse] = useState<string[]>(["-1"]);
    const [responseText, setResponseText] = useState<string[]>([""]);
    const [numberOfEntries, setNumberOfEntries] = useState<number>(1);
    const [description, setDescription] = useState<string>("");


    const [scriptName, setScriptName] = useState("");
    const [range, setRange] = useState(30);
    const [user, setUser] = useContext(UserContext);

    

    const navigate = useNavigate();
    // Load the custom report using useQuery by id
    const customReportQuery = useQuery(["customReport", id], async () => {
        const response = await customReportService.getOne(Number(id));
        if (response) {
            return response.data;
        }
    });

    let scriptsQuery = useQuery(["scripts"], async () => {
        let response = await scriptService.getAll();
        if (response) {
            return response.data;
        }
    });
    //
    let flowchartQuery = useQuery(["flowchart", "script", script], async () => {
        let response = await flowchartService.getFlowchartByScriptID(Number(script));
        // debug(script, JSON.parse(data[0]?.jsonData));
        // let texts = [];
        // data.forEach(d => {
        //     texts[d.id] = d.name;
        // })
        // debug(texts);
        // // setResponseTexts(texts);
        if (response) {
            let data = response.data;
            return JSON.parse(data[0].jsonData ?? "") as NeogenFlowchartData;
        }

        // return data.sort((a, b) => {
        //     if (a.dateSaved < b.dateSaved) {
        //         return -1;
        //     }
        //     if (a.dateSaved > b.dateSaved) {
        //         return 1;
        //     }
        //     return 0;
        // });
    }, { enabled: (script !== -1) });

    let optionTypeQuery = useQuery(["optionTypes"], async () => {
        // const {data} = await OptionTypeService.getAll();
        let result = await questionOptionTypeService.getAll();
        if (result) {
            return result.data;
        }
        // debug("OptionTypeService: ", data);
        // let texts = [];
        // data.forEach(d => {
        //     texts[d.id] = d.name;
        // })
        // debug(texts);
        // setResponseTexts(texts);
        // return data;
        // return data.sort();
    });

    let responsesQuery = useQuery(["customReport", range, script, question, responseChosen], async () => {
        // the date 7 days ago
        let start = new Date(Date.now() - (range * 24 * 60 * 60 * 1000));
        let end = new Date();
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        let result = await responseService.getBetweenDatesWithScriptQuestionAndOptions(start, end, script, question, responseChosen);
        if (result) {
            // alert("x");
            let data = result.data;
            console.log("Responses: ", data, start, end);
            // // @TODO: filter using the database
            // data = data.filter((i:any) => Number(i.scriptId) === Number(script));
            // data = data.filter((i:any) => Number(i.questionNumber) === Number(question));
            // console.log({data, response: responseChosen});
            // data = data.filter((i:any) => i.optionType !== null);
            // data = data.filter((i:any) => Number(i.optionType) === Number(responseChosen));

            data = data.map((i: NeogenResponses) => {
                delete (i.questionNumber);
                delete (i.questionType);

                delete (i.id);
                delete (i.unid);
                delete (i.language);
                delete (i.trained);
                delete (i.sentimentScore);
                delete (i.nextQuestionNumber);
                delete (i.sentimentVote);
                delete (i.msIntoCall);
                console.log({ i, responseChosen });
                i.questionTranscript = i.questionTranscript+"";
                i.transcript = i.transcript+"";
                i.response = responseText[responseChosen.findIndex(id => Number(id) === Number(i.optionType))];
                
                delete (i.optionType);
                i.script = scriptName;
                delete (i.scriptId);
                i.calldate = moment(i.calldate).format("LLLL");
                i.phonenumber = formatPhonenumber((i.phonenumber ?? "").replace(/['"]+/g, ""));
                const j = JSON.parse(JSON.stringify(i, ["calldate","phonenumber", "questionTranscript", "transcript", "response",  ]));
                return j;
            });
            const tempChart:any[] = [];
            data.forEach((i: NeogenResponses) => {
                // Create a count of entries by date
                let date = moment(i.calldate).format("MMMM DD, YYYY");
                let index = tempChart.findIndex(j => j.date === date);
                if (index === -1) {
                    tempChart.push({ date, Responses: 1 });
                }
                else {
                    tempChart[index].Responses++;
                }
            }
            );
            setChartData(tempChart);
            // setChartData(data.map((i: NeogenResponses) => {
            //     return {
            //         date: moment(i.calldate).format("LLLL"),
            //         phonenumber: i.phonenumber,
            //     };
            // }));
            return data.sort((a: any, b: any) => {
                if (a.calldate > b.calldate) {
                    return -1;
                }
                if (a.calldate < b.calldate) {
                    return 1;
                }
                return 0;
            });
        }

        // downloadCSV =
    }, { enabled: (script !== -1 && question[0] !== "-1" && responseChosen[0] !== "-1") });

    useEffect(() => {
        if (customReportQuery.isSuccess) {
            const report = customReportQuery.data as NeoGenCustomReport;
            setScript(report.script);
            setFolder(report.folder);
            setQuestion(JSON.parse(report.questions));
            setResponse(JSON.parse(report.responses));
            setResponseText(JSON.parse(report.responseTexts));
            setNumberOfEntries(report.numberOfEntries);
            setName(report.name);
            setDescription(report.description);
            console.error(report);
        }

    }, [customReportQuery.data, customReportQuery.isSuccess]);

    return (
        <>
            {customReportQuery.isLoading ? <Loader2 /> :            
                <>
                    <PageDescription>
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">{customReportQuery.data?.name}</p>
                        <p className="mb-5">
                            {customReportQuery.data?.description}
                        </p>
                        <div>
                            <SelectNeoGen entries={[
                                { id: "1", name: "1 Day" },
                                { id: "7", name: "7 Days" },
                                { id: "30", name: "30 Days" },
                                { id: "60", name: "60 Days" },
                                { id: "90", name: "90 Days" },
                                { id: "365", name: "365 Days" },
                            ]}
                            onChange={(e: any) => {
                                setRange(e);

                            }}
                            label="Date Range"
                            value={range.toString()}
                            />
                        </div>
                        <div className="mt-5">
                            <CSVLink className={"bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg my-5"} filename={"CustomReport.csv"}
                                data={responsesQuery.data ?? []}><i
                                    className={"fa fa-file-alt mr-3"} /> Download CSV Report</CSVLink>
                        </div>
                    </PageDescription>
                    <div className="bg-white rounded-lg shadow-md dark:bg-gray-800">
                        <DateChart darkMode={props.darkMode} data={chartData ?? []} keys={["Responses"]} indexBy={"date"} legend="Number of Responses"/>
                    </div>
                    {/* <PrintPre>
                        {chartData}
                    </PrintPre> */}
                    <div>
                        <TableNeogen 
                            entries={responsesQuery.data??[]}
                        />
                        {/* <PrintPre>
                            {customReportQuery.data}
                        </PrintPre>
                        <PrintPre>
                            {responsesQuery.data}
                        </PrintPre> */}
                    </div>
                </>
            }
        </>
    );
}

type ShowCustomReportProps = {
    darkMode: boolean;
}