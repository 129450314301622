import React, { useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import phoneNumberService from "../../../../services/phone-number.service";
import PrintPre from "../../../layout/print-pre";
import {formatPhonenumber, formatPrice}                    from "../../../utilities/formatters";


type numbersResponse = {
    id: string,
    type: string,
    provider: string,
    attributes?:
    {
        inbound_rate: number,
        iso_country: string,
        minutes_included: number,
        monthly_cost: number,
        number_type: string,
        rate_center: string,
        rate_type: string,
        state: string,
        value: string,
    }
}

export default function PhoneNumberFinder(props: PhoneNumberFinderProps) {
    const [country, setCountry] = useState("US");
    const [region, setRegion] = useState("");
    const [startsWith, setStartsWith] = useState("");
    const [searching, setSearching] = useState(false);
    const [searched, setSearched] = useState(false);
    const [numbers, setNumbers] = useState<numbersResponse[]>([]);

    async function doSearch() {
        setSearching(true);
        let r:any = await phoneNumberService.searchInventory(country, region, startsWith);
        setSearching(false);
        setSearched(true);
        console.log(r);
        if (r) {
            setNumbers(r.data);
        }
        
    }

    function selectRegion(region: string) {
        setRegion(region);
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-2 p-3  ">
                <div className="col-span-12">
                    {/* <div className="form-group"> */}
                    <label htmlFor="formGroupExampleInput" className={props.wizard?"wizard":""}>Country</label><br />
                    <CountryDropdown
                        value={country}
                        valueType="short"
                        classes={"max-w-full dark:bg-gray-800 rounded-lg "+ (props.wizard?"form-control-wizard":"")}
                        onChange={(val: string) => setCountry(val)} />
                        
                    {/* </div> */}
                </div>
                <div className="col-span-12 mt-5">
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className={props.wizard?"wizard":""}>Region</label><br />
                        <RegionDropdown
                            country={country}
                            value={region}
                            valueType="short"
                            countryValueType="short"
                            classes={"max-w-full dark:bg-gray-800 rounded-lg "+ (props.wizard?"form-control-wizard":"")}
                            onChange={selectRegion} />
                    </div>
                </div>
                <div className="col-span-12 mt-5">
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className={props.wizard?"wizard":""}>Number Starts With:</label><br />
                        <input type="text" className={"dark:bg-gray-800 rounded-lg "+ (props.wizard?"form-control-wizard":"")} value={startsWith} onChange={(e) => setStartsWith(e.currentTarget.value)} />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-2 p-3  ">
                <div className="col-span-12" style={{height: "100%"}}>
                    <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg  mt-auto" onClick={() => doSearch()} disabled={searching}>{searching?"Searching...":"Search"}</button>
                    
                </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 xl:grid-cols-12 gap-2 p-3  " style={{display: searched?"block":"none"}}>
                <div className="col-auto">
                    <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 xl:grid-cols-12 gap-2">
                        {numbers.map((n: numbersResponse) => {
                            return (
                                <div className="col-span-3" key={n.id}>
                                    <div className="shadow-lg rounded-lg dark:border border-gray-600 dark:border-gray-600 m-3 p-0">
                                        <div className="bg-gray-200 text-center p-3 rounded-t-lg dark:bg-gray-700">
                                            <b>14 days free</b>
                                        </div>
                                        <div className="pt-3 pb-3">
                                            
                                            <p className="text-center">
                                                
                                                <b>{formatPrice(n.attributes?.monthly_cost) } / month</b><br />
                                                {formatPrice(n.attributes?.inbound_rate)} / minute
                                            </p><p className="text-muted text-center">
                                            Country: {n.attributes?.iso_country}<br />
                                            Region: {(n.attributes?.state??"").toUpperCase()}<br />
                                            Free Minutes: {n.attributes?.minutes_included??0}<br />
                                                
                                                {n.provider === "f" ?
                                                    (
                                                        <button type="button" className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 mt-3 px-4 border border-indigo-300 hover:border-transparent rounded-lg btn-block"  onClick={() => props.selectNumber(n.id)}>
                                                            {formatPhonenumber(n.id, n.attributes?.iso_country)}
                                                        </button>
                                                    ):(
                                                        <button type="button" className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 mt-3 px-4 border border-indigo-300 hover:border-transparent rounded-lg btn-block"  disabled>
                                                            {formatPhonenumber(n.id, n.attributes?.iso_country)}
                                                        </button>
                                                    )}
                                            </p>
                                        </div>
                                    </div>
                                
                                </div>
                            );
                        })}
                    </div>
                    
                    {/* </tbody> */}
                    {/* </table> */}
                </div>
            </div>
            {/* <PrintPre>{numbers}</PrintPre> */}

        </>
    );
}

type PhoneNumberFinderProps = {
    selectNumber: (num: string) => void,
    wizard?: boolean
}