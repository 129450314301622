/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenApiLog } from "../typings/api/neogen-api-log";
import APIService from "./API.service";
import AuthService from "./auth.service";

function getLastWeeksDate():Date {
    const now = new Date();
  
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
}
class ApiLogService extends APIService<NeogenApiLog> {
    constructor() {
        super("neogen-api-logs");
    }
    async getCallsToAPI(api: number, start: Date, end: Date) {
        // console.warn(number);
        // var start = new Date();
        // if (!today) {
        //     start.setFullYear(1900);
        // }
        start.setHours(0,0,0,0);
        end.setHours(23,59,59,59);
        let filter = {
            where : {
                and: [
                    {eventDatetime: {between: [start, end]}},
                    {api: api}
                ]
            }
            
        };
        console.warn(filter);
        // debug(filter);
        // if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
        return  this.getURL(this.endpoint+"?filter=" + encodeURIComponent(JSON.stringify(filter)));
        // } else {
        // return  this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        // }
    }
    async getAll():Promise<void|AxiosResponse<NeogenApiLog[]>>  {
        let filter = {
            where: {
                eventDatetime: {between: [getLastWeeksDate(), new Date()]},
            },
            order: ["id DESC"],
            limit: 1000
            
        };
        if ( await AuthService.canIAccess("SEE_ALL_APIS")) {
            return this.getURL("neogen-api-logs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            console.log("Not Allx");
            return this.getURL("my-neogen-api-logs?filter=" + encodeURIComponent(JSON.stringify(filter)));
            // return this.getURL("my-neogen-api-logs");
        }
    }
    async getByID(id: number):Promise<void|AxiosResponse<NeogenApiLog[]>>  {
        let filter = {
            where: {
                api: id
            },
            order: ["id DESC"],
            limit: 1000
            
        };
        if ( await AuthService.canIAccess("SEE_ALL_APIS")) {
            return this.getURL("neogen-api-logs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            console.log("Not Allx");
            return this.getURL("my-neogen-api-logs?filter=" + encodeURIComponent(JSON.stringify(filter)));
            // return this.getURL("my-neogen-api-logs");
        }
    }
}

export default new ApiLogService();