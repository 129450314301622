export default function TextAreaNeoGenControlled(props: TextAreaNeoGenProps) {
    console.info(props.value);
    return (
        <div className="mb-5">
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700 dark:text-gray-400 ">
                {props.label}
            </label>
            <div className="mt-1">
                <textarea
                    rows={props.rows??5}
                    name={props.name}
                    id={props.name}
                    // {...props.register(props.name, {required: props.rules?.required??false})}
                    value={props.value}
                    
                    onChange={(e) => props.setValue(e.currentTarget.value)}

                    {...props.rest}                    
                    className="dark:bg-gray-800 dark:text-gray-400 dark:border-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    // defaultValue={props.defaultValue}
                />
                <p id="comments-description" className="text-gray-500 text-sm">
                    {props.description}
                </p>
            </div>
        </div>
    );
}
type TextAreaNeoGenProps = {
    rows?: number,
    name: string,
    label: string,
    description?: string,
    onEnter?: () => void;
    // contents: string,
    // defaultValue: string,
    // register: any,
    value: string,
    setValue: (e:string) => void,
    rules?: any,
    rest?: any,
    // setContents: (e:string) => void,
    notFullWidth?: boolean,
}