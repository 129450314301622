/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenAudioFolder } from "../typings/api";
import { NeogenOnboarding } from "../typings/api/neogen-onboarding";
import { NeogenOnboardingProgress } from "../typings/api/neogen-onboarding-progress";
const debug = require("debug")("NeoGen:AudioFolderService");

const API_URL = process.env.REACT_APP_API_URL;

class OnboardingService extends APIService<NeogenOnboardingProgress> {
    constructor() {
        super("neogen-onboarding-progresses");
    }
    getProgress(userId: string) {
        const filter = {
            
            userId: userId
            
        };
        return this.getFilteredWhere(filter);
    }
    updateByUser( userId: string, onboardingProgress: NeogenOnboardingProgress) {
        const filter = {
            
            userId: userId
            
        };
        return this.updateFilteredWhere( filter, onboardingProgress);
    }
}

export default new OnboardingService();