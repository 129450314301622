/*
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/29/20, 2:38 PM
 *
 */

import React from "react";

if (process.env.NODE_ENV === "development") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
        trackAllPureComponents: false,
    });
} else {
    console.log(process.env);
}