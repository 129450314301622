import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import carrierService from "../../../services/carrier.service";
import { useQueryClient } from "react-query";
import phoneNumberService from "../../../services/phone-number.service";
// import PrintPre from "../../layout/print-pre";
import { NeogenPhoneNumbers } from "../../../typings/api";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
// const Swal = withReactContent(MySwal);

export default function DeleteNumber(props: DeleteNumberProps) {
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [pricePerMinute, setPricePerMinute] = useState("0.00");
    // const [pricePerMonth, setPricePerMonth] = useState("0.00");
    // const [concurrentChannels, setConcurrentChannels] = useState("");
    // const [country, setCountry] = useState("United States");
    // const [carrier, setCarrier] = useState(-1);
    const cache = useQueryClient();

    // Get carriers
    const carrierQuery = useQuery(["carriers"], async () => {
        const result = await carrierService.getAll();
        if (result !== undefined) {
            return result.data;
        } else {
            return [];
        }
    });

    
    
    function deletePhoneNumber(updatedNumber: any) {
        
        return phoneNumberService.patchURL(phoneNumberService.endpoint+"/"+updatedNumber.id, updatedNumber).then(r => {
            console.log(r);
        }).catch(e => {
            console.error(e.response);
        });
        // return true;
    }

    const mutator = useMutation(deletePhoneNumber, {
        onSuccess: (data) => {
            // Update `todos` and the individual todo queries when this mutation succeeds
            cache.invalidateQueries(["phoneNumbers"]).then(r => {
                console.log(r);
            });
        },
        onError: (e: any) => {
            console.error(e);
            console.error(e?.response);
        }
    });

    function deleteNumber() {
        if (props.numberBeingDeleted) {
            const updatedNumber = props.numberBeingDeleted;
            updatedNumber.owner = -1;
            updatedNumber.scriptId = -1;
            updatedNumber.language = 1;
            updatedNumber.bluemixOverride = "";
            updatedNumber.costApi = -1;
            updatedNumber.notes = "";
            updatedNumber.costBody = "";
            updatedNumber.billingRenewal = new Date();
            mutator.mutate(updatedNumber);
            // Swal
            
            Swal.fire({
                title: "Number Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                props.hide();
            });
        }
        
        // props.hide();
    }



    return (
        <Modal show={props.show} onHide={() => props.hide()}>
            <Modal.Header closeButton>
                Delete Phone Number
            </Modal.Header>
            <Modal.Body>
                {!carrierQuery.isSuccess?(<>Loading...</>):(
                    <form>
                        
                        
                        <div className="form-group">
                            {/* <label htmlFor="formGroupExampleInput">Phone Number</label> */}
                            <h3 className="text-red-500"><b>Warning!</b></h3>
                            <p>If you delete this phone number you won&apos;t be able to get it back!</p>
                            <div className="card">
                                <div className="card-body">
                                    Number: <b>{props.numberBeingDeleted?.number}</b><br />
                                    Notes: <b>{props.numberBeingDeleted?.notes}</b><br />
                                </div>
                            </div>
                            {/* <PrintPre>{props.numberBeingDeleted}</PrintPre> */}
                            {/* <input type="text" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} id="formGroupExampleInput" placeholder="Exactly how it is presented (i.e. +393291871754)" /> */}
                        </div>
                        
                    </form>
                )}
                

            </Modal.Body>
            <Modal.Footer>
                <button className={"btn btn-secondary"} onClick={() => props.hide()}>Cancel</button>
                <button className={"btn btn-danger"} onClick={() => deleteNumber()}>Delete Phone Number</button>
            </Modal.Footer>
        </Modal>
    );
}

type DeleteNumberProps = {
    show: boolean,
    hide: any,
    numberBeingDeleted?: NeogenPhoneNumbers
}