import { useState } from "react";
import Swal from "sweetalert2";
import ButtonNeoGen from "./button-neogen";
import ModalFieldValue from "./modal-field-value";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}


export function AddToList(props: AddToListProps) {
    // const [entries, setEntries] = useState<string[]>([]);
    const [showAddEntry, setShowAddEntry] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [idx, setIdx] = useState<number>(-1);
    const [field, setField] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    
    
    function addToList(field: string, value: string, description: string) {
        // single;
        // setEntries([...entries, newEntry]);
        const newEntry: Entry = {
            field: field,
            value: value,
            description: description 

        };
        setShowAddEntry(false);
        props.setEntries([...props.entries, newEntry]);
    }
    function saveEdit(field: string, value: string, description: string) {
        // single;
        // setEntries([...entries, newEntry]);
        const existing = props.entries;
        const newEntry: Entry = {
            field: field,
            value: value,
            description: description
        };
        existing[idx] = newEntry;
        props.setEntries([...existing]);
        setShowAddEntry(false);
        // props.setEntries([...props.entries, newEntry]);
    }
    return (
        <>
            <div className="my-5 rounded-xl shadow p-5 border">
                <p className="text-xl mb-5">
                    {props.title}
                </p>
                <ButtonNeoGen
                    text="Add Entry"
                    onClick={() => 
                    {
                        setIsEdit(false);
                        setShowAddEntry(true);
                    }
                    }
                />
                {props.entries.map((entry: Entry, index: number) => {
                    return (
                        <div key={index} className="p-0 my-3 ">
                            <div className="lg:flex lg:items-center lg:justify-between">
                                <div className="flex-1 min-w-0">
                                    <div
                                        key={entry.field}
                                        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                    >
                                        <div className="flex-shrink-0">
                                            <span className={props.icon} />
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <a href="#" className="focus:outline-none">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                <p className="text-sm font-medium text-gray-900">{entry.value}</p>
                                                <p className="text-sm text-gray-500 ">Field Name: {entry.field}</p>
                                                <p className="text-sm text-gray-500 ">Description: {entry.description}</p>
                                            </a>
                                        </div>

                                    </div>

                                </div>

                                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                <span className="sr-only">Open options</span>
                                                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                    "block px-4 py-2 text-sm"
                                                                )}
                                                                onClick={() => {
                                                                    setField(entry.field);
                                                                    setValue(entry.value);
                                                                    setDescription(entry.description);
                                                                    setIdx(index);
                                                                    setIsEdit(true);
                                                                    setShowAddEntry(true);
                                                                }}
                                                            >
                                                                Edit
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    {/* <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                    "block px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                Move
                                                            </a>
                                                        )}
                                                    </Menu.Item> */}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => {

                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "You won't be able to revert this!",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, delete it!"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            props.setEntries(props.entries.filter((_entry:Entry, _index:number) => {
                                                                                return _index !== index;
                                                                            }
                                                                            ));
                                                                        }
                        
                        
                                                                    });
                                                                }}
                                                                className={classNames(
                                                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                    "block px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                Delete
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>

                                    
                                </div>
                            </div>
                        </div>
                    );
                }
                )}
            </div>
            {showAddEntry && <ModalFieldValue title="New Entry" label="Text" name="entry" field={field} value={value} isEdit={isEdit} saveEdit={saveEdit} show={showAddEntry} close={() => setShowAddEntry(false)} save={addToList} description={description}/>}
        </>
    );
}
export type Entry = {
    field: string;
    value: string;
    description: string;
}
type AddToListProps = {
    title: string;
    entries: Entry[];
    icon: string,
    setEntries: (entries: Entry[]) => void;
}