import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NeogenScript, NeogenScriptFolderEntries, NeogenScriptFolders } from "../../typings/api";
import ScriptService from "../../services/script.service";
import AuthService from "../../services/auth.service";
import ScriptFolderService from "../../services/script-folder.service";
import Loader2 from "./Loader2";
import SelectNeoGen from "../layout/select-neogen";
import PrintPre from "../layout/print-pre";
import { NeogenClickToCall } from "../../typings/api/neogen-click-to-call";
import clickToCallsService from "../../services/click-to-calls.service";

export default function CTCSelector(props: CTCSelectorProps) {
    const [ctc, setCTC] = useState(props.ctc??-1);
    // const [folder, setFolder] = useState(props.folder??-1);
    const [folderIds, setFolderIds] = useState<number[]>([]);
    function compare(a:NeogenClickToCall,b:NeogenClickToCall ) {
        if (a.name?.toLocaleLowerCase() === null || a.name?.toLocaleLowerCase() === undefined) {
            return 1;
        }
        if (b.name?.toLocaleLowerCase() === null || b.name?.toLocaleLowerCase() === undefined) {
            return 1;
        }
        if (a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase()) {
            return -1;
        }
        if (a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase()) {
            return 1;
        }
        
        return 1;
    }
    // useEffect(() => {
    //     if (props.folder) {
    //         setFolder(props.folder);
    //     }
        
    // }, [props.folder]);
    useEffect(() => {
        if (props.ctc) {
            setCTC(props.ctc);
        }
        
    }, [props.ctc]);
    
    let ctcQuery = useQuery(["ClickToCalls"], async () => {
        const result = await clickToCallsService.getAll();
        // console.error("Folders:",result.data);
        let ids: number[] = [];
        if (result) {
            return result.data.map((n:NeogenClickToCall) => {
                if (!n.name) {
                    n.name = "Unnamed ("+n.uuid+")";
                }
                return n;
            }).sort(compare);
        }
    });

    // useEffect(() => {
    //     if (props.myFolderEntriesQuery.isSuccess) {
    //         console.log(props.myFolderEntriesQuery.data);
    //         const unique = props.myFolderEntriesQuery.data.map((item:any) => item.scriptFolderId)
    //             .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    //         console.log(unique);
    //         setFolderIds(unique);
    //     }
    // }, [props.myFolderEntriesQuery.data, props.myFolderEntriesQuery.isSuccess]);

    // console.warn(props.myFolderEntriesQuery.data);

    return (
        <div className="grid grid-cols-12 gap-2 mb-3">
            
            {ctcQuery.isLoading?(
                <div className="col-span-12"><Loader2 /></div>
            ):(
                <>
                    {/* <PrintPre>{ctcQuery.data}</PrintPre> */}
                    
                    <div className="md:col-span-6">
                        <SelectNeoGen value={ctc} entries={ctcQuery.data}  onChange={(e) => {
                            // if (props.setCTCName) {
                            //     props.setCTCName(e);
                            // }
                            // alert(e);
                            setCTC(Number(e));
                            props.setCTC(Number(e));
                        }}/>
                        
                    </div>
                </>
            )}
        </div>
    );
}

type CTCSelectorProps = {
    // myFolderEntriesQuery: any,
    setCTC: (ctc: number) => void,
    setCTCName?: (name: string) => void,
    // setFolder?: (id: number) => void,
    ctc?: number,
    // folder?: number
}