import React, { useCallback, useContext, useState } from "react";
import { useQuery } from "react-query";
import PageDescription from "../layout/page-description";
import UserContext from "../../services/user-context";
import apisService from "../../services/apis.service";
import Loader2 from "../utilities/Loader2";
import PrintPre from "../layout/print-pre";
import { NeogenApis } from "../../typings/api";
import { AxiosResponse } from "axios";
import AddEditPushApi from "./modals/add-edit-push-api";
import apiLogService from "../../services/api-log.service";
import { NeogenApiLog } from "../../typings/api/neogen-api-log";
import { PencilAltIcon } from "@heroicons/react/outline";
import ApiLogModal from "./modals/api-log-modal";
import ReactJson from "react-json-view";
import ButtonNeoGen from "../layout/button-neogen";
import TestAPI from "./modals/test-api";

const people = [
    { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    // More people...
];

function compare(a: any, b: any) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}



export default function PushAPIs() {
    const active = true;
    const [user, setUser] = useContext(UserContext);
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [showApiModal, setShowApiModal] = useState(false);
    const [showTestModal, setShowTestModal] = useState(false);
    const [APIId, setAPIId] = useState(-1);
    const [API, setAPI] = useState<NeogenApis>();

    const getDarkMode = useCallback(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            return false;
        }


    }, []);

    const apiQuery = useQuery(["apis", user.company ?? -1], async () => {
        const filter = {
            where: {
                owner: user.company
            }
        };
        const response = await apisService.getAll();
        if (response) {
            response.data = response.data.map(r => {
                try {
                    r.parsedBody = JSON.parse(r.body??"{}");
                } catch (e) {
                    // console.error(e,r);
                }
                
                return r;
            });
            return response.data.sort(compare);
        }
    });
    const apiLogQuery = useQuery(["apiLogs", user.company ?? -1], async () => {
        const filter = {
            where: {
                owner: user.company
            }
        };
        const response = await apiLogService.getAll();
        if (response) {
            return response.data.sort(compare);
        }
    });

    function addEntry() {
        setShowAddEdit(true);
    }

    return (
        <>
            <main className="-mt-24 pb-2">
                <PageDescription>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Push APIs</p>
                            <p className="mb-5">Here you&apos;ll find push APIs</p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => addEntry()}
                            >
                                Add Push API
                            </button>
                        </div>
                    </div>
                </PageDescription>
            </main>
            <div className="">
                {apiQuery.isLoading || apiLogQuery.isLoading ? <Loader2 /> : (
                    <>

                        {/* {apiLogQuery.data?.map((e:NeogenApiLog) => {
                            return (
                                <div key={e.id}>
                                    {e.api}
                                    <PrintPre>
                                        {e.response}
                                    </PrintPre>
                                </div>
                            );
                        })} */}

                        <div className="mt-2 flex flex-col pl-2 pr-3">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900">
                                            <thead className="bg-gray-50 dark:bg-gray-800">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-gray-400 ">
                                                        Name
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                        URL
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                        Body
                                                    </th>
                                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Method
                                                    </th> */}
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                        Logs
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                        Logs
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                                                {apiQuery.data?.map((apiEntry) => (
                                                    <tr key={apiEntry.id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 dark:text-gray-400 ">
                                                            {apiEntry.name}
                                                        </td>
                                                        <td className="break-all px-3 py-4 text-sm text-gray-500 max-w-lg dark:text-gray-400 ">{(apiEntry.url??"").substring(0,50)}</td>
                                                        <td className="break-all px-3 py-4 text-sm text-gray-500 max-w-lg dark:text-gray-400 "><ReactJson collapsed={false} src={apiEntry.parsedBody} theme={getDarkMode()?"solarized":"summerfruit:inverted"}displayDataTypes={false} name={false} displayObjectSize={false} /></td>
                                                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{apiEntry.method}</td> */}
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">
                                                            <ButtonNeoGen
                                                                type="primary"
                                                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                onClick={() => {
                                                                    setAPIId(apiEntry.id);
                                                                    setShowApiModal(true);
                                                                }
                                                                    
                                                                }
                                                            >
                                                                {apiLogQuery.data?.filter((e: NeogenApiLog) => {
                                                                    return e.api === apiEntry.id;
                                                                }).length} Today
                                                            </ButtonNeoGen>
                                                            

                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ">
                                                            <ButtonNeoGen
                                                                type="info"
                                                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                onClick={() => {
                                                                    setAPI(apiEntry);
                                                                    setShowTestModal(true);
                                                                }
                                                                    
                                                                }
                                                            >
                                                                Test API
                                                            </ButtonNeoGen>
                                                            

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <AddEditPushApi show={showAddEdit} close={() => setShowAddEdit(false)} isEdit={false} />
            <ApiLogModal show={showApiModal} close={() => setShowApiModal(false)} APIId={APIId} />
            <TestAPI show={showTestModal} close={() => setShowTestModal(false)} api={API}/>
        </>
    );
}