/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 7:36 PM
 *
 */

import { AxiosResponse } from "axios";
import { NeogenCompany } from "../typings/api";
import APIService  from "./API.service";
import AuthService from "./auth.service";

const debug        = require("debug")("Neogen:CompanyService");

class CompanyService extends APIService<NeogenCompany> {
    constructor() {
        super("neogen-companies");
    }
    async getAll():Promise<void | AxiosResponse<NeogenCompany[]>> {
        if ( await AuthService.canIAccess("LIST_ALL_COMPANIES")) {
            debug("I can see all companies");
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset

            debug("Can access my company");
            return super.getURL("my-company");

            // return [];
        }

    }
}

export default new CompanyService();