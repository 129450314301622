/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { NeogenScript } from "../../../typings/api";
import "./add-script-modal.css";
import AnsweringServiceWizard from "./components/answering-service-wizard";
const debug = require("debug")("NeoGen:AddScriptModal");
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import AuthService from "../../../services/auth.service";
import { XIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import ModalNeoGen from "../../layout/modal-neogen";
const pricing = {
    tiers: [
        {
            title: "Wizard",
            price: 24,
            frequency: "/month",
            description: "A wizard is a simple way to create an entire AI experience by answering a few short questions.",
            features: ["Simple To Use", "Up and running instantly", "Advanced Options"],
            cta: "Use the Wizard",
            mostPopular: true,
        },
        {
            title: "Custom Script",
            price: 32,
            frequency: "/month",
            description: "For advanced users, you can create your own custom AI using one of our templates.",
            features: [
                "Custom AI Creation",
                "Advanced GUI Flowcharting",
                "Still a 'No Code' solution",
            ],
            cta: "Create Custom Script",
            mostPopular: false,
        },
    ],
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}


/**
 * @component
 * @category Scripts
 */
function AddScriptModal(props: AddScriptModalProps) {
    const [typeSelected, setTypeSelected] = useState(-1);
    const [step, setStep] = useState(1);
    // const [open, setOpen] = useState(true);

    const { isLoading, error, data } = useQuery(["isAdmin"], async () => { return await AuthService.canIAccess("ADMIN_DASHBOARD"); }
        , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false });

    const firstFieldRef = useRef(null);
    useEffect(() => {
        if (data) {
            setTypeSelected(-1);
        } else {
            setTypeSelected(1);
        }

    }, [data, props.show]);
    // if (!props.show) {
    //     return (<></>);
    // }

    // if ()

    return (
        <>
            
            <Transition.Root show={props.show} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    // initialFocus={firstFieldRef}
                    open={props.show}
                    onClose={() => props.close()}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity z-2" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-0 pt-0 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2/3 lg:max-w-2/3 sm:w-2/3 lg:w-2/3 sm:p-0 md:p-0 dark:bg-gray-700">
                                {/* <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white dark:bg-gray-700 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 ring-transparent dark:ring-gray-700"
                                        onClick={() => props.close()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div> */}
                                <div className="z-20">

                                    <div className="mt-0 text-center sm:mt-0">
                                        <Dialog.Title as="h2" className="mt-5 text-3xl font-bold text-gray-600 sm:text-5xl sm:leading-none sm:tracking-normal lg:text-5xl dark:text-gray-400 leading-6  " style={{ display: typeSelected === -1 ? "block" : "none" }}>

                                            Create Virtual Agent

                                        </Dialog.Title>
                                        <div className="mt-0">
                                            <span className="text-sm text-gray-500">
                                                {props.adding ? (<>
                                                    <p className="m-5">
                                                        Adding script, please wait...
                                                    </p>
                                                </>) : typeSelected === -1 ? (

                                                    <div className="pt-0 pl-5 pr-5 pb-0">
                                                        <div className="mx-auto py-8 px-0 bg-white sm:px-2 lg:px-4 dark:bg-gray-700">

                                                            <p className="mt-0 text-xl text-gray-500">
                                                                Choose how you&apos;d like to create your agent. The easy way or the easier way.
                                                            </p>

                                                            {/* Tiers */}
                                                            <div className="mt-14 space-y-2 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8">
                                                                {pricing.tiers.map((tier) => (
                                                                    <div
                                                                        key={tier.title}
                                                                        className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col dark:bg-gray-800 dark:border-gray-800"
                                                                    >
                                                                        <div className="flex-1">
                                                                            <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
                                                                            {tier.mostPopular ? (
                                                                                <p className="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                                                                                    Most popular
                                                                                </p>
                                                                            ) : null}
                                                                            {/* <p className="mt-4 flex items-baseline text-gray-900"> */}
                                                                            {/* <span className="text-5xl font-extrabold tracking-tight">${tier.price}</span> */}
                                                                            {/* <span className="ml-1 text-xl font-semibold">{tier.frequency}</span> */}
                                                                            {/* </p> */}
                                                                            <p className="mt-6 text-gray-500">{tier.description}</p>

                                                                            {/* Feature list */}
                                                                            <ul role="list" className="mt-6 space-y-6">
                                                                                {tier.features.map((feature) => (
                                                                                    <li key={feature} className="flex">
                                                                                        <CheckIcon className="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
                                                                                        <span className="ml-3 text-gray-500">{feature}</span>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>

                                                                        <a
                                                                            href="#"
                                                                            onClick={() => {
                                                                                if (tier.mostPopular) { // Wizard
                                                                                    setStep(1);
                                                                                    setTypeSelected(1);
                                                                                } else {
                                                                                    setTypeSelected(0);
                                                                                }
                                                                            }}
                                                                            className={classNames(
                                                                                tier.mostPopular
                                                                                    ? "bg-indigo-500 text-white hover:bg-indigo-600 dark:text-white"
                                                                                    : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100 dark:bg-gray-600 dark:text-gray-400",
                                                                                "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                                                                            )}
                                                                        >
                                                                            {tier.cta}
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* <p>
                            Please select a script type:                            
                        </p> */}

                                                    </div>
                                                ) : typeSelected === 1 ? (
                                                    <AnsweringServiceWizard step={step} setStep={setStep} close={() => props.close()} folderId={props.folderId} />
                                                ) : (
                                                    <div className="p-4 m-3 text-left">
                                                        <div className="form-group">
                                                            <div className="mb-5 text-lg " >Script Name</div>
                                                            <input type="text" className="w-full mb-5 rounded-lg" name="newScriptName" onChange={(e) => {
                                                                props.setNewScriptName(e.currentTarget.value);
                                                            }} id="newScriptName" aria-describedby="helpId" placeholder="" />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="mb-5 text-lg" >Script Description</div>
                                                            <input type="text" className="w-full mb-5  rounded-lg" name="newScriptDescription" onChange={(e) => {
                                                                props.setNewScriptDescription(e.currentTarget.value);
                                                            }} id="newScriptDescription" aria-describedby="helpId" placeholder="" />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="mb-5 text-lg" >Template To Use</div>
                                                            <select id="scriptToDuplicate" value={props.scriptToDuplicate} className="w-full mb-5  rounded-lg custom-select" onChange={(e) => {
                                                                debug(e.currentTarget.value);
                                                                props.setScriptToDuplicate(e.currentTarget.value);
                                                            }}>
                                                                <option value='-1'>-- Please Select A Script --</option>
                                                                {props.scripts.map((script: NeogenScript) => {
                                                                    if (script.isTemplate === 1) {
                                                                        return (
                                                                            <option value={script.id} key={script.id}>{script.name}</option>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="mt-5 sm:mt-6 flex  justify-end">
                                                            <button
                                                                type="button"
                                                                className="mt-3  rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                                                onClick={() => props.close()}
                                                            // ref={cancelButtonRef}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="ml-3  rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                                                disabled={props.scriptToDuplicate === "-1"} onClick={() => {

                                                                    if (props.scriptToDuplicate !== "-1") {
                                                                        props.addScript();
                                                                    }
                                            
                                                                }}
                                                            >
                                                                Create
                                                            </button>
                                                            
                                                        </div>
                                                    </div>
                                                )}

                                                {props.showProgress && (<div className="m-5"><ProgressBar now={props.addProgress} /></div>)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* <Modal show={props.show} onHide={props.close} size={typeSelected <1 ?"lg":"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Virtual Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 m-0">
                    
                </Modal.Body>
                <Modal.Footer style={{display: typeSelected===1?"none":""}}>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"  onClick={() => props.close()}>Cancel</button>
                    <button type="button" className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg" style={{display: typeSelected === -1||typeSelected===1?"none":"block"}} disabled={props.scriptToDuplicate === "-1"} onClick={() => {

                        if (props.scriptToDuplicate !== "-1") {
                            props.addScript();
                        }

                    }}>
                        <i className="fal fa-trash mr-2" /> Add Script
                    </button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
}

export default AddScriptModal;

type AddScriptModalProps = {
    addProgress: number,
    addScript: any,
    folderId: number,
    adding: boolean,
    close: any,
    newScriptName: string,
    scriptToDuplicate: string,
    scripts: any,
    setNewScriptDescription: any,
    setNewScriptName: any,
    setScriptToDuplicate: any,
    show: boolean,
    showProgress: boolean
};