import { useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import customReportService from "../../../services/custom-report.service";
import ButtonNeoGen from "../../layout/button-neogen";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";

export default function ListReports() {
    const cache = useQueryClient();
    const navigate = useNavigate();
    const [showAddReport, setShowAddReport] = useState(false);
    const customReportsQuery = useQuery(["customReports"], async () => {
        let result = await customReportService.getAll();
        if (result) {
            return result.data;
        }
    }
    );

    return (
        <>
            <PageDescription >
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Custom Reports</p>
                <p className="mb-5">
                    Custom reports are reports that you can create and schedule.
                </p>
                <ButtonNeoGen
                    onClick={() => {
                        navigate("/add-report-builder");
                    }}
                    className="mr-2"
                    
                    size="sm"
                    text="Add Report"
                    icon="fa fa-plus"
                />
                {/* <SelectNeoGen label="Filter:" value="all" className="" entries={filterEntries} onChange={(i) => {typeof(i) === "string" && setFilter(i);}}/> */}
            </PageDescription>
            {customReportsQuery.isLoading ? <Loader2 /> :
                (
                    <>
                        {/* <PrintPre>
                            {customReportsQuery.data}
                        </PrintPre> */}
                        <TableNeogen
                            entries={customReportsQuery.data??[]}
                            actions={[
                                {
                                    icon: "fa fa-pencil mr-3",
                                    action: (entry) => {
                                        navigate(`/report-builder/${entry}`);
                                    },
                                    name: "Edit",
                                    buttonClass: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded",
                                    disabled: (entry) => entry.isRunning,


                                },
                                {
                                    icon: "fa fa-folder-open mr-3",
                                    action: (entry) => {
                                        navigate(`/report/${entry}`);
                                    },
                                    name: "Show Report",
                                    buttonClass: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded",
                                    disabled: (entry) => entry.isRunning,


                                },
                            ]}
                        />
                        
                        
                    </>

                )}
            
        </>
    );
}