/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { NeogenScriptPushIntegration } from "../typings/api/neogen-script-push-integrations";
import APIService from "./API.service";

class ScriptPushIntegrationService extends APIService<NeogenScriptPushIntegration> {
    constructor() {
        super("neogen-script-push-integrations");
    }

}

export default new ScriptPushIntegrationService();