/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/solid";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import onboardingProgressService from "../../../services/onboarding-progress.service";
import onboardingStageService from "../../../services/onboarding-stage.service";
import onboardingService from "../../../services/onboarding.service";
import UserContext from "../../../services/user-context";
import { NeogenOnboarding } from "../../../typings/api/neogen-onboarding";
import { NeogenOnboardingProgress } from "../../../typings/api/neogen-onboarding-progress";
import { NeoGenOnboardingStage } from "../../../typings/api/neogen-onboarding-stage";
import { OnboardingProgressData, QuestionResponse } from "../../../typings/api/onboarding-progress-data";
import ButtonNeoGen from "../../layout/button-neogen";
import CheckBoxNeoGenControlled from "../../layout/checkbox-neogen-controlled";
import InputNeoGenControlled from "../../layout/input-neogen-controlled";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TextAreaNeoGenControlled from "../../layout/text-area-neogen-controlled";
import Loader2 from "../../utilities/Loader2";
import Tabs from "./tabs";

// const steps = [
//     { name: "Create account", description: "Vitae sed mi luctus laoreet.", href: "#", status: "complete" },
//     {
//         name: "Profile information",
//         description: "Cursus semper viverra facilisis et et some more.",
//         href: "#",
//         status: "current",
//     },
//     { name: "Business information", description: "Penatibus eu quis ante.", href: "#", status: "upcoming" },
//     { name: "Theme", description: "Faucibus nec enim leo et.", href: "#", status: "upcoming" },
//     { name: "Preview", description: "Iusto et officia maiores porro ad non quas.", href: "#", status: "upcoming" },
// ];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function OnboardingWizard() {
    const [user, setUser] = useContext(UserContext);
    const [entries, setEntries] = useState<NeogenOnboarding[]>([]);
    const [currentStage, setCurrentStage] = useState<number>(-1);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
    const [currentEntry, setCurrentEntry] = useState<NeogenOnboarding>();
    const [enabledEntries, setEnabledEntries] = useState<NeogenOnboarding[]>([]);
    const cache = useQueryClient();
    const stageQuery = useQuery(["onboarding-stages", currentTab], async () => {
        const response = await onboardingStageService.getAll();
        if (response) {
            // const stageWithSteps = response.data.map((step: any) => {
            //     // Object.assign(step, source)
            //     step.completed = false;
            //     step.status = "upcoming";
            //     if (step.id === currentTab) {
            //         step.status = "current";
            //     }
            //     // step.description = "test";
            //     return step;
            // });
            // // stageWithSteps[0].status = "current";
            // console.log({stageWithSteps, currentStage});
            return response.data;
        }
    });
    useEffect(() => {
        setCurrentEntry(enabledEntries.find(e => e.id === currentTab));
    }, [currentTab, enabledEntries]);
    const entriesQuery = useQuery(["onboarding-entries", currentStage], async () => {
        const response = await onboardingService.getAllByOrderForStage(currentStage);
        if (response) {
            const mapped = response.data.map((entry: NeogenOnboarding) => {
                entry.stageName = stageQuery.data?.find((stage: NeoGenOnboardingStage) => stage.id === entry.stage)?.name ?? "Unknown";
                return entry;
            });
            setCurrentTab(mapped[0].id);
            
            return mapped;
        }
    }, { enabled: stageQuery.isSuccess });

    const progressQuery = useQuery(["onboarding-progress", user.id], async () => {
        const response = await onboardingProgressService.getProgress(user.id ?? "");
        console.log("progressQuery", response);
        if (response) {
            if (response.data.length === 0) {
                const blankEntry: OnboardingProgressData = {
                    completedSteps: [],
                    questionResponses: [],
                    currentStage: 1,
                    currentStep: -1
                };
                const newEntryResponse = await onboardingProgressService.create({
                    userId: user.id,
                    data: JSON.stringify(blankEntry)
                });
                setCurrentStage(1);
                if (newEntryResponse) {
                    console.log("Created new entry: ", newEntryResponse.data);
                    return newEntryResponse.data;
                }
                throw new Error("Failed to create new progress entry");
            } else {
                const entry = response.data[0];
                const data = JSON.parse(entry.data);
                setCurrentStage(Number(data.currentStage));
                console.log("Found progress entry: ", response.data);
                return response.data;
            }

        } else {
            console.error("Failed to get progress entry");
        }
    });
    useEffect(() => {
        if (progressQuery?.isSuccess && entriesQuery?.isSuccess) {
            console.log("progressQuery.data", progressQuery.data);
            console.log("entriesQuery.data", entriesQuery.data);
            const currentEntries = entriesQuery.data?.filter((entry: NeogenOnboarding) => entry.stage === JSON.parse(progressQuery.data?.[0]?.data).currentStage);
            console.log("currentEntries", currentEntries, "current stage", JSON.parse(progressQuery.data[0].data).currentStage);
            setEntries(currentEntries);
        }
    }, [entriesQuery.data, entriesQuery.isSuccess, progressQuery.data, progressQuery.isSuccess]);

    // const saveProgress = useCallback((progress: OnboardingProgressData) => {
    //     if (user.id) {
    //         const ngOnboardingProgress: NeogenOnboardingProgress = {
    //             userId: user.id,
    //             data: JSON.stringify(progress)
    //         };
    //         onboardingProgressService.updateByUser(user.id, ngOnboardingProgress).then(response => {
    //             if (response) {
    //                 console.log("response", response);
    //             }
    //             cache.invalidateQueries(["onboarding-progress", user.id]);
    //         }).catch(error => {
    //             console.log("error", error);
    //         }
    //         );
    //     }


    // }, [cache, user.id]);


    // function checkShowStep(step: NeoGenOnboardingStage) {
    //     if (progressQuery.isSuccess) {
    //         const progress = JSON.parse(progressQuery.data[0].data);
    //         console.log(progress);
    //         if (progress.completedSteps.includes(step.id)) {
    //             return false;
    //         }
    //         return true;
    //     }
    //     return true;
    // }

    function checkboxChanged(checked: boolean, field: string, step: NeogenOnboarding) {
        // Saves the state of the checkbox in the progress object
        console.log({ checked, field, step, progress: JSON.parse(progressQuery.data[0].data) });
        const currentData: OnboardingProgressData = JSON.parse(progressQuery.data[0].data);
        // const currentStep = currentData.currentStep;
        const currentField = currentData.questionResponses.find((question: any) => Number(question.stepId) === Number(step.id));
        if (currentField) {
            currentField.checkboxSelections[field] = checked;
        } else {
            currentData.questionResponses.push({
                stepId: (step.id ?? -1),
                checkboxSelections: {
                    [field]: checked
                },
                textareaResponses: {},
                textfieldResponses: {}

            });
        }
        mutator.mutate(currentData);


    }
    function textAreaChanged(value: string, field: string, step: NeogenOnboarding) {
        // Saves the state of the checkbox in the progress object
        console.log({ value, field, step, progress: JSON.parse(progressQuery.data[0].data) });
        const currentData: OnboardingProgressData = JSON.parse(progressQuery.data[0].data);
        // const currentStep = currentData.currentStep;
        const currentField = currentData.questionResponses.find((question: any) => Number(question.stepId) === Number(step.id));
        if (currentField) {
            currentField.textareaResponses[field] = value;
        } else {
            currentData.questionResponses.push({
                stepId: (step.id ?? -1),
                checkboxSelections: {
                    
                },
                textareaResponses: {
                    [field]: value
                },
                textfieldResponses: {}

            });
        }
        // saveProgress(currentData);
        mutator.mutate(currentData);

    }
    function textfieldChanged(value: string, field: string, step: NeogenOnboarding) {
        // Saves the state of the checkbox in the progress object
        console.log({ value, field, step, progress: JSON.parse(progressQuery.data[0].data) });
        const currentData: OnboardingProgressData = JSON.parse(progressQuery.data[0].data);
        // const currentStep = currentData.currentStep;
        const currentField = currentData.questionResponses.find((question: any) => Number(question.stepId) === Number(step.id));
        if (currentField) {
            currentField.textfieldResponses[field] = value;
        } else {
            currentData.questionResponses.push({
                stepId: (step.id ?? -1),
                checkboxSelections: {
                    
                },
                textareaResponses: {
                    
                },
                textfieldResponses: {
                    [field]: value
                }

            });
        }
        // saveProgress(currentData);
        mutator.mutate(currentData);

    }

    function nl2br(text:string) {
        // return text.replace(/\n/g, "<br />");
        return text.split("\n").map( (it, i) => <span key={"x"+i} className="mt-3 block">{it}</span> );
    }

    
    function nextStage() {
        const currentData: OnboardingProgressData = JSON.parse(progressQuery.data[0].data);
        // const newStage = stageQuery.data?.find(stage => stage.id > currentData.currentStage);
        // Find the highest id stage in the stageData query that is less than the current one
        let newStage: NeoGenOnboardingStage | undefined;
        let lowestStage = 9999999999;
        stageQuery.data?.forEach(stage => {
            if ((stage.id??-1) > currentData.currentStage && (stage.id??-1) < lowestStage) {
                lowestStage = (stage.id??-1);
                newStage = stage;
            }
        });
        if (newStage?.id) {
            currentData.currentStage = newStage.id;
            setCurrentStage(currentData.currentStage);
            // Swal.fire({
            //     title: "Moving Stage",
            //     text: "You are now on stage " + newStage.id,
            //     icon: "info",
            //     confirmButtonText: "OK"
            // }).then(() => {
            //     mutator.mutate(currentData);
            // }
            // );

        } else {
            // We're done
            Swal.fire({
                title: "Congratulations!",
                text: "You have completed the onboarding process",
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
            currentData.currentStage = -1;
            // return;

        }
        // currentData.currentStage++;
        cache.invalidateQueries(["onboarding-stages"]);
        mutator.mutate(currentData);
        setCurrentStage(currentData.currentStage);
        // saveProgress(currentData);
    }
    function previousStage() {
        const currentData: OnboardingProgressData = JSON.parse(progressQuery.data[0].data);
        // Find the highest id stage in the stageData query that is less than the current one
        // let newStage;
        let highestStage = 0;
        stageQuery.data?.forEach(stage => {
            if ((stage.id??-1) < currentData.currentStage && (stage.id??-1) > highestStage) {
                highestStage = (stage.id??-1);
                // newStage = stage;
            }
        });

        currentData.currentStage = highestStage;
        mutator.mutate(currentData);
        setCurrentStage(currentData.currentStage);
        cache.invalidateQueries(["onboarding-stages"]);
        // saveProgress(currentData);
    }

    const mutator = useMutation((progress: OnboardingProgressData) => {
        // if (user.id) {
        console.log("Mutating");
        console.log(progress);
        const ngOnboardingProgress: NeogenOnboardingProgress = {
            userId: user.id??"xxx",
            data: JSON.stringify(progress)
        };
        console.log({ngOnboardingProgress});
        cache.setQueryData(["onboarding-progress", user.id], [ngOnboardingProgress]);
        return onboardingProgressService.updateByUser(user.id??"xxx", ngOnboardingProgress);
            
        // }


    });

    useEffect(() => {
        if (entriesQuery.data?.length > 0) {
            const enabledEntriesCalc =  entriesQuery.data?.filter((entry: NeogenOnboarding) => {
                return (
                // Doesn't rely on anything else
                    (entry.reliesOnFalse === 0 && entry.reliesOnTrue === 0) ||
                                        
                                        // Relies on true to another answer and progress has that as true
                                        (
                                            (entry.reliesOnTrue??0) > 0 && 
                                            JSON.parse(progressQuery.data[0].data).questionResponses.find((qr:QuestionResponse) => {
                                                return qr.stepId === entry.reliesOnTrue && qr.checkboxSelections[entry.checkboxEnabler] === true;
                                            }
                                            )
                                        )
                );
            }).map((entry: NeogenOnboarding) => {
                const newFields =  {
                    id: entry.id,
                    name: entry.title,
                    // description: entry.description,
                    action: () => {
                        alert(entry.id);
                        setCurrentTab(Number(entry.id));
                    },
                    current:entry.id === currentTab,
                    
                };
                Object.assign(entry, newFields);
                return entry;
            });
            console.error({enabledEntriesCalc});
            setEnabledEntries(enabledEntriesCalc);
        }
    }, [currentTab, entriesQuery.data, progressQuery.data]);

    return (
        <div>
            <PageDescription >
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Onboarding</p>
                <p className="mb-5">This section will help you through the onboarding process</p>
            </PageDescription>
            
            <div className="md:grid md:grid-cols-4 md:gap-6">
                {!progressQuery.isSuccess || stageQuery.isLoading || entriesQuery.isLoading ? <Loader2 /> : (
                    <>

                        <div className="md:col-span-1 ">

                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 rounded-xl shadow">
                                <p className="text-xl font-bold text-gray-600 sm:text-xl dark:text-gray-400 mb-3">Onboarding Progress</p>
                                <nav aria-label="Progress">

                                    <ol role="list" className="overflow-hidden">
                                        {stageQuery.data?.map((stage, stepIdx) => (
                                            <li key={stage.name} className={classNames(stepIdx !== (stageQuery.data ?? []).length - 1 ? "pb-10" : "", "relative")}>
                                                {stage.status === "complete" ? (
                                                    <>
                                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={"#"} className="relative flex items-start group">
                                                            <span className="h-9 flex items-center">
                                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                                                    <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 min-w-0 flex flex-col">
                                                                <span className="text-xs font-semibold tracking-wide uppercase">{stage.name}</span>
                                                                <span className="text-sm text-gray-500">{stage.description}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                ) : stage.id === currentStage ? (
                                                    <>
                                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={"#"} className="relative flex items-start group" aria-current="step">
                                                            <span className="h-9 flex items-center" aria-hidden="true">
                                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                                                                    <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 min-w-0 flex flex-col">
                                                                <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">{stage.name}</span>
                                                                <span className="text-sm text-gray-500">{stage.description}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                                            <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={"#"} className="relative flex items-start group">
                                                            <span className="h-9 flex items-center" aria-hidden="true">
                                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                                    <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 min-w-0 flex flex-col">
                                                                <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">{stage.name}</span>
                                                                <span className="text-sm text-gray-500">{stage.description}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </nav>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-3">

                            {/* <PrintPre>
                                {currentEntry}
                                a
                            </PrintPre> */}
                            {/* <PrintPre>
                                {enabledEntries[currentTab]}
                            </PrintPre> */}
                            {/* <PrintPre>
                                {enabledEntries.find(e => e.id === currentTab)}
                            </PrintPre> */}
                            <div className="mb-5">



                                <Tabs tabs={ enabledEntries.map((entry: any) => {
                                    return {
                                        id: entry.id,
                                        name: entry.name,
                                        // description: entry.description,
                                        action: () => {
                                            // alert(entry.id);
                                            setCurrentTab(Number(entry.id));
                                        },
                                        current:entry.id === currentTab
                                    
                                    };
                                })}/>



                            </div>
                            {/* {progressQuery.data[0] && enabledEntries[currentTab]?.map((entry: NeogenOnboarding, idx: number) => ( */}
                            {/* <PrintPre>
                                {currentTab}
                            </PrintPre>
                            <PrintPre>
                                {enabledEntries[currentTab]}
                            </PrintPre>
                            <PrintPre>
                                {entries}
                            </PrintPre> */}
                                    
                            {currentEntry && (
                                <div key={currentEntry.id} className="px-4 py-5 bg-white space-y-6 sm:p-6 rounded-xl shadow mb-5">
                                    {/* <PrintPre>
                                        {entry}
                                    </PrintPre> */}
                                    
                                    <form className="space-y-0 ">
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center">
                                                <div >
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">{currentEntry.title}</h3>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        {nl2br(currentEntry.details??"")}
                                                    </p>
                                                </div>


                                            </div>

                                        </div>
                                        <div className=" justify-between items-center">
                                            <div className=" items-center">

                                                {/* {entry.inputType === 0 && ( */}



                                                <div key={JSON.parse(currentEntry.checkboxEntries ?? "[]").id} className=" items-center ">
                                                    {JSON.parse(currentEntry.checkboxEntries ?? "[]").map((checkboxEntry: any, idx: number) => (
                                                        <div key={idx} className="mt-0">
                                                                
                                                            <CheckBoxNeoGenControlled
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    console.log(e.currentTarget.checked);
                                                                    checkboxChanged(e.currentTarget.checked, checkboxEntry.field, currentEntry);
                                                                }}
                                                                checked={progressQuery.data.find(
                                                                    (pGEntry: NeogenOnboardingProgress) => {
                                                                        const data = JSON.parse(pGEntry.data);
                                                                        for (let response of data.questionResponses) {
                                                                            if (response.stepId === currentEntry.id && response.checkboxSelections[checkboxEntry.field]) {
                                                                                return response.checkboxSelections[checkboxEntry.field];
                                                                            }
                                                                        }
                                                                        return false;
                                                                    }
                                                                )}
                                                                name={checkboxEntry.field}
                                                                label={checkboxEntry.value}
                                                                description={checkboxEntry.description}
                                                            />
                                                        </div>
                                                    ))}


                                                    {/* <span className="text-sm text-gray-500">{entry.title}</span> */}
                                                </div>
                                                <div key={JSON.parse(currentEntry.textareaEntries ?? "[]").id} className=" items-center mt-5">
                                                    {JSON.parse(currentEntry.textareaEntries ?? "[]").map((textAreaEntry: any, idx: number) => (
                                                        <div key={idx} className="f">
                                                                
                                                            <TextAreaNeoGenControlled
                                                                setValue={(e: (string)) => {
                                                                    console.log(e);
                                                                    textAreaChanged(e, textAreaEntry.field, currentEntry);
                                                                }}
                                                                value={JSON.parse(progressQuery.data[0].data).questionResponses.find(
                                                                    (response: QuestionResponse) => {
                                                                    // (pGEntry: NeogenOnboardingProgress) => {
                                                                    //     const data = JSON.parse(pGEntry.data);
                                                                    //     for (let response of data.questionResponses) {
                                                                        if (response.stepId === currentEntry.id && response.textareaResponses[textAreaEntry.field]) {
                                                                        // console.log
                                                                        // return response.textareaResponses[textAreaEntry.field];
                                                                            return true;
                                                                        }
                                                                    }
                                                                )?.textareaResponses[textAreaEntry.field]}
                                                                name={textAreaEntry.field}
                                                                label={textAreaEntry.value}
                                                                description={textAreaEntry.description}
                                                            />
                                                        </div>
                                                    ))}


                                                    {/* <span className="text-sm text-gray-500">{entry.title}</span> */}
                                                </div>
                                                <div key={JSON.parse(currentEntry.textfieldEntries ?? "[]").id} className=" items-center mt-0">
                                                    {JSON.parse(currentEntry.textfieldEntries ?? "[]").map((textfieldEntry: any, idx: number) => (
                                                        <div key={idx} className="f">
                                                                
                                                            <InputNeoGenControlled
                                                                setValue={(e: (string)) => {
                                                                    console.log(e);
                                                                    textfieldChanged(e, textfieldEntry.field, currentEntry);
                                                                }}
                                                                value={JSON.parse(progressQuery.data[0].data).questionResponses.find(
                                                                    (response: QuestionResponse) => {
                                                                    // (pGEntry: NeogenOnboardingProgress) => {
                                                                    //     const data = JSON.parse(pGEntry.data);
                                                                    //     for (let response of data.questionResponses) {
                                                                        if (response.stepId === currentEntry.id && response.textfieldResponses?.[textfieldEntry.field]) {
                                                                        // console.log
                                                                        // return response.textareaResponses[textAreaEntry.field];
                                                                            return true;
                                                                        }
                                                                    }
                                                                )?.textfieldResponses[textfieldEntry.field]??""}
                                                                name={textfieldEntry.field}
                                                                label={textfieldEntry.value}
                                                                description={textfieldEntry.description}
                                                            />
                                                        </div>
                                                    ))}


                                                    {/* <span className="text-sm text-gray-500">{entry.title}</span> */}
                                                </div>
                                                <div>
                                                    {(currentTab !== enabledEntries[0].id) && <ButtonNeoGen onClick={() => {
                                                        let nextId = 0;
                                                        const currentIndex = enabledEntries.findIndex(
                                                            (entry:any) => entry.id === currentTab
                                                        );
                                                        if (currentIndex > -1) {
                                                            console.log({Next: enabledEntries[currentIndex - 1]});
                                                            setCurrentTab(enabledEntries[currentIndex - 1]?.id??1);
                                                            setCurrentTabIndex(currentTabIndex - 1);
                                                        }
                                                        
                                                    }}
                                                    className="mr-5"
                                                    icon="fa fa-chevron-left"
                                                    > Previous Tab </ButtonNeoGen>
                                                    }
                                                
                                                    {(currentTab !== enabledEntries[enabledEntries.length-1].id) && <ButtonNeoGen onClick={() => {
                                                        let nextId = 0;
                                                        const currentIndex = enabledEntries.findIndex(
                                                            (entry:any) => entry.id === currentTab
                                                        );
                                                        if (currentIndex > -1) {
                                                            console.log({Next: enabledEntries[currentIndex + 1]});
                                                            setCurrentTab(enabledEntries[currentIndex + 1]?.id??1);
                                                            setCurrentTabIndex(currentTabIndex + 1);
                                                        }
                                                        
                                                    }}
                                                    icon="fa fa-chevron-right"
                                                    iconAlign="right"
                                                    > Next Tab </ButtonNeoGen>
                                                    }
                                                </div>
                                                {/* )} */}


                                            </div>

                                        </div>
                                    </form>
                                    
                                    

                                    

                                </div>
                            )}
                            
                                

                            
                            
                            {/* <div>
                                
                                {currentStage > 1 && (
                                    <ButtonNeoGen onClick={() => {
                                        // nextStep(entry);
                                        previousStage();
                                    }
                                    }
                                    type="info"
                                    className="mr-5"
                                    icon="fa fa-chevron-left"
                                    >Previous stage</ButtonNeoGen>
                                )}
                                <ButtonNeoGen onClick={() => {
                                    // nextStep(entry);
                                    nextStage();
                                }
                                }
                                icon="fa fa-chevron-right"
                                >Next stage</ButtonNeoGen>
                            </div> */}
                        </div>

                    </>
                )}
            </div>
            {/* <PrintPre>
                {JSON.parse(progressQuery.data[0].data)}
            </PrintPre> */}
        </div>


    );
}
