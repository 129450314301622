import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Switch from "react-switch";
import integrationsService from "../../../../../services/integrations-service";
import virtualAgentFeaturesService from "../../../../../services/virtual-agent-features.service";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../../../../typings/api/neogen-integration";
import IntegrationTypes from "../../../../integrations/integration-types";
import Service from "./service";

type feature = {
    id: number,
    
    enabled: boolean,
    data: string
}



export default function ServicesStep(props: ServicesStepProps) {
    const cache = useQueryClient();
    const [features, setFeatures] = useState<feature[]>([]);

    const servicesQuery = useQuery(["virtualAgentFeatures"], async () => {
        const response = await virtualAgentFeaturesService.getAll();
        if (response) {
            return response.data;
        }
    });

    function addIntegration(i: NeoGenIntegrationWrapper) {
        console.log("add",i);
        props.setServices((c:any) => [...c, i]);
    }

    function removeIntegration(i: NeoGenIntegration) {
        console.log("remove",i);
        integrationsService.deleteByID(i.id).then(r1 => {
            cache.invalidateQueries(["integrations"]).then(r2 => {
                console.log(r1,r2);
                // setSaving(false);
            });
        });
        
        cache.invalidateQueries(["integrationTypes"]).then(r => {
            console.log(r);
            // setSaving(false);
        });
    }

    return (
        <>
            {/* <p className="wizard-step-explanation">
                Here you can select any API&apos;s you&apos;d like to push records to.<br />
                <br />
                Just click connect on any you would like to use and it will be set up for you.  You don&apos;t have to
                select anything here if you&apos;d just like messages delivered to your email.
            </p> */}
            <IntegrationTypes addIntegration={addIntegration} removeIntegration={removeIntegration} services={props.services}/>
            {/* {servicesQuery.isLoading?(<>Loading</>):(
                servicesQuery.data.map((s:any) => {
                    return (
                        <Service key={s.id} s={s} />
                    );
                })
                
            )} */}
            
            
            
        </>
    );
}
type ServicesStepProps = {
    services: NeoGenIntegrationWrapper[],
    setServices: any
}