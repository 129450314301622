import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import usersService from "../../services/users.service";
// import UserService from "../../services/user.service";
import UserService from "../../services/users.service";
import Main from "../layout/Main";
import {Helmet} from "react-helmet";
import { useQuery } from "react-query";
import MultiTenantService from "../../services/multi-tenant.service";
import Loader2 from "../utilities/Loader2";
import { useLocation, useNavigate } from "react-router-dom";

function ResetPasswordComplete() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [p2, setP2] = useState("");
    const [p, setP] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [match, setMatch] = useState(true);
    const [image,setImage] = useState("");
    const [checking, setChecking] = useState(true);
    const [verified, setVerified] = useState(false);
    const mtQuery = useQuery(["MultiTenant"], async () =>
    {
        const result = await MultiTenantService.getAll();
        if (result) {
            console.error(result);
            // const regex = /8px/i;
            setImage(((result.data.find((i:any) => i.url === window.location.hostname)?.logo)??"").replace("h-8", "h-20 p-1").replaceAll("8px", "0px"));
            return result.data;
        }
        
    }
    );
    let {token, user: userIn} = useParams();

    function setNewPassword() {
        console.log("Resetting Password For: "+userIn);
        console.log(p, p2);
        if (p !== p2) {
            setMatch(false);
        } else {
            usersService.resetPasswordComplete(userIn??"", token??"", p).then((r:any) => {
                console.log(r);
                if (r?.data?.done === true) {
                    navigate("/login");
                } else {
                    setVerified(false);
                }
            });
        }
        // setLoading(true);
        // // usersService.resetPassword(email);
        // setLoading(false);
        // setMessage("Reset email sent");
    }

    function checkToken() {
        UserService.getVerificationToken(userIn??"", token??"").then((r:any) => {
            setChecking(false);
            // debug(r);
            
            if (r?.data === token) {
                // debug("Verified");
                setVerified(true);
                // navigate("/login");
            } else {
                // debug("Not Verified");
                setVerified(false);
            }
        });
    }

    useEffect(() => {
        if (p2 !== p) {
            setMatch(false);
        } else {
            setMatch(true);
        }
    }, [p, p2]);

    if (token) {
        // 1 second timeout to be annoying
        setTimeout(checkToken, 1000);
    }

    return (
        <>
            <Helmet>
                <style type="text/css">{`
                                        body {
                                            background-color:#2a108b !important;
                                            position:absolute;
                                            content:"";
                                            background-image:url(/bg-lines.png) !important;
                                            width:100%;
                                            height:100%;
                                            top:0;
                                            left:0;
                                            background-position:center !important;
                                            background-repeat:no-repeat !important;
                                            z-index:0;
                                        }
                                
                                    `}</style>
            </Helmet>
            {mtQuery.isLoading?<Loader2 />:(<>
                <div className="min-h-screen  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        {/* <img className="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}
                    
                        <div className={"p-5 mx-auto h-20 w-auto mb-2 flex flex-col justify-center"} >
                            <div className="mx-auto" dangerouslySetInnerHTML={{ __html: image }} />
                        </div>
                    
                        {/* <img src="/img/logo-black.png" height="20" className="mx-auto h-20 w-auto mb-2" alt={"Logo"} /> */}
                    
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-200">
                        Reset Your Password
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
      
                            <a href="/login" className="font-medium text-indigo-200 hover:text-indigo-500">
                        Or go back to the login page
                            </a>
                        </p>
                    </div>


                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-indigo-200 py-8 px-4 shadow-xl sm:rounded-lg sm:px-10 bg-opacity-10 text-gray-200">
                            <div className="">
                                <div className="grid grid-cols-12 gap-2" >
                                    <div className="md:col-span-12 my-auto mx-auto">
                        
                                        <div className="mx-auto">
                                            
                                            <div className="">
                                                {checking?(
                                                    <>
                                                        Checking token...
                                                    </>
                                                ):!verified?(
                                                    <>
                                                        There was a problem.  Please <a href="/reset">try again.</a>

                                                    </>
                                                ):(
                                                    <>
                                                        <form>
                                                            <div className="col-span-12 ">
                                                
                                                                <div className="mb-5">
                                                                    <label htmlFor="password">New Password</label>
                                                                    <input type="password" name="password" className="mt-5 rounded-lg bg-gray-800 w-full" id="password" placeholder="Password" autoComplete="new-password" value={p} onChange={(e) => setP(e.currentTarget.value)}/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="confirmpassword">New Password Again</label>
                                                                    <input type="password" name="password2" className="mt-5 rounded-lg bg-gray-800 w-full" id="confirmpassword" placeholder="Password Again" autoComplete="new-password" value={p2} onChange={(e) => setP2(e.currentTarget.value)}/>
                                                                </div>
                                                                {!match ? (
                                                                    <h2 className="text-red-500">Passwords Don&apos;t Match</h2>
                                                                ):(
                                                                    <button type="button" className="mt-10 bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg w-full" onClick={() => setNewPassword()}>
                                                                        Set New Password
                                                                    </button>
                                                                )}
                                                
                                                            </div>
                                                        </form>
                                                    </>
                                                )}
                                
                                
                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>)}
            
        </>
    );
}

export default ResetPasswordComplete;
