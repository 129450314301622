/**
 * API Parent Class
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosError, AxiosResponse } from "axios";
import authHeader from "./auth-header";

const debug = require("debug")("NeoGen:API");
const API_URL = process.env.REACT_APP_API_URL;

// axios.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         return new Promise(resolve => {
//             const originalRequest = error.config;
//             const refreshToken = localStorage.getItem("refreshToken");
//             // console.log(refreshToken)
//             if (
//                 error.response &&
//                 error.response.status === 401 &&
//                 error.config &&
//                 !error.config.__isRetryRequest &&
//                 refreshToken
//             ) {
//                 originalRequest._retry = true;

//                 const response = fetch(API_URL + "refresh", {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json"
//                     },
//                     body: JSON.stringify({
//                         refreshToken: refreshToken
//                     })
//                 })
//                     .then((response) => response.json())
//                     .then(res => {
//                         // console.warn(res);
//                         const user = JSON.parse(localStorage.getItem("user"));

//                         user.token = res.accessToken;
//                         console.log(user.token);
//                         localStorage.setItem("user", JSON.stringify(user));
//                         return axios(originalRequest);
//                     }).catch(e => {
//                         console.error(e)
//                     });
//                 resolve(response);
//             }

//             return Promise.reject(error);
//         });
//     }
// );

class API <T> {
    endpoint: string;
    /**
     * Creates an instance of an API (normally done from child class)
     *
     * @param {String} endpoint - The endpoint URL in the REST API
     */
    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    async getURL(URL: string):Promise<void|AxiosResponse<any,any>> {
        try {
            return axios.get(API_URL + URL, { headers: authHeader() }).catch(error => {
                console.warn(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    console.error("Logout3");
                    // localStorage.removeItem("user");
                    // window.location.reload();
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
                console.log(error);
                console.warn(error.response);
                if (error.response.status === 401 || error.response.status === 403) {
                    console.error("Logout4");
                    localStorage.removeItem("user");
                    window.location.href="/login";
                    // window.location.reload();
                }
            });
            
        } catch (e) {
            
            const error = <AxiosError><any>e;
            console.error(error);
            
            // @ts-ignore It doesn't understand casting e as unknown
            if (error.response) {
                // if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.warn(error.response.status);
                if (error.response.status === 401 || error.response.status === 403) {
                    console.error("Logout5");
                    localStorage.removeItem("user");
                    // window.location.reload();
                }
                // }
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
                // localStorage.removeItem("user");
                // window.location.reload();
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
            console.log(error);
            // }
            
            
        }
    }

    async postURL(URL:string, data:any) {
        // console.error(data);
        return axios.post(API_URL + URL, data, { headers: authHeader() }).catch(e => {
            console.error(e.response);
        });
    }
    async deleteURL(URL:string) {
        return axios.delete(API_URL + URL, { headers: authHeader() });
    }

    async patchURL(URL:string, data:any) {
        return axios.patch(API_URL + URL, data, { headers: authHeader() });
    }
    async putURL(URL:string, data:any) {
        return axios.put(API_URL + URL, data, { headers: authHeader() });
    }

    /**
     * Gets a single instance of a child by ID
     *
     * @param {Number} id - the ID of the child to return
     */
    async getOne(id:number|string):Promise<void | AxiosResponse<T>> {
        try {
            console.log("Getting One:",API_URL + this.endpoint + "/" + id);
            const response = await axios.get(API_URL + this.endpoint + "/" + id, { headers: authHeader() });
            console.log(response);
            return response;
        } catch (e) {
            console.error(e);
        }
        
    }

    /**
     * Gets the number of rows
     */
    async getCount():Promise<any> {
        return axios.get(API_URL + this.endpoint + "/count", { headers: authHeader() });
    }

    /**
     * Gets a single instance of a child by ID
     *
     * @param {Number} id - the ID of the child to return
     */
    getOneSync(id:number):Promise<any> {
        return axios.get(API_URL + this.endpoint + "/" + id, { headers: authHeader() });
    }

    /**
     * Returns all entries from this endpoint
     *
     */
    getAll():Promise<void | AxiosResponse<T[]>> {
        return axios.get<T[]>(API_URL + this.endpoint, { headers: authHeader() }).catch(e => {
            console.error(e?.response?.status);
            if (e?.response?.status === 401 || e?.response?.status === 403) {
                console.error("Logout6");
                localStorage.removeItem("user");
                // window.location.reload();
            }
        });
    }

    /**
     * Returns all entries from this endpoint
     *
     */
    async getSome(offset:number, count:number):Promise<void|AxiosResponse<T[]>> {
        return axios
            .get(
                API_URL +
                this.endpoint +
                "?filter=" +
                encodeURIComponent(`{"offset": ${offset}, "limit": ${count}, "order": ["id DESC"]}`),
                { headers: authHeader() }
            )
            .catch(e => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout7");
                    localStorage.removeItem("user");
                    // window.location.reload();
                }
            });
    }

    
    async getFiltered(filter:any):Promise<any> {
        return axios
            .get(
                API_URL +
               this.endpoint +
               "/?filter=" +
               encodeURIComponent(JSON.stringify(filter)),
                { headers: authHeader() }
            )
            .catch(e => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout7");
                    localStorage.removeItem("user");
                    // window.location.reload();
                }
            });
    }
    async getFilteredWhere(filter:any):Promise<any> {
        return axios
            .get(
                API_URL +
               this.endpoint +
               "/?filter=" +
               encodeURIComponent(JSON.stringify({where: filter})),
                { headers: authHeader() }
            )
            .catch(e => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout7");
                    localStorage.removeItem("user");
                    // window.location.reload();
                }
            });
    }
    async updateFilteredWhere(filter:any, data: any):Promise<any> {
        return axios
            .patch(
                API_URL +
               this.endpoint +
               
               "/?filter=" +
               encodeURIComponent(JSON.stringify({where: filter})),
                data,
                { headers: authHeader() }
            )
            .catch(e => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout7");
                    localStorage.removeItem("user");
                    // window.location.reload();
                }
            });
    }

    /**
     * Removes null or undefined parameters
     * @param obj
     */
    clean(obj:any) {
        for (const propName in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, propName)) {
                continue;
            }
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    /**
     * Saves a child instance to the database
     */
    async create(fields:any) {
        delete fields.id;
        // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
        this.clean(fields);
        console.log(this.constructor.name + " Creating with %O", fields);
        return axios.post(API_URL + this.endpoint, fields, { headers: authHeader() }).catch(e => {
            console.error(e);
            console.log(e.response);
        });
    }
    async update(id: any, data:any) {
        return axios.patch(API_URL + this.endpoint+"/"+id, data, { headers: authHeader() });
    }

    // /**
    //  * Saves a child instance to the database
    //  */
    // create(fields) {
    //     delete (fields.id);
    //     // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
    //     this.clean(fields);
    //     console.log(this.constructor.name + " Creating with %O", fields);
    //     return axios.post(API_URL + this.endpoint, fields, {headers: authHeader()}).catch(e => {
    //         console.log(e.response);
    //     });
    // }

    /**
     * Uses introspection to get class fields
     */
    getFields(object:any) {
        return Object.getOwnPropertyNames(object);
    }

    async deleteByID(id?:number):Promise<any> {
        if (!id) {
            throw new Error;
        }
        const fullUrl = API_URL + this.endpoint + "/" + id;
        const header = authHeader();
        console.log(fullUrl, header);
        return axios
            .delete(fullUrl, { headers: header })
            .catch(e => {
                console.log("Delete Error: ", e.response);
                console.log("Delete Error: ", e);
            })
            .then(response => {
                console.log("Delete Response: ", response);
            });
    }

    // deleteByID(id) {
    //     const fullUrl = API_URL + this.endpoint + "/" + id;
    //     const header = authHeader();
    //     console.log(fullUrl,header);
    //     return axios.delete(fullUrl, {headers: header}).catch(e => {
    //         console.log("Delete Error: ",e.response);
    //         console.log("Delete Error: ",e);
    //     }).then(response => {
    //         console.log("Delete Response: ",response);
    //     });
    // }
}

export default API;
