import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NeogenScript, NeogenScriptFolderEntries, NeogenScriptFolders } from "../../typings/api";
import ScriptService from "../../services/script.service";
import AuthService from "../../services/auth.service";
import ScriptFolderService from "../../services/script-folder.service";
import Loader2 from "./Loader2";
import SelectNeoGen from "../layout/select-neogen";
import PrintPre from "../layout/print-pre";
import ScriptFolderAccessService from "../../services/script-folder-access.service";
import ScriptFolderEntryService from "../../services/script-folder-entry.service";
import UserContext from "../../services/user-context";

export default function ScriptSelector(props: ScriptSelectorProps) {
    const [script, setScript] = useState(props.script??-1);
    const [folder, setFolder] = useState(props.folder??-1);
    const [folderIds, setFolderIds] = useState<number[]>([]);
    const [user,setUser] = useContext(UserContext);

    const myFoldersQuery = useQuery(["userFolders", user?.id],  async () => {
        let result = await ScriptFolderAccessService.getByCompanyIdOrUserId(user.company??-1, user.id??"");
        if (result) {
            return result.data;
        } 
    },
    {
        enabled: user !== null,
    });

    const myFolderEntriesQuery = useQuery(["myFolderEntries", user?.id], async () => {
        if (myFoldersQuery && myFoldersQuery.data) {
            const folderIds = [];
        
            for (let folder of myFoldersQuery.data) {
                if (folder.scriptFolderId !== null) {
                    folderIds.push(folder.scriptFolderId);
                }
    
            }
            let result = await ScriptFolderEntryService.getFolderEntriesByMultipleFolders(folderIds);
            if (result) {
                return result.data as NeogenScriptFolderEntries[];
            } else {
                return [];
            }
            
        } else {
            return [];
        }
    },
    {
        enabled: myFoldersQuery.isSuccess,
    });


    function compare(a:NeogenScriptFolders,b:NeogenScriptFolders ) {
        if (a.name === null || a.name === undefined) {
            return 1;
        }
        if (b.name === null || b.name === undefined) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        
        return 1;
    }
    useEffect(() => {
        if (props.folder && props.folder !== -1) {
            setFolder(props.folder);            
        }
        
    }, [props.folder]);
    useEffect(() => {
        if (props.script && props.script !== -1) {
            setScript(props.script);
        }
        
    }, [props.script]);
    const foldersQuery = useQuery(["folders",folderIds,folder], async () => {
        const filter = {
            where: {
                id: {
                    inq: folderIds
                }
            }
        };
        let endpoint = ScriptFolderService.endpoint;
        if (await AuthService.canIAccess("LIST_ALL_SCRIPT_FOLDERS")) {
            console.log("Full Access");
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset

            console.info("Can access my script folders");
            endpoint = "my-neogen-script-folders";

            // return [];
        }
        const response = await ScriptFolderService.getURL(endpoint+"?filter="+encodeURIComponent(JSON.stringify(filter)));
        if (response) {
            // console.error(response.data);
            return (response.data as NeogenScriptFolders[]).sort(compare);
        }
    }, {enabled: folderIds.length > 0});

    let scriptQuery = useQuery(["scripts", folder], async () => {
        const result = await ScriptFolderService.getFolderEntriesByFolder(folder);
        // console.error("Folders:",result.data);
        let ids: number[] = [];
        if (result) {
            result.data?.forEach((sc:NeogenScriptFolderEntries) => {
                if (sc.id) {
                    ids.push(sc.scriptId??-1);
                }
                    
            });
                
            const scriptResult = await ScriptService.getAllByIDs(ids);
            if (scriptResult) {
                return scriptResult.data;
            }
            // return result.data();
        }
    });

    useEffect(() => {
        if (myFolderEntriesQuery.isSuccess) {
            console.log(myFolderEntriesQuery.data);
            const unique = myFolderEntriesQuery.data.map((item:any) => item.scriptFolderId)
                .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
            console.log(unique);
            setFolderIds(unique);
        }
    }, [myFolderEntriesQuery.data, myFolderEntriesQuery.isSuccess]);

    // console.warn(myFolderEntriesQuery.data);

    return (
        <div className="grid grid-cols-12 gap-2 mb-3">
            {/* <PrintPre>{user}</PrintPre> */}
            {myFolderEntriesQuery.isLoading||scriptQuery.isLoading||!foldersQuery.isSuccess?(
                <div className="col-span-12"><Loader2 /></div>
            ):(
                <>
                    
                    <div className="col-span-6">
                        {/* <PrintPre>{foldersQuery.data}</PrintPre> */}
                        <SelectNeoGen 
                            label="Folder:"
                            value={folder} entries={foldersQuery.data}  onChange={(e) => {
                                if (props.setFolder) {
                                    props.setFolder(Number(e));
                                }
                                setFolder(Number(e));
                            }}/>
                        
                    </div>
                    <div className="md:col-span-6">
                        <SelectNeoGen label="Script:" value={script} 
                            entries={myFolderEntriesQuery.data?.filter((e:any) => e.scriptFolderId === folder).map((e:any) => {
                                let script:(NeogenScript|undefined) = scriptQuery.data?.find((i: NeogenScript) => i.id === e.scriptId);
                                if (typeof(script) !== "undefined") {
                                    return script;
                                }                            
                            })}  
                            onChange={(e) => {
                                if (props.setScriptName) {
                                    props.setScriptName(myFolderEntriesQuery.data?.[e as number] as unknown as string);
                                }
                                setScript(Number(e));
                                props.setScript(Number(e));
                            }}/>
                        
                    </div>
                </>
            )}
        </div>
    );
}

type ScriptSelectorProps = {
    myFolderEntriesQuery?: any,
    setScript: (script: number) => void,
    setScriptName?: (name: string) => void,
    setFolder?: (id: number) => void,
    script?: number,
    folder?: number
}