import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import carrierService from "../../../services/carrier.service";
import { useQueryClient } from "react-query";
import phoneNumberService from "../../../services/phone-number.service";
import transcriptionNumbersService from "../../../services/transcription-numbers.service";
// import PrintPre from "../../layout/print-pre";
import { NeogenPhoneNumbers } from "../../../typings/api";
import Swal from "sweetalert2";
import { NeoGenTranscriptionNumber } from "../../../typings/api/neogen-transcription-number";
import ModalNeoGen from "../../layout/modal-neogen";
import { formatPhonenumber } from "../../utilities/formatters";
import SelectNeoGen from "../../layout/select-neogen";
import PrintPre from "../../layout/print-pre";
// import withReactContent from 'sweetalert2-react-content'
// const Swal = withReactContent(MySwal);

export default function EditTranscription(props: EditTranscriptionProps) {
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [pricePerMinute, setPricePerMinute] = useState("0.00");
    // const [pricePerMonth, setPricePerMonth] = useState("0.00");
    // const [concurrentChannels, setConcurrentChannels] = useState("");
    // const [country, setCountry] = useState("United States");
    // const [carrier, setCarrier] = useState(-1);
    const [number, setNumber] = useState(props.numberBeingEdited?.outboundNumber);
    const [isSip, setIsSip] = useState(props.numberBeingEdited?.isSip);
    const [sipUri, setSipUri] = useState<string>(props.numberBeingEdited?.sipUri??"");
    const cache = useQueryClient();


    useEffect(() => {
        setNumber(props.numberBeingEdited?.outboundNumber);
        setIsSip(props.numberBeingEdited?.isSip);
        setSipUri(props.numberBeingEdited?.sipUri??"");
    }, [props.numberBeingEdited?.isSip, props.numberBeingEdited?.outboundNumber, props.numberBeingEdited?.sipUri]);
    // Get carriers
    // const carrierQuery = useQuery(["carriers"], async () => {
    //     const result = await carrierService.getAll();
    //     if (result !== undefined) {
    //         return result.data;
    //     } else {
    //         return [];
    //     }
    // });

    
    
    function editTranscriptionNumber(updatedNumber: any) {
        
        return transcriptionNumbersService.patchURL(transcriptionNumbersService.endpoint+"/"+updatedNumber.id, updatedNumber);
    }

    const mutator = useMutation(editTranscriptionNumber, {
        onSuccess: (data) => {
            // Update `todos` and the individual todo queries when this mutation succeeds
            cache.invalidateQueries(["transcriptionNumbers"]).then(r => {
                console.log(r);
                // props.hide();
                Swal.fire({
                    title: "Number Saved",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    props.hide();
                });
            });
        },
        onError: (e: any) => {
            console.error(e);
            console.error(e?.response);
            Swal.fire({
                title: "Number Not Saved",
                icon: "error",
                // showConfirmButton: false,
                // timer: 3000
            });
            // .then(() => {
            // props.hide();
            // });
        }
    });

    function editNumber() {
        if (props.numberBeingEdited) {
            //         alert(props.numberBeingEdited);
            //         props.hide();
            const updatedNumber = props.numberBeingEdited;
            updatedNumber.outboundNumber = number;
            updatedNumber.isSip = isSip;
            updatedNumber.sipUri = sipUri;
            //         // updatedNumber.scriptId = -1;
            //         // updatedNumber.language = 1;
            //         // updatedNumber.bluemixOverride = "";
            //         // updatedNumber.costApi = -1;
            //         // updatedNumber.notes = "";
            //         // updatedNumber.costBody = "";
            //         // updatedNumber.billingRenewal = new Date();
            mutator.mutate(updatedNumber);
            // Swal
            
           
        }
        
    //     // props.hide();
    }



    return (
        <ModalNeoGen show={props.show} close={() => props.hide()} title="Edit Transcription Number" okText="Save" okAction={() => editNumber()} cancelText="Cancel" size="md">
            
            {/* {!carrierQuery.isSuccess?(<>Loading...</>):( */}
            <form>
                        
                        
                <div className="form-group">
                    {/* <label htmlFor="formGroupExampleInput">Phone Number</label> */}
                    <p>                    
                        Number: <b>{formatPhonenumber( props.numberBeingEdited?.inboundNumber)}</b><br />
                    </p>
                    
                    
                    <div className="mt-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Is this a SIP transfer: 
                        </label>
                        <div className="mt-1">
                            <SelectNeoGen entries={[{id: 1, name: "Yes"},{id: 0, name: "No"}]} value={props.numberBeingEdited?.isSip} onChange={(e) => {
                                // alert(e);
                                // const changedNumber = {...phonenumber};
                                // changedNumber.scriptId = Number(e);
                                // mutator.mutate(changedNumber);
                                setIsSip(Number(e));
                                // alert(e);
                            }}/>
                        </div>
                    </div>
                    {isSip  === 0 ?(
                        <div className="mt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Destination: 
                            </label>
                            <div className="mt-1">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    value={number}
                                    onChange={(e) => setNumber(e.currentTarget.value)}
                                />
                            </div>
                        </div>
                    ): (<div className="mt-5">
                        <label htmlFor="sipUri" className="block text-sm font-medium text-gray-700">
                        SIP URI: 
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="sipUri"
                                id="sipUri"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                value={sipUri}
                                onChange={(e) => setSipUri(e.currentTarget.value)}
                            />
                        </div>
                    </div>)}
                    
                    {/* <PrintPre>{props.numberBeingEdited}</PrintPre> */}
                    {/* <input type="text" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} id="formGroupExampleInput" placeholder="Exactly how it is presented (i.e. +393291871754)" /> */}
                </div>
                        
            </form>
            {/* )} */}
                

            
            
        </ModalNeoGen>
    );
}

type EditTranscriptionProps = {
    show: boolean,
    hide: any,
    numberBeingEdited?: NeoGenTranscriptionNumber
}