import { useQuery, queryCache, useQueryClient } from "react-query";
import scriptFolderEntryService from "../services/script-folder-entry.service";
import { sortByName } from "../sections/utilities/sort.js";

export function useAllScriptFolderEntries() {
    const cache = useQueryClient();
    return useQuery(["scriptFolderEntries"], async() => {

        let { data } = await scriptFolderEntryService.getAll();
        data.forEach(i => {
            // console.log("i:",i);
            cache.setQueryData(["scriptFolderEntry", i.id], i);

        });
        // cache.setQueryData("test", () => ({test: "test"})); // <-- Should create a new query if missing
        // console.log("get query cache", cache.getQueryData("test")); // <-- Doesn't create a new query
        return data.sort(sortByName);

    }, { staleTime: 300000, });
}