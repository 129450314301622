import ModalNeoGen from "../../layout/modal-neogen";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useQuery, useQueryClient } from "react-query";
import companyService from "../../../services/company.service";
import Loader2 from "../../utilities/Loader2";
import { NeogenCompany } from "../../../typings/api";
import transcriptionNumbersService from "../../../services/transcription-numbers.service";

const people = [
    { id: 1, name: "Wade Cooper" },
    { id: 2, name: "Arlene Mccoy" },
    { id: 3, name: "Devon Webb" },
    { id: 4, name: "Tom Cook" },
    { id: 5, name: "Tanya Fox" },
    { id: 6, name: "Hellen Schmidt" },
    { id: 7, name: "Caroline Schultz" },
    { id: 8, name: "Mason Heaney" },
    { id: 9, name: "Claudie Smitham" },
    { id: 10, name: "Emil Schaefer" },
];
  
function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function AddTranscriptionNumber(props: AddTranscriptionNumberProps) {
    const [selected, setSelected] = useState<NeogenCompany|null>(null);
    const [inbound, setInbound] = useState("");
    const [outbound, setOutbound] = useState("");
    const queryCache = useQueryClient();

    const companiesQuery = useQuery(["companies"], async() => {
        let response = await companyService.getAll();
        if (response) {
            setSelected(response.data[0]);
            return response.data;
        }
    });

    function saveDetails() {
        let newTranscriptionNumber = {
            owner: selected?.id,
            inboundNumber: inbound.replace(/[^0-9]/g, ""),
            outboundNumber: outbound.replace(/[^0-9]/g, ""),
        };
        transcriptionNumbersService.create(newTranscriptionNumber).then(r => {
            console.log(r);
            queryCache.invalidateQueries(["transcriptionNumbers"]);
            props.hide();
        });
        
    }

    return (
        <>
            <ModalNeoGen show={props.show} close={props.hide} title="Add Transcription Number" okAction={() => saveDetails()}>
                {companiesQuery.isLoading?(Loader2):(
                    <div className="mb-5">
                        <Listbox value={selected} onChange={setSelected} >
                            {({ open }) => (
                                <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
                                    <div className="mt-1 relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                            <span className="block truncate">{selected?.name}</span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                {companiesQuery.data?.map((company:NeogenCompany) => (
                                                    <Listbox.Option
                                                        key={company.id}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active ? "text-white bg-indigo-600" : "text-gray-900",
                                                                "cursor-default select-none relative py-2 pl-3 pr-9"
                                                            )
                                                        }
                                                        value={company}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                                                                    {company.name}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? "text-white" : "text-indigo-600",
                                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                        )}
                                                                    >
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                )}
                
                <div className="mb-5">
                    <label htmlFor="email2" className="block text-sm font-medium text-gray-700">
                        Inbound Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="email"
                            id="email2"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="i.e. 14074081234"
                            value={inbound}
                            onChange={(e) => setInbound(e.currentTarget.value)}
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Outbound Number
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="i.e. 14074082345"
                            value={outbound}
                            onChange={(e) => setOutbound(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </ModalNeoGen>
        </>
    );
}

type AddTranscriptionNumberProps = {
    show: any,
    hide: any
}