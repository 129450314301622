/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios      from "axios";
import authHeader from "./auth-header";
import APIService from "./API.service";
import { NeogenAudioFolder } from "../typings/api";
import { NeogenOnboarding } from "../typings/api/neogen-onboarding";
const debug = require("debug")("NeoGen:AudioFolderService");

const API_URL = process.env.REACT_APP_API_URL;

class OnboardingService extends APIService<NeogenOnboarding> {
    constructor() {
        super("neogen-onboardings");
    }
    getAllByOrder() {
        // Loopback 4 order by order field
        const filter = {
            order: ["order ASC", "id ASC"]
        };
        return this.getFiltered(filter);
    }
    getAllByOrderForStage(stage: number) {
        // Loopback 4 order by order field
        const filter = {
            where: {
                stage: stage
            },
            order: ["order ASC", "id ASC"]
        };
        return this.getFiltered(filter);
    }
}

export default new OnboardingService();