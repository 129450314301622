/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import { Cdr } from "../typings/api";
import APIService from "./API.service";
import AuthService from "./auth.service";

// const debug        = require('debug')('Neogen:CDRService');

class CDRService extends APIService<Cdr> {
    constructor() {
        super("cdrs");
    }

    async getBetweenDates(start: Date, end: Date, limit=1000):Promise<void|AxiosResponse<Cdr[]>>  {

        let filter = {
            where: {
                calldate: {between: [start, end]},
            },
            order: "calldate DESC",
            limit: limit
        };
            // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            // alert("2");
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getByCTC(ctc: string):Promise<void|AxiosResponse<Cdr[]>> {

        let filter = {
            where: {
                ctc: ctc,
            }
        };
            // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getLastThirty() :Promise<void|AxiosResponse<Cdr[]>>{

        let filter = {
            order: ["calldate DESC"],
            limit: 30
            
        };
            // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getBetweenDatesForScript(start: Date, end: Date, script: number):Promise<void|AxiosResponse<Cdr[]>>  {

        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {userfield: script}
                ]
            },
            limit: 1000
        };
            // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getTransfersBetweenDates(start: Date, end: Date):Promise<void|AxiosResponse<Cdr[]>> {
        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {amaflags: 1}
                ]
                
            }
        };
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getTransferNumbersBetweenDates(start: Date, end: Date):Promise<void|AxiosResponse<Cdr[]>> {
        let filter = {
            fields: {
                dst: true
            },
            where: {
                calldate: {between: [start, end]},
                amaflags: 1
            }
        };
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getCountOfCallsInToNumber(number: string, start: Date, end: Date) {
        // console.warn(number);
        // var start = new Date();
        // if (!today) {
        //     start.setFullYear(1900);
        // }
        start.setHours(0,0,0,0);
        end.setHours(23,59,59,59);
        let filter = {
            and: [
                {calldate: {between: [start, end]}},
                {dst: number},
                {amaflags: 3}
            ]
            
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs/count?where=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs/count?where=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getCallsInToNumber(number:string, start:Date, end:Date):Promise<void|AxiosResponse<Cdr[]>>  {
        // console.warn(number);
        // var start = new Date();
        // if (!today) {
        //     start.setFullYear(1900);
        // }
        start = new Date(start.setHours(0,0,0,0));
        end = new Date(end.setHours(23,59,59,59));
        let filter = {
            where : {
                and: [
                    {calldate: {between: [start, end]}},
                    {dst: number},
                    {amaflags: 3}
                ]
            }
            
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getCallsInToNumberOut(number:string, start:Date, end:Date):Promise<void|AxiosResponse<Cdr[]>>  {
        // console.warn(number);
        // var start = new Date();
        // if (!today) {
        //     start.setFullYear(1900);
        // }
        start = new Date(start.setHours(0,0,0,0));
        end = new Date(end.setHours(23,59,59,59));
        let filter = {
            where : {
                and: [
                    {calldate: {between: [start, end]}},
                    {dst: number},
                    {amaflags: 3}
                ]
            }
            
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getAllCallsInToNumber(number: string) {
        // console.warn(number);
        // var start = new Date();
        // if (!today) {
        //     start.setFullYear(1900);
        // }
        // start.setHours(0,0,0,0);
        // end.setHours(23,59,59,59);
        let filter = {
            where : {
                and: [
                    {dst: number},
                    {amaflags: 3}
                ]
            }
            
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getCountOfTransfersFromNumber(number: string, start: Date, end: Date) {
        
        start.setHours(0,0,0,0);
        end.setHours(23,59,59,59);
        let filter = {
            and: [
                {calldate: {between: [start, end]}},
                {dst: number},
                {amaflags: 1}
            ]
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs/count?where=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs/count?where=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getTransfersFromNumber(number: string, start: Date, end: Date) {
        
        start.setHours(0,0,0,0);
        end.setHours(23,59,59,59);
        let filter = {
            where: {
                and: [
                    {calldate: {between: [start, end]}},
                    {dst: number},
                    {amaflags: 1}
                ]
            },
            fields: {
                "billsec": true,
                "calldate": true,
                "transfer": true,
                "disposition": true,
                "duration": true,
            }
        };
        // console.warn(filter);
        // debug(filter);
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return  this.getURL("cdrs/?filter=" + encodeURIComponent(JSON.stringify(filter)));
        } else {
            return  this.getURL("my-cdrs/?filter=" + encodeURIComponent(JSON.stringify(filter)));
        }
    }
    async getCount():Promise<void|AxiosResponse<{count: number}>> {
        if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
            return this.getURL("cdrs/count");
        } else {
            return this.getURL("my-cdrs/count");
        }
    }
}

export default new CDRService();