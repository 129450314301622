/**
 *
 * @param {string} number
 */
import { parsePhoneNumberFromString } from "libphonenumber-js";
export function formatPhonenumber(number?:(string), country:any = "US") {
    try {
        const response = parsePhoneNumberFromString(number??"", country);
        return response?.formatNational();
    } catch (e) {
        return number??"";
    }
    
}

export function formatPrice(number?:number) {
    return new Intl.NumberFormat(undefined, {
        style: "currency", currency: "USD",}).format(number??0);
}