
import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Menu, Popover, Switch, Transition } from "@headlessui/react";
import {
    BellIcon,
    ClockIcon,
    CreditCardIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuAlt1Icon,
    MenuIcon,
    UserGroupIcon,
    XIcon,
} from "@heroicons/react/outline";
import UserService from "../../services/user.service";
import {
    SearchIcon,
} from "@heroicons/react/solid";
import LogoBox from "./LogoBox";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import AuthService from "../../services/auth.service";
import MultiTenantService from "../../services/multi-tenant.service";
import RoleContext                   from "../../services/role-context";
import { NeogenRoles } from "../../typings/api";
import Avatar from "react-avatar";
import { useIsFetching, useQuery } from "react-query";
import UserContext from "../../services/user-context";
import { Link } from "react-router-dom";
import CallAI from "./call-ai";
import PrintPre from "./print-pre";
import bg from "./backgrounds/tyi2.jpg";
import { useLocation } from "react-router";
import SearchBox from "./search-box";

gsap.registerPlugin(DrawSVGPlugin);

let navigation = [
    { name: "Home", href: "/", icon: HomeIcon, current: true },
    { name: "Agents", href: "/scripts", icon: ClockIcon, current: false },
    { name: "Numbers", href: "/phone-numbers", icon: UserGroupIcon, current: false },
    { name: "Reports", href: "/reports", icon: DocumentReportIcon, current: false },
    { name: "Click To Call", href: "/click-to-call-selector", icon: DocumentReportIcon, current: false },
    { name: "Push APIs", href: "/push-apis", icon: DocumentReportIcon, current: false },
    // { name: "Admin", href: "#", icon: ScaleIcon, current: false },
    // { name: "Calendar", href: "/calendar", icon: CreditCardIcon, current: false },
    // { name: "Integrations", href: "/integrations", icon: CreditCardIcon, current: false },
    { name: "Billing", href: "/billing/transactions", icon: CreditCardIcon, current: false },
    // { name: "Settings", href: "/settings", icon: CreditCardIcon, current: false },
];

function classNames(...classes:any) {
    return classes.filter(Boolean).join(" ");
}

type menuItem = {
    name:         string,
    label:        string,
    url:          string,
    requiredRole: string,
    icon:         any,
    items?:        menuItem[],
    current?: boolean
}

const userNavigation = [
    // { name: "Your Profile", href: "#" },
    { name: "Settings", href: "/settings" },
    { name: "Suggestions", href: "/suggestions" },
    { name: "Sign out", href: "/logout" },
];

const user = {
    id: "",
    name: "Chelsea Hagon",
    email: "chelseahagon@example.com",
    role: "Human Resources Manager",
    imageUrl:
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
 
export default function SideBarNew(props: SideBarNewProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isFetching = useIsFetching();
    const [current, setCurrent] = useState("");
    const tl = useRef<GSAPTimeline|undefined>(undefined);
    const [admin, setAdmin] = useState(false);
    const globalAny:any = global;
    const closeButton = useRef(null);
    const [internalNavigation, setInternalNavigation] = useState(navigation);
    // const [current]
    // const navigate = useNavigate();
    const location = useLocation();

    // const [status, data, error]   = useQuery(["admin-access"], async() => {
    //     return await AuthService.canIAccess("ADMIN_DASHBOARD");
    // });


    const { isLoading, error, data } = useQuery(["isAdmin"], async () =>
    {return AuthService.canIAccess("ADMIN_DASHBOARD");}
    , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false});

    // console.log(data);
    useEffect(() => {        
        const dots = document.getElementsByClassName("dot");
        if (tl && dots.length > 0) {
            console.log(dots);
            tl.current = gsap.timeline({ paused: false, repeat: 1, yoyo: true });
            tl.current.set(dots, { stroke: "rgb(0, 254, 255)" });
            // tl.current.to(".dot", { duration: 2, opacity: 0});
            tl.current.fromTo(dots, {drawSVG: "0 0%", strokeWidth:10}, {duration: 3,drawSVG: "40% 38%", strokeWidth:15});
        }
        

    });

    useEffect(() => {
        if (tl && isFetching && !(tl.current?.isActive())) {
            // console.log("Changed", tl.current?.isActive(), isFetching);
            tl.current?.seek(0);
            tl.current?.play();
        }
        
    }, [isFetching]);
    const roles:NeogenRoles[]                                 = useContext(RoleContext);
    // console.error(roles);
    // const location = useLocation();
    // let history = useNavigate();
    


    useEffect(() => {
        user.name = props.currentUser?.user?.name??"Unknown";
        user.role = props.currentUser?.user?.email??"";
        user.id = props.currentUser?.user?.id??"";
        if (props.currentUser?.user?.stCompany) {
            // console.error(props.currentUser);
            navigation.push({ name: "SmoothTorque", href: "/st-menu", icon: CreditCardIcon, current: false });
            setInternalNavigation(navigation);
        }
        
    }, [props.currentUser]);

    let settingsQuery = useQuery(["settings"], async () => {
        const result = await UserService.getSettingsByAllUserID();
        // console.log(data);
        if (result) {
            return result.data;
        }
        
    });

    const mtQuery= useQuery(["MultiTenant"], async () =>
    {
        const result = await MultiTenantService.getAll();
        if (result) {
            return result.data;
        }
        
    }
    , { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5, refetchOnMount: true, refetchOnWindowFocus: false});


    useLayoutEffect(() => {
        // console.error(location.pathname);
        setInternalNavigation(i => i.map(n => {
            if (n.href === location.pathname) {
                n.current = true;
                // console.error("Setting current to ",n);
                setCurrent(n.href);
            } else {
                n.current = false;
                // console.error("Not Setting current to ",n);
            }
            return n;
        }));
    }, [location.pathname]);

    function setWrapperEnabledDarkMode(enabled:boolean) {
        // $('body').toggleClass('dark-theme');

        // alert(enabled);

        console.log(localStorage.getItem("theme"));
        props.setDarkMode(enabled);
        if (enabled) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
        }
        // window.location.reload();
    }


    function getSetting(userId: string, settingName: string) {
        if (settingsQuery.isSuccess) {
            // console.warn(settingsQuery.data)
            return settingsQuery.data?.find((s:any) => s.settingName === settingName && s.userId === userId) ?? null;
        }
        
    }
    let [actualUser,setUser] = useContext(UserContext);
    user.name = actualUser?.name??"Unknown";
    user.email =actualUser?.email??"";
    user.id = actualUser?.id??"";
    // console.error(props.currentUser.roles);
    // useEffect(() => {
    //     if (closeButton !== null && closeButton.current !== null) {
            
    //         alert("x");
    //         // @ts-ignore - will fix soon
    //         closeButton.current.click();
    //     }
        
    // }, [closeButtonlocation.pathname]);
    return (
        
        <>
        
            {props.currentUser?.roles?.find((r:NeogenRoles) => r.roleCode === "ADMIN_DASHBOARD") === undefined ? (
                
                <div className="w-full">
                    
                    <Popover 
                        as="header" 
                        className={
                            "pb-20 md:pb-24"
                        +" bg-gradient-to-br from-blue-600 via-blue-900 to-cyan-600 dark:from-gray-900 dark:to-blue-900"
                        }
                        style={{
                            // background: `url(${bg})`, 
                            // backgroundPosition: "center center",
                            // backgroundSize: "cover"
                        }}
                    >
                        {({ open }) => (
                            <>
                                {/* <PrintPre>{props.location}</PrintPre> */}
                                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                    <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
                                        
                                        <div className="absolute left-0 py-5 flex-shrink-0 lg:static">
                                            <Link to="/">
                                                <span className="sr-only">NeoGen.AI</span>
                                                {/* https://tailwindui.com/img/logos/workflow-mark-cyan-200.svg */}
                                                
                                                <LogoBox />
                                                
                                            </Link>
                                            
                                        </div>
                                        <div className="absolute left-20 py-5 flex-shrink-0 lg:static">
                                            {!((!isLoading)&&data) && (
                                                <>
                                                    <span className="text-white opacity-70 font-extralight ml-7 tracking-widest text-4xl invisible lg:visible" style={{verticalAlign: "middle",
                                                        display: "inline-block"}}>{mtQuery.isSuccess && mtQuery.data?.find((i:any) => i.url === window.location.hostname)?.name}</span>
                                                    <span className="text-white opacity-70 font-extralight ml-7 tracking-widest text-xs align-text-bottom invisible lg:visible">Version {globalAny.appVersion}</span>
                                                    <span className="loading"></span>
                                                </>
                                            )}
                                        </div>
                                        <div className="flex-grow"></div>

                                        {/* Right section on desktop */}
                                        <div className="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
                                            <Switch.Group as="li" className="py-4 flex items-center justify-between mr-4">
                                                <div className="flex flex-col">
                                                    <Switch.Label as="p" className="text-sm font-medium text-gray-100 dark:text-gray-500" passive>
                                                        {(!props.darkMode)?"Light Mode":"Dark Mode"}
                                                    </Switch.Label>
                                    
                                                </div>
                                                <Switch
                                                    checked={props.darkMode}
                                                    onChange={setWrapperEnabledDarkMode}
                                                    className={classNames(
                                                        (props.darkMode ) ? "bg-blue-800" : "bg-gray-200",
                                                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                                                    )}
                                                >
                                                    <span className="sr-only">Use setting</span>
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            (props.darkMode) ? "translate-x-5" : "translate-x-0",
                                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                        )}
                                                    />
                                                </Switch>
                                            </Switch.Group>
                                            <button
                                                type="button"
                                                className="flex-shrink-0 p-1 text-cyan-200 rounded-full hover:text-white hover:bg-white hover:dark:bg-gray-600 hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                            

                                            {/* Profile dropdown */}
                                            <Menu as="div" className="ml-4 relative flex-shrink-0">
                                                {({ open }) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button className="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                                                                <span className="sr-only">Open user menu</span>
                                                                <Avatar  size="40px" name={user.name}  round={true} email={user.email} facebookId={getSetting(user.id??"", "facebookId")?.settingValue}/>
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 dark:bg-gray-800 ring-black dark:text-gray-100 ring-opacity-5 focus:outline-none"
                                                            >
                                                                {userNavigation.map((item) => (
                                                                    <Menu.Item key={item.name}>
                                                                        {({ active }) => (
                                                                            <Link
                                                                                to={item.href}
                                                                                className={classNames(
                                                                                    active ? "bg-gray-100 dark:bg-gray-700" : "",
                                                                                    "block px-4 py-2 text-sm text-gray-700 dark:text-gray-400"
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                ))}
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </div>

                                        <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                                            <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                                                {/* Left nav */}
                                                <div className="hidden lg:block lg:col-span-2">
                                                    <nav className="flex space-x-4">
                                                        {internalNavigation.map((item) => (
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                className={classNames(
                                                                    current === item.href ? "text-white dark:text-green-300 bg-green dark:bg-gray-800 bg-opacity-40" : "text-cyan-100 dark:text-gray-300 bg-opacity-10 ",
                                                                    "text-sm font-medium rounded-md bg-white hover:bg-indigo-900 hover:bg-opacity-80 px-3 py-2 hover:text-green-400 backdrop-blur-md "
                                                                )}
                                                                aria-current={item.current ? "page" : undefined}
                                                            >
                                                                {item.name}
                                                                {/* {item.current?"x":"y"} */}
                                                            </Link>
                                                        ))}
                                                    </nav>
                                                </div>
                                                <div className="px-12 lg:px-0">
                                                    {/* Search */}
                                                    <div className="max-w-xs mx-auto w-full lg:max-w-md">
                                                        <label htmlFor="search" className="sr-only">
                                                            Help
                                                        </label>
                                                        <div className="relative text-white focus-within:text-gray-600">
                                                            <SearchBox />
                                                            {/* <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center ">
                                                                <SearchIcon className="h-5 w-5 text-gray-200 z-10" aria-hidden="true" />
                                                            </div>
                                                            <input
                                                                id="search"
                                                                className="block backdrop-blur-md  w-full text-white bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                                placeholder="Search For Help"
                                                                type="search"
                                                                name="search"
                                                                onKeyUp={(event) => {
                                                                    console.log(event.key);
                                                                    if (event.key === "Enter") {
                                                                        console.log( "/faq/"+ event.currentTarget.value);
                                                                    }
                                                                }}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Menu button */}
                                        <div className="absolute right-0 flex-shrink-0 lg:hidden">
                                            {/* Mobile menu button */}
                                            <Popover.Button
                                                onClick={() => (open = false)}
                                                className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>

                                <Transition.Root show={open} as={Fragment}>
                                    <div className="lg:hidden">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="duration-150 ease-out"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="duration-150 ease-in"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Popover.Overlay static className="z-20 fixed inset-0 bg-black bg-opacity-25" />
                                        </Transition.Child>

                                        <Transition.Child
                                            as={Fragment}
                                            enter="duration-150 ease-out"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="duration-150 ease-in"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Popover.Panel
                                                focus
                                                static
                                                className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                                            >
                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-blue-900 ">
                                                    <div className="py-0">
                                                        <div className="flex items-center justify-between px-4">
                                                            <div>
                                                                <LogoBox large={true}/>
                                                            </div>
                                                            <div className="-mr-2">
                                                                <Popover.Button  className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                                                                    <span className="sr-only">Close menu</span>
                                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                                </Popover.Button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 px-2 space-y-1">
                                                            {navigation.map((item) => (
                                                                <a
                                                                    key={item.name}
                                                                    href={item.href}
                                                                    className="block rounded-md px-3 py-2 text-base text-gray-100 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            ))}
                                                            <a
                                                                href="/notifications"
                                                                className="block rounded-md px-3 py-2 text-base text-gray-100 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                            >
                                                                Notifications
                                                            </a>
                                                            {userNavigation.map((item) => (
                                                                <a
                                                                    key={item.name}
                                                                    href={item.href}
                                                                    className="block rounded-md px-3 py-2 text-base text-gray-100 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition.Child>
                                    </div>
                                </Transition.Root>
                            </>
                        )}
                    </Popover>
                    
                    <div className="max-w-3xl mx-auto -px-2  sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 p-0">
                            {/* Left column */}
                            <div className={"grid grid-cols-1 col-span-3 p-0 -m-2 pt-2"}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                    {/* </main> */}
                </div>
            ):(<><div className="h-screen flex overflow-hidden bg-gray-100 dark:bg-gray-700">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        static
                        className="fixed inset-0 flex z-40 lg:hidden"
                        open={sidebarOpen}
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-xs w-full shadow bg-blue-500">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            ref={closeButton}
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <LogoBox/>
                                </div>
                                <nav className="mt-5 flex-shrink-0 h-full divide-y divide-blue-800 overflow-y-auto" aria-label="Sidebar">
                                    <div className="px-2 space-y-1">
                                        {props.items.map((item:any) => (
                                            <Link
                                                key={item.name}
                                                to={item.url}
                                                className={classNames(
                                                    item.current ? "bg-blue-800 text-white" : "text-gray-100 hover:text-white hover:bg-blue-600 dark:text-gray-200",
                                                    "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                                )}
                                                aria-current={item.current ? "page" : undefined}
                                            >
                                                <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-blue-200 dark:text-gray-200" aria-hidden="true" />
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                    {/* <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            {secondaryNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-blue-100 hover:text-white hover:bg-blue-600 dark:text-gray-200"
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-blue-200 dark:text-gray-200" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div> */}
                                </nav>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0  ">
                    <div className="flex flex-col w-64 ">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="flex flex-col flex-grow bg-blue-900 pt-10 pb-4 overflow-y-auto shadow  dark:bg-gray-800 dark:border-gray-800">
                            <div className="grid items-center flex-shrink-0 px-4 text-center justify-items-center">
                                <LogoBox/>
                            </div>
                            <nav className="mt-5 flex-1 flex flex-col overflow-y-auto dark:divide-gray-900" aria-label="Sidebar">
                                <div className="px-2 space-y-2">
                                    {props.items.map((item:menuItem) => (
                                        <React.Fragment key={item.name}>
                                            {/* x{item.requiredRole} */}
                                            {/* y{roles.find(itemx => itemx.roleCode === item.requiredRole)} */}
                                            {/* z<PrintPre>{roles}</PrintPre> */}
                                            {(roles.find(itemx => itemx.roleCode === item.requiredRole) !== undefined || typeof(item.requiredRole) === "undefined") && (
                                                <Link
                                                    to={item.url}
                                                    className={classNames(
                                                        item.current ? "bg-blue-500 text-white hover:text-white" : "text-gray-200 hover:text-white hover:bg-blue-600 dark:text-gray-400",
                                                        "group flex items-center px-2 py-2 text-md leading-6 font-medium rounded-md"
                                                    )}
                                                    aria-current={item.current ? "page" : undefined}
                                                >
                                                    <i className={"mr-4 flex-shrink-0 h-6 w-6 "+(item.current ?"text-white":"text-gray-400 dark:text-gray-500 ")+" text-md fa-fw "+item.icon } aria-hidden="true" />
                                                    {item.label}
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                {/* <div className="mt-6 pt-6">
                                    <div className="px-2 space-y-1">
                                        {secondaryNavigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-gray-800 hover:text-white hover:bg-blue-600 dark:text-gray-400"
                                            >
                                                <item.icon className="mr-4 h-6 w-6 text-gray-500 dark:text-gray-400" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div> */}
                                <Switch.Group as="li" className="py-4 mt-5 flex items-center justify-between mr-4 px-4">
                                    <div className="flex flex-col">
                                        <Switch.Label as="p" className="text-sm font-medium text-gray-100 dark:text-gray-500" passive>
                                            {(!props.darkMode)?"Light Mode":"Dark Mode"}
                                        </Switch.Label>
                                    
                                    </div>
                                    <Switch
                                        checked={props.darkMode}
                                        onChange={setWrapperEnabledDarkMode}
                                        className={classNames(
                                            (props.darkMode ) ? "bg-blue-800" : "bg-gray-200",
                                            "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                                        )}
                                    >
                                        <span className="sr-only">Dark Mode</span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                (props.darkMode) ? "translate-x-5" : "translate-x-0",
                                                "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                <CallAI />
                            </nav>
                        </div>
                    </div>
                
                </div>

                <div className="flex-1 overflow-auto focus:outline-none ">
            
                    <main className="relative z-0 ">
            
                        {/* Page header */}
                        <div className="bg-gray-100 shadow dark:bg-gray-700  ">
                            <button
                                className="px-4 border rounded-md m-5 border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <div className=" h-screen dark:bg-gray-700 mt-28 mx-5 p-0">
                                {props.children}
                            </div>
                        </div>

            
                    </main>
                </div>
            </div></>)}
            
        </>
        
    );
}

type SideBarNewProps = {
    cache?: any,
    children: any,
    items: any,
    currentUser: any,
    location: any,
    darkMode?: any,
    setDarkMode?: any,
    
}