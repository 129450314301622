export default function ButtonNeoGen(props:ButtonNeoGenProps) {
    let classes;
    switch(props.type??"") {
        case "danger":
            classes = "bg-red-500 text-white dark:bg-red-700 dark:text-gray-200 ";
            break;
        case "info":
            classes = "bg-blue-600 text-white dark:bg-blue-700 dark:text-gray-200 ";
            break;
        case "secondary":
            classes = "bg-green-600 text-white dark:bg-green-700 dark:text-gray-200 ";
            break;
        case "submit":
        default:
            classes = "bg-indigo-800 hover:bg-indigo-700 text-white dark:text-gray-200 ";
    }
    switch(props.size) {
  
        case "xs":   
            classes += "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "sm":   
            classes += "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "lg": 
            classes += "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        case "xl":  
            classes += "inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
            break;
        default:      
            classes += "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
        
    }
    if (props.block) {
        classes+=" w-full";
    }
    
    if (props.hidden) {
        classes+=" hidden";
    }

    return (
        <button
            type={props.type === "submit"?"submit":"button"}
            onClick={() => props.onClick()}
            className={classes+" "+props.className}
            disabled={props.disabled}
        >
            <span className="flex-grow">
                {props.iconAlign !== "right" && props.icon&&<i className={props.icon+" mr-3"}></i>}
                {props.text}
                {props.children}
                {props.iconAlign === "right" && props.icon&&<i className={props.icon+" ml-3"}></i>}
            </span>
        </button>
    );
}
type ButtonNeoGenProps = {
    children?: any,
    size?: string,
    className?: string,
    text?: string,
    type?: ("danger"|"info"|"primary"|"submit"|"secondary"),
    onClick: () => void,
    icon?: string,
    disabled?: boolean,
    block?: boolean,
    hidden?: boolean,

    iconAlign?: "left"|"right",
}