import { createContext } from "react";
import { NeogenUser } from "../typings/api";

const UserContext = createContext<[NeogenUser, (n:NeogenUser) => void]>([
    {email: ""}, (c:NeogenUser) => {}
]);

export default UserContext;

// export const RoleProvider = roleContext.Provider;
// export const RoleConsumer = roleContext.Consumer;