import React, { useEffect, useState } from "react";
import PageDescription from "../layout/page-description";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import Loader2 from "../utilities/Loader2";
import bg from "./bg.jpg";
export default function Summarize() {
    const [text, setText] = useState("");
    const [result, setResult] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const debounced = useDebounce(text, 500);

    useEffect(() => {
        // alert(debounced);
        setResult("Summarizing");
        setLoaded(false);
        
        if (debounced.length > 0) {
            setLoading(true);
            axios.post("https://summarize.neogen.ai/summarize", {sentence: debounced}).then(r => {
                // alert(r.data);
                console.log(r.data);
                setResult(r.data[0].summary_text);
                setLoaded(true);
                setLoading(false);
            });
        }
        
    }, [debounced]);
    
    return (
        <>
            <PageDescription>
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Custom NLU Groups</p>
                <p className="mb-5">Custom NLU Groups allow you to create a list of NLU Intentions (which themselves are automatically created), and then
                                    detect whether one or more of these groups were recognized.</p>

            </PageDescription>
            <div>
                <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                    Statement to summarize
                </label>
                <div className="mt-1">
                    <textarea
                        rows={14}
                        name="comment"
                        id="comment"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                    />
                </div>
            </div>
            {loading && (
                <Loader2 />
            )}
            {loaded && (
                <div className="relative bg-indigo-800 mt-5 rounded-lg">
                    <div className="absolute inset-0  rounded-lg">
                        <img
                            className="w-full h-full object-cover  rounded-lg"
                            src={bg}
                            alt=""
                        />
                        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply  rounded-lg" aria-hidden="true" />
                    </div>
                    <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Summary</h1>
                        <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
                            {result}
                        </p>
                    </div>
                </div>
            )}
            
        </>
    );
}