/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'light-blue': colors.lightBlue,
          teal: colors.teal,
          cyan: colors.cyan,
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/line-clamp'),
    ]
  }
  ```
*/
import React, { Fragment, useContext, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
    AcademicCapIcon,
    BadgeCheckIcon,
    BellIcon,
    CashIcon,
    ClockIcon,
    MenuIcon,
    ReceiptRefundIcon,
    UsersIcon,
    PhoneIcon,
    XIcon,
} from "@heroicons/react/outline";

import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { CursorClickIcon, MailOpenIcon,ShoppingCartIcon } from "@heroicons/react/outline";

const statsx = [
    { id: 1, name: "Total Subscribers", stat: "71,897", icon: UsersIcon, change: "122", changeType: "increase" },
    { id: 2, name: "Avg. Open Rate", stat: "58.16%", icon: MailOpenIcon, change: "5.4%", changeType: "increase" },
    { id: 3, name: "Avg. Click Rate", stat: "24.57%", icon: CursorClickIcon, change: "3.2%", changeType: "decrease" },
];


const item = { id: 1, name: "Total Subscribers", stat: "71,897", icon: UsersIcon, change: "122", changeType: "increase" };

import { SearchIcon } from "@heroicons/react/solid";
import LogoBox from "../layout/LogoBox";
import { Cdr, NeogenScript, NeogenUser } from "../../typings/api";
import PrintPre from "../layout/print-pre";
import Avatar from "react-avatar";
import UserService from "../../services/user.service";
import { useQuery, useQueryClient } from "react-query";
import AddScriptModal from "../scripts/modals/AddScriptModal";
import { debug } from "console";
import cdrService from "../../services/cdr.service";
import ScriptFolderService from "../../services/script-folder.service";
import ScriptService from "../../services/script.service";
import AuthService from "../../services/auth.service";
import companyService from "../../services/company.service";
import userService from "../../services/user.service";
import TransactionService from "../../services/transaction.service";
import helpDocsService from "../../services/help-docs.service";
import Loader2 from "../utilities/Loader2";
import UserContext from "../../services/user-context";
import { Link } from "react-router-dom";
import ActionsPanel from "../layout/actions-panel";



const actions = [
    {
        id: 1,
        name: "Create A New Agent",
        href: "#",
        icon: "fa fa-square-plus",
        iconForeground: "text-teal-700 dark:text-teal-400 group-hover:text-white  group-hover:ring-opacity-100 ",
        iconBackground: "bg-teal-700 dark:bg-teal-800 bg-opacity-20 dark:bg-opacity-40 group-hover:bg-transparent",
        description: "Use a wizard to create your virtual agent. This is where you're most likely to want to get started",
        bg: "hover:bg-gradient-to-br hover:from-green-300 hover:via-blue-500 hover:to-purple-600 ",
    },
    {
        id: 2,
        name: "Show My Existing Agents",
        href: "/scripts",
        icon: "fa fa-user-headset",
        iconForeground: "text-indigo-700 dark:text-indigo-400  group-hover:text-white group-hover:ring-opacity-100 ",
        iconBackground: "bg-indigo-700 dark:bg-indigo-800 bg-opacity-20 dark:bg-opacity-40 group-hover:bg-transparent",
        description: "Displays a list of folders containing your existing virtual agents",
        bg: "hover:bg-gradient-to-br hover:from-green-300 hover:via-blue-500 hover:to-purple-600 ",
    },
    {
        id: 3,
        name: "Phone Numbers",
        href: "/phone-numbers",
        icon: "fa fa-phone-alt",
        iconForeground: "text-light-blue-700 dark:text-light-blue-400  group-hover:text-white group-hover:ring-opacity-100 ",
        iconBackground: "bg-light-blue-700 dark:bg-light-blue-800  bg-opacity-20 dark:bg-opacity-40 group-hover:bg-transparent",
        description: "Shows you a list of your existing phone numbers and allows you to purchase new ones",
        bg: "hover:bg-gradient-to-br hover:from-green-300 hover:via-blue-500 hover:to-purple-600 ",
    },
    {
        id: 4,
        name: "Billing",
        href: "/billing",
        icon: "fa fa-credit-card",
        iconForeground: "text-orange-700 dark:text-yellow-400  group-hover:text-white group-hover:ring-opacity-100 ",
        iconBackground: "bg-orange-700 dark:bg-yellow-900  bg-opacity-20 dark:bg-opacity-40 group-hover:bg-transparent",
        description: "See the state of your account, add funds and check on your billing history",
        bg: "hover:bg-gradient-to-br hover:from-green-300 hover:via-blue-500 hover:to-purple-600 ",
    },


];

const announcements = [
    {
        id: 1,
        title: "New AI Functionality Added",
        href: "/articles/1",
        preview:
            "Cum qui rem deleniti. Suscipit in dolor veritatis sequi aut. Vero ut earum quis deleniti. Ut a sunt eum cum ut repudiandae possimus. Nihil ex tempora neque cum consectetur dolores.",
    },
    {
        id: 2,
        title: "Holiday Routing",
        href: "/articles/2",
        preview:
            "Alias inventore ut autem optio voluptas et repellendus. Facere totam quaerat quam quo laudantium cumque eaque excepturi vel. Accusamus maxime ipsam reprehenderit rerum id repellendus rerum. Culpa cum vel natus. Est sit autem mollitia.",
    },
    {
        id: 3,
        title: "Creating Call Transfers",
        href: "/articles/3",
        preview:
            "Tenetur libero voluptatem rerum occaecati qui est molestiae exercitationem. Voluptate quisquam iure assumenda consequatur ex et recusandae. Alias consectetur voluptatibus. Accusamus a ab dicta et. Consequatur quis dignissimos voluptatem nisi.",
    },
];

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

type partOfTransaction = {
    amount: number
}

var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default function Level1New(props: Level1NewProps) {
    const [user, setUser]  = useContext(UserContext);
    const [showCreateAgent, setShowCreateAgent] = useState(false);
    const [currentCdr, setCurrentCdr] = useState<Cdr | null>(null);
    const [showingCall, setShowingCall] = useState(false);
    const [holdCalls, setHoldCalls] = useState(false);
    const [callBeingShow, setCallBeingShown] = useState("");
    const [scriptBeingShown, setScriptBeingShown] = useState("");
    const [addProgress, setAddProgress] = useState(0);
    const [deleteProgress, setDeleteProgress] = useState(0);
    const [defaultFolder, setDefaultFolder] = useState(-1);
    const [scriptToDelete, setScriptToDelete] = useState("");
    const [scriptToDuplicate, setScriptToDuplicate] = useState("");
    const [newScriptName, setNewScriptName] = useState("");
    const [newScriptDescription, setNewScriptDescription] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const [adding, setAdding] = useState(false);
    const [showAddScriptModal, setShowAddScriptModal] = useState(false);
    const cache = useQueryClient();
    const [allowedIds, setIds] = useState<number[]>([]);
    const articleQuery = useQuery(["articles"], async () => {
        let responses = await helpDocsService.getArticles();
        if (responses) {
            return responses.data;
        }
    });
    function addScript() {
        let template = scriptToDuplicate;
        console.log(template, newScriptName);
        setAdding(true);
        setNewScriptDescription("");
        setNewScriptName("");
        setShowProgress(true);

        ScriptService.duplicateScript(defaultFolder, Number(template), newScriptName, newScriptDescription, setAddProgress).then((r) => {
            console.log(r);
            // ScriptFolderEntryService.create({
            //                                     scriptId:       r.newScriptId,
            //                                     scriptFolderId: Number(folder.id)
            //                                 }).then(r2 => {
            //     console.log(r2);
            //     setAdding(false);
            cache.invalidateQueries(["scriptFolderEntries", defaultFolder]).then(() => {
                console.log("Cache Invalidated");
                setShowAddScriptModal(false);
            });
            cache.invalidateQueries(["scripts"]).then(() => {
                console.log("Cache Invalidated");
            });
            cache.invalidateQueries(["myFolderEntries"]).then(() => {
                console.log("Cache Invalidated");
            });
            cache.invalidateQueries(["userFolders"]).then(() => {
                console.log("Cache Invalidated");
            });


            // }).catch(e => {
            //     console.log(e.response);
            //     console.error(e);
            //     console.error(e.response);
            // });

        });
    }

    function compare(a: any, b: any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    // Get script templates
    const scriptTemplatesQuery = useQuery(["scriptTemplates"], async () => {
        let result = await ScriptService.getAll();
        if (result) {
            let data = result.data;
            data = data.filter((item: NeogenScript) => {
                return item.isTemplate === 1;
            });
            // console.log("templates: ", data);
            return data.sort(compare);
        }

    });

    let scriptsQuery = useQuery(["scripts"], async () => {
        const result = await ScriptFolderService.getAll();
        // console.error("Folders:",result.data);
        let ids: number[] = [];
        if (result) {
            for (let x = 0;x < result.data.length;x++) {

                let i = result.data[x];
                if (i.id) {
                    let res = await ScriptFolderService.getScriptsByFolder(i.id);
                    // console.error(res);
                    if (res) {
                        res.data?.forEach((sc:NeogenScript) => {
                            ids.push(sc.id??-1);
                        });
                    }
                    
                }
                
                
            }
            setIds(ids);
            // console.error({ids});
            const result2 = await ScriptService.getAllByIDs(ids);
            if (result2) {
                return result2.data;
            }
            // debug("Scripts: ", data, start, end);
            // return data;
        }
        
        
    });

    // const cdrQuery = useQuery(["myRecentCDRs"], async() => {
    //     let results = await cdrService.getLastThirty();
    //     if (results) {
    //         console.log(results);
    //         return results.data;
    //     }
    // });

    function showCall(cdr: Cdr) {
        setCallBeingShown(cdr.uniqueid);
        setScriptBeingShown(cdr.userfield);
        setCurrentCdr(cdr);
        setShowingCall(true);
    }
    // let user;

    if (user) {
        user.name = props.user?.name ?? "Unknown";
        // user.role = props.user?.email ?? "";
        user.id = props.user?.id ?? "";
    }
    
    let settingsQuery = useQuery(["settings"], async () => {
        const result = await UserService.getSettingsByAllUserID();
        // console.log(data);
        if (result) {
            return result.data;
        }

    });


    const balanceQuery = useQuery(["balance", user?.company], async () => {
        const result = await TransactionService.getAllMyAmounts(user.company ?? -1);
        if (result) {
            let sum = result.data.reduce(function (a: number, b: partOfTransaction) {
                return parseFloat((a + b.amount).toFixed(10));
                // return (a + b.amount).toFixed(2);
            }, 0);
            return sum;
        } else {
            return 0;
        }
    });

    const cdrsQuery = useQuery(["myAgentCalls"], async() => {
        // const filter = {
            
        //     userfield: {inq: scriptsQuery.data?.map((i:any) => {
        //         return(i.id);
        //     })}
            
            
            
        // };
        // console.log(filter);
        let response;
        // if ( await AuthService.canIAccess("SHOW_ALL_CDRS")) {
        //     response = await cdrService.getURL("cdrs/count?where=" + encodeURIComponent(JSON.stringify(filter)));
        // } else {
        //     response = await cdrService.getURL("my-cdrs/count?filter=" + encodeURIComponent(JSON.stringify(filter)));
        // }
        response = await cdrService.getCount();
        // const response = await cdrService.getURL(cdrService.endpoint+"?filter="+encodeURIComponent(JSON.stringify(filter)));
        if (response) {
            return response.data as {count: number};
        }
    }, {enabled: scriptsQuery.isSuccess});

    function getSetting(userId: string, settingName: string) {
        if (settingsQuery.isSuccess) {
            // console.warn(settingsQuery.data)
            return settingsQuery.data?.find((s: any) => s.settingName === settingName && s.userId === userId) ?? null;
        }

    }
    return (
        <>

            <main className="-mt-24 pb-8">
                <div className="max-w-3xl mx-auto px-0 sm:px-6 lg:max-w-7xl lg:px-2">
                    <h1 className="sr-only">Profile</h1>
                    {/* Main 3 column grid */}
                    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        {/* Left column */}
                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                            {/* Welcome panel */}
                            <section aria-labelledby="profile-overview-title">
                                <div className="rounded-lg bg-white bg-opacity-60 backdrop-blur-xl overflow-hidden border border-gray-200 dark:border-gray-900 shadow dark:bg-indigo-800 ">
                                    <h2 className="sr-only" id="profile-overview-title">
                                        Profile Overview
                                    </h2>
                                    <div className="bg-white bg-opacity-60 backdrop-blur-xl  p-6 dark:bg-indigo-900">
                                        <div className="sm:flex sm:items-center sm:justify-between">
                                            <div className="sm:flex sm:space-x-5">
                                                {/* <div className="hidden md:block flex-shrink-0">
                                                    <Avatar size="40px" color="transparent" className="bg-indigo-700" name={user?.name} round={true} email={user?.email} facebookId={getSetting(user?.id ?? "", "facebookId")?.settingValue} />
                                                </div> */}
                                                <div className="mt-0 md:mt-0 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                                    <p className="text-sm font-medium text-gray-500">Welcome back,</p>
                                                    <p className="text-xl font-bold text-gray-900 sm:text-2xl dark:text-gray-400">{user?.name}</p>
                                                    <p className="text-sm font-medium text-gray-500">{user?.email}</p>
                                                </div>
                                            </div>
                                            {/* <div className="mt-5 flex justify-center sm:mt-0">
                                                <button
                                                    type="button"
                                                    onClick={() => setHoldCalls(c => !c)}
                                                    className={"ring-0 flex justify-center items-center px-4 py-2 border border-gray-300 dark:border-gray-600  text-sm font-medium rounded-md text-gray-100 " + (holdCalls ? "bg-blue-700 hover:bg-green-600" : "bg-indigo-700 hover:bg-indigo-400") + "  dark:bg-gray-700 dark:hover:bg-gray-900"}
                                                >
                                                    {holdCalls ? "Resume My Calls" : "Hold My Calls"}
                                                </button>
                                            </div> */}
                                            {/* <div className="mt-5 flex justify-center sm:mt-0">
                                                <a
                                                    href="/settings"
                                                    className="flex justify-center items-center px-4 py-2 border border-gray-300 dark:border-gray-600 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-indigo-800 hover:text-white dark:bg-gray-700 dark:hover:bg-gray-900"
                                                >
                                                    Edit profile
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                    
                                </div>
                            </section>




                            <div>
                                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                    <div
                                        className="relative bg-white dark:bg-gray-800 pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                                    >
                                        <dt>
                                            <div className="absolute bg-indigo-500 rounded-md p-3">
                                                <ShoppingCartIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Balance</p>
                                        </dt>
                                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                            <p className="text-2xl font-semibold text-gray-900">
                                                
                                                {balanceQuery.isLoading?(<></>):(
                                                    <Link to="/billing">
                                                        <span className="text-gray-900 dark:text-gray-400">{formatter.format(balanceQuery.data??0)}</span>{" "}                                                        
                                                    </Link>
                                                )}    
                                                
                                            </p>
                                           
                                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 dark:bg-gray-700 ">
                                                <div className="text-sm">
                                                    <a href="/billing" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                        {" "}
                                                        View transactions
                                                    </a>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div
                                        className="relative bg-white  dark:bg-gray-800 pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                                    >
                                        <dt>
                                            <div className="absolute bg-green-500 rounded-md p-3">
                                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Agents</p>
                                        </dt>
                                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                            <p className="text-2xl font-semibold text-gray-900">
                                                
                                                {scriptsQuery.isLoading?(<></>):(
                                                    <Link to="/scripts">
                                                        <span className="text-gray-900 dark:text-gray-400">{(scriptsQuery.data?.length)}</span>{" "}
                                                        
                                                    </Link>
                                                )}
                                                
                                            </p>
                                           
                                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 dark:bg-gray-700 ">
                                                <div className="text-sm">
                                                    <a href="/scripts" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                        {" "}
                                                        View Agents
                                                    </a>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div
                                        className="relative bg-white dark:bg-gray-800 pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                                    >
                                        <dt>
                                            <div className="absolute bg-orange-500 rounded-md p-3">
                                                <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">CDRs</p>
                                        </dt>
                                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                            <p className="text-2xl font-semibold text-gray-900">
                                                
                                                {cdrsQuery.isLoading?(<></>):(
                                                    <Link to="/call-history">
                                                        <span className="text-gray-900 dark:text-gray-400">{(cdrsQuery.data?.count||0).toLocaleString()}</span>{" "}
                                                    </Link>
                                                )}
                                                
                                            </p>
                                           
                                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 dark:bg-gray-700 ">
                                                <div className="text-sm">
                                                    <a href="/call-history" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                        {" "}
                                                        View Call Detail Records
                                                    </a>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>




                            {/* Actions panel */}
                            
                            <section aria-labelledby="quick-links-title">
                                <div className="  divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-8 dark:divide-gray-800 mt-5 ">
                                    <h2 className="sr-only" id="quick-links-title">
                                        Quick links
                                    </h2>
                                    
                                    {actions.map((action, actionIdx) => (
                                        <div
                                            key={action.name}
                                            className={classNames(
                                                // actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
                                                // actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                                                // actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                                                // actionIdx === actions.length - 1 ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none" : "",
                                                action.bg,
                                                `transform hover:translate-x-1 
                                                hover:translate-y-1 hover:shadow rounded-lg relative shadow-xl group  
                                                dark:bg-gray-800 p-6 focus-within:ring-2 focus-within:ring-inset 
                                                focus-within:ring-cyan-500 bg-white hover:from-cyan-500  
                                                dark:hover:from-cyan-800 dark:hover:to-indigo-900 hover:text-white 
                                                dark:hover:bg-gray-900 transition duration-300
                                                `
                                            )}
                                        >
                                            <div>
                                                <span
                                                    className={classNames(
                                                        action.iconBackground,
                                                        action.iconForeground,
                                                        "rounded-lg inline-flex p-3 ring-1 ring-white  ring-opacity-10 dark:ring-opacity-0  "
                                                    )}
                                                >

                                                    <i className={"h-6 w-6 fa-fw  " + action.icon} aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="mt-8 hover:text-white">
                                                <h3 className="text-lg font-medium ">
                                                    {action.id === 1 ? (<>
                                                        <a href="#" className="focus:outline-none hover:text-white" onClick={() => setShowCreateAgent(true)}>
                                                            {/* Extend touch target to entire panel */}
                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                            {action.name}
                                                        </a>
                                                    </>) : (
                                                        <a href={action.href} className="focus:outline-none hover:text-white">
                                                            {/* Extend touch target to entire panel */}
                                                            <span className="absolute inset-0 hover:text-white" aria-hidden="true" />
                                                            {action.name}
                                                        </a>
                                                    )}

                                                </h3>

                                                <p className="mt-2 text-sm opacity-70 pointer-events-none ">

                                                    {action.description}

                                                </p>

                                            </div>
                                            <span
                                                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-white duration-500"
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    className="h-6 w-6 "
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                                </svg>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </div>

                        

                        {/* Right column */}
                        <div className="grid grid-cols-1 gap-4">
                            {/* Announcements */}
                            <section aria-labelledby="announcements-title">
                                <div className="rounded-lg bg-white dark:bg-gray-800 overflow-hidden shadow border-gray-200">
                                    <div className="p-6">
                                        <h2 className="text-base font-medium text-gray-900 dark:text-gray-500" id="announcements-title">
                                            Articles
                                        </h2>
                                        <div className="flow-root mt-6">
                                            <ul className="-my-5 divide-y divide-gray-200 dark:divide-gray-600">
                                                {articleQuery.isLoading ? (<><Loader2 /></>) : (
                                                    <>
                                                        {articleQuery.data?.map((announcement: any) => (
                                                            <li key={announcement.id} className="py-5">
                                                                <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                                                                    <h3 className="text-sm font-semibold text-gray-800">
                                                                        <a href={"/articles/" + announcement.id} className="hover:underline focus:outline-none">
                                                                            {/* Extend touch target to entire panel */}
                                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                                            {announcement.title}
                                                                        </a>
                                                                    </h3>
                                                                    <p className="mt-1 text-sm text-gray-600 line-clamp-2">{announcement.preview}</p>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </>
                                                )}

                                            </ul>
                                        </div>
                                        <div className="mt-6">
                                            <a
                                                href="#"
                                                className={`w-full flex justify-center items-center px-4 py-2 border 
                                                border-gray-300 dark:border-gray-600 shadow-sm text-sm font-medium rounded-md 
                                                hover:text-white text-gray-700 bg-white hover:bg-gradient-to-br 
                                                hover:from-green-300 hover:via-blue-500 hover:to-purple-600 
                                                dark:bg-gray-700  dark:hover:bg-gray-900`
                                                }
                                            >
                                                View all
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                    <div className="border-t border-gray-200 dark:border-gray-800 py-8 text-sm text-gray-500 text-center sm:text-left">
                        <span className="block sm:inline">&copy; 2022 NeoGen.AI</span>{" "}
                        <span className="block sm:inline">All rights reserved.</span>
                    </div>
                </div>
            </footer>
            <AddScriptModal
                show={showCreateAgent}
                addScript={addScript}
                showProgress={showProgress}
                adding={adding}
                folderId={defaultFolder}
                scripts={scriptTemplatesQuery.data}
                newScriptName={newScriptName}
                setNewScriptDescription={setNewScriptDescription}
                setNewScriptName={setNewScriptName}
                setScriptToDuplicate={setScriptToDuplicate}
                scriptToDuplicate={scriptToDuplicate}
                addProgress={addProgress}
                close={() => setShowCreateAgent(false)}
            />
        </>
    );
}

type Level1NewProps = {
    user: NeogenUser
}
