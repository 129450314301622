import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import calendarService from "../../services/calendar.service";
import UserContext from "../../services/user-context";
import PageDescription from "../layout/page-description";
import PrintPre from "../layout/print-pre";
import Loader2 from "../utilities/Loader2";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEvent from "./modal/add-event";
import FindSlot from "./modal/find-slot";
import ShowEvent from "./modal/show-event";

// @ts-ignore just for the moment
let allViews = Object.keys(Views).map(k => Views[k]);
const locales = {
    "en-US": enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});


export default function AICalendar() {
    const [user, setUser]  = useContext(UserContext);
    const [showAddBooking, setShowAddBooking] = useState(false);
    const [showFindSlot, setShowFindSlot] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [event, setEvent] = useState<any>();
    const eventsQuery = useQuery(["events", user.id], async() => {
        const response = await calendarService.getMy();
        if (response) {
            response.data.forEach((e: any) => {
                e.summary = decodeURIComponent(e.summary);
            });
            console.log(response);
            // @ts-ignore just for now
            return response.data as any[];
        }
    });

    function showEvent(event:any) {
        setEvent(event);
        setShowEventModal(true);
    }

    function createCalendar() {
        calendarService.createCalendar().then(r => {
            console.log(r);
        });
    }

    return (
        <>
            <PageDescription>
                <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Calendar</p>
                <p className="mb-5">Run various tests on the Calendar system</p>
                        
            </PageDescription>
            <button
                type="button"
                className="mb-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShowAddBooking(true)}
            >
                Add booking
            </button>
            <button
                type="button"
                className="ml-3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShowFindSlot(true)}
            >
                Find Slot
            </button>
            <button
                type="button"
                className="ml-3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => createCalendar()}
            >
                Create Calendar
            </button>
            <div className="grid gap-3 mt-5">
                {eventsQuery.isLoading?<Loader2 />:(
                    <div className={"bg-white border rounded-lg shadow-lg p-5 max-w-5xl"}>
                        <Calendar
                            events={eventsQuery.data}
                            views={allViews}
                            step={60}
                            showMultiDayTimes
                            style={{height: 800}}
                            titleAccessor="summary"
                            onSelectEvent={(event:any) => showEvent(event)}
                            // onSelectSlot={handleSelect}
                            // max={dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours")}
                            // defaultDate={new Date(2015, 3, 1)}
                            // components={{
                            //     timeSlotWrapper: ColoredDateCellWrapper,
                            // }}
                            localizer={localizer}
                        />
                        {/* <PrintPre> */}
                        {/* {eventsQuery.data} */}
                        {/* </PrintPre> */}
                        
                    </div>
                )}
            </div>
            <AddEvent show={showAddBooking} close={() => setShowAddBooking(false)} />
            <FindSlot show={showFindSlot} close={() => setShowFindSlot(false)} />
            <ShowEvent show={showEventModal} close={() => setShowEventModal(false)} event={event}/>
        </>
    );
}