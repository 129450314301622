/**
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/24/20, 10:39 AM
 *
 */

export default function authHeader() {
    let user   = (JSON.parse(localStorage.getItem("user")))??{token:""};
    if (!user.token) {
        user = {
            token: ""
        };
    }
    // const userId = JSON.parse(localStorage.getItem("userId"));
    // console.error(user);
    
    return {Authorization: "Bearer " + user.token};
    
}