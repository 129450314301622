import React, { useCallback, useContext } from "react";
import { JSONTree } from "react-json-tree";
import { useQuery } from "react-query";
import apiLogService from "../../../services/api-log.service";
import UserContext from "../../../services/user-context";
import { NeogenApiLog } from "../../../typings/api/neogen-api-log";
import ModalNeoGen from "../../layout/modal-neogen";
import PrintPre from "../../layout/print-pre";
import Loader2 from "../../utilities/Loader2";
import ReactJson from "react-json-view";

function compare(a: NeogenApiLog, b: NeogenApiLog) {
    if (a.eventDatetime === null || b.eventDatetime === null) {
        return 0;
    }
    if (a.eventDatetime < b.eventDatetime) {
        return 1;
    }
    if (a.eventDatetime > b.eventDatetime) {
        return -1;
    }
    return 0;
}

const people = [
    { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    // More people...
];

const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
};

export default function ApiLogModal(props: ApiLogModalProps) {
    const [user, setUser] = useContext(UserContext);
    const getDarkMode = useCallback(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            return false;
        }


    }, []);

    const apiLogQuery = useQuery(["apiLogs", props.APIId ?? -1], async () => {

        const response = await apiLogService.getByID(props.APIId);
        if (response) {
            response.data = response.data.map((e:NeogenApiLog) => {
                try {
                    e.sentParsed = JSON.parse(e.sent??"{}");
                    delete(e.sentParsed?.token);
                } catch (e) {
                    console.error(e);
                }
                try {
                    const parsed = JSON.parse(e.response??"{}");
                    e.response = parsed;
                    e.responseIsJSON = true;
                } catch (er) {
                    console.error(er);
                    e.responseIsJSON = false;
                }
                
                return e;
            });
            return response.data.sort(compare);
        }
    });

    return <div className="">
        <ModalNeoGen size="xl" title={"API Logs"} show={props.show} close={() => props.close()} showOk={false}>
            {apiLogQuery.isLoading ? <Loader2 /> : (
                <>

                    <div className="mt-8 flex flex-col ">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-scroll max-h-[64rem]">
                                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900">
                                        <thead className="bg-gray-50 dark:bg-gray-800">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-gray-400 ">
                                                    Date
                                                </th>
                                                
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Remote Response
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-400 ">
                                                    Shim API Received
                                                </th>
                                               
                                                
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                                            {apiLogQuery.data?.map((apiLogEntry) => (
                                                <tr key={apiLogEntry.id}>
                                                    
                                                    <td className="whitespace-nowrap pl-2 pr-0 py-4 text-sm text-gray-500 m-0 dark:text-gray-400 " >
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={() => {
                                                                alert(apiLogEntry.uniqueid);
                                                            }
                                                                    
                                                            }
                                                        >
                                                            {new Date(apiLogEntry.eventDatetime??"").toLocaleString("en", {
                                                                weekday: "short",
                                                                year: "numeric",
                                                                month: "2-digit",
                                                                day: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit"
                                                            }) }
                                                        </button>
                                                    </td>
                                                    <td className="break-all  px-3 py-4 text-sm text-gray-500 max-w-lg dark:text-gray-400 " title={(apiLogEntry.response??"")}>
                                                        {apiLogEntry.responseIsJSON===true?<>
                                                            <ReactJson collapsed={false} displayDataTypes={false} name={false} displayObjectSize={false} src={apiLogEntry.response}  theme={getDarkMode()?"solarized":"summerfruit:inverted"} />
                                                        </>:<>
                                                            {(apiLogEntry.response??"").trim().substring(0,250)}...
                                                        </>}
                                                        
                                                    </td>
                                                    <td className="break-all  px-3 py-4 text-sm text-gray-500  max-w-lg dark:text-gray-400 " title={JSON.stringify(apiLogEntry.sentParsed??"")}><ReactJson collapsed={false} displayDataTypes={false}  theme={getDarkMode()?"solarized":"summerfruit:inverted"} name={false} displayObjectSize={false} src={apiLogEntry.sentParsed} /></td>
                                                    {/* <td className=" px-3 py-4 text-sm text-gray-500">
                                                        {apiLogEntry.uniqueid}
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="my-5 grid grid-cols-2 gap-5">


            </div>

        </ModalNeoGen>
    </div>;
}

type ApiLogModalProps = {
    show: boolean,
    close: () => void,
    // isEdit: boolean,
    APIId: number,
}