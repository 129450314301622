import React, { useContext } from "react";
import { useQuery } from "react-query";
import integrationsTypesService from "../../services/integrations-types.service";
import { NeoGenIntegrationTypes } from "../../typings/api/neogen-integration-types";
import Main from "../layout/Main";
import PrintPre from "../layout/print-pre";
import Title from "../layout/Title";
import "./integration-types.css";
import Pizzly from "pizzly-js";
import { useState } from "react";
import { NeoGenIntegration, NeoGenIntegrationWrapper } from "../../typings/api/neogen-integration";
import UserContext from "../../services/user-context";
import integrationsService from "../../services/integrations-service";
import ConnectedIntegration from "./connected-integration";
import ButtonNeoGen from "../layout/button-neogen";


type project = {
    gid: string,
    name: string,
    resource_type: string
}

export default function IntegrationTypes(props: IntegrationTypeProps) {
    const [user,setUser]  = useContext(UserContext);
    const [contents, setContents] = useState<project[]>([]);
    const [connecting, setConnecting] = useState(false);
    const [isError, setIsError] = useState(false);
    // function test() {
    //     myAPI
    //         .connect()
    //         .then(({ authId }) => {
    //             console.log("Successfully connected!", authId);
    //             myAPI
    //                 .auth(authId) // Replace with the authId previously obtained
    //                 .get("/projects") // Replace with a valid endpoint of the API
    //                 .then((response)=> response.json())
    //                 .then((json) =>  {
    //                     console.log(json);
    //                     setContents(json.data);
    //                 })
    //                 .catch(console.error);
    //         })
    //         .catch(console.error);
    // }
    const integrationTypesQuery = useQuery(["integrationTypes"], async() => {
        const results = await integrationsTypesService.getAll();
        if (results) {
            return results.data;
        }
    });
    const integrationsQuery = useQuery(["integrations"], async() => {
        const results = await integrationsService.getAll();
        if (results) {
            console.log(results.data);
            return results.data;
        }
    });
    
    function connect(i: NeoGenIntegrationTypes) {
        setConnecting(true);
        const pizzly = new Pizzly({ host: "https://pizzly.neogen.ai", publishableKey: "d3dk78fgyhskjhdfksjdff7kvledifkmal" }); // Initialize Pizzly
        const myAPI = pizzly.integration(i.slug); // Replace with the API slugname
        console.log(i);
        if (i.id === 3) { // ZenDesk Chat
            myAPI
                .connect({params: {subdomain: "neogenai"}})
                .then(({ authId }) => {
                    console.log("Successfully connected!", authId);
                    let auth: NeoGenIntegration = {
                        userId: user.id,
                        authId: authId,
                        integrationId: i.id
                    };
                    setConnecting(false);
                    integrationsService.create(auth).then(r => {
                        console.log(r);
                    });
                }).catch(e => {
                    setConnecting(false);
                    console.error(e);
                });
        } else {
            myAPI
                .connect()
                .then(({ authId }) => {
                    console.log("Successfully connected!", authId);
                    let auth: NeoGenIntegration = {
                        userId: user.id,
                        authId: authId,
                        integrationId: i.id
                    };
                    setConnecting(false);
                    integrationsService.create(auth).then(r => {
                        console.log(r);
                    });
                }).catch(e => {
                    setConnecting(false);
                    console.error(e);
                });
        }
        
        
    }
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            {/* <Title title="Integration Types" /> */}
            {!integrationTypesQuery.isSuccess||!integrationsQuery.isSuccess?(<>Loading</>):(<>
                {integrationTypesQuery.data?.map((i: NeoGenIntegrationTypes) => {
                    return (
                        <div className="col-span-4 p-3  pt-2 m-5 shadow-lg border-gray-300 dark:bg-gray-700 rounded-xl" key={i.id}>
                            {/* <div className="card"> */}
                            {/* <div className="card-header pb-1"> */}
                            <a href={i.url}><div className="rounded-lg mb-0 mt-2 p-4 text-center bg-white dark:shadow-lg "><img src={i.icon}  style={{ alignSelf: "right", height: "30px" }} className="mx-auto"/></div></a><br />
                            {/* </div> */}
                            {/* <div className="card-body text-center"> */}
                                
                                    
                            <p className="pl-0 text-left"> {i.description}</p>

                            {integrationsQuery.data?.find((ints: NeoGenIntegration) => {
                                return ints.integrationId === i.id;
                            }) !== undefined?(
                                    <>
                                        <ConnectedIntegration integrationType={i} integration={integrationsQuery.data?.find((ints: NeoGenIntegration) => ints.integrationId === i.id)} addIntegration={props.addIntegration} removeIntegration={props.removeIntegration} services={props.services}/>
                                            
                                    </>
                                ):(
                                    <>
                                        <ButtonNeoGen block={true} disabled={connecting} className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg mb-2 mt-5 " onClick={() => connect(i)}>Connect To {i.name}</ButtonNeoGen>
                                    </>)}
                                
                                
                            {/* <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg mb-5" onClick={() => test()}>Get My Projects</button> */}
                            <div>{contents.length > 0 && contents.map((c:project) => {
                                return (
                                    <div key={c.gid}>
                                        {c.name}
                                    </div>
                                );
                            })
                            }</div>
                        </div>
                    // </div>
                        // </div>
                    );
                })}
            </>)}
        </div>
    );
}
type IntegrationTypeProps = {
    addIntegration: any,
    removeIntegration: any,
    services: NeoGenIntegrationWrapper[]
}