import React from "react";
import { useQuery } from "react-query";
import cdrService from "../../../services/cdr.service";
import scriptService from "../../../services/script.service";
import { Cdr } from "../../../typings/api";
import ModalNeoGen from "../../layout/modal-neogen";
import PrintPre from "../../layout/print-pre";
import Loader2 from "../../utilities/Loader2";

const people = [
    { name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" },
    // More people...
];

export default function ShowCDR(props: ShowCDRProps) {
    const cdrQuery = useQuery(["cdr", "uuid", props.uuid], async () => {
        const result = await cdrService.getByCTC(props.uuid);
        if (result) {
            return result.data;
        }
    });
    let scriptQuery = useQuery(["scripts"], async () => {
        const result = await scriptService.getAll();
        // console.error("Folders:",result.data);
        // let ids: number[] = [];
        if (result) {
            return result.data;
        }
    });
    return (
        <>
            <ModalNeoGen title="CDR" show={props.show} close={() => props.close()} size="lg" showOk={false}>
                {/* {props.uuid} */}
                <>
                    {cdrQuery.isLoading ||scriptQuery.isLoading? <Loader2 /> : (
                        <>
                            {/* <PrintPre>
                                {cdrQuery.data}
                            </PrintPre> */}
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                            Call Date
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Duration
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Agent
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Source
                                                        </th>
                                                        
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Disposition
                                                        </th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {cdrQuery.data?.map((cdr:Cdr) => (
                                                        <tr key={cdr.id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {cdr.calldate}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cdr.roundedBillsec}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{scriptQuery.data?.find(s => s.id === Number(cdr.userfield))?.name}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cdr.src}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cdr.disposition}</td>
                                                            
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            </ModalNeoGen>
        </>
    );
}

type ShowCDRProps = {
    show: boolean,
    close: () => void,
    uuid: string,
}