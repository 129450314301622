// import { Calendar } from 'react-date-range';
/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosResponse }         from "axios";
import ducklingService from "./duckling.service";
import hawkingService from "./hawking.service";
// import nlpAuthHeader from "./nlp-auth-header";
// const debug = require("debug")("NeoGen:NLPService");
// const url = "https://cal.neogen.ai";
const URL = process.env.REACT_APP_CALENDAR??"https://cal.neogen.ai";
 
class CalendarService  {
 
    async getMy():Promise<AxiosResponse> {
        let user   = JSON.parse(localStorage.getItem("user")??"");
        return axios.post(URL+"/events", {token: user.token});
 
    }
    async addEvent(start:Date, end: Date, status: string, summary: string, location: string):Promise<any> {
        let user   = JSON.parse(localStorage.getItem("user")??"");
        return axios.post(URL+"/create-event", {token: user.token, uid: user.id, start: start, end: end, status: status, summary: summary, location: location});
    }
    async createCalendar():Promise<any> {
        let user   = JSON.parse(localStorage.getItem("user")??"");
        return axios.post(URL+"/create-cal", {token: user.token, uid: user.id});
    }
    async deleteEvent(event: any):Promise<any> {
        const start = event.start;
        const end = event.end;
        const summary = event.summary;

        const parts = event.href.split("/");
        const uid = parts[parts.length-1];
        // alert(uid);
        let user   = JSON.parse(localStorage.getItem("user")??"");
        const data = {data: {token: user.token, uid: user.id, id: uid, start: start, end: end, summary: summary}};
        console.log(data);
        return axios.delete(URL+"/event", data);
    }
    async findSlot(search: string) {

        let user   = JSON.parse(localStorage.getItem("user")??"");

        // First call out to the hawking server to get an array
        let r = await ducklingService.recognize(search);
        console.log(r);
        return axios.post(URL+"/find-slot-duckling", {token: user.token, uid: user.id, duckling: r.data});
        // Next call the calendar service to find entries    
        // });

        

    }
 
} 
 
export default new CalendarService();