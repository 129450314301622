import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import onboardingStageService from "../../../../services/onboarding-stage.service";
import InputNeoGenControlled from "../../../layout/input-neogen-controlled";
import ModalNeoGen from "../../../layout/modal-neogen";
import TextAreaNeoGen from "../../../layout/text-area-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-neogen-controlled";
import Loader2 from "../../../utilities/Loader2";

export function AddStage(props:AddStageProps) {
    const [name, setName] = useState<string>("");
    const [order,   setOrder] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const cache = useQueryClient();
    const stageQuery = useQuery(["onboarding-stages"], async () => {
        const response = await onboardingStageService.getAll();
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        if (props.isEdit && stageQuery.isSuccess) {
            const stage = stageQuery.data?.find((stage:any) => stage.id === props.id);
            if (stage) {
                setName(stage.name);
                setOrder(stage.order.toString());
                setDescription(stage.description);
            }
        }
    }, [props.id, props.isEdit, stageQuery.data, stageQuery.isSuccess]);
    async function onSubmit() {
        console.log("submit");
        const fields = {
            name,
            order: Number(order),
            description,
        };
        let response;
        if (props.isEdit) {
            response = await onboardingStageService.update(props.id, fields);
            
        }  else {
            response = await onboardingStageService.create(fields);
        }
        

        console.log(response);
        if (response) {
            props.close();
            Swal.fire({
                title: "Success",
                text: !props.isEdit?"Stage added successfully":"Stage updated successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                cache.invalidateQueries(["onboarding-stages"]);
            });
    
        } else {
            Swal.fire({
                title: "Error",
                text: !props.isEdit?"Error adding stage":"Error updating stage",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
        
    }
    return (
        <>
            <ModalNeoGen title={props.isEdit?"Edit Stage":"Add Stage"} show={props.show} close={props.close } size="md" okAction={onSubmit} okText={props.isEdit?"Save Stage":"Add Stage"}>
                {stageQuery.isLoading ? <Loader2 /> : (
                    <>
                        
                        <InputNeoGenControlled label="Stage Name" name="name" value={name} setValue={setName}/>
                        <TextAreaNeoGenControlled label="Description" name="description" value={description} setValue={setDescription}/>
                        <InputNeoGenControlled label="Order" name="order" value={order} setValue={setOrder} />  
                    </>
                )}
                              
            </ModalNeoGen>
        </>
    );
}

type AddStageProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    id: number;
}