/* eslint-disable @typescript-eslint/ban-ts-comment */

// import { useContext } from "react";
// import { NeogenUser } from "../typings/api";
import { AxiosResponse } from "axios";
import { NeogenTransferDestinations } from "../typings/api/neogen-transfer-destinations";
import APIService from "./API.service";
// @ts-ignore
import AuthService from "./auth.service";
// import UserContext from "./user-context";
// import usersService from "./users.service";
class TransferDestinationService extends APIService<NeogenTransferDestinations> {
    
    constructor() {
        super("neogen-transfer-destinations");
    }
    async getAll():Promise<void|AxiosResponse<NeogenTransferDestinations[]>> {
        if ( await AuthService.canIAccess("SEE_ALL_TRANSFER_DESTINATIONS")) {
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset
            const user = AuthService.getCurrentUser();
            // let user = {company:-1};
            // const user:NeogenUser  = useContext(UserContext);
            const filter = {
                where: {
                    owner: user.user.company??-1
                }
            };
            // console.warn(filter);
            return super.getURL("neogen-transfer-destinations?filter="+encodeURIComponent(JSON.stringify(filter)));

            // return [];
        }
 
    }
}

export default new TransferDestinationService();