import EventEmitter from "events";
import signalingChannel from "./signalling-channel.service";
 
class WebRTCService extends EventEmitter{
    hangup(setPc: any, localStream?: MediaStream, pc?:RTCPeerConnection):boolean {
        console.info("Ending call");
        if (localStream && pc) {
            localStream.getTracks().forEach(track => track.stop());
            pc.close();
            setPc(undefined);
            signalingChannel.send({type: "hangup"});
            return true;
        } 
        return false;
    }
    iceCallback(event:any):void {
        signalingChannel.send({type: "IceCandidate",candidate: event.candidate});
    }

    gotRemoteStream(e: any, setAudioSrc:any) {
        const remoteStream = new MediaStream();         
        remoteStream.addTrack(e.track);
        setAudioSrc( remoteStream);
    }
   
    /**
     * @param  {number} script - The script id you would like to call
     * @param  {(ls:MediaStream)=>void} setLocalStream - A function to save the local stream to a state variable
     * @param  {(pc:RTCPeerConnection)=>void} setPc - Saves the peer connection to a state variable
     * @param  {(state:any)=>void} setState - A useState array containing state changes over time
     * @param  {(final:boolean)=>void} setFinal - A boolean useState that stores whether the transcription is a final result (or interim)
     * @param  {(transcript:string)=>void} setTranscript - A string useState that stores the latest transcription
     * @param  {(remoteStream:MediaStream|null)=>void} setAudioSrc - A function that takes a mediaStream you can pass to an Audio.src
     */
    async makeCall(script:number, setLocalStream:(ls:MediaStream) => void, setPc:(pc:RTCPeerConnection)=> void, setState:(state:any) => void, setFinal:(final:boolean) => void, setTranscript:(transcript:string) => void, setAudioSrc: (remoteStream: MediaStream | null) => void) {
        signalingChannel.connect();
        const ls = await navigator.mediaDevices.getUserMedia({ audio: true, video:false});
        setLocalStream(ls);
        if (ls) {
            const peerConnection = new RTCPeerConnection({
                "iceServers": [{
                    urls: "turn:turn.neogen.ai:5349",
                    username: "guest",
                    credential: "starsock"
                }], "iceTransportPolicy": "relay",
                // sdpSemantics: "unified-plan"
            } );
            setPc(peerConnection);
            ls.getTracks().forEach(track => {
                peerConnection.addTrack(track, ls);
            });

            peerConnection.addEventListener("connectionstatechange", event => {
                console.log(peerConnection.connectionState);
                if (peerConnection.connectionState === "connected") {
                    // Peers connected!
                    console.log("CONNECTED");

                }
            });

            peerConnection.onicecandidate = e => this.iceCallback( e);
            signalingChannel.on("message", async message => {
                console.log(message.data);
                if (message.data ) {
                    let stringMessage = message.data.toString();
                    if (stringMessage.substring(0,4) === "GUI:") {
                        let str = stringMessage.substring(4);
                        let parsed = JSON.parse(str);                    
                        this.emit("gui", parsed);
                    }
                    if (stringMessage.substring(0,5) === "STATE") {
                        let str = stringMessage.substring(6);
                        let parsed = JSON.parse(str);
                        parsed.time = Date.now();
                        
                        setState((s:any) => [parsed, ...s]);
                        this.emit("state", parsed);
                    }
                    if (stringMessage.substring(0,17) === "SpeechRecognition") {
                        let str = stringMessage.substring(18);
                        let parsed = JSON.parse(str);
                        console.log(parsed);
                        console.error(parsed.results[0]);
                        if (parsed.results[0].isFinal) {
                            console.error(parsed.results[0].alternatives[0]);
                            setFinal(true);
                            setTranscript(parsed.results[0].alternatives[0].transcript);
                        } else {
                            setFinal(false);
                            setTranscript(parsed.results[0].alternatives[0].transcript);
                        }
                    }
                    if (stringMessage.substring(0,9) === "Candidate") {
                        let candidateString = stringMessage.substring(10);
                        let candidate = JSON.parse(candidateString);
                        console.warn(candidate.candidate);
                        if (peerConnection.signalingState !== "closed") {
                            peerConnection.addIceCandidate(candidate);
                            console.log("Added remote candidate");
                        }
                    }
                    if (stringMessage.substring(0,10) === "TRANSITION") {
                        let str = stringMessage.substring(11);
                        let json = JSON.parse(str);
                        this.emit("Transition", json);
                    }
                }
    
            });
            signalingChannel.on("answer", async message => {
                console.log({answer: message});
                if (peerConnection.signalingState !== "closed") {
                    const remoteDesc = new RTCSessionDescription(message);
                    await peerConnection.setRemoteDescription(remoteDesc);
                }
                
            });
            signalingChannel.on("candidate", async message => {
                console.log({candidate: message});
    
                try {
                    if (peerConnection.signalingState !== "closed") {
                        await peerConnection.addIceCandidate(message);
                        console.log("Candidate Added");
                    }
                } catch (e) {
                    console.error(e);
                    console.error("Error adding received ice candidate", e);
                }
    
            });
            peerConnection.ontrack = (e) => {
                this.gotRemoteStream(e, setAudioSrc);
            };

            const offerOptions = {offerToReceiveAudio: true};
            const offer = await peerConnection.createOffer(offerOptions);


            await peerConnection.setLocalDescription(offer);
            signalingChannel.send(
                {type: "Initial",
                    scriptId: script,
                    customerId: 1,
                    languageId: 1,
                    bluemixOverride: "google",
                    channel: {
                        id: "randomUUID()",
                        name: "test",
                        language: "en",
                        caller: {
                            name: "testname",
                            number: "12345"
                        },
                        dialplan: {
                            exten: "14072171259"
                        }
                    },
        
                });
            signalingChannel.send(
                {type: "WebRTC",
                    "offer": offer,
                    channel: {
                        id: "randomUUID()",
                        name: "test",
                        language: "en",
                        caller: {
                            name: "testname",
                            number: "12345"
                        },
                        dialplan: {
                            exten: "12345"
                        }
                    },
        
                });
        }
        
        
    }
}
 
export default new WebRTCService();