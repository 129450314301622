/*
 *
 * 
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/9/20, 8:03 AM
 *
 */

export const darkTheme = {
    "body": {
        // "fontFamily": "'Montserrat', sans-serif",
        "background": "#1d222f !important",
        "color": "#a3a8b0"
    },
    "card": {
        "boxShadow": "0 0 0 #112 !important"
    },
    "text": "#aaa",
    "boxShadow": "0 0 55px #111122 !important",
    "border": "1px solid #555",
};

export const lightTheme = {
    "body": {
        // "fontFamily": "'Montserrat', sans-serif",
        "background": "#F8FAF9  !important",
        "color": "#333",
    },
    "card": {
        "boxShadow": "10px 10px 25px #ececed"
    },
    "text": "#444",
    "boxShadow": "10px 10px 25px #ececec",
    "border": "1px solid #ccc",
};